import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faCashRegister, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";


function Master_individual_profile(props) {

    // console.log("Props is ", props);

    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [individualData, setIndividualData] = useState([]);
    const [userRight, setUserRight] = useState([]);
    const [code, setCode] = useState(0);
    const [filterName, setFilterName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthDate, setBirthDate] = useState(new Date());
    const [gender, setGender] = useState("M");
    const [maiden, setMaiden] = useState("");
    const [nationality, setNationality] = useState(null);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [district, setDistrict] = useState(null);
    const [city, setCity] = useState(null);
    const [pin, setPin] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [pan, setPan] = useState("");
    const [gstin, setGstin] = useState("");
    const [risk, setRisk] = useState({ value: 1, label: "LOW" });
    const [riskData, setRiskData] = useState([]);
    const [bankName, setBankName] = useState("");
    const [bankAcc, setBankAcc] = useState("");
    const [sourceFund, setSourceFund] = useState("");
    const [modePayment, setModePayment] = useState("");
    const [review, setReview] = useState(new Date());
    const [remark, setRemark] = useState("");
    const [socialFin, setSocialFin] = useState("");
    const [acRemark, setAcRemark] = useState("");
    const [idType, setIdType] = useState({ value: 0, label: "Select" });
    const [idTypeData, setIdTypeData] = useState([]);
    const [countryIssue, setCountryIssue] = useState(null);
    const [profileId, setProfileId] = useState(0);
    const [idNumber, setIdNumber] = useState("");
    const [issueBy, setIssueBy] = useState("");
    const [issuePlace, setIssuePlace] = useState("");
    const [issueDate, setIssueDate] = useState(new Date());
    const [showAddNewBtn, setShowAddNewBtn] = useState(true);
    const [showFormBtn, setShowFormBtn] = useState(false);
    const [formMode, setFormMode] = useState('Q'); // Q A E 
    const [idData, setIDData] = useState([]);
    const [expDate, setExpDate] = useState(new Date());
    const [randNum, setRandNum] = useState("");
    const [idValue, setIdValue] = useState("");
    const [filterType, setFilterType] = useState("");
    const [limit, setLimit] = useState(10);
    const [id, setId] = useState("");

    const [cntIssueName, setCntIssueName] = useState("");
    const [cntCode, setCntCode] = useState(0);
    const [cntIssueCode, setCntIssueCode] = useState(0);
    const [natCode, setNatCode] = useState(0);
    const [stateCode, setStateCode] = useState(0);
    const [distCode, setDistCode] = useState(0);
    const [cityCode, setCityCode] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [showModalEx, setShowModalEx] = useState(false);

    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    const [radioType, setRadioType] = useState('CW');
    const [radioTypeFilter, setRadioTypeFilter] = useState('CD');
    const [fromNumber, setFromNumber] = useState(0);
    const [toNumber, setToNumber] = useState(0);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [currDate, setCurrDate] = useState(new Date());
    const [counter, setCounter] = useState(0);
    const [idMaxSrno, setIdMaxSrno] = useState(0);
    const [cntIssueDisable, setCntIssueDisable] = useState(false);
    const [individualSearch, setIndividualSearch] = useState(null);
    const [validated, setValidated] = useState(false);
    const [idTypeValue, setIdTypeValue] = useState("");
    const [tranAuditTrail, setTranAuditTrail] = useState([]);


    const handlePurchase = (code) => {
        sessionStorage.setItem('values', JSON.stringify({ code: code, md_type: "PURCHASE" }));
        navigate('/purchase-from-public');
    };

    const handleSaleBtn = (code) => {
        sessionStorage.setItem('values', JSON.stringify({ code: code, md_type: "SALE" }));
        navigate('/sale-public');
    };

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'INDIVIDUALPROFILE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });

            Common.callApi(Common.apiRisk, [sid, 'getRisk'], function (result) {
                setRiskData(JSON.parse(result));
                // console.log(JSON.parse(result));
            });

            Common.callApi(Common.apiIdtype, [sid, 'getIdType'], function (result) {
                setIdTypeData(JSON.parse(result));
                console.log(JSON.parse(result));
            });

            if (props.value === "FABOOK")
                addNew();
            else if (props.value === "TRAN_PP" || props.value === "TRAN_SP" || props.value === "TRAN_VTM_ENCASHMENT") {
                if (props.reqObj.scrIndividual === "E") {
                    const obj = { individualsrno: props.reqObj.nIndivualSrno }
                    Common.callApi(Common.apiIndividualProfile, [sid, "individualfrompicklist", JSON.stringify(obj)], result => {
                        let resp = JSON.parse(result);
                        handleIndiviualSearchChange(resp);
                    });
                } else
                    addNew();
            }

            // Get Session Values--------------------------------------
            const sessionValues = sessionStorage.getItem('flag');
            if (sessionValues === '1') {
                showList();
                sessionStorage.removeItem('flag');
            }
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleCountryValue = (value) => {
        setCountry(value);
        console.log(value);
        const cnt = value.id.split('^');
        setCntCode(cnt[1]);
        setState(null);
        setDistrict(null);
        setCity(null);
    }

    const handleNationalityValue = (value) => {
        setNationality(value);
        const nat = value.id.split('^');
        setNatCode(nat[1]);
    }

    const handleStateValue = (value) => {
        setState(value);
        const stt = value.id.split('^');
        setStateCode(stt[1]);
        setDistrict(null);
        setCity(null);
    }

    const handleDistrictValue = (value) => {
        setDistrict(value);
        const dist = value.id.split('^');
        setDistCode(dist[1]);
        setCity(null);
    }

    const handleCityValue = (value) => {
        setCity(value);
        const ct = value.id.split('^');
        setCityCode(ct[1]);
    }

    const handleIdType = (v) => {
        // console.log(v);
        setIdType(v);
        var id = v.value;
        var type = id.split("^");
        setIdTypeValue(type[1]);
        setIdValue(type[2]);
        setExpDate(new Date());
        console.log("array 2:", type[2]);
        if (type[1] * 1 === 4) {
            setCountryIssue({ id: "^1^INDIA^INDIA^", label: "1 INDIA INDIA" });
            setCntIssueDisable(true);
        } else {
            setCntIssueDisable(false);
        }
    }

    const handleCountryIssueValue = (value) => {
        setCountryIssue(value);
        const issueCnt = value.id.split('^');
        setCntIssueCode(issueCnt[1]);
        setCntIssueName(issueCnt[2]);
    }

    const showList = () => {
        $(".loader").show();
        setIndividualData([]);
        setCounter(counter + 1);
        const obj = {
            id: id,
            code: code,
            limit: limit,
            name: filterName,
            filterType: filterType,
        }
        Common.callApi(Common.apiIndividualProfile, [sid, 'getList', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setIndividualData(resp);
            setCode('');
            setFilterName("");
            setLimit(10);
            setId("");
            setCounter(counter + 1);
            $(".loader").hide();
        });
    }

    const addNew = () => {
        $(".loader").show();
        setRandNum(Common.getRandomString(19));
        setCode(0);
        // setActivityLog([]);
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setBirthDate(new Date());
        setGender("M");
        setMaiden("");

        setNatCode(0);
        setCountry(null);
        setNationality(null);
        setState(null);
        setCity(null);
        setDistrict(null);
        setRisk({ value: 1, label: "LOW" });

        setPin("");
        setAddress("");
        setMobile("");
        setEmail("");
        setPan("");
        setGstin("");
        setBankName("");
        setBankAcc("");
        setSourceFund("");
        setModePayment("");
        setReview(new Date());
        setRemark("");
        setSocialFin("");

        setIdType({ value: 0, label: "Select" });
        setCountryIssue(null);
        setIdNumber("");
        setIssueBy("");
        setIssuePlace("");
        setIssueDate(new Date());

        setFormMode('A');
        setShowAddNewBtn(false);
        setShowFormBtn(true);
        setCounter(0);

        setIDData([]);
        $(".loader").hide();
    }

    function isPassportAdded() {
        const status = idData.some((data) => data.id_desc === "PASSPORT");
        console.log("status", status);
        return status;
    }

    const saveData = () => {
        console.log("idData", idData);
        $(".loader").show();
        var msg = [], i = 0;
        if ((firstName === "" || country == null || nationality === null)
            || (natCode == 1 && (mobile === "" || email === ""))
            || (cntCode == 1 && (state === null || district === null))
            || ((props.value === "TRAN_PP") && idData.length < 1)
            || ((props.value === "TRAN_SP" || props.value === "TRAN_VTM_ENCASHMENT") && !isPassportAdded())
            || (props.value === "TRAN_SP" && !pan.trim())
        ) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (firstName === '' ? "First Name." : '');
            msg[i++] = (nationality === null ? "Nationality." : '');
            msg[i++] = (country === null ? "Country." : '');
            if (cntCode == 1 && (state === null || district === null)) {
                msg[i++] = (state === null ? "State." : '');
                msg[i++] = (district === null ? "District." : '');
            }
            if (props.value === "TRAN_SP" && !pan) msg[i++] = (!pan ? " PAN Number." : "");
            if ((natCode == 1 && (mobile === "" || email === ""))) {
                msg[i++] = (mobile === "" ? "Mobile." : '');
                msg[i++] = (email === "" ? "Email." : '');
            }
            if (props.value === "TRAN_PP" && idData.length < 1) msg[i++] = (idData.length < 1 ? " Add atleast one ID." : "");
            if ((props.value === "TRAN_SP" || props.value === "TRAN_VTM_ENCASHMENT") && !(isPassportAdded())) msg[i++] = (!isPassportAdded() ? "Please add Passport." : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
            $(".loader").hide();
        } else if (formMode === 'E' && acRemark === '') {
            setShowModal(true);
            $(".loader").hide();
        } else {
            const gstinPan = gstin.slice(2, 12);
            if (pan !== "" && gstin !== "" && gstinPan !== pan) {
                setMsgText("Invalid Gstin.");
                setMsgBox(true);
                $(".loader").hide();
            } else {
                var obj = {
                    code: code,
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    birthDate: Common.dateYMD(birthDate),
                    gender: gender,
                    maiden: maiden,

                    branch: 0,
                    activitytype: code * 1 > 0 ? 'MODIFY' : 'CREATE',
                    trancategory: 'M',
                    trantype: 'MINDP',
                    trannumber: code,
                    subtranno: 0,
                    remark: acRemark,

                    country: cntCode,
                    nationality: natCode,
                    state: stateCode,
                    city: cityCode,
                    district: distCode,
                    risk: risk.value,

                    pin: pin,
                    address: address,
                    mobile: mobile,
                    email: email,
                    pan: pan,
                    gstin: gstin,
                    bankName: bankName,
                    bankAcc: bankAcc,
                    sourceFund: sourceFund,
                    modePayment: modePayment,
                    review: Common.dateYMD(review),
                    remark_indpro: remark,
                    socialFin: socialFin,

                    profileId: profileId,
                    idType: idType.value,
                    countryIssue: cntIssueCode,
                    idNumber: idNumber,
                    issueBy: issueBy,
                    issuePlace: issuePlace,
                    issueDate: Common.dateYMD(issueDate),
                    randNum: randNum,

                    id: id,
                    limit: limit,
                    name: firstName,
                    filterType: filterType,
                    idData: idData,
                    routePage: props.value ? props.value : ''

                };
                Common.callApi(Common.apiIndividualProfile, [sid, 'savelist', code, JSON.stringify(obj), JSON.stringify(tranAuditTrail)], (result) => {
                    let resp = JSON.parse(result);
                    if (resp.err === "") {
                        if (props.value === "TRAN_PP" || props.value === "TRAN_SP" || props.value === "FABOOK" || props.value === "TRAN_VTM_ENCASHMENT") {
                            props.hideIndividualProfile(false, resp.srno);
                            $(".loader").hide();
                        } else {
                            setShowFormBtn(false);
                            setShowAddNewBtn(true);
                            setAcRemark("");
                            setIndividualData(resp.data);
                            $(".loader").hide();
                            setAcRemark('');
                        }
                        setTranAuditTrail([]);
                    } else {
                        setMsgText(resp.err);
                        setMsgBox(true);
                        $(".loader").hide();
                    }
                });
            }
        }
    }

    const cancelConfirm = () => {
        if (formMode === "A" || formMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        if (props.value === "TRAN_PP" || props.value === "TRAN_SP" || props.value === "FABOOK" || props.value === "TRAN_VTM_ENCASHMENT") {
            props.hideIndividualProfile(false, 0);
        } else {
            setShowFormBtn(false);
            setShowAddNewBtn(true);
        }
    }

    const fillEditForm = (code) => {
        $(".loader").show();
        setFormMode('Q');
        setShowFormBtn(true);
        setShowAddNewBtn(false);
        // setActivityLog([]);
        Common.callApi(Common.apiIndividualProfile, [sid, 'editList', code], (result) => {
            let resp = JSON.parse(result);
            setCode(resp.code);
            setFirstName(resp.firstName);
            setMiddleName(resp.middleName);
            setLastName(resp.lastName);
            setBirthDate(new Date(resp.birthDate));
            setGender(resp.gender);

            setNationality({ value: resp.nationality, label: resp.cnt_nat })
            setNatCode(resp.nationality);
            setMaiden(resp.maiden);

            setCountry({ value: resp.country, label: resp.cnt_nm });
            setState({ value: resp.state, label: resp.st_nm });
            setCity({ value: resp.city, label: resp.ct_nm });
            setDistrict({ value: resp.district, label: resp.district_nm });

            setCntCode(resp.country);
            setStateCode(resp.state);
            setCityCode(resp.city);
            setDistCode(resp.district);

            setPin(resp.pin);
            setAddress(resp.address);
            setMobile(resp.mobile);
            setEmail(resp.email);
            setPan(resp.pan);
            setGstin(resp.gstin);

            setRisk({ value: resp.risk, label: resp.risk_nm })

            setBankName(resp.bankName);
            setBankAcc(resp.bankAcc);
            setSourceFund(resp.sourceFund);
            setModePayment(resp.modePayment);
            setSocialFin(resp.socialFin);
            setReview(new Date(resp.review));
            setRemark(resp.remark_indpro);

            setIDData(resp.iddetails);
            var countMax = 0;
            for (var i = 0; i < (resp.iddetails).length; i++) {
                if (resp.iddetails[i].mpid_srno > countMax) {
                    countMax = resp.iddetails[i].mpid_srno;
                }
            }
            setIdMaxSrno(countMax);
            $(".loader").hide();
        });
    }

    const editData = () => {
        setFormMode('E');
    }

    const addIdentityDetail = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (countryIssue === null || idNumber === "" || idNumber === " " || idType.value == '0') {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (idType.value == '0' ? "ID Type." : '');
            msg[i++] = (countryIssue === null ? "Country of Issue." : '');
            msg[i++] = (idNumber === '' ? "ID Number." : '');
            msg[i++] = (idNumber === " " ? "ID Number can't have space." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (Common.dateYMD(currDate) > Common.dateYMD(expDate) || idTypeValue == 1 && Common.dateYMD(issueDate) === Common.dateYMD(expDate)) {
            msg[i++] = (Common.dateYMD(currDate) > Common.dateYMD(expDate) ? "ID has been expired!." : '');
            msg[i++] = (idTypeValue == 1 && Common.dateYMD(issueDate) === Common.dateYMD(expDate) ? "Issue Date and Expiry Date can't be same!." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const val = idType.value.split('^');
            var isOk = true;
            //IF any id with same country same id type 
            for (var i = 0; i < idData.length; i++) {
                if (idData[i].mpid_id_type === val[1] && idData[i].mpid_country_issue === cntIssueCode) {
                    if (idData[i].mpid_id_number === idNumber) {
                        // ->same id number than show pop up;
                        isOk = false;
                        setMsgText("Id already exist.");
                    } else {
                        // ->different id number than inactive previous one;
                        idData[i].mpid_active = "0";
                    }
                }
            }
            var obj = {
                uniquekey: Common.getRandomString(10),
                formMode: formMode,
                code: code,
                randNum: randNum,
                mpid_country_issue: cntIssueCode,
                mpid_issue_by: issueBy,
                mpid_issue_place: issuePlace,
                mpid_id_type: val[1],
                mpid_active: "1",
                id_expirable: val[2],
                id_desc: val[3],
                mpid_id_number: idNumber,
                mpid_issue_date: Common.dateYMD(issueDate),
                mpid_expiry_date: Common.dateYMD(expDate),
                cnt_name: cntIssueName,
                mpid_srno: 1 * idMaxSrno + 1,
                delny: 'Y'
            };
            const obj2 = {
                TabType: "Identity",
                AType: "A",
                ALineNo: tranAuditTrail.length + 1,
                TType: 'MINDP',
                CountryIssue: cntIssueCode,
                IssueBy: issueBy,
                IssuePlace: issuePlace,
                IDType: val[1],
                IdActive: "1",
                IdExpirable: val[2],
                IdDescription: val[3],
                IDNumber: idNumber,
                IssueDate: Common.dateYMD(issueDate),
                ExpiryDate: Common.dateYMD(expDate),
                IssueName: cntIssueName,
            }

            if (isOk) {
                if (userRight.edit === "1") {
                    setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
                }

                setIdMaxSrno(1 * idMaxSrno + 1);
                setIDData(Common.arrayAddItem(idData, obj));
                setIdType({ value: 0, label: "Select" });
                setCountryIssue(null);
                setIdNumber("");
                setIssueBy("");
                setIssuePlace("");
                setIssueDate(new Date());
                setExpDate(new Date());
                console.log(idData);
            } else {
                setMsgBox(true);
                // setMsgText("Id already exist.");
            }
            setCounter(counter + 1);
        }
        $(".loader").hide();
    }

    const deleteIdDetail = (code, keyvalue, delny) => {
        if (delny === "N") {
            setMsgBox(true);
            setMsgText("This id has been used before.");
        } else {
            const itemToDelete = idData.find(item => item.uniquekey === keyvalue);
            if (itemToDelete) {
                const formattedItem = {
                    TabType: "Identity",
                    ALineNo: tranAuditTrail.length + 1,
                    AType: "D",
                    TType: 'MINDP',
                    IdType: itemToDelete.id_desc,
                    CountryofIssue: itemToDelete.cnt_name,
                    IdNumber: itemToDelete.mpid_id_number,
                    IssueDate: Common.dateDMYStr(itemToDelete.mpid_issue_date),
                    ExpiryDate: Common.dateDMYStr(itemToDelete.mpid_expiry_date),
                    Status: itemToDelete.mpid_active,
                };
                if (userRight.edit === "1") {
                    setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
                }
            }
            setIDData(Common.arrayRemoveItem(idData, 'uniquekey', keyvalue));
            setCounter(counter - 1);
        }
    }

    const exportFile = () => {
        setShowModalEx(true);
        // var obj = {
        //     right: 'INDIVIDUALPROFILE'
        // }
        // Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MINDP", trannumber: code, trancategory: "M" });
    }

    const btnSaveModal = () => {
        if (acRemark === '') {
            setValidated(true);
        } else {
            saveData();
            setShowModal(false);
            setValidated(false);
        }
    }

    const btnDownnload = () => {
        $(".loader").show();
        var obj = {
            right: 'INDIVIDUALPROFILE',
            location: 0,
            radioType: radioType,
            radioTF: radioTypeFilter,
            fromNo: fromNumber,
            toNo: toNumber,
            fromDate: Common.dateYMD(fromDate),
            toDate: Common.dateYMD(toDate),
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
        setShowModalEx(false);
        setFromDate(new Date());
        setToDate(new Date());
        setFromNumber('');
        setToNumber('');
        $(".loader").hide();
    }

    const handleIdNumber = (v) => {
        var idNum = 0;
        if (idType.value != 0) {
            var id = idType.value.split("^");
            idNum = id[1];
        }
        if (idNum * 1 === 1 && cntIssueCode * 1 === 1) {
            Common.validatePassport(v, setIdNumber);
        } else if (idNum * 1 === 4) {
            Common.validateAdhaar(v, setIdNumber);
        } else {
            Common.validateAlpNumValue(v, setIdNumber);
        }
    }

    const handleIndiviualSearchChange = (value) => {
        console.log("The Value is : ", value);
        setIndividualSearch(value);
        fillEditForm(value.id.split('^')[1]);
        setFormMode('E');
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h1> Individual Profile </h1>
                    </Col>
                </Row>
                {
                    showAddNewBtn &&
                    <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Filter Type</Form.Label>
                                    <Form.Select value={filterType} onChange={e => setFilterType(e.target.value)} required>
                                        <option value="">Select</option>
                                        <option value="Code">Code</option>
                                        <option value="Name">Name</option>
                                        <option value="ID">ID</option>
                                        <option value="PAN">PAN</option>
                                        <option value="Mobile">Mobile</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                filterType == "Code" ?
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Code</Form.Label>
                                            <Form.Control value={code} onChange={e => setCode(e.target.value)} required />
                                        </Form.Group>
                                    </Col>
                                    : filterType == "Name" || filterType == "Mobile" || filterType == "PAN" ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>{filterType}</Form.Label>
                                                <Form.Control value={filterName} onChange={e => setFilterName(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                        : filterType == "ID" ?
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>ID</Form.Label>
                                                    <Form.Control value={id} onChange={e => setId(e.target.value)} required />
                                                </Form.Group>
                                            </Col>
                                            :
                                            null
                            }
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Limit</Form.Label>
                                    <Form.Select value={limit} onChange={e => setLimit(e.target.value)} required>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                    </>
                }
                {
                    showAddNewBtn ?
                        <Row>
                            <Col>
                                <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={(e) => showList()}>List</Button> &nbsp;
                                {userRight.add === "1" ?
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={(e) => addNew()}>
                                        Add New
                                    </Button>
                                    : null}
                                &nbsp;
                                {
                                    userRight.query === "1" ?
                                        <>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={(e) => exportFile()}>Export</Button>
                                        </>
                                        : null

                                }
                            </Col>
                        </Row>
                        : null
                }
                <Row><Col>&nbsp;</Col></Row>
                {
                    showFormBtn ?
                        <>
                            <Row>
                                <Col className="divContentCenter">
                                    <div className="maxFormWidth">
                                        <Row>
                                            <Col>
                                                <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                    cancelConfirm()}>Back</Button>&nbsp;&nbsp;
                                                {
                                                    (userRight.edit === "1" && formMode === 'Q') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            editData()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && formMode === 'E') || (userRight.add === "1" && formMode === 'A') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            saveData()}>Save</Button>&nbsp;
                                                    </> : null

                                                }
                                                {
                                                    (userRight.query === "1" && (formMode === 'E' || formMode === 'Q')) ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                                    </> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>

                                        {
                                            (props.value === "TRAN_PP" || props.value === "TRAN_SP" || props.value === "FABOOK" || props.value === "TRAN_VTM_ENCASHMENT") ? <>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Search..</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={individualSearch}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={Picklist.fetchIndividualMainPicklist}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleIndiviualSearchChange}
                                                                formatOptionLabel={Picklist.formatOptionLabel}
                                                                components={{ Menu: Picklist.menu }}
                                                            >
                                                            </AsyncSelect>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row><Col>&nbsp;</Col></Row>
                                            </> : null
                                        }
                                        <Tabs
                                            id="controlled-tab-example"
                                            defaultActiveKey="basic-details"
                                            className="mb-3"
                                        >
                                            <Tab eventKey="basic-details" title="Basic Details">
                                                <Form>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Code</Form.Label>
                                                                <Form.Control type="text" placeholder="Code" value={code}
                                                                    disabled />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                                                                <Form.Control type="text" placeholder="First Name" maxLength={30}
                                                                    value={firstName}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setFirstName)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Middle Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Middle Name" maxLength={30}
                                                                    value={middleName}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setMiddleName)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Last Name" maxLength={30}
                                                                    value={lastName}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setLastName)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Date of Birth</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={birthDate}
                                                                    onChange={(date) => setBirthDate(date)}
                                                                    disabled={formMode === 'Q'}
                                                                    maxDate={new Date()}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Gender</Form.Label>
                                                                <Form.Select value={gender} onChange={e => setGender(e.target.value)}
                                                                    disabled={formMode === 'Q'} required>
                                                                    <option value="M">Male</option>
                                                                    <option value="F">Female</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Nationality<span className="text-danger">*</span></Form.Label>
                                                                <AsyncSelect
                                                                    isDisabled={formMode === 'Q'}
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={nationality}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchCountryPicklist(search, '')}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleNationalityValue}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Mother's maiden name </Form.Label>
                                                                <Form.Control type="text" placeholder="Mother's maiden name" maxLength={50}
                                                                    value={maiden}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setMaiden)}
                                                                    disabled={formMode === 'Q'} autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Country<span className="text-danger">*</span></Form.Label>
                                                                <AsyncSelect
                                                                    isDisabled={formMode === 'Q'}
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={country}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchCountryPicklist(search, '')}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleCountryValue}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>State{cntCode * 1 === 1 && <span className="text-danger">*</span>}
                                                                </Form.Label>
                                                                <AsyncSelect
                                                                    isDisabled={formMode === 'Q'}
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={state}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchStatePicklist(search, cntCode)}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleStateValue}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>District{cntCode * 1 === 1 && <span className="text-danger">*</span>}</Form.Label>
                                                                <AsyncSelect
                                                                    isDisabled={formMode === 'Q'}
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={district}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchDistrictPicklist(search, stateCode)}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleDistrictValue}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            {/* <Form.Group>
                                                                <Form.Label>City</Form.Label>
                                                                <Select options={cityData} value={city} onChange={v => setCity(v)}
                                                                    isDisabled={formMode === 'Q'} />
                                                            </Form.Group> */}
                                                            <Form.Group>
                                                                <Form.Label>City</Form.Label>
                                                                <AsyncSelect
                                                                    isDisabled={formMode === 'Q'}
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={city}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchCityPicklist(search, distCode)}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleCityValue}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>PIN</Form.Label>
                                                                <Form.Control type="text" placeholder="PIN" maxLength={6}
                                                                    value={pin}
                                                                    onChange={(e) => Common.validateNumValue(e.target.value, setPin)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Address</Form.Label>
                                                                <Form.Control type="text" placeholder="Address" maxLength={100}
                                                                    value={address}
                                                                    onChange={(e) => setAddress(e.target.value)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Mobile{natCode * 1 === 1 && <span className="text-danger">*</span>}</Form.Label>
                                                                <Form.Control type="text" placeholder="Mobile" maxLength={10}
                                                                    value={mobile}
                                                                    onChange={(e) => setMobile(e.target.value)}
                                                                    onBlur={(e) => Common.validateMobileNumber(e.target.value, setMobile)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Email{natCode * 1 === 1 && <span className="text-danger">*</span>}</Form.Label>
                                                                <Form.Control type="text" placeholder="Email" maxLength={30}
                                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                                    onBlur={(e) => Common.validtateEmail(e.target.value, setEmail)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>PAN{props.value === "TRAN_SP" && <span className="text-danger">*</span>}</Form.Label>
                                                                <Form.Control type="text" placeholder="PAN" maxLength={10}
                                                                    value={pan}
                                                                    onChange={(e) => setPan(e.target.value)}
                                                                    onBlur={(e) => Common.validatePan(e.target.value, setPan)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>GSTIN</Form.Label>
                                                                <Form.Control type="text" placeholder="GSTIN" maxLength={15}
                                                                    value={gstin}
                                                                    onChange={(e) => setGstin(e.target.value)}
                                                                    onBlur={(e) => Common.validateGST(e.target.value, setGstin)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Risk</Form.Label>
                                                                <Select options={riskData} value={risk} onChange={v => setRisk(v)}
                                                                    isDisabled={formMode === 'Q'} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Bank Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Bank Name" maxLength={40}
                                                                    value={bankName}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setBankName)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Bank Account no</Form.Label>
                                                                <Form.Control type="text" placeholder="Bank Account no" maxLength={40}
                                                                    value={bankAcc}
                                                                    onChange={(e) => Common.validateNumValue(e.target.value, setBankAcc)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Source of Fund</Form.Label>
                                                                <Form.Control type="text" placeholder="Source of Fund" maxLength={30}
                                                                    value={sourceFund}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setSourceFund)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Mode of Payment</Form.Label>
                                                                <Form.Control type="text" placeholder="Mode of Payment" maxLength={40}
                                                                    value={modePayment}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setModePayment)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Social and Financial Status</Form.Label>
                                                                <Form.Control type="text" placeholder="Social and Financial Status" maxLength={100}
                                                                    value={socialFin}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setSocialFin)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Last Review</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={review}
                                                                    onChange={(date) => setReview(date)}
                                                                    disabled={formMode === 'Q'}
                                                                    maxDate={new Date()}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Remark</Form.Label>
                                                                <Form.Control type="text" placeholder="Remark" maxLength={50}
                                                                    value={remark}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setRemark)}
                                                                    disabled={formMode === 'Q'}
                                                                    autoComplete="off" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                    <Row><Col>&nbsp;</Col></Row>
                                                </Form>
                                            </Tab>
                                            <Tab eventKey="identity-details" title="Identity Details">
                                                {
                                                    formMode === "Q" ? null :
                                                        <>
                                                            <Form>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>ID Type<span className="mandatory text-danger">*</span></Form.Label>
                                                                            <Select options={idTypeData} value={idType} onChange={v => handleIdType(v)}
                                                                                isDisabled={formMode === 'Q'} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>Country of Issue<span className="text-danger">*</span></Form.Label>
                                                                            <AsyncSelect
                                                                                isDisabled={formMode === 'Q' || cntIssueDisable}
                                                                                cacheOptions={false}
                                                                                defaultOptions={false}
                                                                                value={countryIssue}
                                                                                getOptionLabel={e => e.label + ' '}
                                                                                getOptionValue={e => e.id}
                                                                                loadOptions={(search) => Picklist.fetchCountryofIssuePicklist(search, '')}
                                                                                onInputChange={Picklist.handleInputChange}
                                                                                onChange={handleCountryIssueValue}
                                                                            >
                                                                            </AsyncSelect>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>ID Number<span className="mandatory text-danger">*</span></Form.Label>
                                                                            <Form.Control type="text"
                                                                                placeholder="ID Number"
                                                                                maxLength={20}
                                                                                value={idNumber}
                                                                                onChange={(e) => setIdNumber(e.target.value.toUpperCase())}
                                                                                onBlur={(e) => handleIdNumber(e.target.value)}
                                                                                disabled={formMode === 'Q'}
                                                                                autoComplete="off" />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>Issue By </Form.Label>
                                                                            <Form.Control type="text"
                                                                                placeholder="Issue By"
                                                                                maxLength={30}
                                                                                value={issueBy}
                                                                                onChange={(e) => Common.validateAlpValue(e.target.value, setIssueBy)}
                                                                                disabled={formMode === 'Q'}
                                                                                autoComplete="off" />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>Issue Place </Form.Label>
                                                                            <Form.Control type="text"
                                                                                placeholder="Issue Place"
                                                                                maxLength={30}
                                                                                value={issuePlace}
                                                                                onChange={(e) => Common.validateAlpValue(e.target.value, setIssuePlace)}
                                                                                disabled={formMode === 'Q'}
                                                                                autoComplete="off" />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Group>
                                                                            <Form.Label>Issue Date </Form.Label>
                                                                            <DatePicker className="form-control"
                                                                                selected={issueDate}
                                                                                onChange={(date) => setIssueDate(date)}
                                                                                disabled={formMode === 'Q'}
                                                                                maxDate={new Date()}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                showYearDropdown
                                                                                showMonthDropdown
                                                                                useShortMonthInDropdown
                                                                                dropdownMode="select"
                                                                                peekNextMonth
                                                                                customInput={
                                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                }
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {
                                                                        idValue == '1' ?
                                                                            <>
                                                                                <Col>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Expiry Date<span className=" text-danger">*</span></Form.Label>
                                                                                        <DatePicker className="form-control"
                                                                                            selected={expDate}
                                                                                            onChange={(date) => setExpDate(date)}
                                                                                            // minDate={new Date()}
                                                                                            // maxDate={new Date()}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            showYearDropdown
                                                                                            showMonthDropdown
                                                                                            useShortMonthInDropdown
                                                                                            dropdownMode="select"
                                                                                            peekNextMonth
                                                                                            customInput={
                                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </> : null
                                                                    }
                                                                    <Col style={{ marginTop: "33px" }}>
                                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addIdentityDetail()}> Add </Button>
                                                                    </Col>
                                                                    {
                                                                        idValue == '1' ? null : <Col></Col>
                                                                    }
                                                                </Row>
                                                            </Form>
                                                        </>
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Table responsive striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>&nbsp;</th>
                                                                    <th>Id Type</th>
                                                                    <th>Country of Issue</th>
                                                                    <th>Id Number</th>
                                                                    <th>Issue Date</th>
                                                                    <th>Expiry Date</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    idData.map((res) => (
                                                                        <tr key={res.mpid_srno}>
                                                                            <td className="textCenter">
                                                                                {
                                                                                    userRight.delete === "1" && formMode !== "Q" ?
                                                                                        <span className="handCursor colorThemeBlue" onClick={() => deleteIdDetail(res.mpid_srno, res.uniquekey, res.delny)}>
                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                        </span>
                                                                                        : null
                                                                                }
                                                                            </td>
                                                                            <td>{res.id_desc}</td>
                                                                            <td>{res.cnt_name}</td>
                                                                            <td>{res.mpid_id_number}</td>
                                                                            <td>{Common.dateDMYStr(res.mpid_issue_date)}</td>
                                                                            <td>{Common.dateDMYStr(res.mpid_expiry_date)}</td>
                                                                            <td>{(res.mpid_active === "1" ? "Active" : "Inactive")}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th className="textRight">Code</th>
                                                <th>Name</th>
                                                <th>ID Type</th>
                                                <th>ID Number</th>
                                                <th>ID Country</th>
                                                <th>PAN</th>
                                                <th>Mobile</th>
                                                {/* <th>Status</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {individualData.map((res) =>
                                                <tr key={res.bd_code}>
                                                    {userRight.edit === "1" ?
                                                        <td className="textCenter" >
                                                            <span className="handCursor colorThemeBlue" onClick={() => fillEditForm(res.bd_code)} >
                                                                <FontAwesomeIcon icon={faEye} />&nbsp;
                                                                {
                                                                    res.mpid_active === "1" ?
                                                                        <>
                                                                            <span className="handCursor colorThemeBlue" onClick={() => handlePurchase(res.bd_code)}>
                                                                                <FontAwesomeIcon icon={faCartShopping} />
                                                                            </span> &nbsp;
                                                                            {res.id_desc === "PASSPORT" &&
                                                                                <span className="handCursor colorThemeBlue" onClick={() => handleSaleBtn(res.bd_code)}>
                                                                                    <FontAwesomeIcon icon={faCashRegister} />
                                                                                </span>}
                                                                        </> : null
                                                                }
                                                            </span>
                                                        </td>
                                                        : null
                                                    }
                                                    <td className="textRight">{res.bd_code}</td>
                                                    <td>{res.bd_first_name}</td>
                                                    <td>{res.id_desc}</td>
                                                    <td>{res.mpid_id_number}</td>
                                                    <td>{res.cnt_name}</td>
                                                    <td>{res.bd_pan}</td>
                                                    <td>{res.bd_mobile}</td>
                                                    {/* <td>{(res.mpid_active === "1" ? "Active" : "Inactive")}</td> */}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
            <div>
                <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            formMode === 'E' ?
                                <>
                                    {/* <Form.Group>
                                        <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Reason" maxLength={499}
                                            value={acRemark}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAcRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group> */}

                                    <Form validated={validated}>
                                        <Form.Group>
                                            <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                maxLength={499}
                                                autoComplete="off"
                                                value={acRemark}
                                                onChange={(e) => Common.validateAlpValue(e.target.value, setAcRemark)}
                                                placeholder="Reason"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please fill Reason.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form>

                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnSaveModal()}>
                            Save
                        </Button>{' '}
                        <Button variant="outline-danger" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal size='lg' show={showModalEx} onHide={() => setShowModalEx(false)} animation={false} centered>
                    <Modal.Header closeButton>
                        <Modal.Title><h4>Filter</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col>Profle/Id wise</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check checked={radioType === "CW"} onChange={() => setRadioType("CW")} type='radio' label="Profile Wise" />
                                </Col>
                                <Col>
                                    <Form.Check checked={radioType === "IW"} onChange={() => setRadioType("IW")} type='radio' label="Identity Wise" />
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>Date/Srno Wise</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check checked={radioTypeFilter === "CD"} onChange={() => setRadioTypeFilter("CD")} type='radio' label="Creation Date Range" />
                                </Col><Col>
                                    <Form.Check checked={radioTypeFilter === "CC"} onChange={() => setRadioTypeFilter("CC")} type='radio' label="Customer Code Range" />
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                {
                                    radioTypeFilter === 'CD' ?
                                        <>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>From Date</Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={fromDate}
                                                        maxDate={new Date(fromDate)}
                                                        onChange={(date) => setFromDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>To Date</Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={toDate}
                                                        maxDate={new Date(toDate)}
                                                        onChange={(date) => setToDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>From Number</Form.Label>
                                                    <Form.Control type="text" placeholder="From Number" maxLength={10}
                                                        value={fromNumber}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setFromNumber)}
                                                        autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>To Number</Form.Label>
                                                    <Form.Control type="text" placeholder="To Number" maxLength={10}
                                                        value={toNumber}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setToNumber)}
                                                        autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                        </>
                                }
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnDownnload()}>
                            Download
                        </Button>{' '}
                        <Button variant="outline-success" onClick={() => setShowModalEx(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <span className="corner-span">{counter}</span>
        </>
    )
}
export default Master_individual_profile;
