import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Master_individual_profile from '../master/Master_individual_profile';

function Tran_vtm_encashment() {
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');


    const [userRight, setUserRight] = useState([]);
    const [approvalRight, setApprovalRight] = useState([]);
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [scrMode, setScrMode] = useState("");

    const [enSrno, setEnSrno] = useState("");
    const [enLocation, setEnLocation] = useState(null);
    const [enLocationValue, setEnLocationValue] = useState("0");
    const [enIssuer, setEnIssuer] = useState(null);
    const [enCardNo, setEnCardNo] = useState("");
    const [enCorporate, setEnCorporate] = useState(null);
    const [enDate, setEnDate] = useState(null);
    const [enReferenceNo, setEnReferenceNo] = useState("");
    const [enPurchaseNo, setEnPurchaseNo] = useState("");
    const [enRemark, setEnRemark] = useState("");
    const [enValue, setEnValue] = useState("");
    const [enPassport, setEnPassport] = useState("");
    const [enRequestedValue, setEnRequestedValue] = useState("");
    const [enStatus, setEnStatus] = useState('PENDING');
    const [filterStatus, setFilterStatus] = useState('A');

    const [vtmData, setVtmData] = useState([]);
    const [enIndividual, setEnIndividual] = useState(null);

    const [enIsd, setEnIsd] = useState(null);
    const [hdrSrno, setHdrSrno] = useState(0);
    // const [check, setCheck] = useState("");
    const [hdrLocation, setHdrLocation] = useState("");
    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [scrIndividual, setScrIndividual] = useState("");
    const [nIndivualSrno, setNIndivualSrno] = useState("");
    const [enIndividualId, setEnIndividualId] = useState("");


    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setEnLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_VTM_REQUEST'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'VTM_REQUEST_APPROVAL'], function (result) {
            let resp = JSON.parse(result);
            setApprovalRight(resp);
        });

        // const sessionValues = sessionStorage.getItem('values');
        // if (sessionValues) {
        //     const sessionData = JSON.parse(sessionValues);
        //     if (sessionData.md_type == "PURCHASE") {
        //         setScrMode('A');
        //         Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
        //             let resp = JSON.parse(result);
        //             console.log("RESPONSE IS  ", resp);
        //             // const desc = resp.desc.split("^");
        //             handleIndiviualSearchChange({ ...resp, desc: `${resp.desc.replaceAll('^', '  ^  ')}` })
        //             // setNIndividual(resp);
        //         });
        //         sessionStorage.removeItem('values');
        //     } else {
        //         setScrMode('');
        //     }
        // }

    }, []);

    const addNew = () => {
        if (enLocation === null) {
            setMsgBox(true);
            setMsgText("Please Select Location.");
        } else {
            setEnSrno(0);
            setScrMode("A");
            setEnCardNo("");
            setEnCorporate(null);
            setEnDate(null);
            setEnIsd(null);
            setEnRemark("");
            setEnReferenceNo("");
            setEnRequestedValue("");
            setEnIssuer(null);
            setEnPassport("");
            setEnPurchaseNo("");
            setEnValue("");
            setEnIndividual(null);
            setEnStatus("PENDING");
            setHdrSrno(0);
        }
    }

    const listData = () => {
        $(".loader").show();
        const obj = {
            status: filterStatus,
            enLocationValue: enLocationValue
        }
        Common.callApi(Common.apiVtmEncash, [sid, "listdata", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setVtmData(JSON.parse(result));
        });
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const handleLocationChange = (value) => {
        setEnLocation(value);
        var id = value.id;
        var locationArr = id.split("^");
        setEnLocationValue(locationArr[1]);
        setHdrLocation(locationArr[2]);
        console.log(locationArr[2])
        sessionStorage.activeBranch = locationArr[1];
    }

    const fillViewForm = (location, srno) => {
        setHdrSrno(0);
        setScrMode("Q");
        setEnSrno(srno);
        const obj = {
            location: location,
            srno: srno
        }
        Common.callApi(Common.apiVtmEncash, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setEnIsd({ id: resp.isdid, label: resp.isdlabel });
            setEnIssuer({ id: resp.issuerid, label: resp.issuerlabel });
            setEnCardNo(resp.cardno);
            setEnRequestedValue(resp.reqvalue);
            setEnPurchaseNo(resp.purchasebill);
            setEnPassport(resp.passport);
            setEnReferenceNo(resp.refno);
            setEnSrno(resp.srno);
            // setEnLocationValue(resp.location);
            // setHdrLocation();
            setHdrSrno(resp.srno);
            const desc = resp.indDesc.split("^");
            if (resp.individual == 0) setEnIndividual(null);
            else setEnIndividual({ id: resp.indid, label: resp.indlabel, desc: `${desc.slice().join(" ^  ")}` });

            if (resp.encdate === null) setEnDate(null);
            else setEnDate(new Date(resp.encdate));

            setEnValue(resp.encvalue);
            setNIndivualSrno(resp.individual);
            setEnRemark(resp.encremark);
            setEnStatus(resp.encstatus);

            if (resp.corpid == "^0^0^") setEnCorporate(null);
            else setEnCorporate({ id: resp.corpid, label: resp.corplabel });

        });
    }

    const editData = () => {
        setScrMode("E");
    }

    const saveData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (enIsd === null || enIssuer === null || enCardNo === "" || enReferenceNo === "" || enRequestedValue === "" || enIndividual === null) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (enIsd === null ? "Isd" : "");
            msg[i++] = (enIssuer === null ? "Issuer" : "");
            msg[i++] = (enCardNo === "" ? "Card Number" : "");
            msg[i++] = (enReferenceNo === "" ? "Reference Number" : "");
            msg[i++] = (enRequestedValue === "" ? "Request Value" : "");
            msg[i++] = (enIndividual === null ? "Individual Profile" : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                srno: enSrno,
                enIsd: enIsd,
                enIssuer: enIssuer,
                enCardNo: enCardNo,
                enReferenceNo: enReferenceNo,
                enRequestedValue: enRequestedValue,
                enIndividual: enIndividual,
                enPassport: enPassport,
                enCorporate: enCorporate,
                enPurchaseNo: enPurchaseNo,
                enDate: Common.dateYMD(enDate),
                enValue: enValue,
                enRemark: enRemark,
                enLocationValue: enLocationValue,
                status: enStatus,
                scrMode: scrMode,
                individualId: enIndividualId,
            }
            Common.callApi(Common.apiVtmEncash, [sid, "savedata", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    $(".loader").hide();
                    setScrMode("");
                    setVtmData(resp.data);
                } else {
                    $(".loader").hide();
                    setMsgText(Common.getMessage("ERR0000"));
                    setMsgBox(true);
                }
            });
        }
    }

    const exportStatus = () => {
        $(".loader").show();
        const obj = { enSrno: enSrno }
        Common.callApi(Common.apiVtmEncash, [sid, "exportstatus", "EXPORTED", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("");
                listData();
            }
        });
    }

    const approveStatus = () => {
        $(".loader").show();
        var msg = [], i = 0;
        var d = "";
        var today = "";
        if (enDate !== null) {
            d = Common.dateYMD(enDate);
            today = Common.dateYMD(new Date());
        }
        if (enDate == null || enValue === "" || enValue == 0) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (enDate == null ? "Encashed Date" : "");
            msg[i++] = ((enValue === "" || enValue == 0) ? "Encashed Value" : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (d > today) {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText("Encashment date cannot be future date.");
        } else if (d > finEndDate || d < finStartDate) {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText("Encashment " + Common.getMessage("MSG0010"));
        } else {
            const obj = {
                enDate: Common.dateYMD(enDate),
                enValue: enValue,
                enSrno: enSrno
            }
            Common.callApi(Common.apiVtmEncash, [sid, "approvestatus", "APPROVED", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    $(".loader").hide();
                    setScrMode("");
                    listData();
                }
            });
        }
    }

    const rejectStatus = () => {
        $(".loader").show();
        const obj = {
            enSrno: enSrno
        }
        Common.callApi(Common.apiVtmEncash, [sid, "rejectstatus", "REJECTED", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("");
                listData();
            }
        });
    }

    const pendingStatus = () => {
        $(".loader").show();
        const obj = { enSrno: enSrno }
        Common.callApi(Common.apiVtmEncash, [sid, "pendingstatus", "PENDING", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("");
                listData();
            }
        });
    }

    const handleIndiviualSearchChange = (value) => {
        console.log("Invidual Value is ", value);
        setEnIndividual(value);
        const id = value.id.split('^');
        setNIndivualSrno(id[1]);
        setEnIndividualId(id[2]);
        const val = value.desc.split('^')[2].split(' ')[1];
        setEnPassport(val);

    }

    const newIndividual = (btnype) => {
        setShowIndividualForm(true);
        if (btnype === "E" && enIndividual != null) setScrIndividual("E");
        else setScrIndividual("A");
    }


    const hideIndividualProfile = (val, nIndivualSrno) => {
        console.log(`The value is ${val} and Individual is ${nIndivualSrno}`);
        setShowIndividualForm(val);
        if (nIndivualSrno > 0) {
            const obj = {
                individualsrno: nIndivualSrno,
                type: "",
            }
            Common.callApi(Common.apiIndividualProfile, [sid, "individualidfrompicklist", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                console.log("RESPONSE IS  ", resp);
                const desc = resp.desc.split("^");
                if (resp.id === "") setEnIndividual(null);
                // else handleIndiviualSearchChange({ ...resp, desc: `${desc.slice().join("  ^  ")}` });
                else handleIndiviualSearchChange(resp);
                // `${desc.slice(0, 3).join(" ")} ^ ${desc[3]} ^ ${desc.slice(4, 7).join(" ")}  ^    ${desc[7]}`
            });
        }
    }

    return (
        <>
            {
                showIndividualForm ?
                    <Master_individual_profile reqObj={{ nIndivualSrno: nIndivualSrno, scrIndividual: scrIndividual }} value="TRAN_VTM_ENCASHMENT" hideIndividualProfile={hideIndividualProfile} />
                    :
                    <>
                        <Login_header />
                        <Menu />
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h3>VTM Encashment</h3>
                                </Col>
                            </Row>
                            {
                                scrMode === "" ?
                                    <>
                                        <Row>

                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleLocationChange}
                                                        isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}>
                                                        <option value="A">All</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="EXPORTED">Exported</option>
                                                        <option value="ACTIVATED">Activated</option>
                                                        <option value="REJECTED">Rejected</option>
                                                        <option value="ENCASHED">Encashed</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col>
                                                {
                                                    userRight.query === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => listData()}>List</Button>
                                                        : null
                                                }
                                                &nbsp;
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button>
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col>
                                                <Table responsive striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>&nbsp;</th>
                                                            <th>Sr No </th>
                                                            <th>Location</th>
                                                            <th>Isd</th>
                                                            <th>Issuer</th>
                                                            <th>Card No</th>
                                                            <th>Reference No</th>
                                                            <th>Corporate</th>
                                                            <th>Requested Value</th>
                                                            <th>Encashed Value</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            vtmData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span className='colorThemeBlue pointer' onClick={() => fillViewForm(item.tve_location, item.tve_srno)}>
                                                                            <FontAwesomeIcon icon={faEye} />
                                                                        </span>
                                                                    </td>
                                                                    <td>{item.tve_srno}</td>
                                                                    <td>{item.entity_name}</td>
                                                                    <td>{item.tve_isd}</td>
                                                                    <td>{item.issuer_name}</td>
                                                                    <td>{item.tve_card_no}</td>
                                                                    <td>{item.tve_ref_no}</td>
                                                                    <td>{item.corp_name}</td>
                                                                    <td>{item.tve_requested_value}</td>
                                                                    <td>{item.tve_encashed_value}</td>
                                                                    <td>{item.tve_status}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Label>Serial Number : {hdrSrno}</Form.Label>
                                                </Col>
                                                <Col className="col-md-2 col-4">
                                                    <span>Location : <b>{hdrLocation} </b></span>
                                                </Col>
                                            </Row>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Status<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Select disabled value={enStatus} onChange={e => setEnStatus(e.target.value)}>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="EXPORTED">Exported</option>
                                                        <option value="APPROVED">Approved</option>
                                                        <option value="REJECTED">Rejected</option>
                                                        <option value="ENCASHED">Encashed</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enIsd}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, "")}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={value => setEnIsd(value)}
                                                        isDisabled={scrMode === "Q"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Issuer<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enIssuer}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', enLocationValue)} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={value => setEnIssuer(value)}
                                                        isDisabled={scrMode === "Q"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Card No<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Control disabled={scrMode === "Q"} placeholder='Card No' type='text' maxLength={20} value={enCardNo} onChange={e => Common.validateAlpNumValue(e.target.value, setEnCardNo)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Reference No<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Control disabled={scrMode === "Q"} placeholder='Reference No' type='text' maxLength={30} value={enReferenceNo} onChange={e => Common.validateNumValue(e.target.value, setEnReferenceNo)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Requested Value<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Control disabled={scrMode === "Q"} placeholder='Requested Value' type='text' maxLength={10} value={enRequestedValue} onChange={e => Common.validateDecValue(e.target.value, setEnRequestedValue)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-12">
                                                <Form.Group>
                                                    <Form.Label>Individual Profile<span className="colorThemeRed">*</span>
                                                        {
                                                            scrMode === "Q" ? null :
                                                                <>
                                                                    <span className='tranNewFormText' onClick={() => newIndividual("A")}>Not in list? Create New</span>
                                                                    &nbsp;&nbsp;
                                                                    <span className='tranNewFormText' onClick={() => newIndividual("E")}>Edit</span>
                                                                </>
                                                        }
                                                        {/* {individualSelected === 1 ?
                                            <span style={{ display: scrMode === "Q" && "none" }} className="mx-2" onClick={handlePreTransactionInfo}><Badge bg="secondary">Previous Transaction</Badge></span> : null
                                        } */}
                                                    </Form.Label>
                                                    <AsyncSelect
                                                        value={enIndividual}
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchIndividualPicklist(search, "SALE")}
                                                        onInputChange={Picklist.handleInputChange}
                                                        formatOptionLabel={Picklist.formatOptionLabel}
                                                        components={{ Menu: Picklist.menu }}
                                                        onChange={handleIndiviualSearchChange}
                                                        isDisabled={scrMode === "Q"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Passport No</Form.Label>
                                                    <Form.Control disabled={scrMode === "Q" || enPassport !== ""} placeholder='Passport No' type='text' maxLength={20} value={enPassport} onChange={e => Common.validateAlpNumValue(e.target.value, setEnPassport)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Remark</Form.Label>
                                                    <Form.Control disabled={scrMode === "Q"} placeholder='Remark' type='text' maxLength={50} value={enRemark} onChange={e => Common.validateAlpValue(e.target.value, setEnRemark)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-4 col-6">
                                                <Form.Group>
                                                    <Form.Label>Corporate</Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enCorporate}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'CORPORATE', enLocationValue)} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={value => setEnCorporate(value)}
                                                        isDisabled={scrMode === "Q"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            scrMode !== "A" ?
                                                <Row>
                                                    <Col className="col-md-4 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Purchase Bill No</Form.Label>
                                                            <Form.Control disabled placeholder='Purchase Bill No' type='text' maxLength={10} value={enPurchaseNo} onChange={e => Common.validateAlpNumValue(e.target.value, setEnPurchaseNo)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-4 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Encashment Date</Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={enDate}
                                                                onChange={(date) => setEnDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                disabled={enStatus === "APPROVED" || enStatus === "REJECTED" || !(approvalRight.query === "1")}
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-4 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Encashed Value</Form.Label>
                                                            <Form.Control disabled={enStatus === "APPROVED" || enStatus === "REJECTED" || !(approvalRight.query === "1")} placeholder='Encashed Value' type='text' maxLength={6} value={enValue} onChange={e => Common.validateDecValue(e.target.value, setEnValue)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col>
                                                <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                                &nbsp;
                                                {
                                                    userRight.edit === "1" && scrMode === 'Q' && enStatus === "PENDING" ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            editData()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                                    </> : null
                                                }
                                                &nbsp;
                                                {
                                                    enStatus === "PENDING" && userRight.add === "1" && (scrMode === "Q" || scrMode === "E") ?
                                                        <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => exportStatus()}>Export</Button>
                                                        : null
                                                }
                                                &nbsp;
                                                {
                                                    (userRight.query === "1" && enStatus === "PENDING" && scrMode === "Q") &&
                                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => rejectStatus()}>Reject</Button>
                                                }
                                                {
                                                    approvalRight.query === "1" && enStatus === "EXPORTED" ?
                                                        <>
                                                            <Button variant='outline-success' size='sm' className='buttonStyle' onClick={() => approveStatus()}>Approve</Button>
                                                            &nbsp;
                                                            <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => rejectStatus()}>Reject</Button>
                                                            &nbsp;
                                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => pendingStatus()}>Send Back to Branch</Button>
                                                        </>
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Container>
                    </>
            }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
        </>
    )
}

export default Tran_vtm_encashment
