import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import DynamicDropdown from '../../DynamicSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';

const Transaction_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [currencyCode, setCurrencyCode] = useState(0);
    const [selectedItems2, setSelectedItems2] = useState([]);
    const [radioType2, setRadioType2] = useState('A');

    const [tranCode, setTranCode] = useState(0);
    const [selectedItems3, setSelectedItems3] = useState([]);
    const [radioType3, setRadioType3] = useState('A');

    const [productCode, setProductCode] = useState(0);
    const [selectedItems4, setSelectedItems4] = useState([]);
    const [radioType4, setRadioType4] = useState('A');



    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [onceRun, setOnceRun] = useState(false);
    const [stockType, setStockType] = useState("V");
    const [scr, setScr] = useState("");
    const [tranReportList, settranReportList] = useState([]);


    var msg = [], i = 0;

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'TRANSACTION_REPORT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleDataFromChild = (type, newSelectedItems, newRadioType) => {
        if (type == 'location') {
            setSelectedItems(newSelectedItems);
            setRadioType(newRadioType);
        } else if (type == 'currency') {
            setSelectedItems2(newSelectedItems);
            setRadioType2(newRadioType);
        } else if (type == 'transaction') {
            setSelectedItems3(newSelectedItems);
            setRadioType3(newRadioType);
        } else if (type == 'product') {
            setSelectedItems4(newSelectedItems);
            setRadioType4(newRadioType);
        }
    }

    console.log(selectedItems);
    console.log(radioType);



    // const handleRadioChange = (value) => {
    //     setRadioType3(value);
    //     if (value === 'A') {
    //         setSelectedItems3([]);
    //     }
    // };

    // const checkBoxHandler = (e) => {
    //     const value = e.target.value;
    //     const isChecked = e.target.checked;

    //     if (isChecked) {
    //         setSelectedItems3((prevSelectedItems) => [...prevSelectedItems, value]);
    //     } else {
    //         setSelectedItems3((prevSelectedItems) => prevSelectedItems.filter((item) => item !== value));
    //     }
    // };

    // const handleProductRadio = (value) => {
    //     setRadioType4(value);
    //     if (value === 'A') {
    //         setSelectedItems4([]);
    //     }
    // };

    // const productCheckBox = (e) => {
    //         const value = e.target.value;
    //         const isChecked = e.target.checked;

    //         if (isChecked) {
    //             setSelectedItems4((prevSelectedItems) => [...prevSelectedItems, value]);
    //         } else {
    //             setSelectedItems4((prevSelectedItems) => prevSelectedItems.filter((item) => item !== value));
    //         }
    // };

    // const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
    //     setLocationCode(newLocationCode);
    //     setSelectedItems(newSelectedItems);
    //     setRadioType(newRadioType);
    // }

    // const handleDataFromChild2 = (newCurrencyCode, newSelectedItems2, newRadioType2) => {
    //     setCurrencyCode(newCurrencyCode);
    //     setSelectedItems2(newSelectedItems2);
    //     setRadioType2(newRadioType2);
    // }

    const handleGenerate = () => {

        if (!fromDate || !toDate || (radioType == 'O' && locationCode == '0') || (radioType == 'S' && selectedItems == [])
            || (radioType2 == 'O' && currencyCode == '0') || (radioType2 == 'S' && selectedItems2 == [])
            || (radioType3 === 'S' && selectedItems3.length === 0) || (radioType4 === 'S' && selectedItems4.length === 0)) {


            msg[i++] = Common.getMessage("MSG0006");

            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');

            msg[i++] = ((radioType2 == 'O' && currencyCode == '0') ? "Please select currency." : '');
            msg[i++] = ((radioType2 == 'S' && selectedItems2 == []) ? "Please select atleast one currency." : '');

            msg[i++] = ((radioType3 === 'S' && selectedItems3.length === 0) ? "Please select atleast one transaction type." : '');

            msg[i++] = ((radioType4 === 'S' && selectedItems4.length === 0) ? "Please select atleast one product type." : '');

            msg[i++] = (!fromDate ? "Please select from date." : '');
            msg[i++] = (!toDate ? "Please select to date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {

            const obj = {
                fromDate: Common.dateDMY(fromDate),
                toDate: Common.dateDMY(toDate),

                radioTypeLocation: radioType,
                multipleLocation: selectedItems,

                radioTypeCurrency: radioType2,
                multipleCurrency: selectedItems2,

                radioTypeTransaction: radioType3,
                multipleTransaction: selectedItems3,

                radioTypeProduct: radioType4,
                multipleProduct: selectedItems4,
            }

            if (stockType === 'D') {
                Common.callDownloadApiPost(Common.apiTransactionReport, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiTransactionReport, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    settranReportList(resData)
                    // console.log(scr);
                });
            }

        }
    }


    const myStyle = {
        height: "225px",
        width: " 224px",
        border: "1px solid black"
    };


    const myStyle2 = {
        height: "191px",
        overflow: "hidden scroll",
        padding: "5px 10px",
    }


    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h2>Transaction Report</h2>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}
                                >Generate</Button>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={stockType} onChange={e => setStockType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>&nbsp;


                        {/* <Row>
                    <Col >
                        <Form.Label>Location Filter</Form.Label><br />
                        <LocationSelector onDataFromChild={handleDataFromChild} />
                    </Col>

                    <Col >
                        <Form.Label>Currency Filter</Form.Label><br />
                        <CurrencySelector onDataFromChild2={handleDataFromChild2} />
                    </Col>


                    <Col>
                        <Form >
                            <Form.Label>Tran Filter</Form.Label><br />
                            <div style={{ display: "flex" }}>
                                <Form.Check checked={radioType3 === 'A'} onChange={() => handleRadioChange('A')} type='radio' label='All' />&nbsp;
                                <Form.Check checked={radioType3 === 'S'} onChange={() => handleRadioChange('S')} type='radio' label='Selective' />
                            </div>
                            {radioType3 === 'S' && (
                                <>
                                    <div style={myStyle}>
                                        <div style={{ backgroundColor: "lightblue", color: "white", padding: "0 0 0 10px" }}>
                                            <Form.Label>Select Transactions</Form.Label>
                                        </div>
                                        <div style={myStyle2}>
                                            {data.map((item, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type='checkbox'
                                                    id={`checkbox-${index}`}
                                                    label={item.type}
                                                    value={item.code.toString()} // Ensure value is a string
                                                    checked={selectedItems3.includes(item.code.toString())} // Check if item is in selectedItems3
                                                    onChange={checkBoxHandler}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    </Col>

                    <Col>
                        <Form >
                            <Form.Label>Product Filter</Form.Label><br />
                            <div style={{ display: "flex" }}>
                                <Form.Check checked={radioType4 === 'A'} onChange={() => handleProductRadio('A')} type='radio' label='All' />&nbsp;
                                <Form.Check checked={radioType4 === 'S'} onChange={() => handleProductRadio('S')} type='radio' label='Selective' />
                            </div>
                            {radioType4 === 'S' && (
                                <>
                                    <div style={myStyle}>
                                        <div style={{ backgroundColor: "lightblue", color: "white", padding: "0 0 0 10px" }}>
                                            <Form.Label>Select Product</Form.Label>
                                        </div>
                                        <div style={myStyle2}>
                                            {product.map((item, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type='checkbox'
                                                    id={`checkbox-${index}`}
                                                    label={item.type}
                                                    value={item.code.toString()} // Ensure value is a string
                                                    checked={selectedItems4.includes(item.code.toString())} // Check if item is in selectedItems3
                                                    onChange={productCheckBox}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    </Col>
                </Row>&nbsp; */}

                        <Row>
                            <Col>
                                <Form.Label>Location Filter</Form.Label><br />
                                <DynamicDropdown type='location' onDataFromChild={handleDataFromChild} />
                            </Col>

                            <Col>
                                <Form.Label>Currency Filter</Form.Label><br />

                                <DynamicDropdown type='currency' onDataFromChild={handleDataFromChild} />
                            </Col>

                            <Col>
                                <Form.Label>Transaction Filter</Form.Label><br />

                                <DynamicDropdown type='transaction' onDataFromChild={handleDataFromChild} />
                            </Col>

                            <Col>
                                <Form.Label>Product Filter</Form.Label><br />
                                <DynamicDropdown type='product' onDataFromChild={handleDataFromChild} />
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Branch</th>
                                <th>Purchase</th>
                                <th>Transaction</th>
                                <th>Date</th>
                                <th>ISD</th>
                                <th>Deno</th>
                                <th>Quantity</th>
                                <th>Value</th>
                                <th>Rate</th>
                                <th>Net</th>
                                <th>Walk In</th>
                                <th>Manual No.</th>
                                <th>Party</th>
                                <th>Product</th>
                                <th>Issuer</th>
                                <th>VTC Ref</th>
                                <th>Card No</th>
                                <th>Normal/EEFC Flag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tranReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.entity_name}</td>   {/* Branch */}
                                        <td>{item.prd_type}</td>       {/* Purchase */}
                                        <td>{item.prd_srno}</td>       {/* Transaction */}
                                        <td>{item.formatted_date}</td> {/* Date */}
                                        <td>{item.prd_isd}</td>        {/* ISD */}
                                        <td>{item.prd_deno}</td>       {/* Deno */}
                                        <td>{item.prd_qty}</td>        {/* Quantity */}
                                        <td>{item.prd_deno * item.prd_qty}</td>     {/* Value */}
                                        <td>{item.prd_rate}</td>       {/* Rate */}
                                        <td>{(item.prd_deno * 1) * (item.prd_qty * 1) * (item.prd_rate * 1)}</td>   {/* Net */}
                                        <td>{item.bd_first_name + item.bd_middle_name + item.bd_last_name}</td>  {/* Walk In */}
                                        <td>{item.hdr_manual}</td>     {/* Manual No. */}
                                        <td>{item.corporate_name}</td>    {/* Party */}
                                        <td>{item.prd_product}</td>    {/* Product */}
                                        <td>{item.issuer_name}</td> {/* Issuer */}
                                        <td>{item.prd_from}</td> {/* VTC Ref */}
                                        <td>{item.prd_card_no}</td>    {/* Card No */}
                                        <td>{item.hdr_sale_type}</td>       {/* Normal/EEFC Flag */}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </> : null}




            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>

        </div>
    )
}

export default Transaction_report
