import React, { useState, useEffect } from 'react';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import { Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import * as Picklist from "../../Picklist";
import * as Common from "../../Common";
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Dialog from '../../Dialog';
import $ from "jquery";
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tran_modification_payment = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem("sessionId");
    // const userSrno = sessionStorage.getItem("userSrno");
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [tranLocation, setTranLocation] = useState(null);
    const [tranType, setTranType] = useState("PP");
    const [tranSrno, setTranSrno] = useState("");
    const [locationCode, setLocationCode] = useState(0);
    const [paymentData, setPaymentData] = useState([]);
    const [editViewBtn, setEditViewBtn] = useState(false);

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setTranLocation(response);
            response != null && handleLocationChange(response);
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_MODIFICATION'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });

        // const sessionValues = sessionStorage.getItem('values');
        // if (sessionValues) {
        //     const sessionData = JSON.parse(sessionValues);
        //     if (sessionData.md_type == "PURCHASE") {
        //         Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
        //             let resp = JSON.parse(result);
        //         });
        //         sessionStorage.removeItem('values');
        //     } else {
        //         setScrMode('');
        //     }
        // }
    }, []);

    const handleLocationChange = (value) => {
        setTranLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
    }

    const handleList = () => {
        $(".loader").show();
        if (tranSrno == "") {
            $(".loader").hide();
            setMsgText("Please fill Srno.");
            setMsgBox(true);
        } else {
            const obj = {
                locationCode: locationCode,
                tranType: tranType,
                tranSrno: tranSrno,
            }
            Common.callApi(Common.apiModiPayment, [sid, "getPaymentList", JSON.stringify(obj)], (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                setPaymentData(resp.paymentDetails);
                setEditViewBtn(false);
            });
        }
    }

    const handleBankValue = (value, uniquekey) => {
        const itemToUpdate = paymentData.find(item => item.uniquekey === uniquekey);
        if (!itemToUpdate) return;

        setPaymentData(prevData =>
            prevData.map(row =>
                row.uniquekey === uniquekey
                    ? { ...row, tp_ledger: value.label, tp_group: value.id.split('^')[1], tp_account: value.id.split('^')[2] }
                    : row
            )
        );

    }

    const handleChequeValue = (value, uniquekey) => {
        setPaymentData(prevData =>
            prevData.map(row =>
                row.uniquekey === uniquekey
                    ? { ...row, tp_cheque: value }
                    : row
            )
        );
    }

    // const handleType = (value, uniquekey) => {
    //     setPaymentData(prevData =>
    //         prevData.map(row =>
    //             row.uniquekey === uniquekey
    //                 ? { ...row, tp_type: value }
    //                 : row
    //         )
    //     );
    // }

    // const handlePaymentType = (value, uniquekey) => {
    //     setPaymentData(prevData =>
    //         prevData.map(row =>
    //             row.uniquekey === uniquekey
    //                 ? { ...row, tp_payment_type: value }
    //                 : row
    //         )
    //     );
    // }

    const handleEditRequest = (item) => {
        setEditViewBtn(true);
        setPaymentData(prevData =>
            prevData.map(row =>
                row.uniquekey === item.uniquekey
                    ? { ...row, payment_disable: "0" }
                    : row
            )
        );
    }

    const confirmPayment = (item) => {
        $(".loader").show();
        var msg = [], i = 0;
        if (item.tp_cheque === "" || item.tp_group === "" || item.tp_account === "") {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (item.tp_group === "" ? "Bank" : '');
            msg[i++] = (item.tp_account === "" ? "Bank" : '');
            msg[i++] = (item.tp_cheque === "" ? "Cheque" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                // type: item.tp_type,
                // paymentType: item.tp_payment_type,
                group: item.tp_group,
                account: item.tp_account,
                cheque: item.tp_cheque,
                uniquekey: item.uniquekey,
                srno: item.tp_srno,
                type: item.tp_type,
                location: item.tp_location,
                lineNo: item.tp_line_no,

                locationCode: locationCode,
                tranType: tranType,
                tranSrno: tranSrno,
            }
            Common.callApi(Common.apiModiPayment, [sid, "confirmPayment", JSON.stringify(obj)], (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                // console.log(resp);
                setPaymentData(resp.paymentDetails);
                setEditViewBtn(false);
            });
        }
    }

    // const confirmDeletion = (item) => {
    //     $(".loader").show();
    //     const obj = {
    //         srno: item.tp_srno,
    //         type: item.tp_type,
    //         location: item.tp_location,
    //         lineNo: item.tp_line_no,

    //         locationCode: locationCode,
    //         tranType: tranType,
    //         tranSrno: tranSrno,
    //     }
    //     Common.callApi(Common.apiModiPayment, [sid, "confirmDeletion", JSON.stringify(obj)], (result) => {
    //         $(".loader").hide();
    //         let resp = JSON.parse(result);
    //         console.log(resp);
    //         // setPaymentData(resp);
    //     });
    // }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Transaction Payment Modification</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={tranLocation}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleLocationChange}
                            // isDisabled={branchDisable === "true"}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col className="col-md-3 col-6">
                        <Form.Label>Transaction</Form.Label>
                        <Form.Select
                            value={tranType}
                            onChange={(e) => setTranType(e.target.value)}>
                            <option value="PP">PP</option>
                            <option value="PB">PB</option>
                            <option value="SP">SP</option>
                            <option value="SB">SB</option>
                        </Form.Select>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>SrNo<span className="colorThemeRed">*</span></Form.Label>
                            <Form.Control type='text' maxLength={10} value={tranSrno} onChange={e => Common.validateNumValue(e.target.value, setTranSrno)} />
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Button style={{ marginTop: '32px' }} variant="outline-primary" className="buttonStyle" size="sm" onClick={handleList}> List</Button>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Table responsive bordered striped>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Payment Type</th>
                                <th>Bank</th>
                                <th>Cheque No.</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentData.map((item, index) => (
                                <React.Fragment key={index}>
                                    {/* Product Row */}
                                    <tr>
                                        <td>{item.tp_type}</td>
                                        <td>{item.tp_payment_type == "BN" && "Bank"}</td>
                                        <td>{item.tp_group + ' ' + item.tp_account + ' ' + item.mvli_ledger_name}</td>
                                        <td>{item.tp_cheque}</td>
                                        <td>
                                            {(userRight.edit === "1" && !editViewBtn) ?
                                                <span onClick={() => handleEditRequest(item)} className='handCursor colorThemeBlue'>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </span> : null}
                                        </td>
                                    </tr>

                                    {/* Payment Row with Editable Fields */}
                                    {
                                        editViewBtn ?
                                            <>

                                                <tr>
                                                    <td colSpan="13">
                                                        <table style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>Type</th>
                                                        <th>Payment Type</th> */}
                                                                    <th>Bank</th>
                                                                    <th>Cheque No.</th>
                                                                    <th>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    {/* <td>
                                                            {(userRight.edit === "1" && !editViewBtn) ?
                                                                <span onClick={() => confirmDeletion(item)} className='colorThemeBlue pointer'>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </span> : null}
                                                        </td> */}
                                                                    {/* <td className='col-md-3 col-6'>
                                                            <Form.Select
                                                                value={item.tp_type}
                                                                onChange={e => handleType(e.target.value, item.uniquekey)}
                                                                // disabled={item.payment_disable === "1"}
                                                                disabled
                                                            >
                                                                <option value="PP">PP</option>
                                                                <option value="PB">PB</option>
                                                                <option value="SP">SP</option>
                                                                <option value="SB">SB</option>
                                                            </Form.Select>
                                                        </td>
                                                        <td className='col-md-3 col-6'>
                                                            <Form.Control
                                                                type="text"
                                                                maxLength={5}
                                                                value={item.tp_payment_type}
                                                                onChange={e => handlePaymentType(e.target.value, item.uniquekey)}
                                                                // disabled={item.payment_disable === "1"}
                                                                disabled
                                                            />
                                                        </td> */}
                                                                    <td className='col-md-3 col-6' style={{ position: "absolute" }}>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={{ label: item.tp_ledger, id: item.tp_group + '^' + item.tp_account + '^' + item.mvli_ledger_name }}
                                                                            getOptionLabel={e => e.label}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', locationCode)}
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={(value) => handleBankValue(value, item.uniquekey)}
                                                                        // isDisabled={item.payment_disable === "1"}
                                                                        />
                                                                    </td>
                                                                    <td className='col-md-3 col-6'>
                                                                        <Form.Control
                                                                            type="text"
                                                                            maxLength={50}
                                                                            value={item.tp_cheque}
                                                                            onChange={e => handleChequeValue(e.target.value, item.uniquekey)}
                                                                        // disabled={item.payment_disable === "1"}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {(userRight.edit === "1" && editViewBtn) ?
                                                                            <span onClick={() => confirmPayment(item)} className='colorThemeBlue pointer'>
                                                                                <FontAwesomeIcon icon={faSave} />
                                                                            </span> : null} &nbsp; &nbsp;
                                                                        {/* {(userRight.edit === "1" && editViewBtn) ?
                                                                <span onClick={() => handleEditRequest(item)} className='handCursor colorThemeBlue'>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </span> : null} */}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </> : null
                                    }
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
        </>
    )
}

export default Tran_modification_payment;
