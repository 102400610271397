import { faCircleCheck, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import Dialog_IRN from '../Dialog_IRN';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Master_individual_profile from '../master/Master_individual_profile';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_data_unlock_request from '../Tran_data_unlock_request';
import Tran_commission_view from './Tran_commission_view';

function Tran_sp() {
    const productRef = useRef();
    const [radioType, setRadioType] = useState("N");
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [key, setKey] = useState("INFO");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [scrMode, setScrMode] = useState("");
    const [userRight, setUserRight] = useState([]);
    const [residentOptions, setResidentOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const [hdrSrno, setHdrSrno] = useState(0);
    const [spList, setSPList] = useState([]);

    const [spLocation, setSPLocation] = useState(null);
    const [spLocationValue, setSPLocationValue] = useState("");
    const [spFromDate, setSPFromDate] = useState(new Date());
    const [spToDate, setSPToDate] = useState(new Date());
    const [spIndividual, setSPIndividual] = useState(null);
    const [spSrnoFrom, setSPSrnoFrom] = useState("");
    const [spSrnoTo, setSPSrnoTo] = useState("");
    const [spNewDate, setSPNewDate] = useState(new Date());
    const [spStateName, setSPStateName] = useState("");
    const [spStateValue, setSPStateValue] = useState("");
    const [spStateType, setSPStateType] = useState("");
    const [spSaleType, setSPSaleType] = useState("N");
    const [spProductList, setSPProductList] = useState([]);

    const [nExecutive, setNExecutive] = useState(null);
    const [nTraveller, setNTraveller] = useState(null);
    const [nPurpose, setNPurpose] = useState(null);
    const [nPurposeId, setNPurposeId] = useState("");
    const [nResident, setNResident] = useState(1);
    const [nTravellerCode, setNTravellerCode] = useState("");
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [nCorporate, setNCorporate] = useState(null);
    const [nCountry, setNCountry] = useState(null);
    const [nDepDate, setNDepDate] = useState(null);
    const [nTicketNo, setNTicketNo] = useState("");
    const [nFlightNo, setNFlightNo] = useState("");
    const [nDuration, setNDuration] = useState("");
    const [nVisaType, setNVisaType] = useState("");
    const [nTicketClass, setNTicketClass] = useState("");
    const [nPanForm60, setNPanForm60] = useState("");
    const [nMode, setNMode] = useState("B");

    const [pIssuerGroup, setPIssuerGroup] = useState("");
    const [pIssuerAccount, setPIssuerAccount] = useState("");
    const [pIssuer, setPIssuer] = useState(null);
    const [pIsd, setPIsd] = useState(null);
    const [pIsdId, setPIsdId] = useState(0);
    const [pDenomination, setPDenomination] = useState("");
    const [pQuantity, setPQuantity] = useState("");
    const [pRate, setPRate] = useState("");
    const [pProduct, setPProduct] = useState("CN");
    const [prPrefix, setPrPrefix] = useState("");
    const [prFrom, setPrFrom] = useState(null);
    const [prFromValue, setPrFromValue] = useState("");
    const [prTo, setPrTo] = useState("");
    const [pVtmCardNo, setPVtmCardNo] = useState("");
    const [isCommission, setIsCommission] = useState("0");
    const [pAgent, setPAgent] = useState(null);
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [maxPRate, setMaxPRate] = useState(0);
    const [minPRate, setMinPRate] = useState(0);

    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);
    const [pSaleReload, setPSaleReload] = useState("S");
    const [disableOtherCharge, setDisableOtherCharge] = useState(true);
    const [nServiceType, setNServiceType] = useState("");

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [pdAccountValue, setPdAccountValue] = useState("");
    const [pdGroupValue, setPdGroupValue] = useState(0);
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentData, setPaymentData] = useState([]);
    const [chequeBankName, setChequeBankName] = useState("");
    const [chequeDate, setChequeDate] = useState(null);
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [paymentLineNo, setPaymentLineNo] = useState(0);
    const [showMinMaxRateModal, setShowMinMaxRateModal] = useState(false);
    const [minMaxRate, setMinMaxRate] = useState([]);

    const [counter, setCounter] = useState(0);
    const [productLineNo, setProductLineNo] = useState(1);

    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [nTravellerSrno, setNTravellerSrno] = useState("");
    const [scrIndividual, setScrIndividual] = useState("");

    const [commission, setCommission] = useState("");
    const [hdrDate, setHdrDate] = useState(null);
    const [hdrLocation, setHdrLocation] = useState("");

    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [usdRate, setUSDRate] = useState(0);
    const [travellerSelected, setTravellerSelected] = useState(0);
    const [showPreviousTransactionModal, setShowPreviousTransactionModal] = useState(false);
    const [preTransaction, setPreTransaction] = useState([]);
    const [showLRSTransactionModal, setShowLRSTransactionModal] = useState(false);
    const [lrsTransaction, setLrsTransaction] = useState([]);

    const [travellerPan, setTravellerPan] = useState("");
    const [disablePaymentAccount, setDisablePaymentAccount] = useState(true);

    const [isMsgBoxCreditLimit, setMsgBoxCreditLimit] = useState(false);
    const [msgTextCreditLimit, setMsgTextCreditLimit] = useState("");
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [filterProduct, setFilterProduct] = useState("C");

    const [showCommissionDetail, setShowCommissionDetail] = useState(false);
    const [isMsgBoxCancelBill, setMsgBoxCancelBill] = useState(false);
    const [msgTextCancelBill, setMsgTextCancelBill] = useState("");

    const [isIrnGenerated, setIsIrnGenerated] = useState("0");
    const [irnObj, setIrnObj] = useState({ irn: "", ackdate: "", acknum: "", signedqr: "" });
    const [isIrnCancelled, setIsIrnCancelled] = useState("0");
    const [irnCancelDate, setIrnCancelDate] = useState("");
    const [irnCancelReason, setIrnCancelReason] = useState("");
    const [irnCancelLabel, setIrnCancelLabel] = useState("");
    const [irnCancelDeclaration, setIrnCancelDeclaration] = useState(false);

    const [hdrDateYmd, setHdrDateYmd] = useState("");
    const [isIrnApplicable, setIsIrnApplicable] = useState("N");
    const [corpServiceCharge, setCorpServiceCharge] = useState(0);

    const [irnCancelReasonHdr, setIrnCancelReasonHdr] = useState("");
    const [irnCancelReasonSelect, setIrnCancelReasonSelect] = useState("");
    const [irnCancelRemarkHdr, setIrnCancelRemarkHdr] = useState("");

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setSPLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_SP'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        // Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", pProduct], result => {
        //     setIsdOptions(JSON.parse(result)); 
        // });
        Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
            setResidentOptions(JSON.parse(result));
        });

        const sessionValues = sessionStorage.getItem('values');
        if (sessionValues) {
            const sessionData = JSON.parse(sessionValues);
            if (sessionData.md_type == 'SALE') {
                setScrMode('A');
                Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
                    let resp = JSON.parse(result);
                    console.log("RESPONSE IS  ", resp);
                    handleTravellerChange({ ...resp, desc: `${resp.desc.replaceAll('^', '  ^  ')}` })
                    // setNTraveller(resp);
                });
            } else {
                setScrMode('');
            }
        }
    }, []);

    const handleLocationChange = (value) => {
        setSPLocation(value);
        var id = value.id;
        var location = id.split("^");
        setSPLocationValue(location[1]);
        setSPStateValue(location[3] == null ? 0 : location[3]);
        setSPStateType(location[4]);
        setSPStateName(location[5] === "" ? "Select" : location[5]);

        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const handleIndiviualChange = (value) => {
        setSPIndividual(value);
    }

    const listSPdata = () => {
        var msg = [], i = 0;
        $(".loader").show();
        if (spLocation === null || (radioType === 'S' && !spSrnoFrom) || (radioType === 'S' && !spSrnoTo) || (radioType === 'D' && !spFromDate) || (radioType === 'D' && !spToDate) || (radioType === 'I' && spIndividual === null) || (radioType === 'I' && !spFromDate) || (radioType === 'I' && !spToDate) || (radioType === 'C' && nCorporate === null) || (radioType === 'C' && !spFromDate) || (radioType === 'C' && !spToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (spLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !spSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !spSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !spToDate ? " To Date." : '');
            msg[i++] = (radioType === 'I' && spIndividual === null ? " Individual." : '');
            msg[i++] = (radioType === 'I' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'I' && !spToDate ? " To Date." : '');
            msg[i++] = (radioType === 'C' && nCorporate === null ? " Corporate." : '');
            msg[i++] = (radioType === 'C' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'C' && !spToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                type: "SP",
                radioType: radioType,
                spLocation: spLocationValue,
                //ppCorporate: ppCorporate,
                spIndividual: spIndividual,
                spNewDate: Common.dateYMD(spNewDate),
                spSrnoTo: spSrnoTo,
                spSrnoFrom: spSrnoFrom,
                spFromDate: Common.dateYMD(spFromDate),
                spToDate: Common.dateYMD(spToDate)
            }
            Common.callApi(Common.apiTranSP, [sid, "splist", JSON.stringify(obj)], result => {
                console.log(result);
                $(".loader").hide();
                setSPList(JSON.parse(result));
            });
        }
    }

    const addNewForm = () => {
        var d = Common.dateYMD(spNewDate);
        var ddmy = Common.dateDMY(spNewDate);
        var today = Common.dateYMD(new Date());
        if (spLocation == null) {
            setMsgBox(true);
            setMsgText("Please select location");
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else if (d > today) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0011"));
        } else {
            setHdrDate(ddmy);

            setScrMode('A');
            setKey('INFO');
            setProductLineNo(1);
            setHdrSrno(0);
            setNExecutive(null);
            setNMode("B");
            setNTraveller(null);
            setNPurpose(null);
            setNResident(1);
            setNTravellerCode("");
            setNCorporate(null);
            console.log(spStateValue, '----', spStateName);
            if (spStateValue * 1 > 0 && spStateValue != null) setNState({ value: spStateValue, label: spStateName });
            else setNState({ value: 0, label: "Select" });
            setNPanForm60("");
            setNCountry(null);
            setNTicketClass("");
            setNTicketNo("");
            setNFlightNo("");
            setNDepDate(null);
            setNDuration("");
            setNVisaType("");
            setPForexTotal(0);
            setPTotalAmount(0);
            setPCgst(0);
            setPIgst(0);
            setPUgst(0);
            setPSgst(0);
            setPRoundOff(0);
            setPOtherCharges(0);
            setSPProductList([]);
            setPaymentData([]);
            setTravellerSelected(0);
            setCorpServiceCharge(0);

            Common.callApi(Common.apiState, [sid, "getState", 1], result => {
                setStateOptions(JSON.parse(result));
            });
        }
    }

    const fillViewForm = (hdrsrno, location, type, date) => {
        setHdrSrno(hdrsrno);
        setKey('INFO');
        setPrFrom(null);
        setPrFromValue("");
        setViewDate(date);
        //setRandomString("0");
        $(".loader").show();
        const obj = {
            srno: hdrsrno,
            spLocationValue: location,
            type: type
        }
        Common.callApi(Common.apiTranSP, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("Q");
                setSPSaleType(resp.saletype);
                setNTraveller({ id: resp.ind_id, label: resp.ind_label, desc: resp.desc });
                setNTravellerSrno(resp.individual_srno);
                if (resp.executive == null) setNExecutive(null);
                else setNExecutive({ id: resp.executive, label: resp.executive_name });
                setNMode(resp.mode);
                setNPurpose({ id: resp.purpose, label: resp.purpose_name });
                setNServiceType(resp.service_type);
                setNCountry({ id: resp.cntsrno, label: resp.cntname });
                setNResident(resp.residential);
                if (resp.corp_id == null) {
                    setNCorporate(null);
                    setCorpServiceCharge(0);
                } else {
                    setNCorporate({ id: resp.corp_id, label: resp.corp_label });
                    setCorpServiceCharge(resp.corp_id.split("^")[8]);
                }
                setNTravellerCode(resp.emp_code);
                setNState({ value: resp.state, label: resp.statename });
                setNPanForm60(resp.panform60);
                setNTicketClass(resp.ticketclass);
                setNFlightNo(resp.flightno);
                setNTicketNo(resp.ticketno);
                setNDepDate(new Date(resp.depdate));
                setNDuration(resp.duration)
                setNVisaType(resp.visatype);

                setPForexTotal(resp.forextotal);
                setPCgst(resp.cgst);
                setPSgst(resp.sgst);
                setPIgst(resp.igst);
                setPUgst(resp.ugst);
                setPRoundOff(resp.round_off);
                setPTotalAmount(resp.total_inr);
                setPOtherCharges(resp.other_charge);
                setPTaxableVal(resp.taxablevalue);

                setSPProductList(resp.productlist);
                setPaymentData(resp.paydetails);
                setPaymentLineNo(resp.paymaxlineno);
                setProductLineNo(resp.maxlineno);

                setHdrDate(resp.date);
                setHdrLocation(resp.locname);
                setHdrSrno(resp.srno);
                setTravellerSelected(0);
                setTravellerPan(resp.ind_pan);

                setHdrDateYmd(resp.dateymd);
                setIsIrnApplicable(resp.is_irn_applicable);

                setIsIrnGenerated(resp.isIrnGenerated);
                setIsIrnCancelled(resp.isIrnCancelled);
                setIrnCancelDate(resp.irncanceldt);
                setIrnCancelRemarkHdr(resp.irncancelrmk);
                if (resp.isIrnGenerated === "1") {
                    setIrnObj({
                        irn: resp.irn,
                        ackdate: resp.ackdate,
                        acknum: resp.ackno,
                        signedqr: resp.signedqr
                    });
                }
                if (!(resp.irncancelreason == null || resp.irncancelreason == "")) {
                    setIrnCancelReasonHdr(Common.getIrnCancelReason.find(item => item.id == resp.irncancelreason).desc);
                }
            }
        });
    }

    const handleExecutiveChange = (value) => {
        setNExecutive(value);
    }

    const handleTravellerChange = (value) => {
        setNTraveller(value);
        var a = value.id.split("^");
        setNTravellerSrno(a[1]);
        setTravellerSelected(1);
        setTravellerPan(a[4]);
    }

    const handlePurposeChange = (value) => {
        setNPurpose(value);
        var p = value.id.split("^");
        setNPurposeId(p[2]);
    }

    const handleCorporateChange = (value) => {
        setNCorporate(value);
        var id = value.id;
        var corp = id.split("^");
        setNState({ value: corp[3], label: corp[4] });
        if (corp[8] > 0) otherChargeChange(corp[8]);
        setCorpServiceCharge(corp[8] == "" ? 0 : corp[8]);
    }

    const handleCountryChange = (value) => {
        setNCountry(value);
    }

    const handleTabChange = (k) => {
        setKey(k);
        if (k === "PAYMENT") {
            setPdAccount(null);
            setPdPaymentType("");
            const amt = paymentData.reduce((total, payment) => total + payment.tp_amount * 1, 0);
            setPdAmount(1 * pTotalAmount - amt);
        }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    // const cancelData = () => {
    //     setMsgBoxYN(false);
    //     setScrMode("");
    // }

    const cancelData = () => {
        setMsgBoxYN(false);
        const sessionData = JSON.parse(sessionStorage.getItem('values'));
        if (sessionData && sessionData.md_type === "SALE") {
            navigate('/individual-profile');
            sessionStorage.removeItem('values');
            sessionStorage.setItem('flag', '1');
        } else {
            setScrMode("");
        }
    }

    const handleProductChange = (v) => {
        setPProduct(v);
        setPIsd(null);
        setSPSaleType("S");
        setPIssuer(null);
        setPDenomination("");
        if (v === "VTM") setPQuantity(1);
        else setPQuantity("");
        setPRate("");
        setPrFrom(null);
        setPrFromValue("");
        setPrPrefix("");
        setPrTo("");
        setPVtmCardNo("");
    }

    const handleDenoChange = (v) => {
        Common.validateDecValue(v, setPDenomination);
        setCommValue(calcCommission(commission, commType, pQuantity, v, pRate));
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        if (pProduct === "")
            setPrTo((v * 1 + prFrom * 1) - 1);
        setCommValue(calcCommission(commission, commType, v, pDenomination, pRate));
    }

    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setCommValue(calcCommission(commission, commType, pQuantity, pDenomination, v));
    }

    const handleFromChange = (value) => {
        setPrFrom(value);
        var fr = value.id.split("^");
        var v = fr[1];
        setPrFromValue(v);
        setPrTo((v * 1 + pQuantity * 1) - 1);
        if (pSaleReload === "R") setPVtmCardNo(fr[7]);
    }

    const handleChangeIsd = (value) => {
        setPIsd(value);
        let a = value.id.split("^");
        setPIsdId(a[1]);
        setPAgent(null);
        setCommType("");
        setCommValue("");
        setPrFrom(null);
        setPrFromValue("");
        const obj = {
            isd: a[1],
            location: spLocationValue,
            product: pProduct
        }
        Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPRate(Math.round(resp.salerate * 1000) / 1000);//round off upto 3 digit
            setMaxPRate(resp.maxsalerate * 1);
            setMinPRate(resp.minsalerate * 1);
            setUSDRate(resp.usdratesale * 1);
        });
    }

    const saveData = () => {
        $(".loader").show();

        var totalinr = 0;
        var totalCash = 0;
        var today = Common.dateYMD(new Date());
        var depdatedym = 0;
        if (nDepDate !== null) {
            depdatedym = Common.dateYMD(nDepDate);
        }
        for (var k = 0; k < paymentData.length; k++) {
            if (paymentData[k].tp_payment_type === "CH")
                totalCash += paymentData[k].tp_amount * 1;
            if (paymentData[k].tp_payment_type === "PB") //RC
                totalinr -= paymentData[k].tp_amount * 1;
            else totalinr += paymentData[k].tp_amount * 1;
        }

        // console.log(pTotalAmount,"pTotalAmount");
        // console.log(totalinr,"totalinr");

        var msg = [], i = 0;
        if (nTraveller === null || nPurpose === null || nCountry === null || nTicketNo === "" || nFlightNo === "" || nDepDate == null ||
            nDuration === "" || travellerPan.trim() === "" || travellerPan === null || depdatedym < today
            || (corpServiceCharge > 0 && pOtherCharges < corpServiceCharge)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (nTraveller === null ? "Traveller" : '');
            msg[i++] = (travellerPan.trim() === "" || travellerPan === null ? "Traveller PAN is mendatory." : '');
            msg[i++] = (nPurpose === null ? "Purpose" : '');
            msg[i++] = (nCountry === null ? "Country" : '');
            msg[i++] = (nFlightNo === "" ? "Flight Number" : '');
            msg[i++] = (nTicketNo === "" ? "Ticket Number" : '');
            msg[i++] = (nDepDate === null ? "Departure Date" : '');
            msg[i++] = (nDuration === "" ? "Duration of stay abroad" : '');
            msg[i++] = (depdatedym < today ? "Departure date can't be before today." : '');
            msg[i++] = ((corpServiceCharge > 0 && pOtherCharges < corpServiceCharge) ? "Other charges can't be less than pre-set service charges." : "");

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pTotalAmount != totalinr) {
            $(".loader").hide();
            setMsgText("Total amount of payment must be equal to calculated INR.");
            setMsgBox(true);
        } else {
            const obj = {
                srno: hdrSrno,
                spLocationValue: spLocationValue,
                spSaleType: spSaleType,
                spNewDate: Common.dateYMD(spNewDate),
                nCorporate: nCorporate,
                nCountry: nCountry,
                nState: nState.value,
                nResident: nResident,
                nTraveller: nTraveller,
                nPurpose: nPurpose,
                nExecutive: nExecutive,
                pForexTotal: pForexTotal,
                nFlightNo: nFlightNo,
                nTicketNo: nTicketNo,
                nVisaType: nVisaType,
                nDepDate: Common.dateYMD(nDepDate),
                nTicketClass: nTicketClass,
                nDuration: nDuration,
                nPanForm60: nPanForm60,
                nMode: nMode,
                pSgst: pSgst,
                pIgst: pIgst,
                pUgst: pUgst,
                pCgst: pCgst,
                pOtherCharges: pOtherCharges,
                pRoundOff: pRoundOff,
                pTaxableVal: pTaxableVal,
                pTotalAmount: pTotalAmount,
                nTravellerCode: nTravellerCode,
                //randomString: randomString,
                spProductList: spProductList,
                paymentData: paymentData,
                prd_usd_rate: usdRate == "" ? 0 : usdRate,

                radioType: radioType,
                spLocation: spLocationValue,
                //ppCorporate: ppCorporate,
                spIndividual: spIndividual,
                spSrnoTo: spSrnoTo,
                spSrnoFrom: spSrnoFrom,
                spFromDate: Common.dateYMD(spFromDate),
                spToDate: Common.dateYMD(spToDate),
                ppStateType: spStateType,
                ppStateValue: spStateValue
            }

            let isOk = true;
            if (isOk) {
                Common.callApi(Common.apiTranSP, [sid, "savedata", JSON.stringify(obj), JSON.stringify(tranAuditTrail)], result => {
                    $(".loader").hide();
                    let resp = JSON.parse(result);
                    if (resp.msg === "MSG0000") {
                        setSPList(resp.spList);
                        setScrMode("");
                        // listSPdata();
                        if (typeof resp.irn.Status !== "undefined") {
                            if (resp.irn.Status == "0") {
                                alert(JSON.stringify(resp.irn.ErrorDetails));
                            }
                        }
                        setMsgBox(true);
                        setMsgText(Common.getMessage(resp.msg));
                        setTranAuditTrail([]);
                    } else {
                        setMsgBox(true);
                        setMsgText(resp.msg);
                    }
                });

                if (scrMode == 'E') {
                    const obj = {
                        srno: hdrSrno,
                        type: 'SP',
                        status: 'E',
                        currentDate: Common.dateYMD(currentDate),
                        pageright: 'TRAN_SP'
                    }
                    Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                        console.log(result);
                    });
                }
            }
        }
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: hdrSrno,
            type: 'SP',
            pageright: 'TRAN_SP'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');



            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'SP', trannumber: hdrSrno, location: spLocationValue, tranDate: Common.dateYMD(currDate) });
            }
        })


    }

    // const editData = () => {
    //     setScrMode("E");
    // }

    const otherChargeChange = (v) => {
        Common.validateDecValue(v, setPOtherCharges);
        const obj = {
            tranType: "SP",
            type: "SALE",
            pOtherCharges: v === "" ? 0 : v,
            ppStateType: spStateType,
            ppStateValue: spStateValue,
            nState: nState.value,
            nCorporate: nCorporate
        }
        calculateGST(spProductList, obj);
    }

    const addProduct = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if ((pIsd == null || pDenomination === "" || pQuantity === "" || pRate === "")
            ||
            (pProduct === "VTM" && (prFrom == null || pVtmCardNo === "" || pIssuer == null))
            ||
            (isCommission === "1" && (commType === "" || pAgent === null || commValue === ""))
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd == null ? "Please select Isd." : '');
            msg[i++] = (pDenomination === '' ? "Please fill Denomination." : '');
            msg[i++] = (pQuantity == '' ? "Please fill Quantity." : '');
            msg[i++] = (pRate == '' ? "Please fill Rate." : '');
            if (pProduct === "VTM" && (prFrom == null || prTo == "" || pIssuer == null)) {
                msg[i++] = (pIssuer == null ? "Issuer." : '');
                msg[i++] = (prFrom == null ? "From." : '');
                msg[i++] = (pVtmCardNo == '' ? "Card Number." : '');
            }
            if (isCommission === "1" && (commType === "" || pAgent === null || commValue === "")) {
                msg[i++] = (pAgent == null ? "Agent." : '');
                msg[i++] = (commType == '' ? "Commission type." : '');
                msg[i++] = (commValue == '' ? "Commission value." : '');
            }
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pProduct === "VTM" && (prTo * 1 - prFrom * 1 > 100)) {
            $(".loader").hide();
            setMsgText("'To' must be less than 100.");
            setMsgBox(true);
        } else if (maxPRate != 0 && minPRate != 0 && (pRate > maxPRate || pRate < minPRate)) {
            $(".loader").hide();
            msg[i++] = (pRate > maxPRate ? "Maximum rate is " + maxPRate : '');
            msg[i++] = (pRate < minPRate ? "Minimum rate is " + minPRate : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var isdarr = pIsd.id.split("^");
            var agentgrp = 0;
            var agentaccnt = 0;
            var agentname = "";

            var issgrp = 0;
            var issaccnt = 0;
            if (pAgent !== null) {
                var agent = pAgent.id.split("^");
                agentgrp = agent[1];
                agentaccnt = agent[2];
                agentname = agent[5];
            }
            if (pIssuer !== null) {
                var issuer = pIssuer.id.split("^");
                issgrp = issuer[1];
                issaccnt = issuer[2];
            }
            var usdAmt = 0;
            if (usdRate != "" && usdRate != 0) {
                usdAmt = (pRate * pQuantity * pDenomination) / usdRate;
            }
            const obj = {
                tranType: "SP",
                type: "SALE",
                uniqueKey: Common.getRandomString(15),
                prd_line_no: productLineNo,
                prd_location: spLocationValue,
                prd_type: 'SP',
                prd_srno: hdrSrno,
                prd_isd: isdarr[1],
                prd_date: Common.dateYMD(spNewDate),
                prd_product: pProduct,
                prd_deno: pDenomination,
                prd_qty: pQuantity,
                prd_rate: pRate,
                prd_amount: pRate * pQuantity * pDenomination,
                prd_from: prFromValue,
                prd_prefix: prPrefix,
                prd_to: prTo,
                prd_group: issgrp,
                prd_account: issaccnt,
                prd_agent_group: agentgrp,
                prd_agent_ledger: agentaccnt,
                prd_card_no: pVtmCardNo,
                isd_name: isdarr[2],
                prd_commission_type: commType,
                prd_commission_value: commValue == "" ? 0 : commValue,
                prd_commission: commission == "" ? 0 : commission,
                prd_sale_reload: pSaleReload,
                nCorporate: nCorporate,
                nState: nState.value,
                pOtherCharges: pOtherCharges,
                ppStateType: spStateType,
                ppStateValue: spStateValue,
                scrMode: scrMode,
                prFrom: prFrom,
                prd_usd_rate: usdRate.toFixed(2),
                prd_usd_amount: usdAmt.toFixed(2),
                delny: "Y",
                isCommission: isCommission,
                prd_agent_name: agentname
            }
            var productlist = Common.arrayAddItem(spProductList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                // TLine: productLineNo + 1,
                TLoc: spLocationValue,
                TType: 'SP',
                // TNum?
                Product: pProduct,
                ISD: isdarr[2],
                Deno: pDenomination,
                Quantity: pQuantity,
                Rate: pRate,
                Amount: pRate * pQuantity * pDenomination,
                // ...(pProduct === "ETC" ? {
                ProductFrom: prFrom,
                ProductTo: prTo,
                ProductPrefix: prPrefix,
                ProductGroup: issgrp,
                ProductAccout: issaccnt,
                // } : {}),
                CommsnType: commType,
                AgentGroupCode: agentgrp,
                AgentLedgerCode: agentaccnt,
                CommsnRate: commValue == "" ? 0 : commValue,
                CommsnAmount: commission == "" ? 0 : commission,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setSPProductList(productlist);
            setCounter(counter + 1);
            setProductLineNo(1 * productLineNo + 1);
            setPIsd(null);
            setPIssuer(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setPrPrefix("");
            setPrFrom(null);
            setPrFromValue("");
            setPrTo("");
            setPVtmCardNo("");
            setCommValue("");
            //setCommission("");
            setCommType();
            setPAgent(null);
            setCommType("");
            setCommission("");
            calculateGST(productlist, obj);
        }
    }

    const calculateGST = (productlist, obj) => {
        Common.callApi(Common.apiCalcTaxable, [sid, "calcinvoice", JSON.stringify(productlist), JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPForexTotal(resp.fxamt);
            setPSgst(resp.sgst);
            setPTotalAmount(resp.totalamt);
            setPTaxableVal(resp.taxablevalue);
            setPRoundOff(resp.roundoff);
            setPCgst(resp.cgst);
            setPIgst(resp.igst);
            setPUgst(resp.ugst);
            var amt = 0;
            for (var i = 0; i < paymentData.length; i++) {
                amt += paymentData[i].tp_amount * 1;
            }
            setPdAmount(1 * resp.totalamt - amt);
        });
    }

    const deleteProduct = (key) => {
        const itemToDelete = spProductList.find(item => item.uniquekey === key);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: spLocationValue,
                TType: 'SP',
                Product: itemToDelete.prd_product,
                ISD: itemToDelete.isd_name,
                Deno: itemToDelete.prd_deno,
                Quantity: itemToDelete.prd_qty,
                Rate: itemToDelete.prd_rate,
                Amount: itemToDelete.prd_amount,
                ProductFrom: itemToDelete.prd_from,
                ProductTo: itemToDelete.prd_to,
                ProductPrefix: itemToDelete.prd_prefix,
                ProductGroup: itemToDelete.prd_group,
                ProductAccout: itemToDelete.prd_account,
                AgentGroupCode: itemToDelete.prd_agent_group,
                AgentLedgerCode: itemToDelete.prd_agent_ledger,
                CommsnType: itemToDelete.prd_commission_type,
                CommsnRate: itemToDelete.prd_commission_value,
                CommsnAmnt: itemToDelete.prd_commission,
            }
            console.log(formattedItem);
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        const obj = {
            tranType: "SP",
            type: "SALE",
            pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
            ppStateType: spStateType,
            ppStateValue: spStateValue,
            nState: nState.value,
            nCorporate: nCorporate
        }
        const productlist = Common.arrayRemoveItem(spProductList, "uniqueKey", key);
        setSPProductList(productlist);
        setCounter(counter - 1);
        calculateGST(productlist, obj);
    }

    const print = (printflag) => {
        const obj = {
            srno: hdrSrno,
            location: spLocationValue,
            type: 'SP',
            printFlag: printflag,
        }
        Common.callApi(Common.apiPrint, [sid, "print", JSON.stringify(obj)], result => {
            var newWindow = window.open();
            newWindow.document.write(result);
        });
    }

    const newIndividual = (btnype) => {
        setShowIndividualForm(true);
        if (btnype === "E" && nTraveller != null) setScrIndividual("E");
        else setScrIndividual("A");
    }

    const hideIndividualProfile = (val, individualsrno) => {
        console.log('hideIndividualProfile', val, individualsrno);
        setShowIndividualForm(val);
        if (individualsrno > 0) {
            const obj = {
                individualsrno: individualsrno,
                type: "SP"
            }
            Common.callApi(Common.apiIndividualProfile, [sid, "individualidfrompicklist", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                const desc = resp.desc.split("^");
                if (resp.id === "") setNTraveller(null);
                else {
                    setNTraveller({ ...resp, desc: `${desc.slice().join("  ^  ")}` })
                    setTravellerPan(resp.desc.split('^')[1].trim())
                };
            });
        }
    }

    const handleAccountValue = (value) => {
        setPdAccount(value);
        var id = value.id.split("^");
        setPdGroupValue(id[1]);
        setPdAccountValue(id[2]);
    }

    const handlePaymentType = (v) => {
        setDisablePaymentAccount(false);
        setPdPaymentType(v);
        setPdAccount(null);
        setPdAccountValue(0);
        setPdChequeNum("");
        var ldgType = "";
        if (v === "") return;
        if (v === "CH") {
            ldgType = "CASH";
        } else if (v === "BN") {
            ldgType = "BANK";
            setLdgSrchType("BANK");
            return;
        } else if (v === "CQ") {
            ldgType = "CHEQUE";
        } else if (v === "RC") {
            ldgType = "WALKIN";
            if (nCorporate !== null) {
                setPdAccount(nCorporate);
                setDisablePaymentAccount(true);
                return;
            }
        } else {
            ldgType = "WALKIN";
        }
        setLdgSrchType(ldgType);
        Common.callApi(Common.apiLedger, [sid, "getledgerinfo", ldgType], result => {
            let resp = JSON.parse(result);
            setPdAccount({ id: resp.id, label: resp.label });
        });
    }

    const isCreditAllowed = (obj1) => {
        return new Promise((resolve, reject) => {
            Common.callApi(Common.apiTranPP, [sid, "creditAllowed", JSON.stringify(obj1)], result => {
                const response = JSON.parse(result);
                resolve(response);
            });
        });
    }

    const addPayment = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (pTotalAmount < 1) {
            $(".loader").hide();
            msg[i++] = (pTotalAmount < 1 ? "Total amount must be greater than 0." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pdPaymentType === "" || pdAccount === null || pdAmount < 1
            || (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null))
            || (pdPaymentType === "BN" && pdChequeNum === "")
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pdAccount === null ? "Account." : '');
            msg[i++] = (pdPaymentType === "" ? "Payment Type." : '');
            msg[i++] = (pdAmount < 1 ? "Entered amount can't be 0." : '');
            if (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null)) {
                msg[i++] = (pdChequeNum === "" ? "Cheque Number." : '');
                msg[i++] = (chequeBankName === "" ? "Bank Name." : '');
                msg[i++] = (chequeDate === null ? "Cheque Date" : '');
            }
            if (pdPaymentType === "BN" && pdChequeNum === "") msg[i++] = (pdChequeNum === "" ? "Refernece Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var creditAllowed = true;
            if (creditAllowed) {
                var grp = 0;
                var accnt = 0;
                var accntname = '';
                if (pdAccount !== null) {
                    var a = pdAccount.id.split("^");
                    grp = a[1];
                    accnt = a[2];
                    accntname = a[5];
                }
                const obj = {
                    uniquekey: Common.getRandomString(10),
                    tp_line_no: paymentLineNo + 1,
                    tp_location: spLocationValue,
                    tp_srno: hdrSrno,
                    //randomString: randomString,
                    tp_payment_type: pdPaymentType,
                    tp_account: accnt,
                    tp_group: grp,
                    mvli_ledger_name: accntname,
                    tp_amount: pdAmount,
                    tp_cheque: pdChequeNum,
                    tp_cheque_bank: chequeBankName,
                    tp_cheque_date: pdPaymentType === "CQ" ? Common.dateYMD(chequeDate) : "NULL",
                    tp_type: "SP",
                    delny: 'Y'
                }
                const paymentdata = Common.arrayAddItem(paymentData, obj);
                const obj2 = {
                    TabType: "Payment",
                    AType: "A",
                    ALineNo: tranAuditTrail.length + 1,
                    // TLine: paymentLineNo + 1,
                    TLoc: spLocationValue,
                    TType: 'SP',
                    PaymentType: pdPaymentType,
                    PaymentLedgerCode: accnt,
                    PaymentGroupCode: grp,
                    PaymentAccountName: accntname,
                    PaymentAmount: pdAmount,
                    // ...(pdPaymentType == "BN" ? {
                    ReferenceNo: pdChequeNum
                    // } : {}),

                }
                if (userRight.edit === "1" && scrMode === 'E') {
                    setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
                }
                setPaymentData(paymentdata);
                setPdPaymentType("");
                setPaymentLineNo((1 * paymentLineNo) + 1);
                //setPdAmount("");
                setPdAccount(null);
                setPdChequeNum("");
                setChequeBankName("");
                setChequeDate(null);
                setCounter(counter + 1);
                const amt = paymentdata.reduce((total, payment) => total + payment.tp_amount * 1, 0);
                setPdAmount(1 * pTotalAmount - amt);
            } else {
                setMsgBox(true);
                setMsgText("Credit not allowed to selected account");
            }
        }
    }

    const deletePayment = (value, delny) => {
        const paymentdata = Common.arrayRemoveItem(paymentData, "uniquekey", value);
        setPaymentData(paymentdata);
        const amt = paymentdata.reduce((total, payment) => total + payment.tp_amount * 1, 0);
        setPdAmount(1 * pTotalAmount - amt);
        const itemToDelete = paymentData.find(item => item.uniquekey === value);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Payment",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: spLocationValue,
                TType: 'SP',
                PaymentType: itemToDelete.tp_payment_type,
                PaymentGroupCode: itemToDelete.tp_group,
                PaymentLedgerCode: itemToDelete.tp_account,
                PaymentAccountName: itemToDelete.mvli_ledger_name,
                PaymentAmount: itemToDelete.tp_amount,
                ReferenceNo: itemToDelete.tp_cheque,
            };
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }

        }
        setPaymentData(Common.arrayRemoveItem(paymentData, "uniquekey", value));
        setCounter(counter - 1);
    }

    const handleAgentChange = (value) => {
        setPAgent(value);
        Common.callApi(Common.apiLedger, [sid, "getisdcommission", value.id, pIsdId, pProduct], result => {
            console.log(result);
            let resp = JSON.parse(result);
            setCommType(resp.type);
            setCommission(resp.value);
            var commvalue;
            if (resp.type === "P" || resp.type === "R") {
                commvalue = calcCommission(resp.value, resp.type, pQuantity, pDenomination, pRate);
            } else {
                commvalue = resp.value;
            }
            setCommValue(commvalue);
        });
    }

    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = 0;
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        // return comm.toFixed(2);
        return comm;
    }

    const commissionTypeChange = (v) => {
        setCommType(v);
        setCommission("");
        setCommValue("");
    }

    const handleCommissionCalc = (v) => {
        Common.validateDecValue(v, setCommission);
        setCommValue(calcCommission(v * 1, commType, pQuantity, pDenomination, pRate));
    }

    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: spLocationValue, trannumber: hdrSrno, type: 'SP' });
    }

    const handleIssuerChange = (value) => {
        setPIssuer(value);
        var iss = value.id.split("^");
        setPIssuerGroup(iss[1]);
        setPIssuerAccount(iss[2]);
        setPrFrom(null);
        setPrFromValue("");
    }

    const handleSaleTypeChange = (v) => {
        setPSaleReload(v);
        setPrFrom(null);
        setPrFromValue("");
        setPrTo("");
        setPVtmCardNo("");
        setPDenomination("");
        setPRate("");
    }
    const handlePreTransactionInfo = () => {
        $(".loader").show();
        setShowPreviousTransactionModal(true);
        const obj = {
            srno: nTravellerSrno
        }
        Common.callApi(Common.apiTranSP, [sid, "getpretran", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let res = JSON.parse(result);
            if (res === "") {
                alert("Select Individual First ");
                setPreTransaction("");
            } else {
                setPreTransaction(res);
            }
        });

    }
    const handleLRSTransactionInfo = () => {
        $(".loader").show();
        setShowLRSTransactionModal(true);
        const obj = {
            srno: nTravellerSrno
        }
        Common.callApi(Common.apiTranSP, [sid, "getlrntran", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let res = JSON.parse(result);
            if (res === "") {
                alert("Select Individual First ");
                setLrsTransaction("");
            } else {
                setLrsTransaction(res);
            }

        });
    }

    const createCreditLimit = () => {
        $("loader").show();
        const obj = {
            paymentData: paymentData,
            spNewDate: Common.dateYMD(spNewDate),
            nTraveller: nTraveller,
            srno: hdrSrno,
            spLocationValue: spLocationValue
        }
        Common.callApi(Common.apiCreditLimit, [sid, "addlimit", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            $("loader").hide();
            setMsgBoxCreditLimit(false);
            setMsgBox(true);
            setMsgText(resp.msg);
        });
    }

    const switchToPaymentTab = (e) => {
        if (e.key === 'Enter') setKey("PAYMENT");
        else if (e.key === "Tab") {
            e.preventDefault();
            productRef.current.focus();
        }
    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "SP", trannumber: hdrSrno, trancategory: "T", location: spLocationValue });
    }
    const handleRateCard = () => {
        const obj = {
            location: spLocationValue,
            product: filterProduct

        }
        Common.callApi(Common.apiMasterIBR, [sid, 'getratecard', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            const newWindow = window.open('about:blank', '_blank');
            var ratecarddate = resp[0]["mrc_date"];
            newWindow.document.write(`
                <html>
                <head>
                    <title>Rate Card</title>
                    <style>
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td  {
                        border: 2px solid black;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    .header {
                        display: flex;
                        justify-content: space-between; /* Aligns items to opposite corners */
                        align-items: center; /* Vertically centers items */
                    }
                    .header img {
                        width: 300px; /* Adjust the width as needed */
                        height: auto;
                    }
                    .container{
                    background: aliceblue;
                    padding: 20px;
                    }
                    </style>
                </head>
                <body>
                <div class= "container">
                    <div class="header">
                    <img src="img/logo.png" alt="Rate Card Image" />
                        <div>
                            <h1>Zenith Forex</h1>
                            <p>Card Rate as on ${ratecarddate}<p>
                        </div>
                    </div>
                    <table>
                    <thead>
                        <tr>
                        <th>Code</th>
                        <th>Currencies</th>
                        <th>Product</th>
                        <th>Sale Rate</th>
                        <th>Max Sale Rate</th>
                        <th>Min Sale Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${resp.map((item) => `
                        <tr>
                            <td>${item.mrc_isd}</td>
                            <td>${item.isd_name}</td>
                            <td>${item.mrc_product === "C" ? "CN" : item.mrc_product === "R" ? "Card" : item.mrc_product === "D" ? "DD" : item.mrc_product === "T" ? "TT" : ""}</td>
                            <td>${item.mrc_sale_rate}</td>
                            <td>${item.mrc_max_salerate}</td>
                            <td>${item.mrc_min_salerate}</td>
                        </tr>
                        `).join('')}
                    </tbody>
                    </table>
                </div>
                </body>
                </html>

              `);

            newWindow.document.close(); // 
        });
    }

    const handleShowCommissionDetail = () => {
        setShowCommissionDetail(true);
    }

    const cancelBillFinalModal = () => {
        setMsgBoxCancelBill(true);
        setMsgTextCancelBill("Alert!");
        setIrnCancelDeclaration(false);
        setIrnCancelReason("");
        setIrnCancelLabel("I confirm the deletion of this bill.");
        setIrnCancelReasonSelect("");
    }

    const cancelBill = () => {
        const obj1 = {
            hdrSrno: hdrSrno,
            location: spLocationValue,
            type: 'SP',
            irnObj: irnObj,
            irnCancelReason: irnCancelReason,
            irnCancelReasonSelect: irnCancelReasonSelect
        }
        if (irnCancelReason === "") {
            setMsgBoxCancelBill(false);
            setMsgBox(true);
            setMsgText("Please fill reason.");
            return;
        }
        if (!irnCancelDeclaration) {
            setMsgBoxCancelBill(false);
            setMsgBox(true);
            setMsgText("Please check the declaration.");
            return;
        }
        $(".loader").show();
        Common.callApi(Common.apiEInvoice, [sid, 'cancelirn', JSON.stringify(obj1)], result => {
            $(".loader").hide();
            setMsgBoxCancelBill(false);
            let resp = JSON.parse(result);
            if (resp.Status == "2") {
                setMsgBox(true);
                setMsgText(resp.msg);
            } else {
                if (resp.Status == "1") {
                    setScrMode("");
                    setMsgBox(true);
                    setMsgText("IRN cancelled successfully, Cancel Date " + resp.Data.CancelDate);
                } else {
                    alert(JSON.stringify(resp.ErrorDetails));
                }
            }
        });
    }

    const generateIrn = () => {
        const obj1 = {
            coporate: nCorporate,
            individual: nTraveller,
            location: spLocationValue,
            billdate: hdrDateYmd,
            hdrsrno: hdrSrno,
            type: 'SP',
            pForexTotal: pForexTotal,
            pSgst: pSgst,
            pIgst: pIgst,
            pUgst: pUgst,
            pCgst: pCgst,
            pOtherCharges: pOtherCharges,
            pRoundOff: pRoundOff,
            pTaxableVal: pTaxableVal === "" ? 0 : pTaxableVal,
            pTotalAmount: pTotalAmount,
            productList: spProductList,
            ppStateType: spStateType,
            ppStateValue: spStateValue,
            nState: nState.value,
        }
        $(".loader").show();
        Common.callApi(Common.apiEInvoice, [sid, "generateirn", JSON.stringify(obj1)], result => {
            let resp = JSON.parse(result);
            $(".loader").hide();
            if (resp.msg != "") {
                setMsgBox(true);
                setMsgText(resp.msg);
            } else {
                if (resp.irn.Status == "0") {
                    alert(JSON.stringify(resp.irn.ErrorDetails));
                } else {
                    setScrMode("");
                    setMsgBox(true);
                    setMsgText("IRN Generated successfully.");
                }
            }
        });
    }

    const handleMinMaxRate = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (pIsd === null) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd === null ? " Select ISD." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setShowMinMaxRateModal(true);
            setPIsd(pIsd);
            let a = pIsd.id.split("^");
            setPIsdId(a[1]);//
            setPAgent(null);
            setCommType("");
            setCommValue("");
            const obj = {
                isd: a[1],
                location: spLocationValue,
                product: pProduct
            }
            Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                if (resp === "") {
                    alert("Select Individual First ");
                    // setMinMaxRate("");
                    $(".loader").hide();
                } else {
                    if (Array.isArray(resp)) {
                        setMinMaxRate(resp);
                    } else if (resp && typeof resp === 'object') {
                        setMinMaxRate([resp]);
                    }
                    $(".loader").hide();
                }
            });
        }
    }

    return (
        <>
            {
                showIndividualForm ?
                    <Master_individual_profile reqObj={{ nIndivualSrno: nTravellerSrno, scrIndividual: scrIndividual }} value="TRAN_SP" hideIndividualProfile={hideIndividualProfile} />
                    :
                    <>
                        <Login_header />
                        <Menu />
                        <Container fluid>
                            <Row>
                                <Col><h1>Sale to public</h1></Col>
                                <hr />
                            </Row>
                            {
                                scrMode === "" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                        </Col><Col>
                                                            <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check checked={radioType === "I"} onChange={() => setRadioType("I")} type='radio' label="Individual" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check checked={radioType === "C"} onChange={() => setRadioType("C")} type='radio' label="Corporate" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check checked={radioType === "R"} onChange={() => setRadioType("R")} type='radio' label="Rate Card." />&nbsp;
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={spLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleLocationChange}
                                                        isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                radioType === "N" ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Date</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={spNewDate}
                                                                    onChange={(date) => setSPNewDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Type</Form.Label>
                                                                <Form.Select value={spSaleType} onChange={e => setSPSaleType(e.target.value)}>
                                                                    <option value="N">Normal</option>
                                                                    <option value="E">EEFBC</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                    : radioType === "S" ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={5} value={spSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSPSrnoFrom)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={5} value={spSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setSPSrnoTo)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : radioType === "D" || radioType === "I" || radioType === "C" ?
                                                            <>
                                                                {
                                                                    radioType === "I" ?
                                                                        <Col className="col-md-6 col-12">
                                                                            <Form.Group>
                                                                                <Form.Label>Individual Profile<span className="colorThemeRed">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={spIndividual}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={Picklist.fetchIndividualPicklist}
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleIndiviualChange}
                                                                                >
                                                                                </AsyncSelect>

                                                                            </Form.Group>
                                                                        </Col>
                                                                        : null
                                                                }
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                        <DatePicker className="form-control"
                                                                            selected={spFromDate}
                                                                            onChange={(date) => setSPFromDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                        <DatePicker className="form-control"
                                                                            selected={spToDate}
                                                                            onChange={(date) => setSPToDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : radioType === 'R' ?
                                                                <>
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Product<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Select value={filterProduct} onChange={e => setFilterProduct(e.target.value)}
                                                                                required>
                                                                                <option value="C">CN</option>
                                                                                <option value="R">Card</option>
                                                                                <option value="T">TT</option>
                                                                                <option value="D">DD</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </> : null
                                            }
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col>
                                                {
                                                    userRight.query === "1" && (radioType !== "N" && radioType !== "R") ?
                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listSPdata()}>List</Button> : null
                                                }
                                                &nbsp;
                                                {
                                                    userRight.add === "1" && radioType === "N" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                                }
                                                &nbsp;
                                                {
                                                    userRight.add === "1" && radioType === "R" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => handleRateCard()}>Get Rate</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Sr No</th>
                                                        <th>Type</th>
                                                        <th>Location</th>
                                                        <th>Individual</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        spList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type, item.new_date)} className='pointer colorThemeBlue'>
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </span>
                                                                </td>
                                                                <td>{item.hdr_srno}</td>
                                                                <td>{item.hdr_type}</td>
                                                                <td>{item.entity_name}</td>
                                                                <td>{item.bd_first_name + ' ' + item.bd_middle_name + ' ' + item.bd_last_name}</td>
                                                                <td>{item.hdr_date}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row style={{ display: (hdrSrno < 1 || isIrnApplicable === "N") && "none" }}>
                                            <Col>
                                                {
                                                    isIrnCancelled === "1" ?
                                                        <div className='d-flex align-items-baseline'>
                                                            <p><span style={{ color: "red" }}><FontAwesomeIcon icon={faCircleXmark} /></span> <b>Bill Deleted</b></p>
                                                            <p className='mx-2'>Delete Date : <b>{irnCancelDate}</b></p>
                                                            <p className='mx-2'>Reason of Deletion: <b>{irnCancelReasonHdr}</b></p>
                                                            <p className='mx-2'>Deletion Remark: <b>{irnCancelRemarkHdr}</b></p>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                isIrnGenerated === "1" ?
                                                                    <div className='d-flex align-items-baseline'>
                                                                        <p><span style={{ color: "green" }}><FontAwesomeIcon icon={faCircleCheck} /></span> <b>IRN Generated</b></p>
                                                                        {
                                                                            userRight.delete === "1" &&
                                                                            <Badge className='mx-2 pointer' bg='danger' onClick={cancelBillFinalModal}>Delete Bill</Badge>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className='d-flex align-items-baseline'>
                                                                        <p><span style={{ color: "red" }}><FontAwesomeIcon icon={faCircleXmark} /></span> <b>IRN Generation Failed</b></p>
                                                                        {
                                                                            (userRight.delete === "1" && scrMode === "Q") &&
                                                                            <Badge className='mx-2 pointer' bg='warning' onClick={generateIrn}>Generate</Badge>
                                                                        }
                                                                    </div>
                                                            }
                                                        </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                                &nbsp;
                                                {
                                                    (userRight.edit === "1" && scrMode === 'Q') ? <>
                                                        <Button style={{ display: isIrnGenerated === "1" && "none" }} variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnEditRequest()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                                    </> : null
                                                }
                                                {
                                                    (userRight.query === "1" && scrMode === "Q") ? <>
                                                        <Button variant='outline-success' size='sm' className='buttonStyle' onClick={() => print("D")}>With Deno</Button>&nbsp;
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => print("")}>Without Deno</Button>&nbsp;
                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                                        {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                btnActivityLog()}>Activity Log</Button>&nbsp;
                                                        </> : null
                                                        }
                                                    </> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Label>Serial Number : {hdrSrno}</Form.Label>
                                            </Col>
                                            <Col className="col-md-2 col-4">
                                                <span>Location : <b>{hdrLocation} </b></span>
                                            </Col>
                                            <Col className="col-md-2 col-4">
                                                <span>Date : <b>{hdrDate}</b></span>
                                            </Col>
                                            <Col className="col-md-2 col-4">
                                                <span>Mode : <b>{spSaleType === "N" ? "Normal" : "EEFBC"}</b></span>
                                            </Col>
                                            <Col className='ppCharges'>
                                                <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                            </Col>
                                        </Row>
                                        <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                            <Tab eventKey="INFO" title="Info">
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Executive</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nExecutive}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={Picklist.fetchExecutivePicklist}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleExecutiveChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Mode</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nMode} onChange={e => setNMode(e.target.value)}>
                                                                <option value="B">In Branch</option>
                                                                <option value="D">Delivery</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Sale Type</Form.Label>
                                                            <Form.Select value={spSaleType} onChange={e => setSPSaleType(e.target.value)} disabled>
                                                                <option value="N">Normal</option>
                                                                <option value="E">EEFBC</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col><h3>Traveller/Applicant Info</h3></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-12 col-12">
                                                        <Form.Group>
                                                            <Form.Label>Traveller/Applicant Name<span className="colorThemeRed">*</span>
                                                                {
                                                                    scrMode === "Q" ? null :
                                                                        <>
                                                                            <span className='tranNewFormText' onClick={() => newIndividual("A")}>Not in list? Create New</span>
                                                                            &nbsp;&nbsp;
                                                                            <span className='tranNewFormText' onClick={() => newIndividual("E")}>Edit</span>
                                                                        </>
                                                                }
                                                                {travellerSelected === 1 ?
                                                                    <span style={{ display: scrMode === "Q" && "none" }} className="mx-2" onClick={handlePreTransactionInfo}><Badge bg="secondary">Previous Transaction</Badge></span> : null
                                                                }
                                                                {travellerSelected === 1 ?
                                                                    <span style={{ display: scrMode === "Q" && "none" }} className="mx-2" onClick={handleLRSTransactionInfo}><Badge bg="secondary">LRS Transaction</Badge></span> : null
                                                                }
                                                            </Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nTraveller}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchIndividualPicklist(search, "SALE")}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleTravellerChange}
                                                                isDisabled={scrMode === "Q"}
                                                                formatOptionLabel={Picklist.formatOptionLabel}
                                                                components={{ Menu: Picklist.menu }}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Purpose<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nPurpose}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={Picklist.fetchPurposePicklist}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handlePurposeChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Residential Status<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nResident} onChange={e => setNResident(e.target.value)}>
                                                                {
                                                                    residentOptions.map((item, index) => (
                                                                        <option value={item.mrs_srno} key={index}>{item.mrs_resident}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Traveller/Employee Code</Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} value={nTravellerCode} onChange={e => setNTravellerCode(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Corporate</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nCorporate}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'CORPORATE', spLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleCorporateChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Place of Supply</Form.Label>
                                                            <Select isDisabled={scrMode === "Q"} options={stateOptions} value={nState} onChange={v => setNState(v)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>PAN/Form 60</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nPanForm60} onChange={e => setNPanForm60(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="P">PAN</option>
                                                                <option value="F">Form 60</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col><h3>Visit Info</h3></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Country to Visit<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nCountry}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCountryPicklist(search)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleCountryChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Ticket Class</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nTicketClass} onChange={e => setNTicketClass(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="FC">First Class</option>
                                                                <option value="BC">Business Class</option>
                                                                <option value="EC">Economy Class</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Flight No<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Flight No' type='text' maxLength={20} value={nFlightNo} onChange={e => Common.validateAlpNumValue(e.target.value, setNFlightNo)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Ticket No<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Ticket No' type='text' maxLength={20} value={nTicketNo} onChange={e => Common.validateAlpNumValue(e.target.value, setNTicketNo)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Departure Date<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={nDepDate}
                                                                onChange={(date) => setNDepDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                disabled={scrMode === "Q"}
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Duration of stay abroad<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Duration' type='text' maxLength={2} value={nDuration} onChange={e => Common.validateDecValue(e.target.value, setNDuration)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Visa Type</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nVisaType} onChange={e => setNVisaType(e.target.value)}>
                                                                <option value="">Select</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("DETAILS")}>Details-&gt;</Button>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="DETAILS" title="Details">
                                                <Row>
                                                    <Col>
                                                        <h4>Product Info</h4>
                                                    </Col>
                                                </Row>
                                                {
                                                    scrMode === "Q" ? null
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Select value={pProduct} onChange={e => handleProductChange(e.target.value)} ref={productRef}>
                                                                            <option value="CN">CN</option>
                                                                            <option value="VTM">VTM</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pProduct === "VTM" &&
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                            <Form.Select value={pSaleReload} onChange={e => handleSaleTypeChange(e.target.value)}>
                                                                                <option value="S">Sale</option>
                                                                                <option value="R">Reload</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>
                                                                }
                                                                < Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pIsd}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, pProduct)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleChangeIsd}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pProduct === "VTM" ?
                                                                        <Col className="col-md-3 col-6">
                                                                            <Form.Group>
                                                                                <Form.Label>Issuer<span className="colorThemeRed">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={pIssuer}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', spLocationValue)} // location = 1 , 2
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleIssuerChange}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        : null
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>{pProduct === "VTM" ? "Value" : "Denomination"}
                                                                            <span className="colorThemeRed">*</span>
                                                                        </Form.Label>
                                                                        <Form.Control type='text' value={pDenomination} maxLength={5}
                                                                            onChange={e => handleDenoChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6' style={{ display: pProduct === "VTM" && "none" }}>
                                                                    <Form.Group>
                                                                        <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={9}
                                                                            onChange={e => handleQuantityChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>

                                                                        <span style={{ display: scrMode === "Q" && "none" }} className="mx-2" onClick={handleMinMaxRate}><Badge bg="secondary">See Min/Max Rate</Badge></span>
                                                                        <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                                            onChange={e => handleRateChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                pProduct === "VTM" ?
                                                                    <Row>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Prefix</Form.Label>
                                                                                <Form.Control disabled placeholder='Prefix' type='text' maxLength={5} value={prPrefix} onChange={e => Common.validateAlpNumValue(e.target.value, setPrPrefix)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                                {
                                                                                    pSaleReload === "S" ?
                                                                                        <AsyncSelect
                                                                                            cacheOptions={false}
                                                                                            defaultOptions={false}
                                                                                            value={prFrom}
                                                                                            getOptionLabel={e => e.label + ' '}
                                                                                            getOptionValue={e => e.id}
                                                                                            loadOptions={(search) => Picklist.fetchVtcInlist(search, { isd: pIsdId, location: spLocationValue, group: pIssuerGroup, account: pIssuerAccount, product: "VTC", type: "VTCIN" })}
                                                                                            onInputChange={Picklist.handleInputChange}
                                                                                            onChange={handleFromChange}
                                                                                        >
                                                                                        </AsyncSelect>
                                                                                        :
                                                                                        <AsyncSelect
                                                                                            cacheOptions={false}
                                                                                            defaultOptions={false}
                                                                                            value={prFrom}
                                                                                            getOptionLabel={e => e.label + ' '}
                                                                                            getOptionValue={e => e.id}
                                                                                            loadOptions={(search) => Picklist.fetchVtcBilllist(search, { isd: pIsdId, location: spLocationValue, group: pIssuerGroup, account: pIssuerAccount, product: "VTC", type: "SP" })}
                                                                                            onInputChange={Picklist.handleInputChange}
                                                                                            onChange={handleFromChange}
                                                                                        >
                                                                                        </AsyncSelect>
                                                                                }
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control disabled placeholder='To' type='text' maxLength={6} value={prTo} onChange={e => Common.validateNumValue(e.target.value, setPrTo)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Card No</Form.Label>
                                                                                <Form.Control disabled={pSaleReload === "R"} placeholder='Card No' maxLength={10} type='text' value={pVtmCardNo} onChange={e => setPVtmCardNo(e.target.value)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    : null
                                                            }
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission</Form.Label>
                                                                        <Form.Select value={isCommission} onChange={e => setIsCommission(e.target.value)}>
                                                                            <option value="0">No</option>
                                                                            <option value="1">Yes</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    isCommission === "1" ?
                                                                        <>
                                                                            <Col className='col-md-3 col-6'>
                                                                                <Form.Group>
                                                                                    <Form.Label>Agent<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <AsyncSelect
                                                                                        cacheOptions={false}
                                                                                        defaultOptions={false}
                                                                                        value={pAgent}
                                                                                        getOptionLabel={e => e.label + ' '}
                                                                                        getOptionValue={e => e.id}
                                                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', spLocationValue)} // location = 1 , 2
                                                                                        onInputChange={Picklist.handleInputChange}
                                                                                        onChange={handleAgentChange}
                                                                                    >
                                                                                    </AsyncSelect>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Select value={commType} onChange={e => commissionTypeChange(e.target.value)}>
                                                                                        <option value="">Select</option>
                                                                                        <option value="P">Paisa</option>
                                                                                        <option value="R">Percentage</option>
                                                                                        <option value="F">Flat</option>
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Group>
                                                                                    <Form.Label>Commission<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control type='text' maxLength={5} value={commission} onChange={e => handleCommissionCalc(e.target.value)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Group>
                                                                                    <Form.Label>Commission Value</Form.Label>
                                                                                    <Form.Control disabled value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </Row>
                                                            <Row>&nbsp;</Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                                                    &nbsp;
                                                                    <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Badge className='handCursor' bg="primary" onClick={handleShowCommissionDetail}>View Commission Details</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Table responsive bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Product</th>
                                                                <th>Isd Code</th>
                                                                <th>Isd Name</th>
                                                                <th>Denomination</th>
                                                                <th>Quantity</th>
                                                                <th>Rate</th>
                                                                <th>Amount</th>
                                                                <th>USD Rate</th>
                                                                <th>USD Amount</th>
                                                                <th>Prefix</th>
                                                                <th>From</th>
                                                                <th>To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                spProductList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {
                                                                                scrMode === "Q" || item.delny === "N" ? null :
                                                                                    <span onClick={() => deleteProduct(item.uniqueKey)} className='colorThemeBlue pointer'>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                        <td>{item.prd_product}</td>
                                                                        <td>{item.prd_isd}</td>
                                                                        <td>{item.isd_name}</td>
                                                                        <td>{item.prd_deno}</td>
                                                                        <td>{item.prd_qty}</td>
                                                                        <td>{item.prd_rate}</td>
                                                                        <td>{item.prd_amount}</td>
                                                                        <td>{item.prd_usd_rate}</td>
                                                                        <td>{item.prd_usd_amount}</td>
                                                                        <td>{item.prd_prefix}</td>
                                                                        <td>{item.prd_from}</td>
                                                                        <td>{item.prd_to}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="PAYMENT" title="Payment">
                                                <Row>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>Taxable Value: {pTaxableVal}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>SGST: {pSgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>IGST: {pIgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>CGST : {pCgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>UGST : {pUgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>Round Off: {pRoundOff}</Form.Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6" style={{ display: "flex", alignItems: "center" }}>
                                                        <span>OtherCharges: &nbsp;</span>
                                                        <span>
                                                            <Form.Control size='sm' placeholder='Other Charges' type='text'
                                                                maxLength={5}
                                                                value={pOtherCharges}
                                                                onChange={e => otherChargeChange(e.target.value)}
                                                                disabled={disableOtherCharge || scrMode === "Q"}
                                                            />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col><h3>Payment Details</h3></Col>
                                                </Row>
                                                {
                                                    scrMode === "Q" ? null
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Payment Type</Form.Label>
                                                                        <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                            <option value="">Select</option>
                                                                            <option value="CH">Cash</option>
                                                                            <option value="BN">Bank</option>
                                                                            <option value="CQ">Cheque</option>
                                                                            <option value="RC">Recievables</option>
                                                                            {/* <option value="PB">Payables</option> */}
                                                                            {/* <option value="AP">Advance Paid</option>
                                                                            <option value="AR">Advance Recieved</option> */}
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pdAccount}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, spLocationValue)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleAccountValue}
                                                                            isDisabled={disablePaymentAccount}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pdPaymentType !== "" ?
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Amount<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control placeholder='Amount' value={pdAmount} onChange={e => Common.validateDecValue(e.target.value, setPdAmount)} />
                                                                            </Form.Group>
                                                                        </Col> : null
                                                                }
                                                            </Row>
                                                            {
                                                                pdPaymentType === "BN" ?
                                                                    <Row>
                                                                        <Col className="col-md-3 col-6">
                                                                            <Form.Group>
                                                                                <Form.Label>Reference No<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control placeholder='Reference No' maxLength={40} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    : pdPaymentType === "CQ" ?
                                                                        <Row>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Cheque No<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control placeholder='Cheque No' type='text' maxLength={10} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Bank Name<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control placeholder='Bank Name' type='text' maxLength={50} value={chequeBankName} onChange={e => Common.validateAlpNumValue(e.target.value, setChequeBankName)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Cheque Date<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <DatePicker className="form-control"
                                                                                        selected={chequeDate}
                                                                                        onChange={(date) => setChequeDate(date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        showYearDropdown
                                                                                        showMonthDropdown
                                                                                        useShortMonthInDropdown
                                                                                        dropdownMode="select"
                                                                                        peekNextMonth
                                                                                        customInput={
                                                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                        : null
                                                            }
                                                            <Row>&nbsp;</Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addPayment()}>Add</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Type</th>
                                                                <th>Group</th>
                                                                <th>Account</th>
                                                                <th>Name</th>
                                                                <th>Amount</th>
                                                                <th>Cheque/Refernece No</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                paymentData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {
                                                                                scrMode !== "Q" ?
                                                                                    <span className='colorThemeBlue pointer' onClick={() => deletePayment(item.uniquekey, item.delny)}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span> : null
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                item.tp_payment_type === "BN"
                                                                                    ? "Bank" : item.tp_payment_type === "CQ"
                                                                                        ? "Cheque" : item.tp_payment_type === "CH"
                                                                                            ? "Cash" : item.tp_payment_type === "RC"
                                                                                                ? "Reveivables" : item.tp_payment_type === "PB"
                                                                                                    ? "Payables" : item.tp_payment_type === "AP"
                                                                                                        ? "Advance Paid" : item.tp_payment_type === "AR"
                                                                                                            ? "Advance Received" : ""
                                                                            }
                                                                        </td>
                                                                        <td>{item.tp_group}</td>
                                                                        <td>{item.tp_account}</td>
                                                                        <td>{item.mvli_ledger_name}</td>
                                                                        <td>{item.tp_amount}</td>
                                                                        <td>{item.tp_cheque}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </>
                            }
                        </Container>
                    </>
            }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Modal show={showPreviousTransactionModal} size='xl' onHide={() => setShowPreviousTransactionModal(false)} scrollable backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col>
                                <span>Previous Transaction</span>
                                <span>&nbsp;</span>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Table striped responsive bordered>
                            <thead>
                                <tr>
                                    <th> Branch</th>
                                    <th>Type</th>
                                    <th>Certificate</th>
                                    <th>Date</th>
                                    <th>USD</th>
                                    <th>Departure</th>
                                    <th>Cash</th>
                                    <th>Bank</th>
                                    <th>Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    preTransaction.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.entity_name}</td>
                                            <td>{item.tp_type}</td>
                                            <td>{item.hdr_srno}</td>
                                            <td>{item.hdr_date}</td>
                                            <td>{item.prd_usd_amount}</td>
                                            <td>{item.hdr_departure_date}</td>
                                            <td>{item.tp_payment_type === "CH" ? item.tp_amount : ""}</td>
                                            <td>{item.tp_payment_type === "BN" ? item.tp_amount : ""}</td>
                                            <td>{item.tp_payment_type === "RC" ? item.tp_amount : ""}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={showLRSTransactionModal} size='xl' onHide={() => setShowLRSTransactionModal(false)} scrollable backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col>
                                <span>Previous Transaction</span>
                                <span>&nbsp;</span>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Table striped responsive bordered>
                            <thead>
                                <tr>
                                    <th> Branch</th>
                                    <th>Type</th>
                                    <th>Certificate</th>
                                    <th>Date</th>
                                    <th>ISD</th>
                                    <th>USD Equivalent</th>
                                    <th>Value</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lrsTransaction.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.entity_name}</td>
                                            <td>{item.tp_type}</td>
                                            <td>{item.hdr_srno}</td>
                                            <td>{item.hdr_date}</td>
                                            <td>{item.prd_isd}</td>
                                            <td>{item.prd_usd_amount}</td>
                                            <td>{item.prd_rate}</td>
                                            <td>{item.tp_amount}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={showMinMaxRateModal} size='xl' onHide={() => setShowMinMaxRateModal(false)} scrollable backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col>
                                <span>MIN/MAX RATE CARD</span>
                                <span>&nbsp;</span>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Table striped responsive bordered>
                            <thead>
                                <tr>
                                    <th>ISD</th>
                                    <th> MINIMUM</th>
                                    <th>RATE</th>
                                    <th>MAXIMUM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {minMaxRate.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.isd}</td>
                                        <td>{item.minsalerate}</td>
                                        <td>{item.salerate}</td>
                                        <td>{item.maxsalerate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <span className="corner-span">{counter}</span>
            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

            <Tran_commission_view show={showCommissionDetail} onHide={() => setShowCommissionDetail(false)} productList={spProductList} />

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <DialogYesNo isOpen={isMsgBoxCreditLimit} onYes={(e) => createCreditLimit()} onNo={(e) => setMsgBoxCreditLimit(false)}>
                {msgTextCreditLimit}
            </DialogYesNo>

            <Dialog_IRN
                isOpen={isMsgBoxCancelBill}
                onYes={(e) => cancelBill()}
                onNo={(e) => setMsgBoxCancelBill(false)}
                text={msgTextCancelBill}
                checkLabel={irnCancelLabel}
                irnCancelDeclaration={irnCancelDeclaration}
                setIrnCancelDeclaration={setIrnCancelDeclaration}
                irnCancelReason={irnCancelReason}
                setIrnCancelReason={setIrnCancelReason}
                irnCancelReasonSelect={irnCancelReasonSelect}
                setIrnCancelReasonSelect={setIrnCancelReasonSelect}
            />

            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

        </>
    )
}

export default Tran_sp
