import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../../ActivityLog';
import * as Common from '../../Common';
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';
import Tran_data_unlock_request from '../../Tran_data_unlock_request';

const Currency_write_off = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");

    const [writeOffLocation, setWriteOffLocation] = useState(null);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState(0);
    const [writeOffCurrency, setWriteOffCurrency] = useState(null);
    const [currencyCode, setCurrencyCode] = useState('');

    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);

    const [radioType, setRadioType] = useState("N");
    const [writeOffDate, setWriteOffDate] = useState(new Date());
    const [writeOffFromDate, setWriteOffFromDate] = useState(new Date());
    const [writeOffToDate, setWriteOffToDate] = useState(new Date());
    const [writeOffFromSrno, setWriteOffFromSrno] = useState('');
    const [writeOffToSrno, setWriteOffToSrno] = useState('');

    const [writeOffQty, setWriteOffQty] = useState("");
    const [writeOffDeno, setWriteOffDeno] = useState("");
    const [writeOffRate, setWriteOffRate] = useState("");
    const [writeOffReason, setWriteOffReason] = useState('FK');
    const [srNo, setSrNo] = useState(0);
    const [lineNo, setLineNo] = useState(0);
    const [counter, setCounter] = useState(0);

    const [editReqDate, setEditReqDate] = useState('');

    const [viewDate, setViewDate] = useState('');
    const [viewSrno, setViewSrno] = useState(0);
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setWriteOffLocation(response);
                response != null && handleChangLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'CURRENCY_WRITE_OFF'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangLocation = (value) => {
        setWriteOffLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleCurrencyChange = value => {
        setWriteOffCurrency(value);
        const currency = value.id.split('^');
        setCurrencyCode(currency[1]);
    }

    const list = () => {
        if (!writeOffLocation || (radioType == 'S' && !writeOffFromSrno) || (radioType == 'S' && !writeOffToSrno) || (radioType == 'D' && !writeOffFromDate) ||
            (radioType == 'D' && !writeOffToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!writeOffLocation ? " Location." : '');
            msg[i++] = (radioType === 'S' && !writeOffFromSrno ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !writeOffToSrno ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !writeOffFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !writeOffToDate ? " To Date." : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                location: locationCode,
                filterRadioType: radioType,
                filterFromSrno: writeOffFromSrno,
                filterToSrno: writeOffToSrno,
                filterFromDate: Common.dateYMD(writeOffFromDate),
                filterToDate: Common.dateYMD(writeOffToDate),
            }
            Common.callApi(Common.apiWriteOff, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }

    const addNew = () => {
        var date = Common.dateYMD(writeOffDate);
        if (!writeOffLocation || !writeOffDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!writeOffLocation ? " Location." : '');
            msg[i++] = (!writeOffDate ? " Date." : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode('A');
            setInnerData([]);
            setWriteOffCurrency(null);
            setWriteOffDeno("");
            setWriteOffQty('');
            setWriteOffRate('');
            setWriteOffReason('FK');
        }
    }

    const addDetail = () => {
        var msg = [], i = 0;
        if (writeOffCurrency === null || writeOffDeno === "" || writeOffQty === '' || writeOffRate === '') {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (writeOffCurrency === null ? " Currency." : '');
            msg[i++] = (writeOffDeno === "" ? " Denomination." : '');
            msg[i++] = (writeOffQty === '' ? " Quantity." : '');
            msg[i++] = (writeOffRate === '' ? " Rate." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const prd_amount = writeOffDeno * writeOffQty * writeOffRate;
            const obj = {
                unique_no: Common.getRandomString(5),
                prd_line_no: 1 * lineNo + 1,
                prd_location: locationCode,
                prd_type: 'CNWOFF',
                prd_isd: currencyCode,
                prd_date: Common.dateYMD(writeOffDate),
                prd_product: 'CN',
                prd_deno: writeOffDeno,
                prd_qty: writeOffQty,
                prd_rate: writeOffRate,
                prd_amount: prd_amount,
                prd_reason: writeOffReason,
                del: 'Y',
            };
            setInnerData(Common.arrayAddItem(innerData, obj));
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TLoc: locationCode,
                TType: 'CNWOFF',
                ISD: currencyCode,
                Deno: writeOffDeno,
                Quantity: writeOffQty,
                ProductRate: writeOffRate,
                ProductAmount: prd_amount,
                ProductReason: writeOffReason,
            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            console.log(innerData);
            setLineNo(lineNo + 1);
            setWriteOffCurrency(null);
            setWriteOffDeno('');
            setWriteOffQty('');
            setWriteOffRate('');
            setWriteOffReason('FK');
        }
    }

    const deleteItem = (value) => {
        const itemToDelete = innerData.find(item => item.unique_no === value);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: itemToDelete.prd_location,
                TType: 'CNWOFF',
                ISD: itemToDelete.prd_isd,
                Deno: itemToDelete.prd_deno,
                Quantity: itemToDelete.prd_qty,
                ProductRate: itemToDelete.prd_rate,
                ProductAmount: itemToDelete.prd_amount,
                ProductReason: itemToDelete.prd_reason,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        setInnerData(Common.arrayRemoveItem(innerData, 'unique_no', value));
        setCounter(counter + 1);
    }

    const saveData = () => {
        if (innerData.length < 1) {
            setMsgText('Empty List!');
            setMsgBox(true);
        } else {
            const obj = {
                location: locationCode,
                srno: srNo,
                date: Common.dateYMD(writeOffDate),
                data: innerData,
                type: 'CNWOFF',

                filterRadioType: radioType,
                filterFromSrno: writeOffFromSrno,
                filterToSrno: writeOffToSrno,
                filterFromDate: Common.dateYMD(writeOffFromDate),
                filterToDate: Common.dateYMD(writeOffToDate),
            };
            Common.callApi(Common.apiWriteOff, [sid, 'save', JSON.stringify(obj), JSON.stringify(tranAuditTrail)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                setScrMode('');
                // setWriteOffLocation(null);
                setTranAuditTrail([]);
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: viewSrno,
                    type: 'CNWOFF',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'CURRENCY_WRITE_OFF'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }

    const viewList = (hdrSrno, location, type, date, vdate) => {
        setSrNo(hdrSrno);
        setScrMode('Q');
        setViewDate(vdate);
        setViewSrno(hdrSrno);
        setEditReqDate(date)

        const obj = {
            srno: hdrSrno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiWriteOff, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.writeOffList);
                setLineNo(resp.maxlineno);
                setLocationCode(resp.location);
                setLocation(resp.locationname);
            }
        });
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    // const editData = () => {
    //     setScrMode("E");
    // }


    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = editReqDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'CNWOFF',
            pageright: 'CURRENCY_WRITE_OFF'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'CNWOFF', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "CNWOFF", trannumber: viewSrno, trancategory: "T", location: locationCode });
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Currency Write Off</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={writeOffLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={writeOffDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setWriteOffDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={writeOffFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setWriteOffFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={writeOffToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setWriteOffToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={writeOffFromDate}
                                                                onChange={(date) => setWriteOffFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={writeOffToDate}
                                                                onChange={(date) => setWriteOffToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D') ?
                                            < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) => (
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.new_date, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.hdr_date}</td>
                                                        <td>{res.hdr_type}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnEditRequest()}>Edit</Button>
                                        </> : null
                                    }
                                    {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                    </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Sr No  :{srNo}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Date : {viewDate}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "E" || scrMode === "A" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={writeOffCurrency}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'VTC')} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleCurrencyChange}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Denomination</Form.Label>
                                                    <Form.Control
                                                        value={writeOffDeno}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setWriteOffDeno)}
                                                        maxLength={20}
                                                        placeholder="Denomination" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={writeOffQty}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setWriteOffQty)}
                                                        maxLength={Common.maximumLength}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Rate<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={writeOffRate}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setWriteOffRate)}
                                                        maxLength={Common.maximumLength}
                                                        placeholder="Rate" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-5 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={writeOffReason} onChange={e => setWriteOffReason(e.target.value)}
                                                        required>
                                                        <option value="FK">Fake or Forged</option>
                                                        <option value="TH">Theft</option>
                                                        <option value="LT">Lost in Transit</option>
                                                        <option value="OC">Out of Circulation</option>
                                                        <option value="MS">Multilated / Soiled</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Sr No </th>
                                                <th>Currency</th>
                                                <th>Denomination</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                innerData.map((res) =>
                                                    <tr key={res.prd_line_no}>
                                                        {
                                                            userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        scrMode === 'Q' || res.del === 'N' ? null :
                                                                            <>
                                                                                <span className="handCursor colorThemeBlue" onClick={() => deleteItem(res.unique_no)} >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </span> {' '}
                                                                            </>
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td>{res.prd_line_no}</td>
                                                        <td>{res.prd_isd}</td>
                                                        <td>{res.prd_deno}</td>
                                                        <td>{res.prd_qty}</td>
                                                        <td>{res.prd_rate}</td>
                                                        <td>{res.prd_amount}</td>
                                                        <td>{res.prd_reason === 'FK' ? 'Fake or Forged' :
                                                            res.prd_reason === 'TH' ? 'Theft' :
                                                                res.prd_reason === 'LT' ? 'Lost in Transit' :
                                                                    res.prd_reason === 'OC' ? 'Out of Circulation' :
                                                                        'Multilated / Soiled'}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </>
                }
            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>
                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            </div>
        </>
    )
}

export default Currency_write_off
