import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from '../../Common';
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from "../../Picklist";

const Activity_log_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [paymentFromDate, setPaymentFromDate] = useState(new Date());
    const [paymentToDate, setPaymentToDate] = useState(new Date());
    const [paymentViewType, setPaymentViewType] = useState('V');

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');
    const [viewType, setViewType] = useState("V");
    const [scr, setScr] = useState("");
    const [viewList, setViewList] = useState([]);

    const [tranNumber, setTranNumber] = useState();
    const [tranType, setTranType] = useState("MINDP");

    const [logType, setLogType] = useState("A");
    const [activityReportList, setActivityReportList] = useState([]);
    const [auditTrail, setAuditTrail] = useState([]);
    const [showAuditTrailModal, setShowAuditTrailModal] = useState(false);
    const [lgLogNo, setLgLogNo] = useState(0);
    const [location, setLocation] = useState(null);
    const [category, setCategory] = useState("M");

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_AUDIT_TRAIL'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        console.log(newLocationCode)
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }
    const handleAuditTrail = (lg_logno) => {
        setLgLogNo(lg_logno);
        $(".loader").show();
        setShowAuditTrailModal(true);
        const obj = {
            logno: lg_logno,
            category: category,
            type: viewType,
            radioTypeLocation: radioType,
            location: locationCode,
            multipleLocation: selectedItems,
            tranNumber: tranNumber,
            tranType: tranType,
            logType: logType,
        }
        Common.callApi(Common.apiActivityLogReport, [sid, 'viewAuditTrail', JSON.stringify(obj)], (result) => {
            setScr('V');
            const resData = JSON.parse(result);
            $(".loader").hide();
            let res = JSON.parse(result);
            if (res === "") {
                alert("Select Individual First ");
                setAuditTrail("");
            } else {
                setAuditTrail(resData);
            }
        });
    };
    const splitDescription = (description) => {
        const keyValuePairs = description.split('^');
        const obj = {};
        keyValuePairs.forEach(pair => {
            const [key, value] = pair.split(':');
            obj[key] = value;
        });
        return obj;
    };

    const handleGenerate = () => {
        var msg = [], i = 0;
        if ((radioType === 'O' && locationCode === '0') || (radioType == 'S' && selectedItems == [])) {
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                fromDate: Common.dateYMD(paymentFromDate),
                fromDate1: Common.dateDMY(paymentFromDate),
                toDate: Common.dateYMD(paymentToDate),
                toDate1: Common.dateDMY(paymentToDate),
                type: viewType,
                radioTypeLocation: radioType,
                location: locationCode,
                multipleLocation: selectedItems,
                tranNumber: tranNumber,
                tranType: tranType,
                logType: logType,
                category: category

            };
            if (viewType === 'D') {
                Common.callDownloadApiPost(Common.apiActivityLogReport, "post", [sid, 'downloadAudit', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiActivityLogReport, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setActivityReportList(resData);
                    // setViewList(resData)
                    // console.log(scr);
                });
            }
        }
    }
    const downloadAuditTrail = () => {

        const obj = {
            fromDate: Common.dateYMD(paymentFromDate),
            fromDate1: Common.dateDMY(paymentFromDate),
            toDate: Common.dateYMD(paymentToDate),
            toDate1: Common.dateDMY(paymentToDate),
            type: viewType,
            radioTypeLocation: radioType,
            location: locationCode,
            multipleLocation: selectedItems,
            tranNumber: tranNumber,
            tranType: tranType,
            category: category,
            logno: lgLogNo,
            type: viewType,
        }
        Common.callDownloadApiPost(Common.apiActivityLogReport, "post", [sid, "downloadAudit", JSON.stringify(obj)]);
    }
    const handleChangeLocation = (value) => {
        setLocation(value);
        var loc = value.id.split('^');
        setLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];
    }
    const handleCategory = (e) => {
        setCategory(e.target.value);
        e.target.value == "M" ? setTranType("MINDP") : setTranType("PP");
    }
    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h4> Acitivity Log Report</h4>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Category<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={category} onChange={handleCategory}
                                        required>
                                        <option value="M">Master</option>
                                        <option value="T">Transaction</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Transaction Log  <span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={logType} onChange={e => setLogType(e.target.value)}
                                        required>
                                        <option value="A">All</option>
                                        <option value="O">One</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        {logType === "A" ?
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={paymentFromDate}
                                            onChange={(date) => setPaymentFromDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={paymentToDate}
                                            onChange={(date) => setPaymentToDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                {category == "T" ?
                                    <Col className='col-md-6 col-6'>
                                        <Form.Label>Location Filter</Form.Label><br />
                                        <LocationSelector onDataFromChild={handleDataFromChild} />
                                    </Col>
                                    : null}
                            </Row> : null}
                        <Row>&nbsp;</Row>
                        {logType === "O" ?
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group controlId="formBasicText">
                                        <Form.Label>Transaction Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Transraction Number "
                                            value={tranNumber}
                                            onChange={(e) => setTranNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                {category == "T" ?
                                    <Col className='col-md-6 col-6'>
                                        <Form.Group>
                                            <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                            <AsyncSelect
                                                cacheOptions={false}
                                                defaultOptions={false}
                                                value={location}
                                                getOptionLabel={e => e.label + ' '}
                                                getOptionValue={e => e.id}
                                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                onInputChange={Picklist.handleInputChange}
                                                onChange={handleChangeLocation}
                                            // isDisabled={branchDisable === "true"}
                                            >
                                            </AsyncSelect>
                                        </Form.Group>
                                    </Col>
                                    : null}

                            </Row> : null}
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Transaction Type  <span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={tranType} onChange={e => setTranType(e.target.value)}
                                        required>
                                        {category == "T" ?
                                            <>
                                                <option value="MINDP">Master Individual Profile</option>
                                                <option value="PP">Purchase Public</option>
                                                <option value="SP">Sale Public</option>
                                                <option value="PB">Purchase Bulk</option>
                                                <option value="SB">Sale Bulk</option>
                                                <option value="PINV">Purchase Invoice</option>
                                                <option value="VTCIN">Transfer InVtc</option>
                                                <option value="MNLBK">TransferIn ManualBook</option>
                                                <option value="VTCOUT">TransferOut Vtc</option>
                                                <option value="CNOUT">TransferOut Currency</option>
                                                <option value="CNWOFF">Currency WriteOff</option>
                                                <option value="CNCHNG">Currency Change</option>

                                            </> :
                                            <>
                                                <option value="MINDP">Master Individual Profile</option>
                                                <option value="MRISK">Master Risk</option>
                                                <option value="MBN">Master Bank</option>
                                                <option value="MLGET">Master Legal Entity</option>
                                                <option value="MGSTC">Master GST</option>
                                                <option value="MSME">Master MSME</option>
                                                <option value="MVGRP">Master Voucher Group</option>
                                                <option value="MVLGD">Master Voucher Ledger</option>
                                                <option value="MUSER">Master User </option>
                                                <option value="REGN">Master Region List </option>
                                                <option value="MCC">Master Country Class </option>
                                                <option value="MCNT">Master Country List </option>
                                                <option value="MDST">Master District </option>
                                                <option value="MCITY">Master CityList </option>
                                                <option value="MDVSN">Master Division </option>
                                                <option value="MCSC">Master Cost Centre </option>
                                                <option value="MDESG">Master Designation </option>
                                                <option value="MEXEC">Master Executive </option>
                                                <option value="MISUR">Master Issuer </option>

                                            </>

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={viewType} onChange={e => setViewType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>User Name</th>
                                <th>Remark</th>
                                <th>Timestamp</th>
                                <th>Location</th>
                                <th>Transaction Type</th>
                                <th>Transaction Number</th>
                                <th>Activity</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                activityReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.lg_usersrno}</td>
                                        <td>{item.um_user_name}</td>
                                        <td>{item.lg_remark}</td>
                                        <td>{item.lg_date}</td>
                                        <td>{item.entity_name}</td>
                                        <td>{item.lg_tran_type}</td>
                                        <td>{item.lg_tran_number}</td>
                                        <td>{item.lg_activity_type}</td>
                                        <td>
                                            <span onClick={() => handleAuditTrail(item.lg_logno)} className='pointer colorThemeBlue'>
                                                <FontAwesomeIcon icon={faEye} />
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </> : null}
                <Modal show={showAuditTrailModal} size='xl' onHide={() => setShowAuditTrailModal(false)} scrollable backdrop="static">
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <Row>
                                <Col>
                                    <span>Audit Trail Info</span>
                                    <span>&nbsp;</span>
                                    <span className="handCursor colorThemeBlue" onClick={downloadAuditTrail}><FontAwesomeIcon icon={faDownload} /></span>
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <Button onClick={() => {
                                    // props.show = true;
                                    // props.onHide = false;
                                    setShowAuditTrailModal(false);

                                }} variant='outline-danger' size='sm' className='buttonStyle'>Back</Button>
                            </Col>
                            <Col>
                            </Col>
                            <Table striped responsive bordered>
                                <thead>
                                    <tr>
                                        <th>Activity Type</th>
                                        <th>Tab Type</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {auditTrail.map((item, index) => {
                                        const descriptionObj = splitDescription(item.tat_description);
                                        const descriptionKeys = Object.keys(descriptionObj);
                                        return (
                                            <tr key={index}>
                                                <td>{item.tat_activity_type == "A" ? "Add" : "Delete"}</td>
                                                <td>{item.tat_tab}</td>
                                                <td>
                                                    <Table bordered>
                                                        <thead>
                                                            <tr>
                                                                {descriptionKeys.map((key, idx) => (
                                                                    <th key={idx}>{key}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {descriptionKeys.map((key, idx) => (
                                                                    <td key={idx}>{descriptionObj[key]}</td>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Row>
                    </Modal.Body>
                </Modal>

            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Activity_log_report;
