import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from '../Common';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";

export default function Rate_card() {
    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [rateCard, setRateCard] = useState([]);
    const [location, setLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [filterProduct, setFilterProduct] = useState("A");
    const [showRateCard, setShowRateCard] = useState(false);
    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_AUDIT_TRAIL'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);


        }
    }, [onceRun, sid, navigate]);
    const handleGenerate = () => {
        $(".loader").show();
        let obj = {
            location: locationCode,
            product: filterProduct

        }
        Common.callApi(Common.apiMasterIBR, [sid, 'getratecard', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setShowRateCard(true);
            setRateCard(resp);
            $(".loader").hide();
        });

    }
    const handleChangeLocation = (value) => {
        setShowRateCard(false);
        setLocation(value);
        var loc = value.id.split('^');
        setLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];
    }
    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Row>
                        <Col className="col-3 col-md-3">
                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                        </Col>
                    </Row>
                    <Col className='col-md-6 col-6'>
                        <Form.Group>
                            <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={location}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleChangeLocation}
                            // isDisabled={branchDisable === "true"}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Product<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={filterProduct} onChange={(e) => { setFilterProduct(e.target.value); setShowRateCard(false) }}
                                required>
                                <option value="A">All</option>
                                <option value="C">CN</option>
                                <option value="R">Card</option>
                                <option value="T">TT</option>
                                <option value="D">DD</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Row>&nbsp;</Row>
                    {showRateCard ?
                        <Row>
                            <Col>
                                <Table respo nsive bordered striped>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Code</th>
                                            <th>Currencies</th>
                                            <th>Product</th>
                                            <th>We Sell</th>
                                            <th>We Buy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rateCard.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.mrc_isd}</td>
                                                <td>{item.isd_name}</td>
                                                <td>{item.mrc_product == "C" ? "CN" : item.mrc_product == "R" ? "Card" : item.mrc_product == "D" ? "DD" : item.mrc_product == "T" ? "TT" : null}</td>
                                                <td>{item.mrc_sale_rate}</td>
                                                <td>{item.mrc_buy_rate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row> : null}
                </Row>

            </Container>
        </>
    )
}
