import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from "../Login_header";
import Menu from '../Menu';
import * as Picklist from "./../Picklist";


function Master_user() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("Q");  // "Q A E "

    const [userData, setUserData] = useState([]);
    const [showAddEditForm, setAddEditFormShow] = useState(false);
    const [showAddNewBtn, setShowAddNewBtn] = useState(true);

    const [umSrNo, setUmSrNo] = useState(0);
    const [umUserName, setUmUserName] = useState("");
    const [umId, setUmId] = useState("");
    const [umMob, setUmMob] = useState("");
    const [umEml, setUmEml] = useState("");
    const [umEnable, setUmEnable] = useState(0);
    const [ddEnableOptions, setDdEnableOptions] = useState([]);
    const [umEntityCode, setUmEntityCode] = useState("0");
    const [umEntityType, setUmEntityType] = useState("0");
    const [ddEntityTypeOptions, setDdEntityTypeOptions] = useState([]);
    const [umActive, setUmActive] = useState(0);
    const [branchData, setBranchData] = useState([]);

    const [umRemark, setUmRemark] = useState("");

    const [ddActiveOptions, setDdActiveOptions] = useState([]);
    const [ddEntityCodeOptions, setDdEntityCodeOptions] = useState([]);

    const [umRightBranch, setUmRightBranch] = useState(0);
    const [ddUmRightBranchOptions, setDdUmRightBranchOptions] = useState([]);
    const [umRightBranchAllow, setUmRightBranchAllow] = useState(0);

    const [umRightGroup, setUmRightGroup] = useState(0);
    const [ddRightGroupOptions, setDdRightGroupOptions] = useState([]);
    const [umRightGroupList, setUmRightGroupList] = useState([]);

    const [userRight, setUserRight] = useState([]);

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [data, setData] = useState([]);

    // const [activityLog, setActivityLog] = useState([]);
    const [showRemarkModal, setShowRemarkModal] = useState(false)
    const [umExecutive, setUmExecutive] = useState(null);
    const [executiveCode, setExecutiveCode] = useState(0);
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            setDdActiveOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);
            setDdEnableOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);
            setDdEntityTypeOptions([{ name: 'Select', value: "0" }, { name: 'Agent', value: "A" }, { name: 'Branch', value: "B" }]);
            setDdEntityCodeOptions([{ name: 'Select', value: "0" }]);
            Common.callApi(Common.apiRight, [sid, 'all', 'USER'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiUser, [sid, 'listjson'], function (result) {
                setUserData(JSON.parse(result));
            });
            Common.callApi(Common.apiUser, [sid, 'ddlistjson', 'B'], function (result) {
                setDdUmRightBranchOptions(JSON.parse(result));
            });
            Common.callApi(Common.apiRightGroup, [sid, 'ddlistjson'], function (result) {
                setDdRightGroupOptions(JSON.parse(result));
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    function getList() {
        Common.callApi(Common.apiUser, [sid, 'listjson'], function (result) {
            setUserData(JSON.parse(result));
        });
    }

    function addNew() {
        setUmSrNo(0);
        // setActivityLog([]);
        setUmUserName("");
        setUmId("");
        setUmMob("");
        setUmEml("");
        setUmEnable(1);
        setUmEntityType("0");
        setUmEntityCode(0);
        setScrMode("A");
        setUmActive(1);
        setUmRightBranch(0);
        setUmRightGroup(0);
        setBranchData([]);
        setUmExecutive(null);
        setAddEditFormShow(true);
        setShowAddNewBtn(false);
    }

    function fillMasterHead(id) {
        setUmEntityType(id);
        Common.callApi(Common.apiUser, [sid, 'ddlistjson', id], function (result) {
            setDdEntityCodeOptions(JSON.parse(result));
        });
    }

    function saveData() {
        var msg = [], i = 0;
        // console.log(umRemark)
        // console.log(scrMode)

        if (umUserName === "" || umId === "" || umMob === "" || umEml === "" || umExecutive === null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (umUserName === "" ? "User Name." : '');
            msg[i++] = (umId === "" ? " User ID." : '');
            msg[i++] = (umMob === "" ? " Mobile." : '');
            msg[i++] = (umEml === "" ? " Email." : '');
            msg[i++] = (umExecutive === null ? " Executive." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        //  else if (scrMode === 'E' && umRemark === '') {
        //     setMsgText('Please fill remark!');
        //     setMsgBox(true);
        // } 

        else if (scrMode === 'E' && !umRemark) {
            // console.log("hello")
            setShowRemarkModal(true);
        }
        else {
            var object1 = {
                umSrNo: umSrNo,
                umUserName: umUserName,
                umId: umId,
                umMob: umMob,
                umEml: umEml,
                umEnable: umEnable,
                umEntityType: umEntityType,
                umEntityCode: umEntityCode,
                umActive: umActive,
                umRightGroup: umRightGroup,
                umExecutive: executiveCode,

                branch: 0,
                activitytype: umSrNo * 1 > 0 ? 'MODIFY' : 'CREATE',
                trancategory: 'M',
                trantype: 'MUSER',
                trannumber: umSrNo,
                subtranno: 0,
                remark: umRemark
            };
            var object2 = {
                TabType: "Info",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MUSER',
                Id: umId,
                UserName: umUserName,
                Mobile: umMob,
                Email: umEml,
                Enable: umEnable,
                EntityType: umEntityType,
                EntityCode: umEntityCode,
                Active: umActive,
                RightGroup: umRightGroup,
                Executive: executiveCode,
                Remark: umRemark
            };
            var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, object2);
            setTranAuditTrail(tran_audit_trail);


            Common.callApi(Common.apiUser, [sid, 'save', umSrNo, JSON.stringify(object1), JSON.stringify(tran_audit_trail)], function (result) {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setScrMode("Q");
                    setAddEditFormShow(false);
                    setShowAddNewBtn(true);
                    setUmRemark("");
                    getList();
                    setShowRemarkModal(false);
                    setTranAuditTrail([]);
                } else {
                    setShowRemarkModal(false)
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setAddEditFormShow(false);
        setShowAddNewBtn(true);
    }

    // function cancelData() {
    //     setAddEditFormShow(false);
    //     setShowAddNewBtn(true);
    // }

    function fillEditForm(id, executiveCode) {
        Common.callApi(Common.apiUser, [sid, 'detail', id, executiveCode], function (result) {
            setScrMode("Q");
            // setActivityLog([]);
            let resp = JSON.parse(result);
            setUmSrNo(resp.umSrNo);
            console.log("serial number is ", resp.umSrNo);
            setUmUserName(resp.umUserName);
            setUmId(resp.umId);
            setUmMob(resp.umMob);
            setUmEml(resp.umEml);
            setUmEnable(resp.umEnable);
            setUmEntityType(resp.umEntityType);
            setUmActive(resp.umActive);
            setBranchData(resp.umDetail);
            // setUmExecutive(resp.umExecutive);
            setUmExecutive({ id: resp.umExecutive, label: resp.umExecutive });
            Common.callApi(Common.apiUser, [sid, 'ddlistjson', resp.umEntityType], function (result) {
                setDdEntityCodeOptions(JSON.parse(result));
                setUmEntityCode(resp.umEntityCode);
            });
            if (resp.umEntityType === "B") {
                setUmRightBranch(0);
            }
            //setUmRightGroup(resp.umRightGroup);
            setUmRightGroupList(resp.grouplist);
            setAddEditFormShow(true);
            setShowAddNewBtn(false);
        });
    }

    function saveRight() {
        if (umRightBranch === 0) {
            setMsgText(Common.getMessage("MSG0006"));
            setMsgBox(true);
        } else {
            var object1 = {
                umSrNo: umSrNo,
                umRightBranch: umRightBranch,
                umRightBranchAllow: umRightBranchAllow
            };
            var object2 = {
                TabType: "Location",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MUSER',
                RightBranch: umRightBranch,
                RightBranchAllowed: umRightBranchAllow
            }
            if (userRight.edit === "1" && scrMode === 'E') {
                var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, object2);
                setTranAuditTrail(tran_audit_trail);
            }
            Common.callApi(Common.apiUser, [sid, 'saveright', JSON.stringify(object1)], function (result) {
                if (result === "") {
                    Common.callApi(Common.apiUser, [sid, 'locationright', umSrNo], function (result) {
                        setBranchData(JSON.parse(result));
                    });
                } else {
                    setMsgText(result);
                    setMsgBox(true);
                }
            });
        }
    }

    function addGroup() {
        if (umRightGroup == 0) {
            return;
        } else {
            var obj = {
                TabType: "Group",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MUSER',
                Group: umRightGroup
            }
            var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, obj);
            setTranAuditTrail(tran_audit_trail);
            Common.callApi(Common.apiUser, [sid, "addgroup", umSrNo, umRightGroup], result => {
                setUmRightGroupList(JSON.parse(result));
                setUmRightGroup(0);
            });
        }
    }

    function deleteGroup(srno, group) {
        const itemToDelete = umRightGroupList.find(item => item.delgroup === srno);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Group",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TType: 'MUSER',
                Group: itemToDelete.group_name
            };
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        Common.callApi(Common.apiUser, [sid, "delgroup", srno, group], result => {
            setUmRightGroupList(JSON.parse(result));
        });
    }

    const editData = () => {
        setScrMode('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'USER'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    // const btnActivityLog = () => {
    //     const object1 = {
    //         trantype: 'MUSER',
    //         trannumber: umSrNo,
    //         trancategory: "M"
    //     }
    //     Common.callApi(Common.apiActivityLog, [sid, "getlog", JSON.stringify(object1)], result => {
    //         setActivityLog(JSON.parse(result));
    //     });
    // }

    const handleExecutive = (value) => {
        console.log(value);
        setUmExecutive(value);
        const exec = value.id.split('^');
        setExecutiveCode(exec[1]);
    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MUSER", trannumber: umSrNo, trancategory: "M" });
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid >
                <Row>
                    <Col>
                        <h1>User Master</h1>
                    </Col>
                </Row>
                {showAddNewBtn ?
                    <Row>
                        <Col>
                            {userRight.add === "1" ?
                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={addNew}>Add New</Button>
                                : null}
                            {
                                userRight.query === "1" ?
                                    <>&nbsp;
                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                    : <Row>
                        <Col>
                            <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                            {
                                (userRight.edit === "1" && scrMode === 'Q') ? <>
                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                        editData()}>Edit</Button>&nbsp;
                                </> : null
                            }
                            {
                                (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                        saveData()}>Save</Button>&nbsp;
                                </> : null
                            }
                            {
                                (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ? <>
                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                        btnActivityLog()}>Activity Log</Button>&nbsp;
                                </> : null
                            }
                        </Col>
                    </Row>
                }
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col>
                        {/* <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Back</Button> */}
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                {showAddEditForm ?
                    <>
                        <Tabs>
                            <Tab eventKey="INFO" title="Info">
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Serial Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Serial Number" value={umSrNo} disabled />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>User Name<span className="mandatory text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="User" maxLength={50}
                                                            value={umUserName}
                                                            onChange={(e) => Common.validateAlpValue(e.target.value, setUmUserName)}
                                                            autoComplete="off"
                                                            disabled={scrMode === 'Q'} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>User ID<span className="mandatory text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="User ID" maxLength={50} disabled={scrMode !== "A"}
                                                            value={umId}
                                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setUmId)}
                                                            autoComplete="off" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Mobile<span className="mandatory text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Mobile" maxLength={50}
                                                            value={umMob}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setUmMob)}
                                                            autoComplete="off"
                                                            disabled={scrMode === 'Q'} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Email<span className="mandatory text-danger">*</span></Form.Label>
                                                        <Form.Control type="email" placeholder="Email" maxLength={50} autoComplete="off"

                                                            value={umEml} onChange={(e) => setUmEml(e.target.value)}
                                                            onBlur={(e) => Common.validtateEmail(e.target.value, setUmEml)}
                                                            disabled={scrMode === 'Q'}

                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Enable</Form.Label>
                                                        <Form.Select
                                                            value={umEnable}
                                                            onChange={(e) => setUmEnable(e.target.value)} disabled={scrMode === 'Q'}
                                                        >
                                                            {ddEnableOptions.map((res) =>
                                                                <option key={res.value} value={res.value}>{res.name}</option>
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Select
                                                            value={umActive}
                                                            onChange={(e) => setUmActive(e.target.value)}
                                                            disabled={scrMode === 'Q'}   >
                                                            {ddActiveOptions.map((res) =>
                                                                <option key={res.value} value={res.value}>{res.name}</option>
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Type</Form.Label>
                                                        <Form.Select
                                                            // disabled={scrMode === "E"}
                                                            disabled={scrMode === "Q"}
                                                            value={umEntityType}
                                                            onChange={(e) => fillMasterHead(e.target.value)}
                                                        >
                                                            {ddEntityTypeOptions.map((res) =>
                                                                <option key={res.value} value={res.value}>{res.name}</option>
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    umEntityType === "A" ?
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Master Head<span className="mandatory text-danger">*</span></Form.Label>
                                                                <Form.Select value={umEntityCode} onChange={(e) => setUmEntityCode(e.target.value)} >
                                                                    {ddEntityCodeOptions.map((res) =>
                                                                        <option value={res.value}>{res.name}</option>
                                                                    )}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        : <Col>&nbsp;</Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col className='col-md-6 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Executive<span className="text-danger">*</span></Form.Label>
                                                        <AsyncSelect
                                                            // disabled={scrMode === "Q"}
                                                            isDisabled={scrMode === "Q"}
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={umExecutive}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={Picklist.fetchExecutivePicklist}
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleExecutive}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* {
                                                scrMode === 'E' ?
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Remark<span className="mandatory text-danger">*</span></Form.Label>
                                                                    <Form.Control type="text" placeholder="remark" maxLength={50}
                                                                        value={umRemark}
                                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setUmRemark)}
                                                                        autoComplete="off"
                                                                        required />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>&nbsp;</Col>
                                                            <Col>&nbsp;</Col>
                                                            <Col>&nbsp;</Col>
                                                        </Row>
                                                    </> :
                                                    null
                                            } */}
                                            <Row>
                                                <Col>&nbsp;</Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Tab>
                            {
                                scrMode === "E" || scrMode === "Q" ?
                                    <Tab eventKey="LOCATION" title="Location">
                                        {
                                            umEntityType === "B" ?
                                                <>
                                                    {
                                                        scrMode === "E" ?
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>Branch</Form.Label>
                                                                        <Form.Select
                                                                            value={umRightBranch}
                                                                            onChange={(e) => setUmRightBranch(e.target.value)} >
                                                                            {ddUmRightBranchOptions.map((res) =>
                                                                                <option key={res.value} value={res.value}>{res.name}</option>
                                                                            )}
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Allowed
                                                                        </Form.Label>
                                                                        <Form.Select
                                                                            value={umRightBranchAllow}
                                                                            onChange={(e) => setUmRightBranchAllow(e.target.value)} >
                                                                            <option key="1" value="1" >YES</option>
                                                                            <option key="0" value="0" >No</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Group>
                                                                        <Form.Label>&nbsp;</Form.Label><br></br>
                                                                        <Button variant="outline-success" size="sm" className='buttonStyle'
                                                                            onClick={() => saveRight()}>Change Right</Button>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            : null
                                                    }
                                                    <Row><Col>&nbsp;</Col></Row>
                                                    <Row>
                                                        <Col>
                                                            <Table responsive striped bordered hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr No</th>
                                                                        <th>Branch Name</th>
                                                                        <th>Allowed</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {branchData.map((res) =>
                                                                        <tr key={res.entity_id}>
                                                                            <td className="textRight">{res.entity_id}</td>
                                                                            <td>{res.entity_name}</td>
                                                                            <td>{res.entity_right}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>

                                                    <Row><Col>&nbsp;</Col></Row>
                                                </>
                                                : null
                                        }
                                    </Tab> : null
                            }
                            {
                                scrMode === "E" || scrMode === "Q" ?
                                    <Tab eventKey="GROUP" title="Group">
                                        {
                                            scrMode === "E" ?
                                                <Row>
                                                    <Col className='col-md-3'>
                                                        <Form.Group>
                                                            <Form.Label>Group<span className="mandatory text-danger">*</span></Form.Label>
                                                            <Form.Select
                                                                value={umRightGroup}
                                                                onChange={(e) => setUmRightGroup(e.target.value)}
                                                            >
                                                                {ddRightGroupOptions.map((res) =>
                                                                    <option key={res.value} value={res.value}>{res.name}</option>
                                                                )}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3'>
                                                        <Button size='sm' style={{ marginTop: "33px" }} className='buttonStyle' variant='outline-primary' onClick={() => addGroup()}>Add</Button>
                                                    </Col>
                                                </Row>
                                                :
                                                null
                                        }
                                        <Row>&nbsp;</Row>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>
                                                    <th>User ID</th>
                                                    <th>Group</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    umRightGroupList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.um_id}</td>
                                                            <td>{item.group_name}</td>
                                                            <td>
                                                                {
                                                                    scrMode === "E" && userRight.add == 1 ?
                                                                        <span className='colorThemeBlue handCursor' onClick={() => deleteGroup(item.rgtgrp_userno, item.rgtgrp_grpno)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span> : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Tab> : null
                            }
                        </Tabs>
                        {/* <Row>
                            {
                                activityLog.length > 0 ?
                                    <Table responsive striped bordered>
                                        <thead>
                                            <tr>
                                                <th>User ID</th>
                                                <th>Remark</th>
                                                <th>Timestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                activityLog.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.lg_usersrno}</td>
                                                        <td>{item.lg_remark}</td>
                                                        <td>{item.date_time}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    : null
                            }
                        </Row> */}
                    </>
                    : <>
                        <Row>
                            <Col >
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th className="textRight">Sr No</th>
                                            <th >User Name</th>
                                            <th>User ID</th>
                                            <th >Status</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tblBody">
                                        {userData.map((res) =>
                                            <tr key={res.um_srno}>
                                                {userRight.edit === "1" ?
                                                    <td className="textCenter" >
                                                        <span className="handCursor" onClick={() => fillEditForm(res.um_srno, res.um_executive)} >
                                                            <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                        </span>
                                                    </td>
                                                    : null
                                                }
                                                <td className="textRight">{res.um_srno}</td>
                                                <td>{res.um_username}</td>
                                                <td>{res.um_id}</td>
                                                <td>{res.um_active}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}
            {/* jitendra */}
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <div>
                <Modal size='lg' show={showRemarkModal} onHide={() => setShowRemarkModal(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            scrMode === 'E' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Remark<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Remark" maxLength={499}
                                            value={umRemark}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setUmRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group>
                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => saveData()}>
                            Save
                        </Button>{' '}
                        <Button variant="outline-success" onClick={() => setShowRemarkModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    )
}

export default Master_user
