import React from 'react';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { Form } from "react-bootstrap";
import * as Common from './Common';

let dialogStyles = {
    width: '500px',
    maxWidth: '100%',
    margin: '0 auto',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zindex: '999',
    backgroundColor: '#fff',
    padding: '10px 20px 40px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 40px 20px rgba(0,0,0,0.26)'
};

let dialogCloseButtonStyles = {
    marginBottom: '15px',
    padding: '3px 8px',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    width: '30px',
    height: '30px',
    fontWeight: 'bold',
    alignSelf: 'flex-end'
}

function Dialog_IRN(props) {
    let dialog = (
        <div style={dialogStyles}>
            <button style={dialogCloseButtonStyles} onClick={props.onNo}><FontAwesomeIcon icon={faWindowClose} /></button>
            <div>
                <h2>{props.text}</h2>
            </div>
            <div>
                <Form.Group>
                    <Form.Select value={props.irnCancelReasonSelect} onChange={e => props.setIrnCancelReasonSelect(e.target.value)} placeholder='Reason' >
                        <option value="">Select</option>
                        {
                            Common.getIrnCancelReason.map((item, key) =>
                                <option key={item.id} value={item.id}>{item.desc}</option>
                            )
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Control value={props.irnCancelReason} onChange={e => Common.validateAlpNumValue(e.target.value, props.setIrnCancelReason)} placeholder='Reason' />
                </Form.Group>
                <Form.Group className='mt-2'>
                    <Form.Check type='checkbox' checked={props.irnCancelDeclaration} value={props.irnCancelDeclaration} onChange={e => props.setIrnCancelDeclaration(!props.irnCancelDeclaration)} label={props.checkLabel} />
                </Form.Group>
            </div>
            <br />
            <div style={{ "textAlign": "center" }}>
                <Button variant="danger" className='buttonStyle' size="sm" onClick={props.onYes}  >Yes</Button>&nbsp;&nbsp;
                <Button variant="outline-success" className='buttonStyle' size="sm" onClick={props.onNo}  >No</Button>&nbsp;&nbsp;
            </div>
        </div>
    );

    if (!props.isOpen) {
        dialog = null;
    }


    return (
        <div>
            {dialog}
        </div>
    );
}

export default Dialog_IRN;