import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import CurrencySelector from '../../../CurrencySelector';
import LocationSelector from '../../../LocationSelector';
import Login_header from '../../../Login_header';
import Menu from '../../../Menu';
import * as Common from './../../../Common';
import Dialog from './../../../Dialog';
import $ from "jquery";

const Stock_in_hand = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [stockFromDate, setStockFromDate] = useState(new Date());
    const [stockToDate, setStockToDate] = useState(new Date());
    const [stockType, setStockType] = useState("V");

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [currencyCode, setCurrencyCode] = useState('');
    const [selectedItems2, setSelectedItems2] = useState([]);
    const [radioType2, setRadioType2] = useState('A');
    const [scr, setScr] = useState("")
    const [stockReportList, setStockReportList] = useState([]);


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'STOCK_IN_HAND_REPORT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleDataFromChild2 = (newCurrencyCode, newSelectedItems2, newRadioType2) => {
        setCurrencyCode(newCurrencyCode);
        setSelectedItems2(newSelectedItems2);
        setRadioType2(newRadioType2);
    }

    const handleGenerate = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if ((radioType === 'O' && locationCode === '0') || (radioType == 'S' && selectedItems == []) || (radioType2 === 'O' && currencyCode === '0') || (radioType2 === 'S' && selectedItems2 == [])) {
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            msg[i++] = ((radioType2 == 'O' && currencyCode == '0') ? "Please select currency." : '');
            msg[i++] = ((radioType2 == 'S' && selectedItems2 == []) ? "Please select atleast one currency." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
            $(".loader").hide();
        } else {
            const obj = {
                fromDate: Common.dateYMD(stockFromDate),
                fromDate1: Common.dateDMY(stockFromDate),
                toDate: Common.dateYMD(stockToDate),
                toDate1: Common.dateDMY(stockToDate),
                type: stockType,
                radioTypeLocation: radioType,
                location: locationCode,
                multipleLocation: selectedItems,
                radioTypeCurrency: radioType2,
                currency: currencyCode,
                multipleCurrency: selectedItems2.length > 0 ? selectedItems2.split(',').join("','") : selectedItems2,
            };
            if (stockType === 'D') {
                Common.callDownloadApiPost1(Common.apiStockInHandReport, "post", [sid, 'getData', JSON.stringify(obj)], (result) => {
                    $(".loader").hide();
                    setMsgText(Common.buildMessageFromArray([result]));
                    setMsgBox(true);
                    console.log("stock in habnd report", result)
                });
            } else if (stockType === 'V') {
                Common.callApi(Common.apiStockInHandReport, [sid, 'getData', JSON.stringify(obj)], (result) => {
                    $(".loader").hide();
                    setScr('V');
                    const resData = JSON.parse(result);
                    setStockReportList(resData)
                    console.log(scr);
                });
            } else {
                Common.callApi(Common.apiStockInHandReport, [sid, 'getData', JSON.stringify(obj)], (result) => {
                    $(".loader").hide();
                    var newWindow = window.open();
                    newWindow.document.write(result);
                });
            }
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h3>Stock in Hand Report</h3>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={stockFromDate}
                                        onChange={(date) => setStockFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={stockToDate}
                                        onChange={(date) => setStockToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={stockType} onChange={e => setStockType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col className='col-md-6 col-6'>
                                <Form.Label>Location Filter</Form.Label><br />
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                            <Col className='col-md-6 col-6'>
                                <Form.Label>Currency Filter</Form.Label><br />
                                <CurrencySelector onDataFromChild2={handleDataFromChild2} />
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>ISD</th>
                                <th>Branch</th>
                                <th>Deno</th>
                                <th>Opn Bal</th>
                                <th>Purchase</th>
                                <th>Seles</th>
                                <th>Trans In</th>
                                <th>Trans Out</th>
                                <th>Fake</th>
                                <th>Quant in Hand</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stockReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.prd_isd}</td>
                                        <td>{item.prd_location + " " + item.entity_name}</td>
                                        <td>{item.prd_deno}</td>
                                        <td>{item.opn_bal}</td>
                                        <td>{item.purchase}</td>
                                        <td>{item.sales}</td>
                                        <td>{item.trans_in}</td>
                                        <td>{item.trans_out}</td>
                                        <td>{item.fake}</td>
                                        <td>{item.qty_in_hand}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </> : null}
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Stock_in_hand;
