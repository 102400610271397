
var msgModel = [];
msgModel['ERR0000'] = 'Unidentified Error. Please contact to administrator';
msgModel['MSG0000'] = 'Bill Saved';
msgModel['MSG0001'] = 'Invalid username or password.';
msgModel['MSG0002'] = 'Session has been expired. Please login again.';
msgModel['MSG0003'] = 'User is inactive.';
msgModel['MSG0004'] = 'User is blocked by Admin or Head office.';
msgModel['MSG0005'] = 'Please check password policy.';
msgModel['MSG0006'] = 'Please fill mandatory feilds.';
msgModel['MSG0007'] = 'Date of birth sould be less than today.';
msgModel['MSG0008'] = 'Payment does not tally. Please check.';
msgModel['MSG0009'] = 'New password and confirm password does not match.';
msgModel['MSG0010'] = "Date should be within financial year.";
msgModel['WRN0000'] = "Do you really want to discard the changes.";
msgModel['MSG0011'] = "Entry can't be made for Future date.";
msgModel['GSTWRN000.'] = "Invalid GSTIN.";

export const getMessage = (key) => {
    return msgModel[key];
}

export const apiBasePath = "";
/*export const apiBasePath = "http://localhost:1001";*/ // same
//export const apiBasePath = "http://localhost:2001"; // cross domain

export const apiPicklist = apiBasePath + "/api/picklist_a.php";
export const apiLogin = apiBasePath + "/api/login_a.php";
export const apiUser = apiBasePath + "/api/user_a.php";
export const apiCountry = apiBasePath + '/api/country_a.php';
export const apiState = apiBasePath + '/api/state_a.php';
export const apiCity = apiBasePath + '/api/city_a.php';
export const apiIsd = apiBasePath + '/api/isd_a.php';
export const apiIssuer = apiBasePath + '/api/issuer_a.php';
export const apiEntity = apiBasePath + '/api/entity_a.php';
export const apiRightGroup = apiBasePath + '/api/right_group_a.php';
export const apiRight = apiBasePath + '/api/right_a.php';
export const apiPurpose = apiBasePath + '/api/purpose_a.php';
export const apiUploadFile = apiBasePath + '/api/upload_a.php';
export const apiIdtype = apiBasePath + '/api/idtype_a.php';
export const apiDealer = apiBasePath + '/api/dealer_a.php';
export const apiVoucher = apiBasePath + '/api/voucher_a.php';
export const apiLedger = apiBasePath + '/api/ledger_a.php';
export const apiRegion = apiBasePath + '/api/region_a.php';

export const apiCountryClass = apiBasePath + '/api/country_class_a.php';
export const apiDivision = apiBasePath + '/api/division_a.php';
export const apiRisk = apiBasePath + '/api/risk_a.php';
export const apiBusinessNature = apiBasePath + '/api/business_nature_a.php';
export const apiLegalEntity = apiBasePath + '/api/legal_entity_a.php';
export const apiDistrict = apiBasePath + '/api/district_a.php';
export const apiCostCenter = apiBasePath + '/api/cost_center_a.php';
export const apiGstClassification = apiBasePath + '/api/gst_classification_a.php';
export const apiMsme = apiBasePath + '/api/msme_a.php';
export const apiDesignation = apiBasePath + '/api/designation_a.php';
export const apiExecutive = apiBasePath + '/api/executive_a.php';
export const apiVoucherType = apiBasePath + '/api/voucher_type_a.php';
export const apiIndividualProfile = apiBasePath + '/api/individual_profile_a.php';
export const apiGrpDoc = apiBasePath + '/api/doc_link_a.php';
export const apiReport = apiBasePath + '/api/report_a.php';
export const apiDocument = apiBasePath + '/api/document_a.php';
export const apiActivityLog = apiBasePath + '/api/activitylog_a.php';
export const apiFinYear = apiBasePath + '/api/finyear_a.php';
export const apiTranPP = apiBasePath + '/api/tran_pp_a.php';
export const apiTranOpnTrial = apiBasePath + '/api/tran_opn_trial_a.php';
export const apiResidentialStatus = apiBasePath + '/api/residential_status_a.php';
export const apiTranOpnCmr = apiBasePath + '/api/tran_opn_cmr_a.php';
export const apiTranCurrencyStocks = apiBasePath + '/api/tran_currency_stock_a.php';
export const apiTranOpnVtc = apiBasePath + '/api/tran_opn_vtc_a.php';
export const apiOpnBankStmt = apiBasePath + '/api/opn_bank_statement_a.php';
export const apiTranOpnTc = apiBasePath + '/api/tran_opn_tc_a.php';
export const apiTranOpnEtc = apiBasePath + '/api/tran_opn_etc_a.php';
export const apiVtmEncash = apiBasePath + '/api/vtm_encashment_a.php';
export const apiTranOpnEvtm = apiBasePath + '/api/tran_opn_evtm_a.php';
export const apiTranOpnTcSt = apiBasePath + '/api/tran_opn_tc_st_a.php';
export const apiTranOpnVtmSt = apiBasePath + '/api/tran_opn_vtm_st_a.php';
export const apiBankChequeBookIn = apiBasePath + '/api/bank_cheque_book_in_a.php';
export const apiBookEntry = apiBasePath + '/api/tran_book_entry_a.php';
export const apiTranPB = apiBasePath + '/api/tran_pb_a.php';
export const apiTranSP = apiBasePath + '/api/tran_sp_a.php';
export const apiTranTRF = apiBasePath + '/api/tran_trf_vtc_a.php';
export const apiChequeDeposit = apiBasePath + '/api/cheque_deposit_a.php';
export const apiBankChequeBookOut = apiBasePath + '/api/bank_cheque_book_out_a.php';
export const apiFormat = apiBasePath + '/api/download_format_a.php';
export const apiReceivedChequeBounce = apiBasePath + '/api/received_cheque_bounce_a.php';
export const apiPrint = apiBasePath + '/api/print_a.php';
export const apiFaViewLog = apiBasePath + '/api/fa_view_a.php';
export const apiIssueChequeBounce = apiBasePath + '/api/issue_cheque_bounce_a.php';
export const apiBankReconciliation = apiBasePath + '/api/bank_reconciliation_a.php';
export const apiCheck = apiBasePath + '/api/sample_checkbox_a.php';
export const apiTRFManualBook = apiBasePath + '/api/tran_trf_manual_book_a.php';
export const apiFinancialLedger = apiBasePath + '/api/financial_ledger_a.php';
export const apiReconciliationActivity = apiBasePath + '/api/reconciliation_activity_a.php';
export const apiCalcTaxable = apiBasePath + "/api/calc_invoice_a.php";
export const apiTrialBalance = apiBasePath + '/api/trial_balance_a.php';
export const apiTranTransferOutVTC = apiBasePath + '/api/tran_transfer_out_vtc_a.php';
export const apiTranTransferOutCurrency = apiBasePath + '/api/tran_transfer_out_currency_a.php';
export const apiTranSB = apiBasePath + '/api/tran_sb_a.php';
export const apiAcceptInterBranch = apiBasePath + '/api/out_log_a.php';
export const apiBillWiseDetail = apiBasePath + '/api/rep_fin_recv_bill_wise_detail_a.php';
export const apiLedgerLink = apiBasePath + '/api/ledger_link_a.php';
export const apiChequeInHandReport = apiBasePath + '/api/cheque_in_hand_report_a.php';
export const apiChequeUsedReport = apiBasePath + '/api/cheque_used_report_a.php';
export const apiIBEntry = apiBasePath + '/api/inter_branch_entry_a.php';
export const apiMargin = apiBasePath + '/api/margin_a.php';
export const apiMasterIBR = apiBasePath + '/api/IBR_a.php';
export const apiDataUnlockRequest = apiBasePath + '/api/tran_data_unlock_request_a.php';
export const apiDataUnlock = apiBasePath + '/api/tran_data_unlock_a.php';
export const apiWriteOff = apiBasePath + '/api/currency_write_off_a.php';
export const apiTransactionReport = apiBasePath + '/api/transaction_report_a.php';
export const apiTranPI = apiBasePath + '/api/tran_pi_a.php';
export const apiCurrencyChange = apiBasePath + '/api/currency_change_a.php';
export const apiCancelManualBook = apiBasePath + '/api/tran_manual_book_cancellation_a.php';
export const apiCancelChequeBook = apiBasePath + '/api/bank_cheque_book_caancellation_a.php';
export const apiSettlementVtm = apiBasePath + '/api/settlement_vtm_a.php';
export const apiUnlockEdit = apiBasePath + '/api/unlock_a.php';
export const apiGVTSettlement = apiBasePath + '/api/glb_vtm_settlement_a.php';
export const apiExpenseTransfer = apiBasePath + '/api/expense_transfer_a.php';
export const apiFundTransfer = apiBasePath + '/api/fund_transfer_a.php';
export const apiStockInHandReport = apiBasePath + '/api/stock_in_hand_report_a.php';
export const apiManualBookReport = apiBasePath + '/api/manual_book_report_a.php';
export const apifundRequest = apiBasePath + "/api/fund_request_a.php";
export const apiMasterDSR = apiBasePath + '/api/master_dsr_a.php';
export const apiFlmReport = apiBasePath + "/api/flm_report_a.php";
export const apiFxDocumentUpload = apiBasePath + "/api/fx_document_a.php";
export const apiTranEVTMSurrender = apiBasePath + "/api/tran_evtm_surrender_a.php";
export const apiTranModulePayment = apiBasePath + "/api/tran_module_payment_a.php";
export const apiAgentLinking = apiBasePath + "/api/agent_linking_a.php";
export const apiCommission = apiBasePath + "/api/commission_a.php";
export const apiBillWiseDetailPayable = apiBasePath + '/api/bill_wise_detail_payable_a.php';
export const apiPaymentReport = apiBasePath + "/api/tran_payment_report_a.php";
export const apiConveyanceEntry = apiBasePath + "/api/tran_conveyance_a.php";
export const apiActivityLogReport = apiBasePath + "/api/activitylog_report_a.php";
export const apiCreditLimit = apiBasePath + "/api/creditlimit_a.php";
export const apiEInvoice = apiBasePath + "/api/einvoice_a.php"

export const apiTranModification = apiBasePath + "/api/tran_modification_a.php"
export const apiModiCommission = apiBasePath + "/api/modification_commission_a.php"
export const apiModiTransaction = apiBasePath + "/api/modification_transaction_a.php"
export const apiTranSI = apiBasePath + "/api/tran_si_a.php"
export const apiModiPayment = apiBasePath + "/api/tran_modification_payment_a.php";

export const editDateLimit = 1;
export const maximumLength = 10;
export const maxFromLength = 20;
export const prefixLength = 5;

//export const validEmail = new RegExp( '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'  );
export const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$');
//      '^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$'
//     '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/';

export const removeSpecialChar = (getString) => {
    if (typeof getString != 'number' && getString != null) {
        getString = getString.replace(/\&amp;/g, "and");
        getString = getString.replace(/\&/g, "and");
        getString = getString.replace(/\$/g, " ");
        getString = getString.replace(/\#/g, " ");
        getString = getString.replace(/\*/g, " ");
        getString = getString.replace(/\</g, " ");
        getString = getString.replace(/\>/g, " ");
        getString = getString.replace(/\-/g, " ");
        getString = getString.replace(/\_/g, " ");
        getString = getString.replace(/\+/g, " ");
        getString = getString.replace(/\%/g, " ");
    }
    return getString;
}

function replaceSpecialChar(getString) {
    if (typeof getString != 'number' && getString != null) {
        getString = getString.replace(/\#/g, "^^hash^^");
        getString = getString.replace(/\+/g, "^^plus^^");
        getString = getString.replace(/\%/g, "^^mod^^");
        getString = getString.replace(/\&amp;/g, "^^and^^");
        getString = getString.replace(/\&/g, "^^and^^");
    }
    return getString;
}

function ajaxRequest() {
    if (window.XMLHttpRequest) {// code for IE7+, Firefox, Chrome, Opera, Safari
        return new XMLHttpRequest();
    }
}

export const callApi = (url, prm, Success) => {
    var prms = "";
    if (typeof (prm) != "undefined") {
        for (let i = 0; i < prm.length; i++) {
            if (prms.length > 0) prms = prms + "&";
            prms = prms + (i + 1) + '=' + replaceSpecialChar(prm[i]);
        }
    }
    var xhr = new ajaxRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            var SessXML = xhr.responseText.replace(/[\r\n\s]*</g, "<").replace(/[\r\n\s]*$/g, "");
            if (SessXML.indexOf(".php") >= 0) {
                alert(SessXML);
                //$('.loader').hide();
            }
            else if (SessXML.trim() === '{"status": "MSG0002"}') {
                sessionStorage.clear();
                const myLoc = window.location.href.split('/');
                window.location.replace(myLoc[0] + '//' + myLoc[2] + '/');
            }
            else {
                Success(SessXML);
            }
        }
    }
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send(prms);
}

const alpRegExp = new RegExp('^([a-zA-Z ]+)$');
export const validateAlpValue = (e, f) => {
    if (e === "" || alpRegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}

// Input field Validations
const intRegExp = new RegExp('^([0-9]+)$');
export const validateNumValue = (e, f) => {
    if (e === "" || intRegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}
const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
export const validateDecValue = (e, f) => {
    if (e === "" || floatRegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}

const alpNumRegExpWS = new RegExp('^([0-9a-zA-Z]+)$');
export const validateAlpNumWSValue = (e, f) => {
    if (e === "" || alpNumRegExpWS.test(e)) {
        f(e);
    } else {
        return false;
    }
}

const alpNumRegExp = new RegExp('^([0-9a-zA-Z ]+)$');
export const validateAlpNumValue = (e, f) => {
    if (e === "" || alpNumRegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}

const alpNumSpl1RegExp = new RegExp('^([0-9a-zA-Z- ]+)$');
export const validateAlpNumSpl1Value = (e, f) => {
    if (e === "" || alpNumSpl1RegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}
const alpNumSplRegExp = new RegExp('^([0-9a-zA-Z/-_!@$^*()#%& ]+)$');
export const validateAlpNumSplValue = (e, f) => {
    if (e === "" || alpNumSplRegExp.test(e)) {
        f(e);
    } else {
        return false;
    }
}

const emailRegExp = new RegExp('^[a-zA-Z0-9_.-]+@[a-zA-Z0-9]+[.]+[A-Za-z]+$');
export const validtateEmail = (e, f) => {
    if (e === "" || emailRegExp.test(e)) {
        f(e);
    } else {
        f("");
    }
}
const panRegExp = new RegExp('^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$');
export const validatePan = (e, f) => {
    e = e.toUpperCase();
    var letter4_valid = /([A,B,C,F,G,H,J,L,P,T])/;
    var letter4 = e.substring(3, 4);
    if ((e === "" || panRegExp.test(e)) && (letter4_valid.test(letter4))) {
        f(e);
    } else {
        f("");
    }
}

export const validatePanFlag = (e) => {
    e = e.toUpperCase();
    var letter4_valid = /([A,B,C,F,G,H,J,L,P,T])/;
    var letter4 = e.substring(3, 4);
    if ((e === "" || panRegExp.test(e)) && (letter4_valid.test(letter4))) {
        return true;
    } else {
        return false;
    }
}

// Date Conversion
//---------------Change Date Format 
export const dateYMD1 = (dt) => {       // Convert To YYYY-MM-DD old function 
    if (dt === "" || dt === null) {
        return dt;
    } else {
        return dt.toJSON().slice(0, 10);
    }
}

export const dateYMD = (dt) => {       // Convert To YYYY-MM-DD
    if (dt === "" || dt === null) {
        return dt;
    } else {
        var dtstr = dt.toString();
        dtstr = dtstr.replace("00:00:00 GMT+0530", "05:30:00 GMT+0530");
        dt = new Date(dtstr);
        return dt.toJSON().slice(0, 10);
    }
}

export const dateDMY = (dt) => {       // Convert to DD/MM/YYYY
    if (dt === "" || dt === null) {
        return dt;
    } else {
        return dt.toJSON().slice(0, 10).split('-').reverse().join('/');
    }
}

export const dateDMYStr = (dt) => {       // Convert to DD/MM/YYYY
    if (dt === "" || dt === null) {
        return dt;
    } else {
        return dt.slice(0, 10).split('-').reverse().join('/');
    }
}


// Associative array manupulations 

export const arrayRemoveItem = (arr, key, value) => {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i][key] === value) {
                arr.splice(i, 1);
            }
        }
    }
    return arr;
}

export const arrayAddItem = (arr, obj) => {
    arr.push(obj);
    // console.log(JSON.stringify(arr,null,' ') );
    return arr;
}

export const arrayAddlog = (arr, obj) => {
    arr.push(obj);
    return arr;
}

export const arrayChangeItem = (arr, uKey, uValue, key, value) => {
    if (arr.length > 0) {
        for (var i = 0; i < arr.length; ++i) {
            if (arr[i][uKey] === uValue) {
                arr[i][key] = value;
            }
        }
    }
    // console.log(JSON.stringify(arr,null,' ') );
    return arr;
}

// RandomString Generator
export const getRandomString = (x = 20) => {
    var a = (Math.random() + 1).toString(36).substring(2);
    var b = (Math.random() + 1).toString(36).substring(2);
    return (a + b).substring(0, x);
}

//formData.append('username', 'Chris');
//formData.append('userpic', myFileInput.files[0], 'chris.jpg');
//
export const uploadApi = (obj, fileId, Success) => {
    var myFile = document.getElementById(fileId);
    var files = myFile.files;
    var file = files[0];

    var formData = new FormData();
    formData.append('1', obj);
    formData.append('file', file, file.name);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", apiUploadFile, true);
    xhr.onload = function () {
        if (xhr.status === 200) {
            var responseText = xhr.responseText.replace(/[\r\n\s]*</g, "<").replace(/[\r\n\s]*$/g, "");

            if (responseText.indexOf(".php") >= 0) {
                alert(responseText);
            } else if (responseText.trim() === '{"status": "MSG0002"}') {
                sessionStorage.clear();
                const myLoc = window.location.href.split('/');
                window.location.replace(myLoc[0] + '//' + myLoc[2] + '/');
            } else {
                Success(responseText);
            }
            // Success('Upload complete!' + responseText);
        } else {
            Success('Upload error. Try again.');
        }
    }
    xhr.send(formData);
}
export const callDownloadApiPost = (path, method, prm) => {
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);
    form._submit_function_ = form.submit;

    for (var i = 1; i <= prm.length; i++) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", i);
        hiddenField.setAttribute("value", prm[i - 1]);
        form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form._submit_function_();
}
//jitendra arya
export const callDownloadApiPost1 = (path, method, prm, Success) => {
    //set data in the form;
    var formData = new FormData();
    for (var i = 0; i < prm.length; i++) {
        formData.append(`${i + 1}`, prm[i]);
    }
    var xhr = new XMLHttpRequest();
    xhr.open(method, path, true);
    xhr.responseType = 'blob'; // Set response type as blob to handle binary data
    xhr.onload = function () {
        if (xhr.status === 200) {
            // Create a blob from the response
            const blob = xhr.response;
            console.log(blob)

            // Get the filename from the Content-Disposition header
            const contentDisposition = xhr.getResponseHeader('Content-Disposition');
            console.log(contentDisposition)
            let filename = "downloaded_file.xlsx"; // Default filename

            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameMatch = contentDisposition.split("filename=");
                console.log(filenameMatch)
                if (filenameMatch.length === 2) {
                    filename = filenameMatch[1];
                }
            }

            // Create a link element to download the file
            const url = window.URL.createObjectURL(blob);
            console.log(url)
            const link = document.createElement('a');
            console.log(link)
            link.href = url;
            link.setAttribute('download', filename);

            // Append the link to the document and trigger click to start download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            // Optionally, call success callback
            Success && Success("File downloaded successfully");
        } else {
            console.error("Error during file download: ", xhr.statusText);
        }
    };

    xhr.onerror = function () {
        console.error("Error during XMLHttpRequest");
    };
    xhr.send(formData);
}

export const callDocumentViewApi = (url, prm, Success) => {
    var prms = "";
    if (typeof (prm) != "undefined") {
        for (let i = 0; i < prm.length; i++) {
            if (prms.length > 0) prms = prms + "&";
            prms = prms + (i + 1) + '=' + replaceSpecialChar(prm[i]);
        }
    }
    var xhr = new ajaxRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            Success(xhr.responseText);
        }
    }
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.send(prms);
}
export const buildDateFormat = (v, e) => {
    var len = v.length;
    if (len === 2 || len === 5) {
        e.value = v + '/';
    }
}

export const buildMessageFromArray = (prm) => {
    var msg = "", i = 0;
    if (typeof (prm) != "undefined") {
        for (; i < prm.length; i++) {
            if (prm[i] !== "") {
                msg = msg + "<div class='row'><div class='col-sm-12'>" + prm[i] + "</div></div>";
            }
        }
    }
    return msg;
}

export const setPageTitle = (v) => {
    if (v === 0) {
        document.title = "ZeneRemit";
        document.getElementById("ZenFavicon").href = "/favicon.ico";
    } else {
        document.title = "ZeneRemit ( " + v + " )";
        document.getElementById("ZenFavicon").href = "/faviconred.ico";
    }

    // document.getElementById("ZenFavicon").href = "%PUBLIC_URL%/favicon1.ico";
}

export const getPanRelationCode = (v) => {
    var r = "0";
    switch (v.toUpperCase()) {
        case "SELF":
            r = "1";
            break;
        case "HUSBAND":
            r = "2";
            break;
        case "WIFE":
            r = "3";
            break;
        case "SON":
            r = "4";
            break;
        case "DAUGHTER":
            r = "5";
            break;
        case "BROTHER":
            r = "6";
            break;
        case "SISTER":
            r = "7";
            break;
        case "FRIEND":
            r = "8";
            break;
        case "FATHER":
            r = "9";
            break;
        case "MOTHER":
            r = "10";
            break;
        case "OTHER":
            r = "11";
            break;
        default:
            r = "0";
    }
    return r;
}

export const getSrNoFromRefNo = (v) => {
    var refArray = v.split('/');
    var refArrayLength = refArray.length;
    var lastItem = refArray[refArrayLength - 1];
    var srno = lastItem.substring(1);
    return srno;
}

export function calcGSTTaxableValue(fxamnt, totchrg) {
    var GSTTaxableValslab = [250, 60000, 100000, 1000000, 0.01, 0.005, 0.001, 1000, 5500];
    //var fxamnt = $('#txtPayFxAmnt').val() * 1;
    //var totchrg = $("#txtPayTotChrg").val() * 1;
    var policy = 2; // sessionStorage.gstpolicy * 1;
    var TaxableVal = 0;
    switch (policy) {
        case 1: // total fx and charges then calculate taxable
            if (fxamnt + totchrg > 0 && fxamnt + totchrg <= GSTTaxableValslab[2]) {
                TaxableVal = Math.round((fxamnt + totchrg) * GSTTaxableValslab[4] * 100) / 100;
            } else if (fxamnt + totchrg > GSTTaxableValslab[2] && fxamnt + totchrg <= GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[7] + Math.round((fxamnt + totchrg - GSTTaxableValslab[2]) * GSTTaxableValslab[5] * 100) / 100;
            } else if (fxamnt + totchrg > GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[8] + Math.round((fxamnt + totchrg - GSTTaxableValslab[3]) * GSTTaxableValslab[6] * 100) / 100;
            }
            break;
        case 2: // calculate taxable from fx then add charges
            if (fxamnt > 0 && fxamnt <= GSTTaxableValslab[2]) {
                TaxableVal = Math.round((fxamnt) * GSTTaxableValslab[4] * 100) / 100;
            } else if (fxamnt > GSTTaxableValslab[2] && fxamnt <= GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[7] + Math.round((fxamnt - GSTTaxableValslab[2]) * GSTTaxableValslab[5] * 100) / 100;
            } else if (fxamnt > GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[8] + Math.round((fxamnt - GSTTaxableValslab[3]) * GSTTaxableValslab[6] * 100) / 100;
            }

            break;
        case 3: // calculate taxable only from fx
            if (fxamnt > 0 && fxamnt <= GSTTaxableValslab[2]) {
                TaxableVal = Math.round((fxamnt) * GSTTaxableValslab[4] * 100) / 100;
            } else if (fxamnt > GSTTaxableValslab[2] && fxamnt <= GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[7] + Math.round((fxamnt - GSTTaxableValslab[2]) * GSTTaxableValslab[5] * 100) / 100;
            } else if (fxamnt > GSTTaxableValslab[3]) {
                TaxableVal = GSTTaxableValslab[8] + Math.round((fxamnt - GSTTaxableValslab[3]) * GSTTaxableValslab[6] * 100) / 100;
            }
            break;
    }
    if (TaxableVal < GSTTaxableValslab[0])
        TaxableVal = GSTTaxableValslab[0];
    if (TaxableVal > GSTTaxableValslab[1])
        TaxableVal = GSTTaxableValslab[1];
    if (policy == 2) {
        TaxableVal += totchrg;
    }
    return (Math.round(TaxableVal * 100) / 100);
}

const passportRegExp = new RegExp('^[A-Z][1-9]\\d\\s?\\d{4}[1-9]$');
export const validatePassport = (e, f) => {
    if (e === "" || passportRegExp.test(e)) {
        f(e);
    } else {
        f("");
    }
}

const adhaarRegExp = new RegExp('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$');
export const validateAdhaar = (e, f) => {
    e = e.toUpperCase();
    //var letter4 = e.substring(3, 4);
    if (e === "" || adhaarRegExp.test(e)) {
        f(e);
    } else {
        f("");
    }
}


export function getActiveBranch() {
    return new Promise((resolve, reject) => {
        const sid = sessionStorage.getItem("sessionId");
        const activeBranch = sessionStorage.getItem("activeBranch");
        callApi(apiEntity, [sid, "activelocation", activeBranch], result => {
            const response = JSON.parse(result);
            resolve(response);
        });
    });
}


// Validate GST--------------------------------------------
const gstRegExp = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
export const validateGST = (e, f) => {
    e = e.toUpperCase();
    if (e === "" || gstRegExp.test(e)) {
        f(e);
    } else {
        f("");
    }
}

// Regular expression for validating IFSC Code
const ifscRegex = new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$');
export function validateIFSC(e, f) {
    e = e.toUpperCase();
    console.log("hello", e);
    // Test the provided IFSC code against the regex
    if (ifscRegex.test(e) || e === "") {
        f(e);
    } else {
        return f("");
    }
}


// Validate Mobile Number-------------------------
const mobileNumberRegExp = new RegExp('^[6-9]{1}[0-9]{9}$');
export const validateMobileNumber = (e, f) => {
    if (e === "" || mobileNumberRegExp.test(e)) {
        f(e);
    } else {
        f("");
    }
}

// IRN cancel reasons
export const getIrnCancelReason = [
    { id: "1", desc: "DUPLICATE" },
    { id: "2", desc: "DATA ENTRY MISTAKE" },
    { id: "3", desc: "ORDER CANCELLED" },
    { id: "4", desc: "OTHERS" }
];


// all right common api call
export function callApiPromise(api, params) {
    return new Promise((resolve, reject) => {
        callApi(api, params, function (result) {
            try {
                const resp = JSON.parse(result);
                resolve(resp);
            } catch (error) {
                reject(error);
            }
        });
    });
}