import { faCircleCheck, faCircleXmark, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import * as Common from "../Common";
import Dialog from "../Dialog";
import DialogYesNo from "../Dialog_Yes_No";
import FaView from "../FaView";
import Login_header from "../Login_header";
import Menu from "../Menu";
import * as Picklist from "../Picklist";

function FundRequest() {
    const navigate = useNavigate();
    const sid = sessionStorage.getItem("sessionId");
    const userSrno = sessionStorage.getItem("userSrno");
    // const startDate = sessionStorage.getItem("startDate");
    // const endDate = sessionStorage.getItem("endDate");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [srcMode, setSrcMode] = useState('')
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [amount, setAmount] = useState("");
    const [location, setLocation] = useState(null);
    const [locationCode, setLocationCode] = useState("");
    const [bank, setBank] = useState(null);
    const [refno, setRefno] = useState("");
    const [ddate, setDate] = useState(new Date());
    const [fromWhom, setFromWhom] = useState("");
    const [fromWhomSelectedVal, setfromWhomSelectedVal] = useState("");
    const [dataList, setDataList] = useState([]);
    const [selectedFundRequest, setSelectedFundRequest] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [fromDate, setFromDate] = useState(new Date("2023/04/01"));
    const [todate, setToDate] = useState(new Date("2025/03/31"));
    const [status, setStatus] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [srno, setSrno] = useState(0);
    const [bankFilterList, setBankFilterList] = useState([])
    const [bankFilter, setBankFilter] = useState('')
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = useState(10)
    const [isMsgBoxAR, setIsMsgBoxAR] = useState(false);
    const [msgTextAR, setMsgTextAR] = useState("")
    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);




    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: selectedFundRequest.location_label.split(' ')[1], trannumber: selectedFundRequest.tfa_srno, type: 'TFA' });
    }


    function showConfirmPopUp(fundReq, st) {
        setMsgTextAR(`Do you really want to ${st === 'A' ? "Approve" : "Reject"} this fund Request`);
        setSelectedFundRequest(fundReq);
        setRefno(fundReq.tfa_refno);
        setSrno(fundReq.tfa_srno);
        setStatus(st);
        setIsMsgBoxAR(true);
    }

    function updateStatus() {
        $(".loader").show();
        const obj = {
            ...selectedFundRequest,
            refno: refno,
            srno: srno,
            status: status,
        };
        Common.callApi(
            Common.apifundRequest, [sid, "createFundRequest", JSON.stringify(obj)], function (result) {
                $(".loader").hide();
                console.log(result);
                applyFilter();
                setIsMsgBoxAR(false);
            }
        );


    }

    function getBankFilter() {
        Common.callApi(Common.apifundRequest, [sid, "bankFilter"], function (result) {
            let resp = JSON.parse(result);
            console.log(resp[0].mvli_ledger_name)
            setBankFilterList(resp);
        });
    }

    function amountHandler(e) {
        Common.validateDecValue(e.target.value, setAmount);
    }

    useEffect(() => {
        console.log("useEffect", onceRun, sid, navigate);
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, "all", "FUND_REQUEST"], function (result) {
                let resp = JSON.parse(result);
                console.log(resp)
                if (resp.query === "1") {
                    setUserRight(resp);
                    getBankFilter();
                } else {
                    navigate("/");
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    function applyFilter() {
        setSrcMode('')
        console.log('apply filter', status);
        $(".loader").show();
        const obj = {
            fromDate: fromDate,
            toDate: todate,
            status: filterStatus,
            bankFilter: bankFilter,
            searchText: searchText,
            limit: limit,
        };
        Common.callApi(
            Common.apifundRequest,
            [sid, "applyFilter", JSON.stringify(obj)],
            function (result) {
                console.log(typeof JSON.parse(result), "list", result);
                setDataList(JSON.parse(result));
                $(".loader").hide();
                makeAllValueEmpty();
            }
        );
    }

    function viewRequest(srno, fromWhom) {
        setSrcMode('Q');
        console.log("Status", status);
        $(".loader").show();
        console.log(srno, fromWhom);
        Common.callApi(
            Common.apifundRequest,
            [sid, "getBySRNO", JSON.stringify({ srno, fromWhom })],
            function (result) {
                console.log(JSON.parse(result), "list", result);
                $(".loader").hide();
                const res = JSON.parse(result)[0];
                console.log(res.location_id);
                setSelectedFundRequest(res);
                setStatus(res.tfa_status);
                setAmount(res.tfa_amount);
                setRefno(res.tfa_refno);
                setLocation({ id: res.location_id, label: res.location_label });
                setBank({ id: res.bank_id, label: res.bank_label });
                setFromWhom(res.tfa_from_whom);
                setDate(new Date(res.tfa_date));
                setSrno(res.tfa_srno);
                if (res.tfa_from_whom === "WALKIN") {
                    setfromWhomSelectedVal(res.tfa_walkin_name);
                } else {
                    setfromWhomSelectedVal({ id: res.corp_id, label: res.corp_label });
                }
            }
        );
    }

    function addNew() {
        setSrcMode('A')
    }

    async function backButtonHandler() {
        setSelectedFundRequest();
        setIsEdit(false);
        applyFilter();
        makeAllValueEmpty();
        setMsgBoxYN(false)
        // setFilterStatus('')
    }


    const handleChangeLocation = (value) => {
        let val = value.label.split(" ");
        setLocationCode(val[0]);
        setLocation(value);
        console.log("location", value);
        setBank(null)
        setfromWhomSelectedVal("")
    };

    const handleBankValue = (value) => {
        if (!location) {
            alert("please selecet location");
        }
        console.log("bank", value);
        setBank(value);
    };

    const handleFromWhom = (value) => {
        if (!location) {
            alert("please selecet location");
        }
        console.log("handleFromWhom", value);
        setfromWhomSelectedVal(value);
    };

    function createNewFundRequestHandler() {
        var msg = [], i = 0;
        if (amount === "" || refno === "" || bank === null || location === null || fromWhom === "" || fromWhomSelectedVal === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (amount === "" ? " Amount." : '');
            msg[i++] = (refno === "" ? " Ref no." : '');
            msg[i++] = (bank === null ? " Bank " : '');
            msg[i++] = (location === null ? " Location " : '');
            msg[i++] = (fromWhom === "" ? " Party " : '');
            if (fromWhom !== '' && fromWhom === 'WALKIN') {
                msg[i++] = (fromWhomSelectedVal === "" ? " Party Name " : '');
            }
            if (fromWhom !== '' && fromWhom !== 'WALKIN') {
                msg[i++] = (fromWhomSelectedVal === "" ? " Party " : '');
            }
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
            return;
        }
        $(".loader").show();
        const bankArr = bank.id.split("^");
        const locationId = location.id.split("^")[1];
        console.log(bank, location);
        var acct = 4516;
        var grp = 1001;
        if (fromWhom != "WALKIN" && fromWhomSelectedVal !== null) {
            const val = fromWhomSelectedVal.id.split("^");
            acct = val[2];
            grp = val[1];
        }
        const obj = {
            tfa_location: locationId,
            tfa_bank_group: bankArr[1], // group at the index 1
            tfa_bank_acc: bankArr[2], // acc at the index 2
            tfa_amount: amount,
            refno: refno,
            tfa_date: Common.dateDMY(ddate),
            from_whom: fromWhom,
            srno: srno,
            status: status,
            approve_user: userSrno,
            walkin_name: fromWhom === "WALKIN" ? fromWhomSelectedVal : "",
            tfa_account: acct,
            tfa_group: grp
        };
        Common.callApi(
            Common.apifundRequest, [sid, "createFundRequest", JSON.stringify(obj)], function (result) {
                $(".loader").hide();
                console.log(result);
                applyFilter();
                makeAllValueEmpty();
            }
        );
    }

    //make all variable empty
    function makeAllValueEmpty() {
        setBank(null);
        setAmount('');
        setLocation(null)
        setLocationCode('')
        setRefno('')
        setDate(new Date())
        setFromWhom('')
        setfromWhomSelectedVal('')
        setSelectedFundRequest('')
        setSrno(0);
    }

    const cancelConfirm = () => {
        if (srcMode === "A" || srcMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            backButtonHandler();
        }
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                {/* Heading */}
                <Row>
                    <Col>
                        <h3>Fund Request</h3>
                    </Col>
                </Row>

                {srcMode === '' ? <>

                    {/* Apply Filter */}
                    <Row>
                        {/* status dropdown  */}
                        <Col className="col-md-3 col-6">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                value={filterStatus}
                                onChange={(e) => {
                                    setFilterStatus(e.target.value);
                                }}
                            >
                                <option value="">ALL</option>
                                <option value="P">PENDING</option>
                                <option value="R">REJECTED</option>
                                <option value="A">APPROVED</option>
                                <option value="C">CANCELLED</option>
                            </Form.Select>
                        </Col>
                        {/* from date  */}
                        {filterStatus !== "P" && <>
                            <Col>
                                <Form.Label>From Date</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selected={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dropdownMode="select"
                                    peekNextMonth
                                    customInput={
                                        <input
                                            type="text"
                                            size="sm"
                                            onKeyUp={(e) =>
                                                Common.buildDateFormat(e.target.value, e.target)
                                            }
                                        ></input>
                                    }
                                />
                            </Col>
                            <Col>
                                <Form.Label>To Date</Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selected={todate}
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dropdownMode="select"
                                    peekNextMonth
                                    customInput={
                                        <input
                                            type="text"
                                            size="sm"
                                            onKeyUp={(e) =>
                                                Common.buildDateFormat(e.target.value, e.target)
                                            }
                                        ></input>
                                    }
                                />
                            </Col>
                        </>
                        }
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col className="col-md-3 col-6">
                            <Form.Label>Bank</Form.Label>
                            <Form.Select
                                value={bankFilter}
                                onChange={(e) => {
                                    setBankFilter(e.target.value);
                                }}
                            >
                                <option value="">ALL</option>
                                {bankFilterList.map((bank, index) => (<option key={index} value={bank.mvli_ledger_name}>{bank.mvli_ledger_name.toUpperCase()}</option>))}
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Search
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    maxLength={15}
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col className="col-md-3 col-6">
                            <Form.Label>Limit</Form.Label>
                            <Form.Select
                                value={limit}
                                onChange={(e) => {
                                    setLimit(e.target.value);
                                }}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="all">All</option>
                            </Form.Select>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    {/* add new and LIST button  */}
                    <Row>
                        <Col >
                            <Button style={{ marginRight: '10px' }}
                                variant="outline-primary"
                                className="buttonStyle"
                                size="sm"
                                onClick={applyFilter}
                            >
                                List
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="buttonStyle"
                                size="sm"
                                onClick={addNew}
                            >
                                Add New
                            </Button>

                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    {/* LIST  */}
                    <Row>
                        <Col>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {/* <th>Bank Name</th> */}
                                        <th>Sr No</th>
                                        <th>Status</th>
                                        <th>Ref No</th>
                                        <th>Party</th>
                                        {/* <th>Party Name</th> */}
                                        <th>Amount</th>
                                        <th>Requester Name</th>
                                        <th>Created Date</th>
                                        <th>Approver Name</th>
                                        <th>Approve Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList.map((res, index) => (
                                        <tr key={index}>
                                            <td className="textCenter">
                                                <span
                                                    className="handCursor colorThemeBlue"
                                                    onClick={() =>
                                                        viewRequest(res.tfa_srno, res.tfa_from_whom)

                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </span>
                                            </td>
                                            <td>{res.tfa_srno}</td>
                                            <td>
                                                {res.tfa_status.toUpperCase() === "P" && (<>
                                                    <div >
                                                        <FontAwesomeIcon style={{ color: 'green', paddingRight: '10px', paddingLeft: '10px', cursor: 'pointer' }}
                                                            onClick={() =>
                                                                showConfirmPopUp(res, 'A')}
                                                            icon={faCircleCheck} />
                                                        <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }}
                                                            onClick={() =>
                                                                showConfirmPopUp(res, 'R')}
                                                            icon={faCircleXmark} />
                                                    </div>
                                                </>)}
                                                {res.tfa_status.toUpperCase() === "A" && "APPROVED"}
                                                {res.tfa_status.toUpperCase() === "R" && "REJECTED"}
                                                {res.tfa_status.toUpperCase() === "C" && "CANCELLED"}
                                            </td>
                                            <td>{res.tfa_refno}</td>
                                            <td>{res.tfa_from_whom + " : "} {res.tfa_walkin_name === "" ? res.party : res.tfa_walkin_name}</td>
                                            {/* <td>{res.tfa_walkin_name === "" ? res.party : res.tfa_walkin_name}</td> */}
                                            <td>{res.tfa_amount}</td>
                                            <td>{res.tfa_requester + " " + res.requester_name}</td>
                                            <td>{res.requesteddate} {res.requestedtime}</td>
                                            <td>{(res.tfa_approve_user !== null ? res.tfa_approve_user : '') + " " + (res.approver_name !== null ? res.approver_name : '')}</td>
                                            <td>{res.approvedate !== null ? res.approvedate : ''} {res.approvetime !== null ? res.approvetime : ''}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>&nbsp;</Row>
                </> : <>
                    {/* EDIT BUTTON  */}
                    {srcMode !== '' && srcMode !== 'A' ?
                        <Row>
                            <Col className="col-md-3 col-6">
                                <span>Requester Name : <b>{selectedFundRequest?.requester_name} </b></span>
                            </Col>
                            {selectedFundRequest?.tfa_status !== 'P' && <><Col className="col-md-3 col-6">
                                <span> Approver Name : <b>{selectedFundRequest?.approver_name} </b></span>
                            </Col >
                                <Col className="col-md-3 col-6">
                                    <span> Approved Date : <b>{(selectedFundRequest?.approvedate !== null && selectedFundRequest?.approvedate) + " " + (selectedFundRequest?.approvetime !== null && selectedFundRequest?.approvetime)} </b></span>
                                </Col></>}
                        </Row> :
                        null
                    }
                    {/* <Row>&nbsp;</Row> */}
                    <Row>
                        {srcMode === 'Q' ? (
                            <>
                                <Col>
                                    {selectedFundRequest?.tfa_status === 'P' &&
                                        <Button style={{ marginRight: '5px' }}
                                            variant="outline-primary"
                                            className="buttonStyle"
                                            size="sm"
                                            onClick={() => setSrcMode('E')}
                                        >
                                            Edit
                                        </Button>}
                                    {selectedFundRequest?.tfa_status === 'A' &&
                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>}
                                </Col>

                            </>
                        ) : <Col>&nbsp;</Col>}
                        <Col></Col>
                        <Col></Col>
                    </Row>
                    {/* <Row>&nbsp;</Row> */}
                    <Row>
                        <Col className='col-md-3 col-6'>
                            <Form.Label>Sr No : {srno}</Form.Label>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Amount<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Amount"
                                    maxLength={15}
                                    value={amount}
                                    onChange={(e) =>
                                        amountHandler(e)
                                    }
                                    disabled={srcMode === 'Q' || srcMode === 'E'}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Ref No<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ref No"
                                    maxLength={15}
                                    value={refno}
                                    onChange={(e) =>
                                        Common.validateAlpNumWSValue(e.target.value, setRefno)
                                    }
                                    disabled={srcMode === 'Q' || srcMode === 'E'}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="col-md-3 col-6">
                            <Form.Group>
                                <Form.Label>
                                    Location<span className="text-danger">*</span>
                                </Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={location}
                                    getOptionLabel={(e) => e.label + " "}
                                    getOptionValue={(e) => e.id}
                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleChangeLocation}
                                    isDisabled={srcMode === 'Q' || srcMode === 'E'}
                                ></AsyncSelect>
                            </Form.Group>
                        </Col>
                        <Col className="col-md-3 col-6">
                            <Form.Group>
                                <Form.Label>
                                    Bank<span className="text-danger">*</span>
                                </Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={bank}
                                    getOptionLabel={(e) => e.label + " "}
                                    getOptionValue={(e) => e.id}
                                    loadOptions={(search) =>
                                        Picklist.fetchLedgerPicklistByType(
                                            search,
                                            "BANK",
                                            locationCode
                                        )
                                    }
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleBankValue}
                                    isDisabled={srcMode === 'Q' || srcMode === 'E'}
                                ></AsyncSelect>
                            </Form.Group>
                        </Col>
                    </Row>
                    &nbsp;
                    <Row>
                        <Col className="col-md-3 col-6">
                            <Form.Group>
                                <Form.Label>{srcMode === "A" ? "Date " : "Requested Date"}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <DatePicker
                                    className="form-control"
                                    selected={ddate}
                                    onChange={(date) => setDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dropdownMode="select"
                                    peekNextMonth
                                    disabled={srcMode === 'Q' || srcMode === 'E'}
                                    customInput={
                                        <input
                                            type="text"
                                            size="sm"
                                            onKeyUp={(e) =>
                                                Common.buildDateFormat(e.target.value, e.target)
                                            }
                                        ></input>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col className="col-md-3 col-6">
                            <Form.Label>Party</Form.Label>
                            <Form.Select
                                value={fromWhom}
                                onChange={(e) => setFromWhom(e.target.value)}
                                disabled={srcMode === 'Q' || srcMode === 'E'}
                            >
                                <option value="0">Select</option>
                                <option value="WALKIN">Walk in</option>
                                <option value="CORPORATE">Corporate</option>
                                <option value="DEALER">Dealer</option>
                            </Form.Select>
                        </Col>
                        <Col>
                            {
                                fromWhom === "WALKIN" ? (
                                    <>
                                        <Form.Label>
                                            Walk In Name<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Walk in"
                                                maxLength={15}
                                                value={typeof fromWhomSelectedVal === 'object' ? "" : fromWhomSelectedVal}
                                                onChange={(e) => setfromWhomSelectedVal(e.target.value)}
                                                disabled={srcMode === 'Q' || srcMode === 'E'}
                                            />
                                        </Form.Group>
                                    </>
                                ) : null
                            }
                            {
                                fromWhom === "CORPORATE" || fromWhom === "DEALER" ? (
                                    <>
                                        <Form.Label>
                                            Party Name<span className="text-danger">*</span>
                                        </Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={fromWhomSelectedVal}
                                            getOptionLabel={(e) => e.label + " "}
                                            getOptionValue={(e) => e.id}
                                            loadOptions={(search) =>
                                                Picklist.fetchLedgerPicklistByType(
                                                    search,
                                                    fromWhom,
                                                    locationCode
                                                )
                                            }
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleFromWhom}
                                            isDisabled={srcMode === 'Q' || srcMode === 'E'}
                                        ></AsyncSelect>
                                    </>
                                ) : null
                            }
                        </Col>
                        {
                            (srcMode === "Q" || srcMode === 'E') &&
                            <Col className="col-md-3 col-6">
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    disabled={srcMode === "Q" || selectedFundRequest?.tfa_status === 'C' || selectedFundRequest?.tfa_status !== 'P'}
                                >
                                    {
                                        userRight.edit === '0' ? (selectedFundRequest?.tfa_status !== 'P' ?
                                            <>
                                                {status === 'A' && <option>APPROVE</option>}
                                                {status === 'R' && <option>REJECTED</option>}
                                                {status === 'C' && <option>CANCEL</option>}
                                            </> :
                                            <>
                                                <option value="C">CANCEL</option>
                                                <option value="P">PENDING</option>
                                            </>) :
                                            <>
                                                {status === "C" && <option value="C">CANCEL</option>}
                                                <option value="P">PENDING</option>
                                                <option value="A">APPROVE</option>
                                                <option value="R">REJECTED</option>
                                            </>
                                    }
                                    {/* {
                                        userRight.edit === '1' ? <>
                                            <option value="P">PENDING</option>
                                            <option value="A">APPROVE</option>
                                            <option value="R">REJECTED</option></> :
                                            (status === 'P' && <><option value="C">CANCEL</option>
                                                <option value="C">PENDING</option></>)
                                    } */}
                                </Form.Select>
                            </Col>
                        }
                    </Row>
                    &nbsp;
                    {/* requester button  */}
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                className="buttonStyle"
                                size="sm"
                                onClick={createNewFundRequestHandler}
                                disabled={srcMode === 'Q'}
                            >
                                Save
                            </Button>
                            &nbsp;
                            <Button
                                variant="outline-primary"
                                className="buttonStyle"
                                size="sm"
                                onClick={cancelConfirm}
                            >
                                Back
                            </Button>
                            &nbsp;
                        </Col>
                    </Row>

                </>}


            </Container>

            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => backButtonHandler()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <DialogYesNo isOpen={isMsgBoxAR} onYes={(e) => updateStatus()} onNo={(e) => setIsMsgBoxAR(false)}>
                    {msgTextAR}
                </DialogYesNo>
            </div>
        </>
    );
}

export default FundRequest;
