import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import "../../../mcss/main.css";
import * as Common from "../../Common";
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from "../../Picklist";
function Modification_transaction() {


    const navigate = useNavigate();
    const sid = sessionStorage.getItem("sessionId");
    const userSrno = sessionStorage.getItem("userSrno");
    const [scrMode, setScrMode] = useState('');
    const [filtertransaction, setFiltertransaction] = useState('A');
    const [onceRun, setOnceRun] = useState(false);
    const [filterTabType, setFilterTabType] = useState('');
    const [tranDataList, setTranDataList] = useState([]);
    const [pProduct, setPProduct] = useState("CN");
    const [pProductList, setPProductList] = useState([]);
    const [pVtm, setPVtm] = useState(null);
    const [pIsdId, setPIsdId] = useState("");
    const [pIsd, setPIsd] = useState(null);
    const [pIssuer, setPIssuer] = useState(null);
    const [pDenomination, setPDenomination] = useState("");
    const [pQuantity, setPQuantity] = useState("");
    const [pRate, setPRate] = useState("");
    const [prPrefix, setPrPrefix] = useState("");
    const [prFrom, setPrFrom] = useState("");
    const [prTo, setPrTo] = useState("");
    const [pVtmCardNo, setPVtmCardNo] = useState("");
    const [isCommission, setIsCommission] = useState("0");
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [pAgent, setPAgent] = useState(null);
    const [ppLocationValue, setPPLocationValue] = useState(0);
    const [usdRate, setUSDRate] = useState('');
    const [randomString, setRandomString] = useState("");
    const [tranAuditTrail, setTranAuditTrail] = useState([]);

    const productRef = useRef();
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [radioType, setRadioType] = useState("N");

    const [ppNewDate, setPPNewDate] = useState(new Date());
    const [ppSrnoFrom, setPPSrnoFrom] = useState("");
    const [ppSrnoTo, setPPSrnoTo] = useState("");
    const [ppFromDate, setPPFromDate] = useState(new Date());
    const [ppToDate, setPPToDate] = useState(new Date());

    const [ppList, setPPList] = useState([]);

    const [ppLocation, setPPLocation] = useState(null);
    const [ppCorporate, setPPCorporate] = useState(null);
    const [ppIndividual, setPPIndividual] = useState(null);
    const [ppStateType, setPPStateType] = useState(0);
    const [ppStateValue, setPPStateValue] = useState(0);
    const [ppStateName, setPPStateName] = useState("");
    const [userRight, setUserRight] = useState([]);

    const [showIndividualForm, setShowIndividualForm] = useState(false);

    const [isdOptions, setIsdOptions] = useState([]);
    const [residentOptions, setResidentOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [hdrSrno, setHdrSrno] = useState(0);
    const [nIndividual, setNIndividual] = useState(null);
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [nCountry, setNCountry] = useState(null);
    const [nServiceType, setNServiceType] = useState("");
    const [nEmployeeCode, setNEmployeeCode] = useState("");
    const [nResident, setNResident] = useState("1");
    const [nCorporate, setNCorporate] = useState(null);
    const [nDocument, setNDocument] = useState("");
    const [nDealer, setNDealer] = useState(null);
    const [nManualNo, setNManualno] = useState(null);
    const [nExecutive, setNExecutive] = useState(null);
    const [nRemark1, setNRemark1] = useState("");
    const [nRemark2, setNRemark2] = useState("");
    const [nArrivalDate, setNArrivalDate] = useState(null);
    const [nCDFNum, setNCDFNum] = useState("");
    const [nLrsReverse, setNLrsReverse] = useState(1);
    const [nMode, setNMode] = useState("B");


    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);
    const [disableOtherCharge, setDisableOtherCharge] = useState(true);

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [key, setKey] = useState("INFO");
    const [pdAccountOptions, setPdAccountOptions] = useState([]);
    const [pdAccountValue, setPdAccountValue] = useState("");
    const [pdGroupValue, setPdGroupValue] = useState(0);
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentData, setPaymentData] = useState([]);
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [commission, setCommission] = useState("");
    const [maxPRate, setMaxPRate] = useState(0);
    const [minPRate, setMinPRate] = useState(0);


    const [counter, setCounter] = useState(0);
    const [productLineNo, setProductLineNo] = useState(0);
    const [paymentLineNo, setPaymentLineNo] = useState(0);

    const [printFlag, setPrintFlag] = useState('');
    const [chequeValue, setChequeValue] = useState(null);
    const [chequeNum, setChequeNum] = useState(0);
    const [chequeLocation, setChequeLocation] = useState(0);
    const [chequeLineNo, setChequeLineNo] = useState(0);
    const [chequeSrno, setChequeSrno] = useState(0);

    const [nIndivualSrno, setNIndivualSrno] = useState("");
    const [scrIndividual, setScrIndividual] = useState("");

    const [hdrDate, setHdrDate] = useState(null);
    const [hdrLocation, setHdrLocation] = useState("");

    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });


    const [ppManualNo, setPPManualNo] = useState('');
    const [arrivalSelected, setArrivalSelected] = useState(0);
    const [individualSelected, setIndividualSelected] = useState(0);
    const [showPreviousTransactionModal, setShowPreviousTransactionModal] = useState(false);
    const [showMinMaxRateModal, setShowMinMaxRateModal] = useState(false);

    const [preTransaction, setPreTransaction] = useState([]);
    const [minMaxRate, setMinMaxRate] = useState([]);
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });

    const [disablePaymentAccount, setDisablePaymentAccount] = useState(true);
    const [filterProduct, setFilterProduct] = useState("C");
    const [showCommissionDetail, setShowCommissionDetail] = useState(false);


    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setPPLocation(response);
            response != null && handleLocationChange(response);
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PP'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", pProduct], result => {
            setIsdOptions(JSON.parse(result));
        });
        Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
            setResidentOptions(JSON.parse(result));
        });
        Common.callApi(Common.apiState, [sid, "getState", 1], result => {
            setStateOptions(JSON.parse(result));
        });


        const sessionValues = sessionStorage.getItem('values');
        if (sessionValues) {
            const sessionData = JSON.parse(sessionValues);
            if (sessionData.md_type == "PURCHASE") {
                setScrMode('A');
                setNServiceType('R');
                Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
                    let resp = JSON.parse(result);
                    console.log("RESPONSE IS  ", resp);
                    // const desc = resp.desc.split("^");
                    handleNewIndiviualChange({ ...resp, desc: `${resp.desc.replaceAll('^', '  ^  ')}` })
                    // setNIndividual(resp);
                });
                sessionStorage.removeItem('values');
            } else {
                setScrMode('');
            }
        }
    }, []);
    const handleNewIndiviualChange = (value) => {
        setNIndividual(value);
        var id = value.id.split("^");
        setNIndivualSrno(id[1]);
        setIndividualSelected(1);
        setNCDFNum("");
    }
    const handleLocationChange = (value) => {
        setPPLocation(value);
        var id = value.id;
        var location = id.split("^");
        setPPLocationValue(location[1]);
        setPPStateValue(location[3] == null ? 0 : location[3]);
        setPPStateType(location[4]);
        setPPStateName(location[5] === "" ? "Select" : location[5]);
        setHdrLocation(location[2]);
        // console.log("1", location[1], "2", location[3], "3", location[4], "4", location[5], "5", location[2])
        sessionStorage.activeBranch = location[1];
    }
    const applyFilter = () => {
        var obj = {
            transactionType: filtertransaction,
            tabtype: filterTabType

        }
        Common.callApi(Common.apiTranModification, [sid, "trandetaillist", JSON.stringify(obj)], function (result) {
            let resp = JSON.parse(result);
            console.log(resp.trandetailproduct);
            setTranDataList(resp.trandetailproduct);
        });

    }

    const handleTranView = (srno, location, prdtype) => {
        setScrMode('Q');
        setNArrivalDate("");
        setHdrSrno(srno);
        setKey('INFO');
        setRandomString("0");
        setPVtm(null);
        var obj = {
            srno: srno,
            location: location,
            productype: prdtype
        }
        Common.callApi(Common.apiTranModification, [sid, "trandetailview", JSON.stringify(obj)], function (result) {
            let resp = JSON.parse(result);
            console.log(resp);
            setPProductList(resp.trandetailproduct);
        })
        console.log(obj)

    }
    const handleVtmValue = (value) => {
        setPVtm(value);
        var v = value.id.split("^");
        const isdVal = { id: `^${v[2]}^${v[7]}`, label: `${v[2]} ${v[7]}` }
        setPIsd(isdVal);
        setPIssuer({ id: `^${v[3]}^${v[4]}`, label: `${v[3]} ${v[4]}` });
        setPVtmCardNo(v[5]);
        handleFromChange(v[6]);
        setPDenomination(v[8]);
        handleChangeIsd(isdVal);
        // handleMinMaxRate(isdVal);
    }
    const handleFromChange = (v) => {
        Common.validateNumValue(v, setPrFrom);
        setPrTo((v * 1 + pQuantity * 1) - 1);
        if (pProduct === "EVTM") {
            setPrTo(v);
        }
    }

    const handleChangeIsd = (value) => {
        setPIsd(value);
        let a = value.id.split("^");
        setPIsdId(a[1]);
        setPAgent(null);
        setCommType("");
        setCommValue("");
        const obj = {
            isd: a[1],
            location: ppLocationValue,
            product: pProduct
        }
        Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPRate(resp.buyrate * 1);
            setMaxPRate(resp.maxbuyrate * 1);
            setMinPRate(resp.minbuyrate * 1);
            setUSDRate(resp.usdratebuy * 1);
        });
    }
    const handleDenoChange = (v) => {
        Common.validateDecValue(v, setPDenomination);
        setCommValue(calcCommission(commission, commType, pQuantity, v, pRate));
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        if (pProduct === "")
            setPrTo((v * 1 + prFrom * 1) - 1);
        setCommValue(calcCommission(commission, commType, v, pDenomination, pRate));
    }
    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setCommValue(calcCommission(commission, commType, pQuantity, pDenomination, v));
    }

    const addProduct = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (((pProduct === "CN" || pProduct === "ETC") && pIsd == null || pDenomination === "" || pQuantity === "" || pRate === "")
            ||
            ((pProduct === "ETC" || pProduct === "EVTM") && (prFrom == "" || prTo == "" || pIssuer == null))
            ||
            (isCommission === "1" && (commType === "" || pAgent === null || commValue === ""))
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd == null ? "Isd." : '');
            msg[i++] = (pDenomination === '' ? "Denomination." : '');
            msg[i++] = (pQuantity == '' ? "Quantity." : '');
            msg[i++] = (pRate == '' ? "Rate." : '');
            if ((pProduct === "ETC" || pProduct === "EVTM") && (prFrom == "" || prTo == "" || pIssuer == null)) {
                msg[i++] = (pIssuer == null ? "Issuer." : '');
                msg[i++] = (prFrom == '' ? "From." : '');
                msg[i++] = (prTo == '' ? "To." : '');
            }
            if (isCommission === "1" && (commType === "" || pAgent === null || commValue === "")) {
                msg[i++] = (pAgent == null ? "Agent." : '');
                msg[i++] = (commType == '' ? "Commission type." : '');
                msg[i++] = (commValue == '' ? "Commission value." : '');
            }
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if ((pProduct === "ETC" || pProduct === "EVTM") && (prTo * 1 - prFrom * 1 > 100)) {
            $(".loader").hide();
            setMsgText("'To' must be less than 100.");
            setMsgBox(true);
        } else if (maxPRate != 0 && minPRate != 0 && (pRate > maxPRate || pRate < minPRate)) {
            $(".loader").hide();
            msg[i++] = (pRate > maxPRate ? "Maximum rate is " + maxPRate : '');
            msg[i++] = (pRate < minPRate ? "Minimum rate is " + minPRate : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pRate < 1) {
            $(".loader").hide();
            setMsgText(Common.buildMessageFromArray(["Rate should be greater than 0"]));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var isdarr = pIsd.id.split("^");
            var agentgrp = 0;
            var agentaccnt = 0;
            var agentname = "";
            var issgrp = 0;
            var issaccnt = 0;
            if (pAgent !== null) {
                var agent = pAgent.id.split("^");
                agentgrp = agent[1];
                agentaccnt = agent[2];
                agentname = agent[5];
            }
            if (pIssuer !== null) {
                var issuer = pIssuer.id.split("^");
                issgrp = issuer[1];
                issaccnt = issuer[2];
            }
            var usdAmt = 0;
            if (usdRate != "" && usdRate != 0) {
                usdAmt = (pRate * pQuantity * pDenomination) / usdRate;
            }
            const obj = {
                tranType: "PP",
                type: "PURCHASE",
                uniquekey: Common.getRandomString(15),
                // prd_line_no: productLineNo + 1,
                prd_location: ppLocationValue,
                prd_type: 'PP',
                prd_srno: hdrSrno,
                prd_isd: isdarr[1],
                prd_date: Common.dateYMD(ppNewDate),
                prd_product: pProduct,
                prd_deno: pDenomination,
                prd_qty: pQuantity,
                prd_rate: pRate,
                prd_amount: pRate * pQuantity * pDenomination,
                prd_from: prFrom,
                prd_prefix: prPrefix,
                prd_to: prTo,
                prd_group: issgrp,
                prd_account: issaccnt,
                prd_agent_group: agentgrp,
                prd_agent_ledger: agentaccnt,
                prd_agent_name: agentname,
                prd_card_no: pVtmCardNo,
                isd_name: isdarr[2],
                randomString: randomString,
                prd_commission_type: commType,
                prd_commission_value: commValue == "" ? 0 : commValue,
                prd_commission: commission == "" ? 0 : commission,
                nCorporate: nCorporate,
                nState: nState.value,
                pOtherCharges: pOtherCharges,
                ppStateType: ppStateType,
                ppStateValue: ppStateValue,
                scrMode: scrMode,
                pVtm: pVtm,
                prd_usd_rate: usdRate == "" ? 0 : usdRate.toFixed(2),
                prd_usd_amount: usdAmt.toFixed(2),
                isCommission: isCommission,
                delny: "Y"
            }
            var productlist = Common.arrayAddItem(pProductList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                // TLine: productLineNo + 1,
                TLoc: ppLocationValue,
                TType: 'PP',
                // TNum?
                Product: pProduct,
                ISD: isdarr[2],
                Deno: pDenomination,
                Quantity: pQuantity,
                Rate: pRate,
                Amount: pRate * pQuantity * pDenomination,
                // ...(pProduct === "ETC" ? {
                ProductFrom: prFrom,
                ProductTo: prTo,
                ProductPrefix: prPrefix,
                ProductGroup: issgrp,
                ProductAccout: issaccnt,
                // } : {}),
                CommsnType: commType,
                AgentGroupCode: agentgrp,
                AgentLedgerCode: agentaccnt,
                CommsnRate: commValue == "" ? 0 : commValue,
                CommsnAmount: commission == "" ? 0 : commission,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }

            setPProductList(productlist);
            // setCounter(counter + 1);
            // setProductLineNo(1 * productLineNo + 1);
            setPIsd(null);
            setPIssuer(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setPrPrefix("");
            setPrFrom("");
            setPrTo("");
            setPVtmCardNo("");
            setCommValue("");
            setCommission("");
            setPAgent(null);
            setCommType("");
            calcInvoice(productlist, obj);
        }
    }
    const btnEditRequest = () => {
        setScrMode("E");
    }

    const calcInvoice = (productlist, obj) => {
        Common.callApi(Common.apiCalcTaxable, [sid, "calcinvoice", JSON.stringify(productlist), JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPForexTotal(resp.fxamt);
            setPSgst(resp.sgst);
            setPTotalAmount(resp.totalamt);
            setPTaxableVal(resp.taxablevalue);
            setPRoundOff(resp.roundoff);
            setPCgst(resp.cgst);
            setPIgst(resp.igst);
            setPUgst(resp.ugst);
            var amt = 0;
            for (var i = 0; i < paymentData.length; i++) {
                amt += paymentData[i].tp_amount * 1;
            }
            setPdAmount(1 * resp.totalamt - amt);
        });
    }
    const handleAgentChange = (value) => {
        setPAgent(value);
        Common.callApi(Common.apiLedger, [sid, "getisdcommission", value.id, pIsdId, pProduct], result => {
            let resp = JSON.parse(result);
            setCommType(resp.type);
            setCommission(resp.value);
            var commvalue;
            if (resp.type === "P" || resp.type === "R") {
                commvalue = calcCommission(resp.value, resp.type, pQuantity, pDenomination, pRate);
            } else {
                commvalue = resp.value;
            }
            setCommValue(commvalue);
        });
    }

    const handleProductChange = (v) => {
        setPProduct(v);
        setPIsd(null);
        setPVtm(null);
        setPDenomination("");
        setPRate("");
        setPrFrom("");
        setPVtmCardNo("");
        setPIssuer(null);
        if (v === "EVTM") setPQuantity("1");
        else setPQuantity("");
        Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", v], result => {
            setIsdOptions(JSON.parse(result));
        });
    }


    const commissionTypeChange = (v) => {
        setCommType(v);
        setCommission("");
        setCommValue("");
    }
    const handleCommissionCalc = (v) => {
        Common.validateDecValue(v, setCommission);
        setCommValue(calcCommission(v * 1, commType, pQuantity, pDenomination, pRate));
    }

    const deleteProduct = (value) => {
        const itemToDelete = pProductList.find(item => item.uniquekey === value);

        if (itemToDelete) {
            // console.log("inme se dekh ", itemToDelete);
            // Custom keys for the deleted item
            const formattedItem = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                // TLine: productLineNo + 1,// item to 
                TLoc: ppLocationValue,
                TType: 'PP',
                Product: itemToDelete.prd_product,
                ISD: itemToDelete.isd_name,
                Deno: itemToDelete.prd_deno,
                Quantity: itemToDelete.prd_qty,
                Rate: itemToDelete.prd_rate,
                Amount: itemToDelete.prd_amount,
                //ETC EXTRA
                // ...(itemToDelete.prd_product === "ETC" ? {
                ProductFrom: itemToDelete.prd_from,
                ProductTo: itemToDelete.prd_to,
                ProductPrefix: itemToDelete.prd_prefix,
                ProductGroup: itemToDelete.prd_group, //prd_group
                ProductAccout: itemToDelete.prd_account,//prd_account
                // } : {}),
                // commision 
                AgentGroupCode: itemToDelete.prd_agent_group,
                AgentLedgerCode: itemToDelete.prd_agent_ledger,
                CommsnType: itemToDelete.prd_commission_type,
                CommsnRate: itemToDelete.prd_commission_value,
                CommsnAmnt: itemToDelete.prd_commission,
                // to: itemToDelete.prd_to,
                // uniqueKey: itemToDelete.uniquekey // Or keep some keys the same
            };
            // if(itemToDelete.prd_product == "ETC"){
            //     formattedItem.ProductFrom = itemToDelete.prd_from;
            //     formattedItem.ProductTo = itemToDelete.prd_to;
            //     formattedItem.ProductPrefix = itemToDelete.prd_prefix;
            //     formattedItem.ProductFrom = itemToDelete.prd_from;
            //     formattedItem.ProductFrom = itemToDelete.prd_from;
            //     formattedItem.ProductFrom = itemToDelete.prd_from;
            // }
            console.log(formattedItem);
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }

        }
        const obj = {
            tranType: "PP",
            type: "PURCHASE",
            uniquekey: Common.getRandomString(15),
            nState: nState.value,
            pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
            ppStateType: ppStateType,
            ppStateValue: ppStateValue
        }
        const productlist = Common.arrayRemoveItem(pProductList, "uniquekey", value);
        setPProductList(productlist);
        calcInvoice(productlist, obj);
        setCounter(counter - 1);
    }

    const cancelConfirm = () => {
        setScrMode("");
    }
    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = 0;
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        // return comm.toFixed(2);
        return comm;
    }

    return (

        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Transaction Modification Commission </h3>
                    </Col>
                </Row>
                {scrMode === '' ?
                    <Row>
                        <Col className="col-md-3 col-6">
                            <Form.Label>Transaction</Form.Label>
                            <Form.Select
                                value={filtertransaction}
                                onChange={(e) => {
                                    setFiltertransaction(e.target.value);
                                }}
                            >
                                <option value="">ALL</option>
                                <option value="PP">PP</option>
                                <option value="PB">PB</option>
                                <option value="SP">SP</option>
                                <option value="SB">SB</option>
                            </Form.Select>
                        </Col>
                        <Col className="col-md-3 col-6">
                            <Form.Label>TabType</Form.Label>
                            <Form.Select
                                value={filterTabType}
                                onChange={(e) => {
                                    setFilterTabType(e.target.value);
                                }} >
                                <option value="PR">Product</option>
                                <option value="PY">Payment</option>
                            </Form.Select>
                        </Col>
                        <Row>&nbsp;</Row>

                        <Row>
                            <Col >
                                <Button style={{ marginRight: '10px' }} variant="outline-primary" className="buttonStyle" size="sm" onClick={applyFilter}> List</Button>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            {/* <th></th> */}
                                            <th>Sr No</th>
                                            <th>Individual Name</th>
                                            <th>ISD</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tranDataList.map((res, index) => (
                                            <tr key={index}>
                                                {/* <td>{res.tranDataList !== null ? res.approvedate : ''} {res.approvetime !== null ? res.approvetime : ''}</td> */}
                                                <td>{res.prd_srno}</td>
                                                <td>{res.bd_first_name}</td>
                                                <td>{res.prd_isd}</td>
                                                <td>{res.prd_product}</td>
                                                <td>{res.prd_amount}</td>
                                                <td>
                                                    <span onClick={() => handleTranView(res.prd_srno, res.prd_location, res.prd_type)} className='pointer colorThemeBlue'>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </Row> : null}
                {(scrMode === "Q" || scrMode === "E") ? <>
                    <Row>
                        <Col>
                            <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                            &nbsp;
                            {
                                (userRight.edit === "1" && scrMode === 'Q') ? <>
                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                        btnEditRequest()}>Edit</Button>&nbsp;
                                </> : null
                            }
                            {/* {
                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.query === "1" && scrMode === "Q") ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => print("D")}>With Deno</Button>&nbsp;
                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => print("")}>Without Deno</Button>&nbsp;
                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>&nbsp;
                                                        {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                btnActivityLog()}>Activity Log</Button>&nbsp;
                                                        </> : null
                                                        }
                                                    </> : null
                                                } */}



                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Product Info</h4>
                        </Col>
                    </Row>
                    {
                        (scrMode === "Q") ? null
                            :
                            <>
                                <Row>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                            <Form.Select value={pProduct} onChange={e => handleProductChange(e.target.value)} ref={productRef}>
                                                <option value="CN">CN</option>
                                                <option value="ETC">TC</option>
                                                <option value="EVTM">VTM</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {
                                        pProduct === "EVTM" ?  // filling automatically by vtm encashment
                                            <Col className='col-md-6 col- 6'>
                                                <Form.Group>
                                                    <Form.Label>Vtm's</Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={pVtm}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchVtmEncashmentPicklist(search, "APPROVED")} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleVtmValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            :
                                            <>
                                                < Col className="col-md-3 col-6">
                                                    <Form.Group>
                                                        <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={pIsd}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, pProduct)} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleChangeIsd}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    pProduct === "ETC" || pProduct === "EVTM" ?
                                                        <Col className="col-md-3 col-6">
                                                            <Form.Group>
                                                                <Form.Label>Issuer<span className="colorThemeRed">*</span></Form.Label>
                                                                <AsyncSelect
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={pIssuer}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', ppLocationValue)} // location = 1 , 2
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={value => setPIssuer(value)}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                        : null
                                                }
                                            </>
                                    }
                                </Row>
                                <Row>
                                    <Col display={{ display: pProduct === "EVTM" && "none" }} className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>{pProduct === "EVTM" ? "Value" : "Denomination"}
                                                <span className="colorThemeRed">*</span>
                                            </Form.Label>
                                            <Form.Control disabled={pProduct === "EVTM"} type='text' value={pDenomination} maxLength={5}
                                                onChange={e => handleDenoChange(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col style={{ display: pProduct === "EVTM" && "none" }} className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                            <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={9}
                                                onChange={e => handleQuantityChange(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>
                                            {/* <span style={{ display: scrMode === "Q" && "none" }} className="mx-2" onClick={handleMinMaxRate}><Badge bg="secondary">See Min/Max Rate</Badge></span> */}
                                            <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                onChange={e => handleRateChange(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    pProduct === "ETC" || pProduct === "EVTM" ?
                                        <Row>
                                            <Col className='col-md-3 col-6' style={{ display: pProduct === "EVTM" && "none" }}>
                                                <Form.Group>
                                                    <Form.Label>Prefix</Form.Label>
                                                    <Form.Control placeholder='Prefix' type='text' maxLength={5} value={prPrefix} onChange={e => Common.validateAlpNumValue(e.target.value, setPrPrefix)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                    <Form.Control disabled={pProduct === "EVTM"} placeholder='From' type='text' maxLength={6} value={prFrom} onChange={e => handleFromChange(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            {
                                                pProduct === "ETC" ?
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled placeholder='To' type='text' maxLength={6} value={prTo} onChange={e => Common.validateNumValue(e.target.value, setPrTo)} />
                                                        </Form.Group>
                                                    </Col> :
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Card No</Form.Label>
                                                            <Form.Control disabled placeholder='Card No' type='text' value={pVtmCardNo} onChange={e => setPVtmCardNo(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                            }
                                        </Row>
                                        : null
                                }
                                <Row>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Commission</Form.Label>
                                            <Form.Select value={isCommission} onChange={e => setIsCommission(e.target.value)}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {
                                        isCommission === "1" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Agent<span className="colorThemeRed">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={pAgent}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', ppLocationValue)} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleAgentChange}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="col-md-3 col-6">
                                                    <Form.Group>
                                                        <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Select value={commType} onChange={e => commissionTypeChange(e.target.value)}>
                                                            <option value="">Select</option>
                                                            <option value="P">Paisa</option>
                                                            <option value="R">Percentage</option>
                                                            <option value="F">Flat</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Commission<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={commission} onChange={e => handleCommissionCalc(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Commission Value</Form.Label>
                                                        <Form.Control disabled value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : null
                                    }
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col>
                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                        &nbsp;
                                        {/* <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button> */}
                                    </Col>
                                </Row>
                            </>
                    }
                    <Row>
                        {/* <Col className="col-md-3 col-6">
                                <Badge className='handCursor' bg="primary" onClick={handleShowCommissionDetail}>View Commission Details</Badge>
                            </Col> */}
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Table responsive bordered striped>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Product</th>
                                    <th>Isd Code</th>
                                    <th>Isd Name</th>
                                    <th>Denomination</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                    <th>USD Rate</th>
                                    <th>USD Amount</th>
                                    <th>Prefix</th>
                                    <th>From</th>
                                    <th>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pProductList.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {
                                                    scrMode === "Q" || item.delny === "N" ? null :
                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </span>
                                                }
                                            </td>
                                            <td>{item.prd_product}</td>
                                            <td>{item.prd_isd}</td>
                                            <td>{item.isd_name}</td>
                                            <td>{item.prd_deno}</td>
                                            <td>{item.prd_qty}</td>
                                            <td>{item.prd_rate}</td>
                                            <td>{item.prd_amount}</td>
                                            <td>{item.prd_usd_rate}</td>
                                            <td>{item.prd_usd_amount}</td>
                                            <td>{item.prd_prefix}</td>
                                            <td>{item.prd_from}</td>
                                            <td>{item.prd_to}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </> : null}

            </Container>

        </>
    )
}

export default Modification_transaction
