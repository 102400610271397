import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap';
import * as Common from '../Common';

function Tran_commission_view(props) {
    const sid = sessionStorage.getItem('sessionId');
    return (
        <div>
            <Modal {...props} size='lg' centered dialogClassName="custom-modal-width">
                <Modal.Header closeButton>
                    <Modal.Title>Commission Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Isd</th>
                                <th>Agent</th>
                                <th>Commission Type</th>
                                <th>Commission</th>
                                <th>Commission Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.productList.map(item => (
                                    <>
                                        {
                                            item.isCommission === "1" ?
                                                <tr key={item.prd_line_no}>
                                                    <td>{item.prd_isd}</td>
                                                    <td>{item.prd_agent_group + " " + item.prd_agent_ledger + " " + item.prd_agent_name}</td>
                                                    <td>{item.prd_commission_type === "P" ? "Paisa"
                                                        : item.prd_commission_type === "R" ? "Percentage"
                                                            : item.prd_commission_type === "F" ? "Flat" : ""}</td>
                                                    <td>{item.prd_commission}</td>
                                                    <td>{item.prd_commission_value}</td>
                                                </tr> : null
                                        }
                                    </>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Tran_commission_view;
