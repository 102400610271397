import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../../ActivityLog';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';
import Login_header from '../../Login_header';
import Master_individual_profile from '../../master/Master_individual_profile';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';
import Tran_data_unlock_request from '../../Tran_data_unlock_request';


const Inter_branch_entry = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [locationValue, setLocationValue] = useState(null);
    const [locationCode, setLocationCode] = useState('');
    const [location, setLocation] = useState('');
    const [toLocationValue, setToLocationValue] = useState(null);
    const [toLocationCode, setToLocationCode] = useState('');
    const [toLocation, setToLocation] = useState('');
    const [branchGroup, setBranchGroup] = useState('');
    const [branchAccount, setBranchAccount] = useState('');
    const [branchAccountName, setBranchAccountName] = useState('');
    const [noteType, setNoteType] = useState('D');
    const [radioType, setRadioType] = useState("N");
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [srnoFrom, setSrnoFrom] = useState("");
    const [srnoTo, setSrnoTo] = useState("");
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const [filterDate, setFilterDate] = useState(new Date());
    const currentDate = new Date();
    const [scrMode, setScrMode] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [ledgerValue, setLedgerValue] = useState('');
    const [grpCode, setGrpCode] = useState('0');
    const [accCode, setAccCode] = useState('0');
    const [accountType, setAccountType] = useState('');
    const [accountName, setAccountName] = useState('');
    const [amount, setAmount] = useState('');
    const [knockoffValue, setKnockoffValue] = useState(null);
    const [knockoffSrno, setKnockoffSrno] = useState('');
    const [knockoffType, setKnockoffType] = useState('');
    const [knockoffAmt, setKnockoffAmt] = useState('');
    const [knockoffPending, setKnockoffPending] = useState('');
    const [knockoffLineNo, setKnockoffLineNo] = useState('');
    const [knockoffFlag, setKnockoffFlag] = useState('');
    const [knockoffLedger, setKnockoffLedger] = useState('');
    const [knockoffData, setKnockoffData] = useState([]);
    const [flag, setFlag] = useState('D');
    const [limit, setLimit] = useState('10');
    const [srch, setSrch] = useState('');
    const [show, setShow] = useState(false);
    const [counter, setCounter] = useState('0');
    const [bookList, setBookList] = useState([]);
    const [showHeaderList, setShowHeaderList] = useState(false);
    const [headerList, setHeaderList] = useState([]);
    const [srNo, setSrNo] = useState("0");
    const [randomString, setRandomString] = useState("0");
    const [showList, setShowList] = useState(false);
    const [chequeNum, setChequeNum] = useState("");
    const [bankName, setBankName] = useState("");
    const [chequeDate, setChequeDate] = useState(new Date());
    const [totalDebit, setTotalDebit] = useState("0");
    const [totalCredit, setTotalCredit] = useState("0");
    const [totalDifference, setTotalDifference] = useState("0");
    const [isChecked, setChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [acRemark, setAcRemark] = useState("");
    const [code, setCode] = useState(0);
    const [addCount, setAddCount] = useState("0");
    const [narration, setNarration] = useState("");
    const [viewSrno, setViewSrno] = useState("");
    const [viewDate, setViewDate] = useState("");
    const [transferCheque, setTransferCheque] = useState('T');
    const [chequeValue, setChequeValue] = useState("");
    const [chequeValueNum, setChequeValueNum] = useState("");
    const [chequeLine, setChequeLine] = useState("");
    const [chequeSrno, setChequeSrno] = useState("");
    const [chequeLocation, setChequeLocation] = useState("");
    const [chequeStatus, setChequeStatus] = useState(null);
    const [nIndividual, setNIndividual] = useState(null);
    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [nCorporate, setNCorporate] = useState(null);
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [faStateValue, setFAStateValue] = useState(0);
    const [faStateName, setFAStateName] = useState("");
    const [editDate, setEditDate] = useState('');
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [activityLogObject, setActivityLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [hdrSrno, setHdrSrno] = useState(0);


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setLocationValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'INTER_BRANCH_ENTRY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChange = (value) => {
        setLocationValue(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleLocationChange = (value) => {
        setToLocationValue(value);
        const loc = value.id.split('^');
        setToLocationCode(loc[1]);
        setToLocation(loc[2]);
        setBranchGroup(loc[6]);
        setBranchAccount(loc[7]);
        setBranchAccountName(loc[8]);
    }

    const handleNoteType = (e) => {
        let value = e.target.value;
        setNoteType(value);
        if (value == 'C') {
            setRadioType('S');
        } else {
            setRadioType('N');
        }
    }

    const handleAddNew = () => {
        var msg = [], i = 0;
        var d = Common.dateYMD(filterDate);
        if (locationValue === null || toLocationValue === null || !filterDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (locationValue === null ? " From Branch." : '');
            msg[i++] = (toLocationValue === null ? " To Branch." : '');
            msg[i++] = (!filterDate ? " Select date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (locationCode === toLocationCode) {
            setMsgText("From Branch and To Branch should not be same!.");
            setMsgBox(true);
        } else if (d > finEndDate || d < finStartDate) {
            setMsgText(Common.getMessage("MSG0010"));
            setMsgBox(true);
        } else {
            setScrMode('A');
            setRandomString(Common.getRandomString(15));
            setAmount('');
            setBookList([]);
        }
    }

    const handleCancel = () => {
        setMsgTextYN("Do you really want to exit");
        setMsgBoxYN(true);
    }

    const cancelData = () => {
        setScrMode('');
        setShowHeaderList(true);
        handleShowList();
        setChecked(false);
        setMsgBoxYN(false);
        setAmount("");
        setLedgerValue(null);
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        const account = value.id.split('^');
        setGrpCode(account[1]);
        setAccCode(account[2]);
        setAccountType(account[4]);
        setAccountName(account[3]);
    }

    const handleKnockoffValue = (value) => {
        setKnockoffValue(value);
        const knockoff = value.id.split('^');
        setKnockoffSrno(knockoff[3]);
        setKnockoffType(knockoff[2]);
        setKnockoffAmt(knockoff[6]);
        setKnockoffLineNo(knockoff[4]);
        setKnockoffPending(knockoff[8]);
        setKnockoffFlag(knockoff[9]);
        setKnockoffLedger(knockoff[10]);
    }

    const handleKnockoffSelective = () => {
        var msg = [], i = 0;
        if (ledgerValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select account." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                data: `${flag}^${grpCode}^${accCode}^${Common.dateYMD(filterDate)}`,
                limit: limit,
                srch: srch
            }
            Common.callApi(Common.apiIBEntry, [sid, 'getknockoff', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setKnockoffData(resp);
            });
            setShow(true);
        }
    }

    const updateKnockoffFields = (index, e, name) => {
        let tempArr = knockoffData.map((item, i) => {
            if (index === i) {
                if (name === "tfd_amount") {
                    return { ...item, [name]: e.target.value };
                } else {
                    if (e.target.checked) {
                        return { ...item, [name]: '1' };
                    } else {

                        return { ...item, [name]: '0' };
                    }
                }
            } else {
                return item;
            }
        });
        console.log(tempArr);
        setKnockoffData(tempArr);
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleAddKnockoff = () => {
        const obj = {
            knockoffData: knockoffData,
            randomString: randomString,
            flag: 'C',
        }
        Common.callApi(Common.apiIBEntry, [sid, 'addknockoffSelective', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setShow(false);
            // setShowKnockoff(true);
            setCounter(counter + 1);
            setShowList(true);
            setLedgerValue(null);
            setNIndividual(null);
            calculateTotal(resp);
            setKnockoffValue(null);
            setKnockoffType("");
            setKnockoffSrno("");
            setKnockoffAmt("");
            setAmount('');
            setAddCount(addCount + 1);
            for (let i = 0; i < knockoffData.length; i++) {
                knockoffData[i].tfd_transfer_type = "";
                knockoffData[i].tfd_cheque_status = "";
                knockoffData[i].cheque_date = "";
                knockoffData[i].tfd_cheque_bank_name = "";
                knockoffData[i].tfd_cheque_no = "";
                knockoffData[i].tfd_individual_code = "";
                knockoffData[i].tfd_account_type = "";
                knockoffData[i].chequeValueNum = "";
                knockoffData[i].tfd_flag = noteType;
                knockoffData[i].tfd_srno = resp[0].tfd_srno;
                knockoffData[i].tfd_line_no = resp[0].tfd_line_no;
                knockoffData[i].tfd_knockoff_amount = resp[0].tfd_amount;
                if (knockoffData[i].ischecked == '0') continue;
                setBookList(Common.arrayAddItem(bookList, knockoffData[i]));
            }
        });
    }

    const handleAdd = () => {
        var msg = [], i = 0;
        $(".loader").show();
        if (ledgerValue == null || amount === '' ||
            (accountType == 'CHEQUE' && chequeDate == null) || (accountType == 'CHEQUE' && chequeNum == '') || (accountType == 'CHEQUE' && bankName == '') ||
            (accountType == 'BANK' && noteType == 'C' && transferCheque == 'T' && chequeNum == '') || (accountType == 'BANK' && noteType == 'C' && transferCheque == 'C' && chequeNum == '') ||
            (noteType == 'D' && transferCheque == 'T' && accountType == 'BANK' && chequeNum == '') || (noteType == 'D' && transferCheque == 'C' && accountType == 'BANK' && chequeNum == '') ||
            (noteType == 'D' && transferCheque == 'C' && accountType == 'BANK' && bankName == '')) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select account." : '');
            msg[i++] = (amount === '' ? "Please fill amount." : '');
            msg[i++] = ((accountType == 'CHEQUE' && chequeNum == '') ? 'Fill Cheque number' : '');
            msg[i++] = ((accountType == 'CHEQUE' && bankName == '') ? 'Fill Bank Name' : '');
            msg[i++] = ((accountType == 'CHEQUE' && chequeDate == null) ? "Please select date." : '');
            msg[i++] = ((accountType == 'BANK' && noteType == 'C' && transferCheque == 'T' && chequeNum == '') ? 'Fill Reference Number' : '');
            msg[i++] = ((accountType == 'BANK' && noteType == 'C' && transferCheque == 'C' && chequeNum == '') ? 'Fill Cheque Number' : '');
            msg[i++] = ((accountType == 'BANK' && noteType == 'D' && transferCheque == 'T' && chequeNum == '') ? 'Fill Reference Number' : '');
            msg[i++] = ((accountType == 'BANK' && noteType == 'D' && transferCheque == 'C' && chequeNum == '') ? 'Fill Cheque Number' : '');
            msg[i++] = ((noteType == 'D' && transferCheque == 'C' && accountType == 'BANK' && bankName == '') ? 'Fill Bank Name' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setAddCount(addCount + 1);
            let individual_name = '';
            let individual_id = 0;
            console.log(nIndividual);
            if (nIndividual != null) {
                let individual = nIndividual.id.split('^');
                individual_name = individual[2];
                individual_id = individual[1];
                console.log(individual[2]);
                console.log(individual[1]);
            }
            let chqDate = "";
            let transferType = "";
            if (accountType == 'CHEQUE') {
                chqDate = Common.dateYMD(chequeDate);
            } else if (accountType == 'BANK') {
                transferType = transferCheque;
                chqDate = chequeDate;
            }
            let obj;
            if (knockoffValue == null) {
                obj = {
                    uniquekey: Common.getRandomString(15),
                    tfd_date: Common.dateYMD(filterDate),
                    tfd_type: 'IB',
                    tfd_flag: 'C',
                    tfd_amount: amount,
                    tfd_location: locationCode,
                    tfd_group: grpCode,
                    tfd_account: accCode,
                    tfd_account_type: accountType,
                    mvli_ledger_name: accountName,
                    srno: srNo,
                    tfd_individual: individual_name,
                    tfd_individual_code: individual_id,
                    tfd_cheque_no: chequeNum,
                    tfd_cheque_bank_name: accountType == 'BANK' ? accountName : bankName,
                    cheque_date: Common.dateYMD(chqDate),//
                    tfd_cheque_status: chequeStatus,
                    chequeValueNum: chequeValueNum,
                    tfd_transfer_type: transferType,
                    tfd_knockoff_srno: 'null',
                    tfd_knockoff_type: "",
                    tfd_knockoff_lineno: 'null',
                    delny: 'Y',
                }
            } else {
                obj = {
                    tfd_line_no: '',
                    tfd_location: locationCode,
                    tfd_type: 'IB',
                    tfd_srno: '',
                    tfd_knockoff_type: knockoffType,
                    tfd_knockoff_srno: knockoffSrno,
                    tfd_knockoff_lineno: knockoffLineNo,
                    tfd_group: grpCode,
                    tfd_account: accCode,
                    tfd_amount: amount,
                    tfd_flag: 'C',
                    // tfd_flag: knockoffFlag,
                    tfd_date: Common.dateYMD(filterDate),
                    mvli_ledger_name: knockoffLedger,
                    randomString: randomString,
                    delny: 'Y',
                    tfd_transfer_type: "",
                    tfd_cheque_status: "",
                    cheque_date: "",
                    tfd_cheque_bank_name: "",
                    tfd_cheque_no: "",
                    tfd_individual_code: "",
                    tfd_account_type: "",
                    chequeValueNum: "",
                }
                Common.callApi(Common.apiIBEntry, [sid, 'addknockoffSingle', JSON.stringify(obj)], (result) => {
                    let resp = JSON.parse(result);
                    obj.tfd_line_no = resp[0].tfd_line_no;
                    obj.tfd_srno = resp[0].tfd_srno;
                    // console.log(obj);
                })
            }
            setBookList(Common.arrayAddItem(bookList, obj));
            setCounter(counter + 1);
            setShowList(true);
            setAmount("");
            setNoteType("D");
            setLedgerValue(null);
            setBankName("");
            setChequeNum("");
            setBankName("");
            setNIndividual(null);
            setChequeStatus(null);
            calculateTotal(bookList);
            setChequeDate(new Date());
            setAccountType("");
            setTransferCheque('T');
            setKnockoffValue(null);
            setKnockoffType("");
            setKnockoffSrno("");
            setKnockoffAmt("");
            $(".loader").hide();
        }
    }

    const handleSave = () => {
        if (addCount == '0' && scrMode == 'A') {
            setMsgText("You can't save without adding data");
            setMsgBox(true);
        } else if (scrMode === 'E' && acRemark === '') {
            setShowModal(true);
            $(".loader").hide();
        } else {
            const obj = {
                remark: acRemark,
                activitytype: code == 1 ? 'MODIFY' : 'CREATE',
                subtranno: code,
                trannumber: srNo,
                trantype: 'IB',
                trancategory: 'T',
                branch: locationCode,

                location: locationCode,
                toLocation: toLocationCode,
                flag: noteType,
                date: Common.dateYMD(filterDate),
                type: 'IB',
                narration: narration,
                bookList: bookList,
                radioType: radioType,
                scrMode: scrMode,
                srNo: srNo,
                totalCredit: totalCredit,
                totalDebit: totalDebit,
                group: grpCode,
                account: accCode,
                chequeSrno: chequeSrno,
                chequeLine: chequeLine,
                chequeLocation: chequeLocation,
                chequeNum: chequeNum,
                randomString: randomString,
                knockoffValue: knockoffValue,
            }
            Common.callApi(Common.apiIBEntry, [sid, 'saveIBEntry', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setScrMode('Q');
                calculateTotal(resp.resarray);
                if (radioType == 'N') {
                    setBookList(resp.resarray);
                    setSrNo(resp.srno);
                } else {
                    setNarration(narration);
                    setBookList(resp.resarray);
                }
                setChecked(false);
                setAddCount(0);
                setAcRemark('');
                setViewSrno(resp.srno);
                setAmount('');
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'IB',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'INTER_BRANCH_ENTRY'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })
            }
        }
    }

    const handleDelete = (val, loc, srno, lineno, amt) => {
        // console.log(srno, 'srno');
        // console.log(lineno, 'lineno');
        const object = {
            location: loc,
            flag: noteType,
            type: 'IB',
            srno: srno,
            lineno: lineno,
            amt: amt < 0 ? -1 * amt : amt,
            randomString: randomString
        }
        // console.log(object);
        Common.callApi(Common.apiIBEntry, [sid, 'deleteKnockoff', JSON.stringify(object)], (result) => {
            let resp = JSON.parse(result);
            setBookList(resp);
        });
        setBookList(Common.arrayRemoveItem(bookList, "uniquekey", val));
        setCounter(counter - 1);
        // console.log(bookList);
        calculateTotal(bookList);
    }

    const handleBack = () => {
        setScrMode('');
    }

    const handleView = (vsrno, vtype, vdate, toGroup, toLedgerName, date) => {
        $(".loader").show();
        setEditDate(date)
        if (radioType == 'N') {
            setRadioType('A');
        }
        console.log(date);
        setSrNo(vsrno);
        const obj = {
            srNo: vsrno,
            location: locationCode,
            toLocation: toLocationCode,
            type: vtype,
        }
        Common.callApi(Common.apiIBEntry, [sid, 'viewdata', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setBookList(resp);
            $(".loader").hide();
            setScrMode('Q');
            setShowHeaderList(false);
            setShowHeaderList(false);
            setShowList(true);
            setViewDate(vdate);
            setViewSrno(vsrno);
            calculateTotal(resp);
            setNIndividual(null);
            setLocationValue(null);
        });
    }

    const handleShowList = () => {
        var msg = [], i = 0;
        if (locationValue === null || (radioType === 'S' && !srnoFrom) || (radioType === 'S' && !srnoTo) || (radioType === 'D' && !fromDate) || (radioType === 'D' && !toDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (locationValue === null ? " From Branch." : '');
            msg[i++] = (radioType === 'S' && !srnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !srnoTo ? " To Srno." : '');
            msg[i++] = ((radioType === 'D' && !fromDate) ? " From date." : '');
            msg[i++] = ((radioType === 'D' && !toDate) ? " To date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                date: Common.dateYMD(filterDate),
                type: 'IB',
                location: locationCode,
                radioType: radioType,
                fromSrno: srnoFrom,
                toSrno: srnoTo,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),
                flag: noteType
            }
            Common.callApi(Common.apiIBEntry, [sid, 'showIBEntry', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setHeaderList(resp);
                setShowHeaderList(true);
            });
        }
    }

    const calculateTotal = (list) => {
        let totalDebt = 0;
        let totalCred = 0;
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].tfd_flag == 'D') {
                totalDebt = totalDebt + list[i].tfd_amount * 1;
            } else {
                totalCred += list[i].tfd_amount < 0 ? -1 * list[i].tfd_amount : list[i].tfd_amount * 1;
            }
        }
        setTotalDebit(totalDebt);
        setTotalCredit(totalCred);
        let totalDiff = totalDebt - totalCred;
        setTotalDifference(totalDiff.toFixed(2));
        console.log(totalDiff);
        if (totalDiff < 0) {
            console.log("jjjjjj");
            setFlag('D');
            setAmount(-1 * totalDiff);
        } else if (totalDiff == 0) {
            setAmount("");
        }
        else {
            setFlag('C');
            setAmount(totalDiff);
        }
    }

    const handleChequeNum = value => {
        setChequeValue(value);
        const cheque = value.id.split('^');
        console.log(cheque);
        setChequeNum(cheque[4]);
        setChequeValueNum(cheque[4]);
        setChequeSrno(cheque[1]);
        setChequeLine(cheque[2]);
        setChequeLocation(cheque[3]);
        setChequeDate(cheque[5]);
    }

    const handleReset = () => {
        setNIndividual(null);
    }

    const newIndividual = () => {
        setShowIndividualForm(true);
    }

    const handleNewIndiviualChange = (value) => {
        console.log(value);
        setNIndividual(value);
        if (nCorporate == null) {
            setNState({ value: faStateValue, label: faStateName });
        }
    }

    const hideIndividualProfile = (val, individualsrno) => {
        setShowIndividualForm(val);
        if (individualsrno > 0) {
            Common.callApi(Common.apiBookEntry, [sid, "individual", individualsrno], result => {
                console.log(result);
                let resp = JSON.parse(result);
                setNIndividual(resp);
            });
        }
    }

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        // console.log(differenceInDays);
        const obj = {
            srno: viewSrno,
            type: 'IB',
            pageright: 'INTER_BRANCH_ENTRY'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');
            } else {
                setShowEditRequest(true);
                setLogObject({ trantype: 'IB', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setActivityLogObject({ trantype: 'IB', trannumber: srNo, trancategory: "T" });
    }

    const btnSaveModal = () => {
        if (scrMode === 'E' && acRemark === '') {
            setShowModal(false);
            setMsgText('Please fill Activity Remark!');
            setMsgBox(true);
        } else {
            handleSave();
            setShowModal(false);
        }
    }

    return (
        <>
            {
                showIndividualForm ?
                    <Master_individual_profile value="FABOOK" hideIndividualProfile={hideIndividualProfile} />
                    : <>  <Login_header />
                        <Menu />

                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2>Inter Branch Entry <span style={{ color: 'white' }}>{counter}</span></h2>
                                </Col>
                            </Row>&nbsp;

                            {
                                scrMode == '' ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From Branch<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={locationValue}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={(e) => handleChange(e)}
                                                        isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To Branch<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={toLocationValue}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={(e) => handleLocationChange(e)}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Label>Note Type<span className="colorThemeRed">*</span></Form.Label><br />
                                                <select class="form-select" aria-label="Default select example" value={noteType}
                                                    onChange={(e) => handleNoteType(e)} >
                                                    <option value="D">Debit</option>
                                                    <option value="C">Credit</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Row style={{ marginTop: "30px" }}>
                                                        {
                                                            noteType == 'D' &&
                                                            <Col>
                                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" selected />
                                                            </Col>
                                                        }
                                                        <Col>
                                                            <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>&nbsp;
                                        <Row>
                                            {
                                                radioType === "N" ?
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                            <DatePicker className="form-control"
                                                                selected={filterDate}
                                                                onChange={(date) => setFilterDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                placeholder="dd/mm/yyyy"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    : radioType === "S" ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control value={srnoFrom}
                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setSrnoFrom)}
                                                                        placeholder='From Srno'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control value={srnoTo}
                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setSrnoTo)}
                                                                        placeholder='To Srno'

                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : radioType === "D" ?
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                                        <DatePicker className="form-control"
                                                                            selected={fromDate}
                                                                            onChange={(date) => setFromDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                                        <DatePicker className="form-control"
                                                                            selected={toDate}
                                                                            onChange={(date) => setToDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                            }
                                        </Row>&nbsp;
                                        <Row>
                                            <Col>
                                                {radioType == 'N' ?
                                                    <Button variant='outline-success' className='buttonStyle' size='sm'
                                                        onClick={handleAddNew}
                                                    >Add New</Button>
                                                    : <Button variant='outline-primary' className='buttonStyle' size='sm'
                                                        onClick={handleShowList}
                                                    >List</Button>
                                                }
                                            </Col>
                                        </Row>&nbsp;
                                        {
                                            showHeaderList &&
                                            <Row>
                                                <Col>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                {userRight.add === "1" ? <th></th> : null}
                                                                <th>Srno.</th>
                                                                <th>Type</th>
                                                                <th>Date</th>
                                                                <th>Total Debit</th>
                                                                <th>Total Credit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {headerList.map((res) =>
                                                                <tr key={res.tfd_line_no}>
                                                                    {userRight.delete === "1" ?
                                                                        <td className="textCenter" >
                                                                            {
                                                                                <span className="handCursor"
                                                                                    onClick={() => handleView(res.tfh_srno, res.tfh_type, res.formatted_date, res.entity_name, res.hdr_group, res.mvli_ledger_name, res.tfh_date)}
                                                                                >
                                                                                    <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                        : null
                                                                    }
                                                                    <td>{res.tfh_srno}</td>
                                                                    <td>{res.tfh_type}</td>
                                                                    <td>{res.formatted_date}</td>
                                                                    <td>{res.tfh_total_debit}</td>
                                                                    <td>{res.tfh_total_credit}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                    </> :
                                    <>
                                        <Row>
                                            <Col >
                                                {scrMode == 'A' || scrMode == 'E' ?
                                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleCancel} >Cancel</Button>
                                                    : <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>
                                                }&nbsp;
                                                {
                                                    (userRight.edit === "1" && scrMode === 'Q') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnEditRequest()}>Edit</Button>
                                                        {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() =>
                                                     handleDownload()}>Print</Button>&nbsp;
                                                 <Button variant="outline-success" className='buttonStyle' size="sm"
                                                     onClick={() => handlePrintAdvance()}>Advance</Button> */}
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ?
                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSave} >Save</Button> : null
                                                }
                                                &nbsp;
                                                {
                                                    (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnActivityLog()}>Activity Log</Button>
                                                    </> : null
                                                }
                                            </Col>
                                        </Row>&nbsp;
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Label>Serial Number : {hdrSrno}</Form.Label>
                                            </Col>

                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Type : IB</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From Branch : {location}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To Branch : {toLocation}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    {
                                                        scrMode == 'A' ?
                                                            <Form.Label>Date : {Common.dateDMY(filterDate)} </Form.Label> :
                                                            <Form.Label>Date : {viewDate} </Form.Label>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Note Type : {noteType == 'D' ? 'Debit' : 'Credit'}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Debit to branch : {branchGroup} {branchAccountName}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        &nbsp;
                                        {scrMode == 'E' || scrMode == 'A' ?
                                            <>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className="text-danger">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={ledgerValue}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleLedgerValue}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control
                                                                value={amount}
                                                                maxLength={Common.maximumLength}
                                                                onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                                                                placeholder="Amount"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-9 col-12'>
                                                        <Form.Group>
                                                            <Form.Label>Knockoff</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={knockoffValue}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchKnockoffPicklist(search, 1, `${flag}^${grpCode}^${accCode}^${Common.dateYMD(filterDate)}`)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleKnockoffValue}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>&nbsp;
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Button variant="outline-primary" onClick={handleKnockoffSelective}>
                                                            Selective Knockoff
                                                        </Button>
                                                    </Col></Row>&nbsp;
                                                <Modal show={show} onHide={handleClose} size='xl'>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Selective Knockoff</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row>
                                                            <Col className='col-md-4 '>
                                                                <Form.Label>Search</Form.Label>
                                                                <Form.Control
                                                                    value={srch}
                                                                    maxLength={Common.maximumLength}
                                                                    placeholder="Search"
                                                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setSrch)}
                                                                />
                                                            </Col>&nbsp;
                                                            <Col className='col-md-4 '>
                                                                <Form.Label>Limit</Form.Label>
                                                                <Form.Select value={limit} onChange={(e) => setLimit(e.target.value)} required>
                                                                    <option value="10">10</option>
                                                                    <option value="2">15</option>
                                                                    <option value="20">20</option>
                                                                </Form.Select>
                                                            </Col>&nbsp;

                                                            <Col className='col-md-3 '>
                                                                <Button variant="outline-primary" onClick={handleKnockoffSelective}>
                                                                    Search
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Table striped bordered hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Pending</th>
                                                                            <th>Type</th>
                                                                            <th>Date</th>
                                                                            <th>Total Amount</th>
                                                                            <th>KnockOff Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {knockoffData.map((res, index) =>
                                                                            <tr key={index}>
                                                                                <td> <Form.Check
                                                                                    type="checkbox"
                                                                                    checked={res.ischecked == '1'}
                                                                                    name="ischecked"
                                                                                    onChange={(e) => updateKnockoffFields(index, e, "ischecked")}
                                                                                /></td>
                                                                                <td> <Form.Control value={res.tfd_amount} name='tfd_amount'
                                                                                    onChange={(e) => updateKnockoffFields(index, e, "tfd_amount")}
                                                                                /></td>
                                                                                <td>{res.tfd_knockoff_type}</td>
                                                                                <td>{res.tfd_date}</td>
                                                                                <td>{res.total_amount}</td>
                                                                                <td>{res.knockoff_amount}</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={handleAddKnockoff}>
                                                            Add
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Row>
                                                    {accountType == 'BANK' &&
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                                <Form.Select value={transferCheque} onChange={(e) => setTransferCheque(e.target.value)} required>
                                                                    <option value="T">Transfer/Ref No.</option>
                                                                    <option value="C">Cheque</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    }
                                                    {
                                                        accountType == "CHEQUE" ?
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                        <Form.Control
                                                                            value={chequeNum}
                                                                            maxLength={Common.maximumLength}
                                                                            onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                            placeholder={accountType == 'CHEQUE' || accountType == 'BANK' && transferCheque == 'C' ? 'Cheque Number' : 'Reference Number'}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Bank Name<span className="text-danger">*</span></Form.Label>
                                                                        <Form.Control
                                                                            value={bankName}
                                                                            maxLength={100}
                                                                            onChange={(e) => Common.validateAlpValue(e.target.value, setBankName)}
                                                                            placeholder="Bank Name"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Cheque Date<span className="text-danger">*</span></Form.Label><br />
                                                                        <DatePicker className="form-control"
                                                                            selected={chequeDate}
                                                                            onChange={(date) => setChequeDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            placeholder="dd/mm/yyyy"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </> : accountType == "BANK" ?
                                                                <> <Col className='col-md-3 col-6'>
                                                                    <Form.Group>

                                                                        {
                                                                            noteType == 'C' && transferCheque == 'T' &&
                                                                            <>
                                                                                <Form.Label>Reference Number<span className="text-danger">*</span></Form.Label>
                                                                                <Form.Control
                                                                                    value={chequeNum}
                                                                                    maxLength={100}
                                                                                    onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                                    placeholder="Reference Number"
                                                                                />
                                                                            </>
                                                                        }
                                                                        {
                                                                            noteType == 'C' && transferCheque == 'C' &&
                                                                            <>
                                                                                <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={chequeValue}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={(search) => Picklist.fetchChequeBookBankPicklist(search, grpCode, accCode, locationCode)}
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={(e) => handleChequeNum(e)}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </>
                                                                        }
                                                                        {
                                                                            noteType == 'D' && transferCheque == 'T' &&
                                                                            <>
                                                                                <Form.Label>Reference Number<span className="text-danger">*</span></Form.Label>
                                                                                <Form.Control
                                                                                    value={chequeNum}
                                                                                    maxLength={100}
                                                                                    onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                                    placeholder="Reference Number"
                                                                                />
                                                                            </>
                                                                        }&nbsp;
                                                                        {
                                                                            noteType == 'D' && transferCheque == 'C' &&
                                                                            <>
                                                                                <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={chequeValue}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={(search) => Picklist.fetchChequeBookBankPicklist(search, grpCode, accCode, locationCode)}
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={(e) => handleChequeNum(e)}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </>
                                                                        }&nbsp;
                                                                    </Form.Group>
                                                                </Col>
                                                                    {noteType == 'D' && transferCheque == 'C' &&
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Bank Name<span className="text-danger">*</span></Form.Label>
                                                                                <Form.Control
                                                                                    value={bankName}
                                                                                    maxLength={100}
                                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setBankName)}
                                                                                    placeholder="Bank Name"
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }&nbsp;
                                                                </>
                                                                : null
                                                    }
                                                </Row>
                                                <Row>
                                                    {accountType == 'WALKIN' &&
                                                        <Col className="col-md-3 col-6">
                                                            <Form.Group>
                                                                <Form.Label>Individual Profile
                                                                    {
                                                                        scrMode === "A" &&
                                                                        <span>  <span className='tranNewFormText'
                                                                            onClick={() => newIndividual()}
                                                                        >Not in list? Create New </span>
                                                                            <span>
                                                                                <Badge bg="secondary" onClick={handleReset}>Reset</Badge>
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                </Form.Label>
                                                                <AsyncSelect
                                                                    cacheOptions={false}
                                                                    defaultOptions={false}
                                                                    value={nIndividual}
                                                                    getOptionLabel={e => e.label + ' '}
                                                                    getOptionValue={e => e.id}
                                                                    loadOptions={Picklist.fetchIndividualMainPicklist}
                                                                    onInputChange={Picklist.handleInputChange}
                                                                    onChange={handleNewIndiviualChange}
                                                                    isDisabled={scrMode === "Q"}
                                                                >
                                                                </AsyncSelect>
                                                            </Form.Group>
                                                        </Col>
                                                    }
                                                </Row>&nbsp;
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAdd} >Add</Button>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Show Additional Details"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </Col>
                                                </Row>&nbsp;
                                            </> : null
                                        }
                                        {showList &&
                                            <Row>
                                                <Col>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    isChecked ?
                                                                        <td
                                                                            colSpan={10}
                                                                        ></td> : <td
                                                                            colSpan={7}
                                                                        ></td>
                                                                }
                                                                <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Credit:</b> <span>{totalCredit}</span></td>
                                                            </tr>
                                                            <tr>
                                                                {userRight.delete === "1" ? <th></th> : null}
                                                                <th>Group</th>
                                                                <th>Account</th>
                                                                <th>Account Name</th>
                                                                <th>Individual</th>
                                                                <th>Knockoff</th>
                                                                <th style={{ textAlign: 'right' }}>Debit</th>
                                                                <th style={{ textAlign: 'right' }}>Credit</th>
                                                                {isChecked && <>
                                                                    <th>Cheque Number</th>
                                                                    <th>Cheque Date</th>
                                                                    <th>Cheque Bank Name</th>
                                                                </>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bookList.map((res) =>
                                                                <tr key={res.tfd_line_no}>
                                                                    {userRight.delete === "1" ?
                                                                        <td className="textCenter" >
                                                                            {
                                                                                (scrMode == 'E' || scrMode == 'A') && res.delny == "Y" ?
                                                                                    <span className="handCursor colorThemeBlue"
                                                                                        onClick={() => handleDelete(res.uniquekey, res.tfd_location, res.tfd_srno, res.tfd_line_no, res.tfd_amount)}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span>
                                                                                    : null
                                                                            }
                                                                        </td>
                                                                        : null
                                                                    }
                                                                    <td>{res.tfd_group}</td>
                                                                    <td>{res.tfd_account}</td>
                                                                    <td>{res.mvli_ledger_name}</td>
                                                                    <td>{res.tfd_individual}</td>
                                                                    <td>{res.tfd_knockoff_srno == "null" || res.tfd_knockoff_srno == null ? null : res.tfd_knockoff_type + ' ' + res.tfd_knockoff_srno}</td>
                                                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag === 'D' ? res.tfd_amount : ''}</td>
                                                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag === 'C' ? (res.tfd_amount < 0 ? -1 * res.tfd_amount : res.tfd_amount) : ''}</td>
                                                                    {isChecked && <>
                                                                        <td>{res.tfd_cheque_no}</td>
                                                                        <td>{res.cheque_date}</td>
                                                                        <td>{res.tfd_cheque_bank_name}</td>
                                                                    </>}

                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                            }
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Container>
                    </>
            }
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <ActivityLog logObject={activityLogObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <Tran_data_unlock_request logObject={logObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            scrMode === 'E' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Reason" maxLength={499}
                                            value={acRemark}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAcRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group>
                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnSaveModal()}>
                            Save
                        </Button>{' '}
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Inter_branch_entry
