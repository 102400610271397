import React from 'react';
import * as Common from '../mc/Common';
import { components } from "react-select";
import axios from 'axios';

export const handleInputChange = (value) => {
    // setInputValue(value);
}

export const fetchLocationAllowedPicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'locationallowed',
        srch: searchString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchLedgerAllowedPicklist = (searchString, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'ledgerallowed',
        srch: searchString,
        location: location
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}
//picklist for  additional credit limit component 
export const fetchLedgerAdditionalCreditAllowedPicklist = (searchString, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'additionalCreditledgerallowed',
        srch: searchString,
        location: location
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchIndividualPicklist = (searchString, trantype = "PURCHASE") => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'individual',
        srch: searchString,
        trantype: trantype,
        a: "b"
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchLedgerPicklistByType = (searchString, grouptype, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'ledgerfromtype',
        srch: searchString,
        grouptype: grouptype,
        location: location
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchCurrencyAllowedPicklist = (searchString, product) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'currencyallowed',
        srch: searchString,
        product: product
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchVtmEncashmentPicklist = (searchString, status, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'vtmencashment',
        srch: searchString,
        status: status,
        location: location
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchExecutivePicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'executive',
        srch: searchString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchPurposePicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'purpose',
        srch: searchString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchCountryPicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'country',
        srch: searchString,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchIndividualMainPicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'individualList',
        srch: searchString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchStatePicklist = (searchString, country) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'state',
        srch: searchString,
        country: country,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchDistrictPicklist = (searchString, state) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'district',
        srch: searchString,
        state: state,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchCityPicklist = (searchString, district) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'city',
        srch: searchString,
        district: district,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchGroupPicklist = (searchString, grouptype) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'groupfromtype',
        srch: searchString,
        grouptype: grouptype,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;

}

export const fetchBankPicklist = (searchString, location) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'bank',
        srch: searchString,
        location: location
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;

}

export const fetchChequePicklist = (searchString, location, randomString, filterDate) => {
    console.log(randomString);
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'cheque',
        srch: searchString,
        location: location,
        randomString: randomString,
        date: filterDate
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;

}

export const fetchChequeBookBankPicklist = (searchString, groupCode, bankCode, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'chequebookbank',
        srch: searchString.trim(),
        groupCode: groupCode,
        bankCode: bankCode,
        location: location,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchReceivedChequePicklist = (searchString, location, bankGroup, bankAccount) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'receivedcheque',
        srch: searchString,
        location: location,
        bankGroup: bankGroup,
        bankAccount: bankAccount
        // randomString: randomString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchIssueChequePicklist = (searchString, location, bankGroup, bankAccount) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'issuecheque',
        srch: searchString,
        location: location,
        bankGroup: bankGroup,
        bankAccount: bankAccount
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchManualAllPicklist = (searchString, location) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'manualall',
        srch: searchString,
        location: location,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchManualTypePicklist = (searchString, location, trantype) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'manualtype',
        srch: searchString,
        location: location,
        trantype: trantype
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchDealerTypePicklist = (searchString, location, dealertype) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'dealertype',
        srch: searchString,
        location: location,
        dealertype: dealertype
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchDebtorCreditorAllowedPicklist = (searchString, location, flag) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'debtorcreditorallowed',
        srch: searchString,
        location: location,
        flag: flag
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchProductBankPicklist = (searchString, location, groupCode, bankCode, currencyCode) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'productbank',
        srch: searchString,
        location: location,
        groupCode: groupCode,
        accountCode: bankCode,
        currencyCode: currencyCode,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchKnockoffPicklist = (searchString, location, data) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'knockoff',
        srch: searchString,
        location: location,
        data: data,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchVtcInlist = (searchString, reqObj) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'vtcinlist',
        srch: searchString,
        location: reqObj.location,
        group: reqObj.group,
        isd: reqObj.isd,
        account: reqObj.account
    };
    //obj = { ...obj, ...reqObj };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchVtcBilllist = (searchString, reqObj) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'vtcbilllist',
        srch: searchString,
        location: reqObj.location,
        group: reqObj.group,
        isd: reqObj.isd,
        account: reqObj.account
    };
    //obj = { ...obj, ...reqObj };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchVtSettled = (searchString, reqObj) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'vtsettled',
        srch: searchString,
        location: reqObj.location,
        group: reqObj.group,
        isd: reqObj.isd,
        account: reqObj.account
    };
    //obj = { ...obj, ...reqObj };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });
    return result;
}

export const fetchLocationPicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'location',
        srch: searchString
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}

export const fetchCountryofIssuePicklist = (searchString) => {
    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'countryIssue',
        srch: searchString,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;
}


export const fetchBankDetailsPicklist = (searchString, location, bank) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'bankDetails',
        srch: searchString,
        location: location,
        bank: bank
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;

}

export const fetchExpenseSubPurposeDetailsPicklist = (searchString) => {

    var obj = {
        sid: sessionStorage.getItem('sessionId'),
        type: 'expenseSubPurpose',
        srch: searchString,
    };

    var result = axios.create({
        baseURL: Common.apiPicklist,
        headers: {},
    }).get('?1=' + JSON.stringify(obj)).then(result => {
        return JSON.parse(result.data);
    });

    return result;

}


export const formatOptionLabel = ({ ID, label, desc }) => {
    const valArr = desc.split("^");
    const headerLength = valArr.length;
    // const id = valArr[0];
    // const name = valArr[1];
    // const panId = valArr[2];
    // const idtype = valArr[3];
    // const idNum = valArr[4];
    // const add = valArr[5];

    return (
        <div style={{ width: '100%', display: 'flex', borderRight: '2px solid black', paddingBottom: '0px' }}>
            {valArr.map((data) =>
                <div style={{ display: 'table-cell', width: `${100 / (headerLength)}%`, paddingLeft: '1%' }}>
                    {data}
                </div>
            )}
            {/* <div style={{ display: 'table-cell', width: '28%', padding: '5px' }}>
                <span style={{ fontWeight: 'bold', }}> <FontAwesomeIcon style={{ fontSize: '80%', color: 'red' }} icon={faUser} />{id + " " + name}</span>
            </div>
            <div style={{ display: 'table-cell', width: '22%', padding: '5px' }}>
                <span style={{ fontWeight: 'bold', }}><FontAwesomeIcon style={{ fontSize: '80%', color: 'red' }} icon={faIdCard} />{panId}</span>
            </div>
            <div style={{ display: 'table-cell', width: '35%', padding: '5px' }}>
                <span style={{ fontWeight: 'bold', }}><FontAwesomeIcon style={{ fontSize: '80%', color: 'red' }} icon={faAddressCard} />{idtype + " " + idNum} </span>
            </div>
            <div style={{ display: 'table-cell', width: '15%', padding: '5px' }}>
                <span style={{ fontWeight: 'bold', }}><FontAwesomeIcon style={{ fontSize: '80%', color: 'red' }} icon={faLocationDot} />{add}</span>
            </div> */}
        </div>
    )
}

export const menu = (props) => (
    <components.Menu {...props}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ width: '100%' }}>
                {/* <tr style={{ width: '100%' }}> */}
                {/* <th >Name</th>
                    <th >Pancard No</th>
                    <th >Id</th>
                    <th >Addressss</th> */}
                <tr style={{ width: '100%', display: 'flex', fontWeight: 'bold', backgroundColor: '#f8f9fa', }}>
                    <div style={{ width: '28%', paddingLeft: '3%' }}>Name</div>
                    <div style={{ width: '22%', paddingLeft: '3%' }}>Pancard no</div>
                    <div style={{ width: '35%', paddingLeft: '3%' }}>Id</div>
                    <div style={{ width: '15%', paddingLeft: '3%' }}>Country</div>
                </tr>
                {/* </tr> */}
            </thead>
            <tbody >
                {props.children}
            </tbody>
        </table>
        {/* <div style={{ display: 'table', width: '100%', borderCollapse: 'collapse' }}>
            <div style={{ display: 'table-header-group', backgroundColor: '#f8f9fa', borderBottom: '2px solid #ddd' }}>
                <div style={{ display: 'table-row' }}>
                    
                </div>
            </div>
            <div style={{ display: 'table-body-group' }}>
            </div>
        </div> */}
    </components.Menu>
);