import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import * as Common from './Common';



const ActivityLog = (props) => {
    const sid = sessionStorage.getItem('sessionId');
    const [activityData, setActivityData] = useState([]);
    const [show, setShow] = useState(false);
    const [showAuditTrailModal, setShowAuditTrailModal] = useState(false);
    const [auditTrail, setAuditTrail] = useState([]);
    const [test, setTest] = useState(0);

    useEffect(() => {
        if (props.show) {
            Common.callApi(Common.apiActivityLog, [sid, "getlog", JSON.stringify(props.logObject)], result => {
                let resp = JSON.parse(result);
                setActivityData(resp);

            });
        }
    }, [props.show]);
    const handleAuditTrail = (lg_logno, branch) => {
        //props.show = false;
        props.onHide();
        // props.setShowActivityLog(false);
        $(".loader").show();
        setShowAuditTrailModal(true);
        const obj = {
            logno: lg_logno,
            branch: branch
        }
        Common.callApi(Common.apiActivityLog, [sid, "getaudittrail", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let res = JSON.parse(result);
            if (res === "") {
                alert("Select Individual First ");
                setAuditTrail("");
            } else {
                setAuditTrail(res);
            }
        });
    };
    const splitDescription = (description) => {
        const keyValuePairs = description.split('^');
        const obj = {};
        keyValuePairs.forEach(pair => {
            const [key, value] = pair.split(':');
            obj[key] = value;
        });
        return obj;
    };

    return (
        <div>
            <Modal {...props} animation={false} size='xl' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Activity Log</Modal.Title>
                </Modal.Header>
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Remark</th>
                            <th>Timestamp</th>
                            <th>Activity</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activityData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.lg_usersrno}</td>
                                    <td>{item.lg_remark}</td>
                                    <td>{item.date_time}</td>
                                    <td>{item.lg_activity_type}</td>
                                    <td>
                                        <span onClick={() => handleAuditTrail(item.lg_logno, item.lg_branch)} className='pointer colorThemeBlue'>
                                            <FontAwesomeIcon icon={faEye} />
                                        </span>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAuditTrailModal} size='xl' onHide={() => setShowAuditTrailModal(false)} scrollable backdrop="static">
                <Modal.Header closeButton >
                    <Modal.Title>
                        <Row>
                            <Col>
                                <span>Audit Trail Info</span>
                                <span>&nbsp;</span>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col>
                            <Button onClick={() => {
                                // props.show = true;
                                // props.onHide = false;
                                setShowAuditTrailModal(false);

                            }} variant='outline-danger' size='sm' className='buttonStyle'>Back</Button>
                        </Col>
                        <Col>
                        </Col>
                        <Table striped responsive bordered>
                            <thead>
                                <tr>
                                    <th>Activity Type</th>
                                    <th>Tab Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auditTrail.map((item, index) => {
                                    const descriptionObj = splitDescription(item.tat_description);
                                    const descriptionKeys = Object.keys(descriptionObj);
                                    return (
                                        <tr key={index}>
                                            <td>{item.tat_activity_type == "A" ? "Add" : "Delete"}</td>
                                            <td>{item.tat_tab}</td>
                                            <td>
                                                <Table bordered>
                                                    <thead>
                                                        <tr>
                                                            {descriptionKeys.map((key, idx) => (
                                                                <th key={idx}>{key}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {descriptionKeys.map((key, idx) => (
                                                                <td key={idx}>{descriptionObj[key]}</td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ActivityLog;

