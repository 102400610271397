import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "./Common";
import * as Picklist from "./Picklist";

const LocationSelector = ({ onDataFromChild }) => {

    const sid = sessionStorage.getItem("sessionId");
    const branchDisable = sessionStorage.getItem('branchDisable');
    const navigate = useNavigate();
    const [userRight, setUserRight] = useState([]);
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');
    const [location, setLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setLocation(response);
            response != null && handleChangeLocation(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'REPORTS'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        Common.callApi(Common.apiCheck, [sid, 'location'], (result) => {
            let resp = JSON.parse(result);
            setData(resp);
        });
        onDataFromChild(locationCode, selectedItems.toString(), radioType);
        if (branchDisable === "true") setRadioType("O");
    }, [selectedItems]);


    const handleRadioChange = (value) => {
        setRadioType(value);
        setLocation(null);
        setLocationCode(0);
        setSelectedItems([]);
        onDataFromChild(locationCode, selectedItems, value);
    }

    const handleChangeLocation = (value) => {
        setLocation(value);
        var loc = value.id.split('^');
        setLocationCode(loc[1]);

        onDataFromChild(loc[1], selectedItems, radioType);
        sessionStorage.activeBranch = loc[1];
    }


    const checkBoxHandler = (e) => {
        let checked = e.target.checked;
        let value = e.target.value;
        setSelectedItems((prevSelectedItems) => {
            if (checked) {
                return [...prevSelectedItems, value];
            } else {
                return prevSelectedItems.filter((item) => item !== value);
            }
        });
    }


    const myStyle = {
        height: "250px",
        border: "1px solid black",
        //width: "235px"
    };


    const myStyle2 = {
        height: "195px",
        overflow: "hidden scroll",
        padding: "5px 10px",
    }


    return (
        <>
            <Row>
                <Col style={{ display: "flex" }}>
                    <Form.Check disabled={branchDisable === "true"} checked={radioType === "A"} onChange={() => handleRadioChange("A")} type='radio' label="All" />&nbsp;
                    <Form.Check checked={radioType === "O"} onChange={() => handleRadioChange("O")} type='radio' label="One" />&nbsp;
                    <Form.Check disabled={branchDisable === "true"} checked={radioType === "S"} onChange={() => handleRadioChange("S")} type='radio' label="Selective" />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-6'>
                    {
                        radioType === 'A' ?
                            <>

                            </>
                            :
                            radioType === 'O' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={location}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </>
                                :
                                <>
                                    <div style={myStyle}>
                                        <div style={{ backgroundColor: "lightblue", color: "white", padding: "0 0 0 10px" }}>
                                            <Row>
                                                <Col><h3>Locations List</h3></Col>
                                            </Row>
                                        </div>
                                        <div style={myStyle2}>
                                            {
                                                data.map((item, index) => (
                                                    <div key={index} className="mb-3">
                                                        <Form.Check
                                                            label={item.entity}
                                                            type="checkbox"
                                                            value={item.entity_id}
                                                            onChange={(e) => checkBoxHandler(e)}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                    }
                </Col>
            </Row>
        </>
    )
}

export default LocationSelector
