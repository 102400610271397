import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';

import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import ActivityLog from '../ActivityLog';

function Master_city_list() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [cityData, setCityData] = useState([]);

    const [showAddNewBtn, setShowAddNewBtn] = useState(true);
    const [showAddEditForm, setAddEditFormShow] = useState(false);
    const [srNo, setSrNo] = useState(0);
    const [city, setCity] = useState("");

    const [remark, setRemark] = useState("");

    const [status, setStatus] = useState(0);
    const [ddActiveOptions, setDdActiveOptions] = useState([]);

    const [state, setState] = useState(0);
    const [ddStateOptions, setDdStateOptions] = useState([]);

    const [country, setCountry] = useState(0);
    const [ddCountryOptions, setDdCountryOptions] = useState([]);

    const [district, setDistrict] = useState(0);
    const [ddDistrictOptions, setDdDistrictOptions] = useState([]); ///new form

    const [filterActiveCountry, setFilterActiveCountry] = useState(0);
    const [ddFilterActiveCountryOptions, setDdFilterActiveCountryOptions] = useState([]);

    const [filterActiveState, setFilterActiveState] = useState(0);
    const [ddFilterActiveStateOptions, setDdFilterActiveStateOptions] = useState([]);

    const [filterActiveDistrict, setFilterActiveDistrict] = useState(0);
    const [ddFilterActiveDistrictOptions, setDdFilterActiveDistrictOptions] = useState([]); /// new filter

    const [userRight, setUserRight] = useState([]);

    const [formMode, setFormMode] = useState('Q'); // Q A E 

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [showActivityLog, setShowActivityLog] = useState(false);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [tranAuditTrail, setTranAuditTrail] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            setDdActiveOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);

            /*Common.callApi(Common.apiCity,[sid,'listjson'],function(result){ 
                setCityData(JSON.parse(result));
            });*/
            Common.callApi(Common.apiRight, [sid, 'all', 'CITY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiCountry, [sid, 'ddlistjson'], function (result) {
                setDdFilterActiveCountryOptions(JSON.parse(result));
                setDdCountryOptions(JSON.parse(result));
                setFilterActiveCountry(0);
            });
            Common.callApi(Common.apiState, [sid, 'ddlistjson', filterActiveCountry], function (result) {
                setDdStateOptions(JSON.parse(result));
                setDdFilterActiveStateOptions(JSON.parse(result));
                setFilterActiveState(0);

            });
            Common.callApi(Common.apiDistrict, [sid, 'ddlistjson', filterActiveState], function (result) {
                setDdDistrictOptions(JSON.parse(result));
                setDdFilterActiveDistrictOptions(JSON.parse(result));
                setFilterActiveDistrict(0);
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate, filterActiveCountry, filterActiveState]);

    function refreshFormDistrict(id) {
        setCountry(id);
        Common.callApi(Common.apiState, [sid, 'ddlistjson', id], function (result) {
            setDdStateOptions(JSON.parse(result));
        });
    }

    function handleStateChange(id) {
        setState(id);
        Common.callApi(Common.apiDistrict, [sid, 'ddlistjson', id], function (result) {
            setDdDistrictOptions(JSON.parse(result));
            filterActiveDistrict(0);
        });
    }

    function refreshState(id) {
        setFilterActiveCountry(id);
        Common.callApi(Common.apiState, [sid, 'ddlistjson', id], function (result) {
            setDdFilterActiveStateOptions(JSON.parse(result));
            filterActiveState(0);
        });
    }


    function refreshDistrict(id) {
        setFilterActiveState(id);
        Common.callApi(Common.apiDistrict, [sid, 'ddlistjson', id], function (result) {
            setDdFilterActiveDistrictOptions(JSON.parse(result));
            filterActiveDistrict(0);
        });
    }

    function refreshList(id) {
        setFilterActiveDistrict(id);
        getList(id);
        filterActiveDistrict(0);
    }

    function getList(id) {
        setFilterActiveDistrict(id);
        Common.callApi(Common.apiCity, [sid, 'listjson', id], function (result) {
            setCityData(JSON.parse(result));
        });
    }

    function addNew() {
        setSrNo(0);
        // setActivityLog([]);
        setStatus(1);
        setFormMode('A');
        setCity("");
        setDistrict(0);
        setState(0);
        setCountry(0);
        setAddEditFormShow(true);
        setShowAddNewBtn(false);
    }

    function saveData() {
        var msg = [], i = 0;
        if (city === "" || district == "0" || state == "0" || country == "0") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (city === '' ? "City." : '');
            msg[i++] = (district == '0' ? "District." : '');
            msg[i++] = (state == '0' ? "State." : '');
            msg[i++] = (country == '0' ? "Country." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (formMode === "E" && remark === "") {
            setMsgText("Please fill Remark!");
            setMsgBox(true);
        } else {
            var object1 = {
                srno:srNo,
                city: city,
                dist: district,
                state: state,
                act: status,
                branch: 0,
                activitytype: srNo * 1 > 0 ? 'MODIFY' : 'CREATE',
                trancategory: 'M',
                trantype: 'MCITY',
                trannumber: srNo,
                subtranno: 0,
                remark: remark
            };
            var obj2 = {
                TabType: "Edit",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MCITY',
                City: city,
                District: district,
                State: state,
                Status: status,
                remark: remark
            }
            var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, obj2);
            setTranAuditTrail(tran_audit_trail);
            Common.callApi(Common.apiCity, [sid, 'save', srNo, JSON.stringify(object1), JSON.stringify(tran_audit_trail)], function (result) {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setAddEditFormShow(false);
                    setShowAddNewBtn(true);
                    setRemark("");
                    getList(filterActiveDistrict);
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }
    }

    const cancelConfirm = () => {
        if (formMode === "A" || formMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setAddEditFormShow(false);
        setShowAddNewBtn(true);
        setShowActivityLog(false);
    }

    // function cancelData() {
    //     setAddEditFormShow(false);
    //     setShowAddNewBtn(true);
    // }

    function fillEditForm(id) {
        setFormMode('Q');
        // setActivityLog([]);
        setShowActivityLog(false);
        Common.callApi(Common.apiCity, [sid, 'detail', id], function (result) {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setCity(resp.city);
            refreshFormDistrict(resp.cntsrno);
            setStatus(resp.active);
            setState(resp.statesrno);
            setDistrict(resp.dist);
            setAddEditFormShow(true);
            handleStateChange(resp.statesrno);
            setShowAddNewBtn(false);
        });
    }

    const editData = () => {
        setFormMode('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'CITY'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MCITY", trannumber: srNo, trancategory: "M" });
    }

    return (
        <>
            <Login_header />
            <Menu></Menu>
            <Container fluid >
                <Row>
                    <Col>
                        <h1>City Master </h1>
                    </Col>
                </Row>
                {showAddNewBtn ?
                    <Row>
                        <Col>
                            {userRight.add === "1" ?
                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={addNew}>Add New</Button>
                                : null}
                            {
                                userRight.query === "1" ?
                                    <>&nbsp;
                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                    : null
                }
                <Row><Col>&nbsp;</Col></Row>
                {showAddEditForm ?
                    <>
                        <Row>
                            <Col className="divContentCenter">
                                <div className="maxFormWidth">
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Serial Number" value={srNo} disabled />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>City<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="City" maxLength={50}
                                                        value={city}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setCity)}
                                                        autoComplete="off"
                                                        disabled={formMode === 'Q'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                formMode === "A" ?
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Country<span className="mandatory text-danger">*</span></Form.Label>
                                                            <Form.Select
                                                                value={country}
                                                                onChange={(e) => refreshFormDistrict(e.target.value)}
                                                                disabled={formMode === 'Q'}

                                                            >
                                                                {ddCountryOptions.map((res) =>
                                                                    <option key={res.value} value={res.value}>{res.name}</option>
                                                                )}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    : null
                                            }
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>State<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        value={state}
                                                        onChange={(e) => handleStateChange(e.target.value)}
                                                        disabled={formMode === 'Q'}

                                                    >
                                                        {ddStateOptions.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>District<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Select
                                                        value={district}
                                                        onChange={(e) => setDistrict(e.target.value)}
                                                        disabled={formMode === 'Q'}

                                                    >
                                                        {ddDistrictOptions.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        value={status}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        disabled={formMode === 'Q'}

                                                    >
                                                        {ddActiveOptions.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            formMode === 'E' ?
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Remark<span className="mandatory text-danger">*</span></Form.Label>
                                                                <Form.Control type="text" placeholder="Remark" maxLength={50}
                                                                    value={remark}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setRemark)}
                                                                    autoComplete="off"
                                                                    required />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                </> :
                                                null
                                        }
                                        <Row><Col>&nbsp;</Col></Row>
                                        <Row>
                                            <Col>
                                                {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => saveData()}>Save</Button>&nbsp; */}
                                                {/* <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Cancel</Button> */}

                                                <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                    cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                                                {
                                                    (userRight.edit === "1" && formMode === 'Q') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            editData()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && formMode === 'E') || (userRight.add === "1" && formMode === 'A') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            saveData()}>Save</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.query === "1" && (formMode === 'E' || formMode === 'Q')) ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                                    </> : null
                                                }

                                            </Col>
                                        </Row>
                                        <Row><Col>&nbsp;</Col></Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                showActivityLog &&
                                <>
                                    {/* <ActivityLog trantype='MCITY' trannumber={srNo} trancategory="M" /> */}
                                    <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
                                </>
                            }
                        </Row>
                    </>
                    : <>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        value={filterActiveCountry}
                                        onChange={(e) => refreshState(e.target.value)}>
                                        {ddFilterActiveCountryOptions.map((res) =>
                                            <option key={res.value} value={res.value}>{res.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Select
                                        value={filterActiveState}
                                        onChange={(e) => refreshDistrict(e.target.value)}>
                                        {ddFilterActiveStateOptions.map((res) =>
                                            <option key={res.value} value={res.value}>{res.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>District</Form.Label>
                                    <Form.Select
                                        value={filterActiveDistrict}
                                        onChange={(e) => refreshList(e.target.value)}>
                                        {ddFilterActiveDistrictOptions.map((res) =>
                                            <option key={res.value} value={res.value}>{res.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>&nbsp;</Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th className="textRight">Sr No</th>
                                            <th>City</th>
                                            <th>District</th>
                                            <th>State</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cityData.map((res) =>
                                            <tr key={res.city_id}>
                                                {userRight.edit === "1" ?
                                                    <td className="textCenter" >
                                                        <span className="handCursor" onClick={() => fillEditForm(res.city_id)} >
                                                            <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                        </span>
                                                    </td>
                                                    : null
                                                }
                                                <td className="textRight">{res.city_id}</td>
                                                <td>{res.city_name}</td>
                                                <td>{res.dist_district_name}</td>
                                                <td>{res.state_name}</td>
                                                <td>{res.cnt_name}</td>
                                                <td>{res.city_active}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }

            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    );
}
export default Master_city_list; 