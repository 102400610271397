import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from '../../Common';
import Dialog from '../../Dialog';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';

const Flm1_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [flmLocation, setFlmLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [flmFilter, setFlmFilter] = useState("1");
    const [flmFilterText, setFlmFilterText] = useState("FLM1 Daily Summ and Bal.(FC)");
    const [flmViewType, setFlmViewType] = useState('V');
    const [flmDate, setFlmDate] = useState(new Date('2024-03-31'));
    const [locationTypeFlm, setLocationTypeFlm] = useState("A");
    const [fromDateFlm, setFromDateFlm] = useState(new Date("2024-11-01"));
    const [toDateFlm, setToDateFlm] = useState(new Date("2024-11-21"));
    const [interbranch, setInterbranch] = useState(true);
    const [filterCN, setFilterCN] = useState(true);
    const [filterTC, setFilterTC] = useState(true);
    const [filterVTM, setFilterVTM] = useState(true);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setFlmLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'REPORTS_RBI_FLM'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setFlmLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleGenerate = () => {
        $(".loader").show();
        if (flmLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if (Common.dateYMD(fromDateFlm) < startDate || Common.dateYMD(toDateFlm) > endDate) {
            $(".loader").hide();
            setMsgText("Date should be within financial year");
            setMsgBox(true);
        } else {
            const obj = {
                locationType: locationTypeFlm,
                location: locationCode,
                flmFilter: flmFilter,
                fromDate: Common.dateYMD(fromDateFlm),
                toDate: Common.dateYMD(toDateFlm),
                date: Common.dateYMD(toDateFlm),
                type: flmViewType,
                filterInterbranch: interbranch,
                filterCN: filterCN,
                filterTC: filterTC,
                filterVTM: filterVTM
            }
            if (flmFilter === "8") {
                Common.callDownloadApiPost1(Common.apiFlmReport, "post", [sid, 'downloadFLM8', JSON.stringify(obj)], (result) => {
                    $(".loader").hide();
                    setMsgText(Common.buildMessageFromArray([result]));
                    setMsgBox(true);
                });
            } else if (flmFilter == "1") {
                Common.callApi(Common.apiFlmReport, [sid, "printflm1", JSON.stringify(obj)], result => {
                    var newWindow = window.open();
                    newWindow.document.write(result);
                    $(".loader").hide();
                });

            } else if (flmFilter == "3") {
                if (flmViewType === 'D') {
                    Common.callDownloadApiPost1(Common.apiFlmReport, "post", [sid, "Flm3", JSON.stringify(obj)], result => {
                        console.log("this is result ", result);
                        $(".loader").hide();
                        setMsgText(Common.buildMessageFromArray([result]));
                        setMsgBox(true);
                    });
                } else {

                }

            }
            else {
                Common.callDownloadApiPost(Common.apiFlmReport, "post", [sid, 'getData', JSON.stringify(obj)]);
            }
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>{flmFilterText}</h3>
                    </Col>
                </Row>
                <Row>&nbsp; </Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Location Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select value={locationTypeFlm} onChange={e => setLocationTypeFlm(e.target.value)}
                                required>
                                <option value="A">All</option>
                                <option value="O">One</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    {locationTypeFlm === 'O' ? <>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={flmLocation}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleChangeLocation}
                                    isDisabled={branchDisable === "true"}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col></> : null}

                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>FLM Filter<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={flmFilter}
                                onChange={(e) => {
                                    setFlmFilterText(e.target.options[e.target.selectedIndex].text);
                                    setFlmFilter(e.target.value);
                                }}
                                required>
                                <option value="1">FLM1 Daily Summ and Bal.(FC)</option>
                                <option value="2">FLM2 Daily Summ and Bal.(TC)</option>
                                <option value="3">FLM3 Purchase from Public(FC)</option>
                                <option value="4">FLM4 from FFMC/RMC-FC</option>
                                <option value="5">FLM5 Sale to Public (FC/TC)</option>
                                <option value="6">FLM6 Sale to FFMC/RMC (FC)</option>
                                <option value="7">FLM7 TC's Surrender to AD's</option>
                                <option value="8">FLM8 Purchase and Sale of (FC)</option>
                                <option value="9">FLM9 TC's Purchase and Surrender</option>
                                <option value="10">FLM10 Sales(FC/TC) for B.Vst</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>

                    {
                        flmFilter === "1" || flmFilter === "8" || flmFilter === '3' ?
                            <>
                                <Row><h4>Report Period</h4></Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={fromDateFlm}
                                            onChange={(date) => setFromDateFlm(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </> : null
                    }
                    {
                        (flmFilter === "1" || flmFilter === "8" || flmFilter === '3') ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={toDateFlm}
                                            onChange={(date) => setToDateFlm(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </> : null}
                    {flmFilter === "1" || flmFilter === "8" ? <>
                        <Row>
                            <Col>
                                <Form.Check
                                    size='sm'
                                    type="checkbox"
                                    label="Show inter branch "
                                    checked={interbranch}
                                    onChange={() => setInterbranch(!interbranch)}
                                />
                            </Col>
                        </Row>
                    </> : null}
                    {flmFilter === '3' ? <Row>
                        <Col>
                            <Form.Check
                                size='sm'
                                type="checkbox"
                                label="CN"
                                checked={filterCN}
                                onChange={() => setFilterCN(!filterCN)}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                size='sm'
                                type="checkbox"
                                label="TC"
                                checked={filterTC}
                                onChange={() => setFilterTC(!filterTC)}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                size='sm'
                                type="checkbox"
                                label="VTM"
                                checked={filterVTM}
                                onChange={() => setFilterVTM(!filterVTM)}
                            />
                        </Col>
                    </Row> : null}

                    {flmFilter === '3' ? <>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                <Form.Select value={flmViewType} onChange={e => setFlmViewType(e.target.value)}
                                    required>
                                    <option value="V">View</option>
                                    <option value="D">Download</option>
                                    {/* <option value="P">PDF</option> */}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </> : null}
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                    </Col>
                </Row>
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Flm1_report;