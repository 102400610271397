import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import Login_header from '../Login_header';
import Menu from '../Menu';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import * as Common from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';


const Accept_interbranch = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [headerData, setHeaderData] = useState([]);
    const [innerData, setInnerData] = useState([]);

    const [isMsgBox, setMsgBox] = useState(false);
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [isErrBox, setErrBox] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [msgText, setMsgText] = useState("");
    const [errText, setErrText] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [showModalView, setShowModalView] = useState(false);

    const [radioType, setRadioType] = useState('L');
    const [acceptIBLocation, setAcceptIBLocation] = useState(null);
    const [fromLocationCN, setFromLocationCN] = useState("")//C for code and N for name
    const [toLocation, setToLocation] = useState("");
    const [toLocationCode, setToLocationCode] = useState(0);
    const [locationCode, setLocationCode] = useState(0);
    const [acceptIBFromDate, setAcceptIBFromDate] = useState(new Date());
    const [acceptIBToDate, setAcceptIBToDate] = useState(new Date());
    const [acceptIBStatus, setAcceptIBStatus] = useState('P');
    const [acceptIBDate, setAcceptIBDate] = useState(new Date());
    const [srNo, setSrNo] = useState(0);
    const [type, setType] = useState("");//VTCOUT,IB,CNOUT,CHQOUT
    const [groupCode, setGroupCode] = useState(0);
    const [accountCode, setAccountCode] = useState(0);
    const [acceptIBTotalAmount, setAcceptIBTotalAmount] = useState(0);
    const [fromLocation, setFromLocation] = useState(0);
    const [viewDateUnloc, setViewDateUnloc] = useState(false);
    // const tableHeader = {'VTCOUT'=>['']}




    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setAcceptIBLocation(response);
                response != null && handleChangeLocation(response);
            });

            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_ACCEPT_BRANCH'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeLocation = (value) => {
        setAcceptIBLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setFromLocationCN(value.value);
        sessionStorage.activeBranch = loc[1];
    }


    const list = () => {
        if (radioType === 'L' && acceptIBLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else {
            const obj = {
                filterRadioType: radioType,
                filterLocation: locationCode,
                filterFromDate: Common.dateYMD(acceptIBFromDate),
                filterToDate: Common.dateYMD(acceptIBToDate),
                filterStatus: acceptIBStatus,
            }
            Common.callApi(Common.apiAcceptInterBranch, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }


    const viewList = (res) => {
        // srno, type, location, fromLocation, toLocation, groupCode, accountCode, totalAmount
        // res.tol_from_srno, res.tol_from_type, res.tol_from_loc, res.fromlocation, res.tolocation, res.hdr_group, res.hdr_ledger, res.hdr_forex_total
        if (res.tol_from_type === 'CHQOUT') {
            setFromLocationCN(res.tol_from_location);
            setToLocation(res.tolocation);
            setSrNo(res.tol_from_srno);
            setType(res.tol_from_type);
            // setGroupCode(res.hdr_group);
            // setAccountCode(res.hdr_ledger);
            setAcceptIBTotalAmount(res.hdr_forex_total);
            setShowModalView(true);
            const obj = {
                srno: res.tol_from_srno,
                type: res.tol_from_type,
                location: locationCode,
            }
            Common.callApi(Common.apiAcceptInterBranch, [sid, 'view', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setInnerData(resp);
            });
        }
    }


    // const handleAccept = (res) => {
    //     setShowModal(true);
    //     setSrNo(res.tol_from_srno);
    //     setToLocationCode(res.tol_to_loc);
    //     setType(res.tol_from_type);
    //     setGroupCode(res.hdr_group)
    //     setAccountCode(res.hdr_ledger);
    //     setFromLocation(res.tol_from_loc);
    // }

    const handleAccept = (res) => {
        console.log(res)
        // location, srno, toLocation, type
        // res.tol_from_loc, res.tol_from_srno, res.tol_to_loc, res.tol_from_type
        // setLocationCode(location);// testing i dont want know why we reset location.
        setViewDateUnloc(res.new_date);
        const dateA = new Date(res.new_date);
        const dateB = new Date(Common.dateYMD(new Date()));
        // Calculate the difference in milliseconds
        // console.log(dateA);
        // console.log(dateB);
        const differenceInMilliseconds = dateB - dateA;
        // Convert milliseconds difference to days
        console.log(differenceInMilliseconds);
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
        const obj1 = {
            srno: res.tol_from_srno,
            type: res.tol_from_type,
            pageright: 'TRAN_ACCEPT_BRANCH'
        }
        console.log(differenceInDays);
        console.log(Common.editDateLimit);
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj1)], (result) => {
            let resp = JSON.parse(result);
            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setShowModal(true);
                setSrNo(res.tol_from_srno);
                // setLocationCode(location);
                setToLocationCode(res.tol_to_loc);
                setGroupCode(res.hdr_group);
                setFromLocation(res.tol_from_loc);
                setAccountCode(res.hdr_ledger);
                setType(res.tol_from_type);
            } else {
                alert("Transaction is locked.");
            }
        });
    }


    const handleChanges = () => {
        const obj = {
            location: locationCode,
            type: type,
            srno: srNo,
            date: Common.dateYMD(acceptIBDate),
            toLocation: toLocationCode,
            groupCode: groupCode,
            accountCode: accountCode,
            fromLocation: fromLocation,

            filterRadioType: radioType,
            filterLocation: locationCode,
            filterFromDate: Common.dateYMD(acceptIBFromDate),
            filterToDate: Common.dateYMD(acceptIBToDate),
            filterStatus: acceptIBStatus,
        }
        Common.callApi(Common.apiAcceptInterBranch, [sid, 'save', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setHeaderData(resp);
        })
        setShowModal(false);
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Accept Inter Branch</h2>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-4 col-6'>
                        <Form.Group>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Form.Check checked={radioType === "L"} onChange={() => setRadioType("L")} type='radio' label="Location" />
                                </Col><Col>
                                    <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                </Col><Col>
                                    <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="Status" />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    {
                        radioType === 'L' ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={acceptIBLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            </> :
                            radioType === 'D' ?
                                <>

                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                            <DatePicker className="form-control"
                                                selected={acceptIBFromDate}
                                                onChange={(date) => setAcceptIBFromDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                                dropdownMode="select"
                                                peekNextMonth
                                                customInput={
                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                            <DatePicker className="form-control"
                                                selected={acceptIBToDate}
                                                onChange={(date) => setAcceptIBToDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                                dropdownMode="select"
                                                peekNextMonth
                                                customInput={
                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </> :
                                radioType === 'S' ?
                                    <>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Status<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={acceptIBStatus} onChange={e => setAcceptIBStatus(e.target.value)}
                                                    required>
                                                    <option value="P">Pending</option>
                                                    <option value="D">Done</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </> : null
                    }
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    {userRight.edit === "1" ? <th></th> : null}
                                    <th>SrNo</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    {userRight.edit === "1" ? <th></th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    headerData.map((res) => (
                                        <tr key={res.tol_srno}>
                                            {userRight.edit === "1" ?
                                                <td align='center'>
                                                    <span className="handCursor colorThemeBlue" onClick={() => viewList(res)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>
                                                </td>
                                                : null
                                            }
                                            <td>{res.tol_srno}</td>
                                            <td>{res.tol_from_location}</td>
                                            <td>{res.tol_to_location}</td>
                                            <td>{res.tol_from_type}</td>
                                            <td>{res.tol_from_date}</td>
                                            <td>{res.tol_status === 'P' ? 'Pending' : 'Done'}</td>
                                            <td align='center'> 
                                            {/* {
                                                res.tol_status === 'P' ?
                                                    <span> <Button variant='primary' size='sm' onClick={() => handleAccept(res)}>Accept</Button>  </span> : null
                                            } */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>
            </div>
            <div>
                <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Enter Date:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label>
                            <DatePicker className="form-control"
                                selected={acceptIBDate}
                                onChange={(date) => setAcceptIBDate(date)}
                                minDate={new Date(startDate)}
                                maxDate={new Date(endDate)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleChanges()}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal size='lg' show={showModalView} onHide={() => setShowModalView(false)} animation={false} centered>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col className='col-md-4 col-6'>From Location : {fromLocationCN}</Col>
                                <Col className='col-md-4 col-6'>To Location : {toLocation}</Col>
                                {
                                    type === 'CNOUT' ?
                                        <>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Total Amount</Form.Label>
                                                    <Form.Control
                                                        value={acceptIBTotalAmount}
                                                        disabled
                                                        placeholder="Total" />
                                                </Form.Group>
                                            </Col>
                                        </> : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                {
                                                    type === 'CNOUT' ?
                                                        <>
                                                            <th>Currency</th>
                                                            <th>Denomination</th>
                                                            <th>Quantity</th>
                                                            <th>Rate</th>
                                                            <th>Amount</th>
                                                        </> :
                                                        type === 'CHQOUT' ?
                                                            <>
                                                                <th>Quantity</th>
                                                                <th>From</th>
                                                                <th>To</th>
                                                                <th>Date</th>
                                                                <th>Bank</th>
                                                                <th>Action</th>
                                                            </> :
                                                            type === 'IB' ?
                                                                <>
                                                                    <th>Type</th>
                                                                    <th>Location</th>
                                                                    <th>Date</th>
                                                                    <th>Credit</th>
                                                                    <th>Debit</th>
                                                                    <th>Group</th>
                                                                    <th>Account</th>
                                                                    <th>Account Name</th>
                                                                </> :
                                                                <>
                                                                    <th>ISD</th>
                                                                    <th>Prefix</th>
                                                                    <th>Issuer Name</th>
                                                                    <th>Quantity</th>
                                                                    <th>From</th>
                                                                    <th>To</th>
                                                                </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                type === 'VTCOUT' || type === 'CNOUT' || type === 'IB' ?
                                                    <>
                                                        {
                                                            innerData.map((res) =>
                                                                <tr key={res.prd_line_no}>
                                                                    {
                                                                        type === 'VTCOUT' ?
                                                                            <>
                                                                                <td>{res.prd_isd}</td>
                                                                                <td>{res.prd_prefix}</td>
                                                                                <td>{res.mvli_ledger_name}</td>
                                                                                <td>{res.prd_qty}</td>
                                                                                <td>{res.prd_from}</td>
                                                                                <td>{res.prd_to}</td>
                                                                            </> :
                                                                            type === 'IB' ?
                                                                                <>
                                                                                    <td>{res.tfd_type}</td>
                                                                                    <td>{res.entity_name}</td>
                                                                                    <td>{res.formatted_date}</td>
                                                                                    <td>{res.tfd_flag === 'D' ? res.tfd_amount : ''}</td>
                                                                                    <td>{res.tfd_flag === 'C' ? -1 * res.tfd_amount : ''}</td>
                                                                                    <td>{res.tfd_group}</td>
                                                                                    <td>{res.tfd_account}</td>
                                                                                    <td>{res.mvli_ledger_name}</td>

                                                                                </> :
                                                                                type === 'CNOUT' ?
                                                                                    <>
                                                                                        <td>{res.prd_isd}</td>
                                                                                        <td>{res.prd_deno}</td>
                                                                                        <td>{res.prd_qty}</td>
                                                                                        <td>{res.prd_rate}</td>
                                                                                        <td>{res.prd_amount}</td>
                                                                                    </> : null
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    </> :
                                                    <>
                                                        {
                                                            innerData.map((res) =>
                                                                <tr key={res.tcb_line_no}>
                                                                    <td>{res.tcb_quantity}</td>
                                                                    <td>{res.tcb_from}</td>
                                                                    <td>{res.tcb_to}</td>
                                                                    <td>{res.tcb_date}</td>
                                                                    <td>{res.bank}</td>
                                                                    <td align='center'> {
                                                                        res.tol_status === 'P' ?
                                                                            <span>
                                                                                <Button variant='primary' size='sm' onClick={() => handleAccept(res)}>Accept</Button>
                                                                            </span> : null
                                                                    }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModalView(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Accept_interbranch
