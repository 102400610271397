import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import "../../../mcss/main.css";
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from "../../Picklist";
function Modification_commission() {


    const navigate = useNavigate();
    const sid = sessionStorage.getItem("sessionId");
    const userSrno = sessionStorage.getItem("userSrno");
    const [scrMode, setScrMode] = useState('');
    const [commissionDisable, setCommissionDisable] = useState(false);
    const [filtertransaction, setFiltertransaction] = useState('PP');
    const [onceRun, setOnceRun] = useState(false);
    const [pProductList, setPProductList] = useState([]);
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [pAgent, setPAgent] = useState(null);
    const [ppLocationValue, setPPLocationValue] = useState(0);
    const [tranAuditTrail, setTranAuditTrail] = useState([]);

    const [msgTextYN, setMsgTextYN] = useState("");

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [ppNewDate, setPPNewDate] = useState(new Date());

    const [ppLocation, setPPLocation] = useState(null);
    const [userRight, setUserRight] = useState([]);

    const [hdrLocation, setHdrLocation] = useState("");
    const [ppSrnoFrom, setPPSrnoFrom] = useState("");
    const [ppSrnoTo, setPPSrnoTo] = useState("");
    const [commissionData, setCommissionData] = useState([]);
    const [commissionDataToDelete, setCommissionDataToDelete] = useState([]);
    const [editbtnView, setEditbtnView] = useState(true);
    const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [saveMsgTextYN, setSaveMsgTextYN] = useState("Are you sure you want to save?");
    const [deleteMsgTextYN, setDeleteMsgTextYN] = useState("Are you sure you want to delete?");


    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setPPLocation(response);
            response != null && handleLocationChange(response);
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'COMMISSION_MODIFICATION'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        const sessionValues = sessionStorage.getItem('values');
        if (sessionValues) {
            const sessionData = JSON.parse(sessionValues);
            if (sessionData.md_type == "PURCHASE") {
                setScrMode('A');
                // setNServiceType('R');
                Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
                    let resp = JSON.parse(result);
                });
                sessionStorage.removeItem('values');
            } else {
                setScrMode('');
            }
        }
    }, []);
    const handleLocationChange = (value) => {
        setPPLocation(value);
        var id = value.id;
        var location = id.split("^");
        setPPLocationValue(location[1]);
        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const applyFilter = (srno, location, prdtype) => {
        $(".loader").show();
        // setCommissionDisable(true);
        var msg = [], i = 0;
        if (ppSrnoFrom === "") {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ppSrnoFrom === "" ? "Srno" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            var obj = {
                location: ppLocationValue,
                transaction: filtertransaction,
                srnofrom: ppSrnoFrom,
                srnoto: ppSrnoTo
            }
            Common.callApi(Common.apiModiCommission, [sid, "trandetailview", JSON.stringify(obj)], function (result) {
                let resp = JSON.parse(result);
                // console.log(resp);
                if (resp.trandetailproduct.length > 0) {
                    setPProductList(resp.trandetailproduct);
                    $(".loader").hide();
                } else {
                    $(".loader").hide();
                    setMsgText("Data Not Found");
                    setMsgBox(true);

                }
            })
        }

    }
    const handleAgentChange = (value, uniquekey) => {
        // console.log(value);
        const itemToUpdate = pProductList.find(item => item.uniquekey === uniquekey);

        if (!itemToUpdate) return;

        const { prd_qty, prd_deno, prd_rate, prd_isd, prd_product } = itemToUpdate;

        Common.callApi(Common.apiLedger, [sid, "getisdcommission", value.id, prd_isd, prd_product], result => {
            const resp = JSON.parse(result);
            let commvalue;

            if (resp.type === "P" || resp.type === "R") {
                commvalue = calcCommission(resp.value, resp.type, prd_qty, prd_deno, prd_rate);
                console.log("Calculated commission using type P/R");
            } else {
                commvalue = resp.value;
                console.log("Directly set commission value for type F");
            }

            // Update commission value and type in specific row
            setPProductList(prevData =>
                prevData.map(row =>
                    row.uniquekey === uniquekey
                        ? { ...row, prd_agent_name: value.label, prd_agent_group: value.id.split('^')[1], prd_agent_ledger: value.id.split('^')[2], prd_commission_value: commvalue, prd_commission: resp.value, prd_commission_type: resp.type }
                        : row
                )
            );
        });
    };
    //console.log(pProductList);
    const commissionTypeChange = (v, uniquekey) => {
        setPProductList(prevData =>
            prevData.map(row =>
                row.uniquekey === uniquekey
                    ? { ...row, prd_commission_type: v, prd_commission_value: "", prd_commission: "" }
                    : row
            )
        );
    };

    const handleCommissionCalc = (v, uniqueKey) => {
        const itemToUpdate = pProductList.find(item => item.uniquekey === uniqueKey);
        if (!itemToUpdate) return; // Check if item exists

        const { prd_qty: quantity, prd_deno: denomination, prd_rate: rate, prd_commission_type: commissionType } = itemToUpdate;
        setPAgent(v);

        Common.validateDecValue(v, (validatedValue) => {
            const calculatedValue = calcCommission(validatedValue, commissionType, quantity, denomination, rate);

            setPProductList(prevList =>
                prevList.map(item =>
                    item.uniquekey === uniqueKey
                        ? { ...item, prd_commission: validatedValue, prd_commission_value: calculatedValue }
                        : item
                )
            );

            // Update `commValue` to be used directly in the UI for the specific uniqueKey
            setCommValue(prev => ({ ...prev, [uniqueKey]: calculatedValue }));
        });
    };




    const deleteCommission = (item) => {
        $(".loader").show();
        setDeleteDialogOpen(false);
        const obj1 = {
            TabType: "Commission",
            AType: "D",
            TLoc: ppLocationValue,
            ALineNo: 1,
            TType: item.prd_type,
            Product: item.prd_product,
            ISD: item.prd_isd,
            Deno: item.prd_deno,
            Quantity: item.prd_qty,
            CommsnType: item.prd_commission_type,
            AgentGroupCode: item.prd_agent_group,
            AgentLedgerCode: item.prd_agent_ledger,
            CommsnRate: item.prd_commission == "" ? 0 : item.prd_commission,
            CommsnAmount: item.prd_commission_value == "" ? 0 : item.prd_commission_value,

        }
        const updatedTranAuditTrail = [...tranAuditTrail, obj1];
        Common.callApi(Common.apiModiCommission, [sid, "deletecommission", JSON.stringify(item), JSON.stringify(updatedTranAuditTrail)], function (result) {
            let resp = JSON.parse(result);
            setPProductList(resp.trandetailproduct);
            setTranAuditTrail([]);
            $(".loader").hide();
            // setCommissionDisable(true);
            setPProductList(prevData =>
                prevData.map(row =>
                    row.uniquekey === item.uniquekey
                        ? { ...row, commission_disable: "1" }
                        : row
                )
            );
            setMsgText("Success");
            setMsgBox(true);
            setEditbtnView(true);
        })

    }
    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = 0;
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        return comm;
    }
    const saveCommission = (item) => {
        $(".loader").show();
        setSaveDialogOpen(false);
        const obj2 = {
            TabType: "Commission",
            AType: "A",
            TLoc: ppLocationValue,
            ALineNo: 1,
            TType: item.prd_type,
            Product: item.prd_product,
            ISD: item.prd_isd,
            Deno: item.prd_deno,
            Quantity: item.prd_qty,
            CommsnType: item.prd_commission_type,
            AgentGroupCode: item.prd_agent_group,
            AgentLedgerCode: item.prd_agent_ledger,
            CommsnRate: item.prd_commission === "" ? 0 : item.prd_commission,
            CommsnAmount: item.prd_commission_value === "" ? 0 : item.prd_commission_value,
        };

        const updatedTranAuditTrail = [...tranAuditTrail, obj2];

        Common.callApi(Common.apiModiCommission, [sid, "updatecommission", JSON.stringify(item), JSON.stringify(updatedTranAuditTrail)], function (result) {
            let resp = JSON.parse(result);
            setPProductList(resp.trandetailproduct);
            $(".loader").hide();
            // setCommissionDisable(true);
            setPProductList(prevData =>
                prevData.map(row =>
                    row.uniquekey === item.uniquekey
                        ? { ...row, commission_disable: "1" }
                        : row
                )
            );
            setMsgText("Success");
            setMsgBox(true);
            setEditbtnView(true);
            setTranAuditTrail([]);

        });
    };

    const handleEditRequest = (item) => {
        setPProductList(prevData =>
            prevData.map(row =>
                row.uniquekey === item.uniquekey
                    ? { ...row, commission_disable: "0" }
                    : row
            )
        );
        // setCommissionDisable(false);
        setEditbtnView(false);
    }
    const confirmCommission = (item) => {
        var msg = [], i = 0;
        if (item.prd_agent_group === "" || item.prd_agent_ledger === "" || item.prd_commission_type === "" || item.prd_commission_value === "" || item.prd_commission === "") {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (item.prd_agent_group === "" ? "Agent" : '');
            msg[i++] = (item.prd_agent_ledger === "" ? "Agent" : '');
            msg[i++] = (item.prd_commission_type === "" ? "Commission Type" : '');
            msg[i++] = (item.prd_commission_value === "" ? "Commission Value" : '');
            msg[i++] = (item.prd_commission === "" ? "Commission" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setCommissionData(item);
            setSaveDialogOpen(true);
        }

    }
    const confirmdeletion = (item) => {
        setCommissionDataToDelete(item);
        setDeleteDialogOpen(true);
    }

    return (

        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Transaction Modification Commission </h3>
                    </Col>
                </Row>
                {scrMode === '' ?
                    <Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                    <AsyncSelect
                                        cacheOptions={false}
                                        defaultOptions={false}
                                        value={ppLocation}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleLocationChange}
                                    // isDisabled={branchDisable === "true"}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                            <Col className="col-md-3 col-6">
                                <Form.Label>Transaction</Form.Label>
                                <Form.Select
                                    value={filtertransaction}
                                    onChange={(e) => {
                                        setFiltertransaction(e.target.value);
                                    }}>
                                    <option value="PP">PP</option>
                                    <option value="PB">PB</option>
                                    <option value="SP">SP</option>
                                    <option value="SB">SB</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>SrNo<span className="colorThemeRed">*</span></Form.Label>
                                    <Form.Control type='text' maxLength={10} value={ppSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setPPSrnoFrom)} />
                                </Form.Group>
                            </Col>
                            {/* <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>SrNo To<span className="colorThemeRed">*</span></Form.Label>
                                    <Form.Control type='text' maxLength={10} value={ppSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setPPSrnoTo)} />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>&nbsp;</Row>

                        <Row>
                            <Col >
                                <Button style={{ marginRight: '10px' }} variant="outline-primary" className="buttonStyle" size="sm" onClick={applyFilter}> List</Button>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Table responsive bordered striped>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Isd Code</th>
                                        <th>Isd Name</th>
                                        <th>Denomination</th>
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>USD Rate</th>
                                        <th>USD Amount</th>
                                        <th>Prefix</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pProductList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {/* Product Row */}
                                            <tr>
                                                <td>{item.prd_product}</td>
                                                <td>{item.prd_isd}</td>
                                                <td>{item.isd_name}</td>
                                                <td>{item.prd_deno}</td>
                                                <td>{item.prd_qty}</td>
                                                <td>{item.prd_rate}</td>
                                                <td>{item.prd_amount}</td>
                                                <td>{item.prd_usd_rate}</td>
                                                <td>{item.prd_usd_amount}</td>
                                                <td>{item.prd_prefix}</td>
                                                <td>{item.prd_from}</td>
                                                <td>{item.prd_to}</td>
                                            </tr>

                                            {/* Commission Row with Editable Fields */}
                                            <tr>
                                                <td colSpan="13">
                                                    <table style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Agent</th>
                                                                <th>Type</th>
                                                                <th>Commission</th>
                                                                <th>Commission Value</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {(userRight.edit === "1" && !editbtnView) ?
                                                                        <span onClick={() => confirmdeletion(item)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span> : null}
                                                                </td>
                                                                <td>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}

                                                                        value={{ label: item.prd_agent_name, id: item.prd_agent_ledger }}
                                                                        getOptionLabel={e => e.label}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', ppLocationValue)}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={(value) => handleAgentChange(value, item.uniquekey)}
                                                                        isDisabled={item.commission_disable === "1"}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Select
                                                                        value={item.prd_commission_type}
                                                                        onChange={(e) => commissionTypeChange(e.target.value, item.uniquekey)}
                                                                        disabled={item.commission_disable === "1"}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option value="P">Paisa</option>
                                                                        <option value="R">Percentage</option>
                                                                        <option value="F">Flat</option>
                                                                    </Form.Select>
                                                                </td>

                                                                <td>
                                                                    <Form.Control
                                                                        type="text"
                                                                        maxLength={5}
                                                                        value={item.prd_commission}
                                                                        onChange={e => handleCommissionCalc(e.target.value, item.uniquekey)}
                                                                        disabled={item.commission_disable === "1"}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Control
                                                                        disabled
                                                                        value={item.prd_commission_value}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {(userRight.edit === "1" && !editbtnView) ?
                                                                        <span onClick={() => confirmCommission(item)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faSave} />
                                                                        </span> : null}
                                                                    {(userRight.edit === "1" && editbtnView) ?
                                                                        <span onClick={() => handleEditRequest(item)} className='handCursor colorThemeBlue'>
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </span> : null}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>


                        </Row>

                    </Row> : null}
            </Container >
            <DialogYesNo isOpen={isSaveDialogOpen} onYes={(e) => saveCommission(commissionData)} onNo={(e) => setSaveDialogOpen(false)}>
                {saveMsgTextYN}
            </DialogYesNo>
            <DialogYesNo isOpen={isDeleteDialogOpen} onYes={(e) => deleteCommission(commissionDataToDelete)} onNo={(e) => setDeleteDialogOpen(false)}>
                {deleteMsgTextYN}
            </DialogYesNo>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

        </>
    )
}

export default Modification_commission;

