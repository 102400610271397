import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';
import Tran_data_unlock_request from '../Tran_data_unlock_request';


const Tran_trf_out_vtc = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [outVTCFromLocation, setOutVTCFromLocation] = useState(null);
    const [outVTCToLocation, setOutVTCToLocation] = useState(null);
    const [fromLocation, setFromLocation] = useState("");
    const [fromLocationCode, setFromLocationCode] = useState(0);
    const [toLocation, setToLocation] = useState("");
    const [toLocationCode, setToLocationCode] = useState(0);

    const [radioType, setRadioType] = useState("N");
    const [outVTCDate, setOutVTCDate] = useState(new Date());
    const [outVTCFromDate, setOutVTCFromDate] = useState(new Date());
    const [outVTCToDate, setOutVTCToDate] = useState(new Date());
    const [outVTCFromSrno, setOutVTCFromSrno] = useState('');
    const [outVTCToSrno, setOutVTCToSrno] = useState('');

    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [subInnerData, setSubInnerData] = useState([]);

    const [issuer, setIssuer] = useState(null);
    const [outVTCQuantity, setOutVTCQuantity] = useState("");
    const [outVTCFrom, setOutVTCFrom] = useState("");
    const [outVTCTo, setOutVTCTo] = useState("");
    const [outVTCPrefix, setOutVTCPrefix] = useState("");
    const [currency, setCurrency] = useState(null);
    const [currencyType, setCurrencyType] = useState("1");
    const [groupCode, setGroupCode] = useState(0);
    const [accountCode, setAccountCode] = useState(0);
    const [currencyCode, setCurrencyCode] = useState("");
    const [currencyName, setCurrencyName] = useState("");
    const [srNo, setSrNo] = useState(0);
    const [lineNo, setLineNo] = useState(0);
    const [issuerName, setIssuerName] = useState("");
    const [outVTCFrom1, setOutVTCFrom1] = useState("");
    const [counter, setCounter] = useState(0);

    const [viewSrno, setViewSrno] = useState(0);
    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setOutVTCFromLocation(response);
                response != null && handleChangeFromLocation(response);
            });

            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_TRF_OUT_VTC'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeFromLocation = (value) => {
        setOutVTCFromLocation(value);
        const loc = value.id.split('^');
        setFromLocationCode(loc[1]);
        setFromLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }


    const handleChangeToLocation = (value) => {
        setOutVTCToLocation(value);
        const loc = value.id.split('^');
        setToLocationCode(loc[1]);
        setToLocation(loc[2]);
    }


    const handleCurrencyChange = (value) => {
        setCurrency(value);
        let curr = value.id.split('^');
        setCurrencyCode(curr[1]);
        setCurrencyName(curr[2]);
    }


    const handleIssuerChange = (value) => {
        console.log(value);
        setIssuer(value);
        const iss = value.id.split('^');
        setGroupCode(iss[1]);
        setAccountCode(iss[2]);
        setIssuerName(iss[5]);
        //when issuer chnage than quantity,from,to should empty 
        setOutVTCQuantity("");
        setOutVTCFrom("");
        setOutVTCTo("");
    }


    const handleQuantity = (e) => {
        Common.validateNumValue(e.target.value, setOutVTCQuantity);
        if (outVTCFrom1 === "" || e.target.value === "") {
            setOutVTCTo("");
        } else {
            setOutVTCTo(1 * e.target.value + (1 * outVTCFrom1 - 1));
        }
    }


    const handleProductBankValue = (value) => {
        setOutVTCFrom(value);
        console.log(value);
        const from = value.id.split('^');
        setOutVTCPrefix(from[4]);
        setOutVTCFrom1(from[5]);
        if (from[5] === "" || outVTCQuantity === "") {
            setOutVTCTo("");
        } else {
            setOutVTCTo(1 * from[5] - 1 + (1 * (outVTCQuantity)));
        }
    }

    const list = () => {
        var msg = [], i = 0;
        if (outVTCFromLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if (radioType === 'S' && (outVTCFromSrno === "" || outVTCToSrno === "")) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (radioType === 'S' && outVTCFromSrno === "" ? " From." : '');
            msg[i++] = (radioType === 'S' && outVTCToSrno === "" ? " To." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            console.log(outVTCFromLocation);
            const obj = {
                filterRadioType: radioType,
                filterLocation: fromLocationCode,
                filterFromDate: Common.dateYMD(outVTCFromDate),
                filterToDate: Common.dateYMD(outVTCToDate),
                filterFromSrno: outVTCFromSrno,
                filterToSrno: outVTCToSrno,
            }
            Common.callApi(Common.apiTranTransferOutVTC, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                // console.log(resp[0].hdr_srno);
            });
        }
    }

    const addNew = () => {
        var date = Common.dateYMD(outVTCDate);
        var msg = [], i = 0;
        if (outVTCFromLocation === null || outVTCToLocation === null) {
            msg[i++] = Common.getMessage('MSG0006');
            msg[i++] = (outVTCFromLocation === null ? " From Location." : '');
            msg[i++] = (outVTCToLocation === null ? " To Location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (fromLocation === toLocation) {
            setMsgText("From location and To location should not be same!.");
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setCurrency(null);
            setIssuer(null);
            setInnerData([]);
            setOutVTCQuantity('');
            setOutVTCFrom('');
            setOutVTCTo('');
            setScrMode('A');
            setLineNo(0);//a new product is added in the list.
            setSrNo(0);//new entry means srno should be 0
        }
    }
    //check same ISD same ISSUER -> FROM TO should not be conflict.
    function isIsdFromTo(obj) {
        let isLie = true;
        innerData.find((data) => {
            if ((data.prd_isd === obj.prd_isd) && (data.prd_group === obj.prd_group) && (data.prd_account === obj.prd_account)) {
                if (((data.prd_from * 1) <= (obj.prd_from * 1) && (obj.prd_from * 1) <= (data.prd_to * 1)) ||
                    ((data.prd_from * 1) <= (obj.prd_to * 1) && (obj.prd_to * 1) <= (data.prd_to * 1))) {
                    isLie = false;
                }
            }
        })
        return isLie;
    }

    const addDetail = () => {
        var msg = [], i = 0;
        if (outVTCQuantity === "" || outVTCFrom === "" || issuer === null || (currencyType === "1" && currency === null)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((currencyType === "1" && currency === null) ? " Select Currency." : '');
            msg[i++] = (issuer === null ? " Select Issuer." : '');
            msg[i++] = (outVTCQuantity === "" ? " Quantity." : '');
            msg[i++] = (outVTCFrom === "" ? " From." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (outVTCQuantity >= 101) {
            setMsgText("Quantity Should be less than 101!");
            setMsgBox(true);
        } else {
            const fromDataArray = outVTCFrom.id.split('^');
            const obj = {
                prd_location: fromLocationCode,
                prd_from: outVTCFrom1,
                prd_to: outVTCTo,
                type: 'VTCIN',
                prd_isd: (currencyType === "1") ? currencyCode : '',
                // new field 
                prd_qty: outVTCQuantity,
                prd_account: accountCode,//setOutVTCFrom
                prd_group: groupCode,
            };
            //check same ISD same ISSUER -> FROM TO should not be conflict.
            if (!isIsdFromTo(obj)) {
                setMsgText(`Already exist From To in the List`);
                setMsgBox(true);
                return;
            }
            Common.callApi(Common.apiTranTransferOutVTC, [sid, 'vtcList', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                if (resp === outVTCQuantity) {
                    const obj = {
                        unique_no: Common.getRandomString(5),
                        prd_qty: outVTCQuantity,
                        prd_from: outVTCFrom1,
                        prd_to: outVTCTo,
                        prd_location: fromLocationCode,
                        prd_issuername: issuerName,
                        prd_isd: (currencyType === "1") ? currencyCode : '',
                        prd_date: Common.dateYMD(outVTCDate),
                        prd_type: 'VTCOUT',
                        prd_prefix: outVTCPrefix,
                        prd_product: 'VTC',
                        prd_group: groupCode,
                        prd_account: accountCode,
                        prd_line_no: (1 * lineNo) + 1,
                        tpb_in_srno: fromDataArray[1],//header srno
                        tpb_in_line_no: fromDataArray[2],//tran_product_bank line no
                        del: "Y",
                    };
                    setInnerData(Common.arrayAddItem(innerData, obj));
                    const obj2 = {
                        ALineNo: tranAuditTrail.length + 1,
                        AType: "A",
                        TLoc: fromLocationCode,
                        TType: 'VTCOUT',
                        Product: 'VTC',
                        IssuerName: issuerName,
                        ISD: (currencyType === "1") ?
                            currencyCode : '',
                        Quantity: outVTCQuantity,
                        ProductFrom: outVTCFrom1,
                        ProductTo: outVTCTo,
                        ProductPrefix: outVTCPrefix,
                        // ProductGroup: groupCode,
                        ProductAccout: accountCode,
                        // CommsnType: commType,
                        AgentGroupCode: groupCode,
                        Account: accountCode,

                    }
                    if (userRight.edit === "1" && scrMode === 'E') {
                        setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
                    }
                    console.log(innerData);
                    setLineNo(lineNo + 1);
                    setCurrency(null);
                    setIssuer(null);
                    setOutVTCQuantity('');
                    setOutVTCFrom('');
                    setOutVTCTo('');
                    setOutVTCPrefix('');
                } else {
                    setMsgText("Doesn't exist!");
                    setMsgBox(true);
                }
            });
        }
    }


    const deleteItem = (value) => {
        const itemToDelete = innerData.find(item => item.unique_no === value);
        if (itemToDelete) {
            const formattedItem = {
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: itemToDelete.prd_location,
                TType: 'VTCOUT',
                Product: 'VTC',
                ISD: itemToDelete.prd_isd,
                IssuerName: itemToDelete.prd_issuername,
                Quantity: itemToDelete.prd_qty,
                ProductFrom: itemToDelete.prd_from,
                ProductTo: itemToDelete.prd_to,

                ProductPrefix: itemToDelete.prd_prefix,
                ProductGroup: itemToDelete.prd_group,
                ProductAccout: itemToDelete.prd_account,

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        setInnerData(Common.arrayRemoveItem(innerData, "unique_no", value));
        setCounter(counter + 1);
    }


    const saveData = () => {
        if (innerData.length < 1) {
            setMsgText("Empty List!");
            setMsgBox(true);
        } else {
            const obj = {
                group: groupCode,
                account: accountCode,
                fromLocation: fromLocationCode,
                toLocation: toLocationCode,
                srno: srNo,
                date: Common.dateYMD(outVTCDate),
                data: innerData,
                status: 'P',
                type: 'VTCOUT',

                filterRadioType: radioType,
                filterLocation: fromLocationCode,
                filterFromDate: Common.dateYMD(outVTCFromDate),
                filterToDate: Common.dateYMD(outVTCToDate),
                filterFromSrno: outVTCFromSrno,
                filterToSrno: outVTCToSrno,
            }
            Common.callApi(Common.apiTranTransferOutVTC, [sid, 'save', JSON.stringify(obj), JSON.stringify(tranAuditTrail)], (result) => {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setHeaderData(resp.outVTCArr);
                    // setOutVTCFromLocation(null);
                    setInnerData([]);
                    setScrMode("");
                    setTranAuditTrail([]);
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'VTCOUT',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_TRF_OUT_VTC'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }


    const viewList = (hdrSrno, location, type, vdate) => {
        setSrNo(hdrSrno);
        setScrMode("Q");
        setViewDate(vdate);
        setViewSrno(hdrSrno);
        const obj = {
            srno: hdrSrno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiTranTransferOutVTC, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.outVTCList);
                setLineNo(resp.maxlineno);
                setFromLocationCode(resp.fromLocationCode);
                setToLocationCode(resp.toLocationCode);
                setGroupCode(resp.groupcode);
                setAccountCode(resp.ledgercode);
                setFromLocation(resp.fromLocation);
                setToLocation(resp.toLocation);
                // setIssuer(resp.issuer);
            }
        });
    }


    const viewDetails = (srno, lineno, location, type) => {
        const obj = {
            srno: srno,
            lineno: lineno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiTranTransferOutVTC, [sid, 'viewDtls', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setSubInnerData(resp);
        });
        setShowModal(true);
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    const editData = () => {
        setScrMode("E");
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'VTCOUT',
            pageright: 'TRAN_TRF_OUT_VTC'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'VTCOUT', trannumber: viewSrno, location: fromLocationCode, tranDate: Common.dateYMD(currDate) });
            }
        });


    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "VTCOUT", trannumber: viewSrno, trancategory: "T", location: fromLocationCode });
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Transfer Out VTC</h2>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={outVTCFromLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeFromLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={outVTCDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setOutVTCDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To Location<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={outVTCToLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLocationPicklist(search)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleChangeToLocation}
                                                    // isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={outVTCFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setOutVTCFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={outVTCToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setOutVTCToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={outVTCFromDate}
                                                                onChange={(date) => setOutVTCFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={outVTCToDate}
                                                                onChange={(date) => setOutVTCToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) => (
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.hdr_type}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnEditRequest()}>Edit</Button>
                                        </> : null
                                    }
                                    {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                    </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-3'>
                                    <Form.Label>Sr No : {srNo}</Form.Label>
                                </Col>
                                <Col className='col-md-6 col-6'>
                                    <Form.Label>From Location : {fromLocation}</Form.Label>
                                </Col>
                                <Col className='col-md-6 col-6'>
                                    <Form.Label>To Location : {toLocation}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "E" || scrMode === "A" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Card Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={currencyType} onChange={e => setCurrencyType(e.target.value)}
                                                        required>
                                                        <option value="1">Single Currency</option>
                                                        <option value="0">Multi Currency</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            {
                                                currencyType === "1" &&
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={currency}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'VTC')} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleCurrencyChange}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            }
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Issuer<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={issuer}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', fromLocationCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleIssuerChange}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>

                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={outVTCQuantity}
                                                        onChange={(e) => handleQuantity(e)}
                                                        maxLength={Common.maximumLength}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            {/* <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Prefix</Form.Label>
                                                    <Form.Control
                                                        value={outVTCPrefix}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setOutVTCPrefix)}
                                                        maxLength={Common.prefixLength}
                                                        placeholder="Prefix" />
                                                </Form.Group>
                                            </Col> */}
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={outVTCFrom}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchProductBankPicklist(search, fromLocationCode, groupCode, accountCode, currencyCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleProductBankValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={outVTCTo}
                                                        disabled
                                                        placeholder="To" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null

                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Sr No</th>
                                                <th>ISD</th>
                                                <th>Prefix</th>
                                                <th>Issuer Name</th>
                                                <th>Quantity</th>
                                                <th>From</th>
                                                <th>To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                innerData.map((res) => (
                                                    <tr key={res.prd_line_no}>
                                                        {
                                                            userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        scrMode === 'Q' || res.del === 'N' ? null :
                                                                            <>
                                                                                <span className="handCursor colorThemeBlue" onClick={() => deleteItem(res.unique_no)} >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </span> {' '}
                                                                            </>
                                                                    }
                                                                    {
                                                                        scrMode === 'Q' ?
                                                                            <span className="handCursor colorThemeBlue" onClick={() => viewDetails(res.prd_srno, res.prd_line_no, res.prd_location, res.prd_type)}>
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </span>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td>{res.prd_line_no}</td>
                                                        <td>{res.prd_isd}</td>
                                                        <td>{res.prd_prefix}</td>
                                                        <td>{res.prd_issuername}</td>
                                                        <td>{res.prd_qty}</td>
                                                        <td>{res.prd_from}</td>
                                                        <td>{res.prd_to}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>

                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />
            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Sr No.</th>
                                                <th>Out Type</th>
                                                <th>Out Line No.</th>
                                                <th>Out Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subInnerData.map((res) =>
                                                    <tr key={res.prd_line_no}>
                                                        <td>{res.tpb_from}</td>
                                                        <td>{res.tpb_out_srno}</td>
                                                        <td>{res.tpb_out_type}</td>
                                                        <td>{res.tpb_out_line_no}</td>
                                                        <td>{res.tpb_out_location}</td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Tran_trf_out_vtc
