import { faBuildingColumns, faChartLine, faCircleRight, faHome, faLock, faRectangleList, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../mcss/menu.css';
import * as Common from "./Common";

function Menu() {
    const navigate = useNavigate();

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);

    const [allRight, setAllRight] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'allrightquery'], function (result) {
                let base64ToString;
                let resp;
                let resp1;
                try {
                    base64ToString = Buffer.from(result, "base64").toString();
                    resp = JSON.parse(base64ToString);
                } catch (error) {
                    resp = JSON.parse('{"LOGOUT": ""}');
                }
                try {
                    resp1 = JSON.parse(result);
                } catch (error) {
                    resp1 = JSON.parse('{"status": ""}');
                }

                setAllRight(resp);
                console.log(resp);
                if (resp.LOGOUT === "0" || resp1.status === "MSG0002") {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid]);

    function goToPage(id) {
        clearInterval(sessionStorage.myInterval);
        //Common.setPageTitle(0);
        switch (id) {
            case 0:
                sessionStorage.clear();
                navigate('/');
                break;
            case 'home':
                navigate('/home');
                break;
            case 'userlist':
                navigate('/user-list');
                break;
            case 'right':
                navigate('/right-list');
                break;
            case 'country':
                navigate('/country-list');
                break;
            case 'city':
                navigate('/city-list');
                break;
            case 'state':
                navigate('/state-list');
                break;
            case 'district':
                navigate('/district-list');
                break;
            case 'issuer':
                navigate('/issuer-list');
                break;
            case 'purpose':
                navigate('/purpose-list');
                break;
            case 'currency':
                navigate('/isd-list');
                break;
            case 'idtype':
                navigate('/id-type');
                break;
            case 'dealer':
                navigate('/dealer-list');
                break;
            case 'vouchergroup':
                navigate('/voucher-group');
                break;
            case 'voucherledger':
                navigate('/voucher-ledger');
                break;
            case 'region':
                navigate('/region-list');
                break;
            case 'countryclass':
                navigate('/country-class');
                break;
            case 'division':
                navigate('/division-list');
                break;
            case 'risk':
                navigate('/risk-list');
                break;
            case 'businessnature':
                navigate('/business-nature');
                break;
            case 'legalentity':
                navigate('/legal-entity');
                break;
            case 'costcenter':
                navigate('/cost-center');
                break;
            case 'gstclassification':
                navigate('/gst-classification');
                break;
            case 'msme':
                navigate('/msme');
                break;
            case 'designation':
                navigate('/designation');
                break;
            case 'executive':
                navigate('/executive');
                break;
            case 'vouchertype':
                navigate('/voucher-type');
                break;
            case 'purchasepublic':
                navigate('/purchase-from-public');
                break;
            case 'purchasebulk':
                navigate('/purchase-bulk');
                break;
            case 'individualprofile':
                navigate('/individual-profile');
                break;
            case 'groupdoc':
                navigate('/group-doc');
                break;
            case 'finyear':
                navigate('/financial-year');
                break;
            case 'sampleform':
                navigate('/sampleform');
                break;
            case 'trial':
                navigate('/tran-opn-trial');
                break;
            case 'residentialstatus':
                navigate('/residential-status');
                break;
            case 'cmrcmp':
                navigate('/tran-opn-cmrcmp');
                break;
            case 'stock':
                navigate('/tran-currency-stock');
                break;
            case 'vtc':
                navigate('/tran-opn-vtc');
                break;
            case 'bankstatement':
                navigate('/opn-bank-statement');
                break;
            case 'tc':
                navigate('/tran-opn-tc');
                break;
            case 'etc':
                navigate('/tran-opn-etc');
                break;
            case 'vtmencash':
                navigate('/vtm-encashment');
                break;
            case 'evtm':
                navigate('/tran-opn-evtm');
                break;
            case 'tc-settlement':
                navigate('/tc-settlement');
                break;
            case 'vtm-settlement':
                navigate('/vtm-settlement');
                break;
            case 'chequebookin':
                navigate('/cheque-book-in');
                break;
            case 'fa-book-entry':
                navigate('/fa-book-entry');
                break;
            case 'salespublic':
                navigate('/sale-public');
                break;
            case 'transfer-vtc':
                navigate('/transfer-vtc');
                break;
            case 'chequedeposit':
                navigate('/cheque-deposit');
                break;
            case 'chequebookout':
                navigate('/cheque-book-out');
                break;
            case 'chequebounce':
                navigate('/received-cheque-bounce');
                break;
            case 'issuechequebounce':
                navigate('/issue-cheque-bounce');
                break;
            case 'bankreconciliation':
                navigate('/bank-reconciliation');
                break;
            case 'testing':
                navigate('/testing');
                break;
            case 'manual-book':
                navigate('/manual-book');
                break;
            case 'ledger':
                navigate('/ledger');
                break;
            case 'salebulk':
                navigate('/sale-bulk');
                break;
            case 'trialbalance':
                navigate('/trial-balance');
                break;
            case 'transferoutvtc':
                navigate('/out-vtc');
                break;
            case 'transeroutcurrency':
                navigate('/out-currency');
                break;
            case 'acceptinterbranch':
                navigate('/accept-interbranch')
                break;
            case 'repfinrecvbillwisedetail':
                navigate('/rep-fin-recv-bill-wise-detail');
                break;
            case 'ledgerlink':
                navigate('/ledger-link');
                break;
            case 'chequeinhandreport':
                navigate('/cheque-in-hand-report');
                break;
            case 'chequeusedreport':
                navigate('/cheque-used-report')
                break;
            case 'ib-entry':
                navigate('/ib-entry')
                break;
            case 'margin':
                navigate('/margin');
                break;
            case 'ibr':
                navigate('/ibr');
                break;
            case 'dataunlock':
                navigate('/data-unlock');
                break;
            case 'transactionreport':
                navigate('/transaction-report');
                break;
            case 'currencywriteoff':
                navigate('/currency-writeoff');
                break;
            case 'purchaseinvoice':
                navigate('/purchase-invoice');
                break;
            case 'globalvtmsettle':
                navigate('/global-vtm-settlement');
                break;
            case 'currencychange':
                navigate('/currency-change');
                break;
            case 'cancellation':
                navigate('/cancellation');
                break;
            case 'entity':
                navigate('/entity');
                break;
            case 'settlement-vtm':
                navigate('/settlement-vtm');
                break;
            case 'expense-transfer':
                navigate('/expense-transfer');
                break;
            case 'fund-transfer':
                navigate('/fund-transfer');
                break;
            case 'stockinhandreport':
                navigate('/stock-in-hand-report');
                break;
            case 'manualbookreport':
                navigate('/manual-book-report');
                break;
            case 'fundRequest':
                navigate('/fund-request');
                break;
            case 'masterdsr':
                navigate('/master-dsr');
                break;
            case 'flmreport':
                navigate('/flm-report');
                break;
            case 'fxdocument':
                navigate('/fx-document');
                break;
            case 'evtmsurrender':
                navigate('/evtm-surrender');
                break;
            case 'commission':
                navigate('/commission-module');
                break;
            case 'conveyance':
                navigate('/conveyance-module');
                break;
            case 'tranmodulepayment':
                navigate('/tran-module-payment');
                break;
            case 'agentlinking':
                navigate('/agent-linking');
                break;
            case 'chngpass':
                navigate('/changepassword');
                break;
            case 'billwisedetailpayable':
                navigate('/bill-wise-detail-payable');
                break;
            case 'commissiontds':
                navigate('/commission-tds');
                break;
            case 'tranpaymentreport':
                navigate('/tran-payment-report');
                break;
            case 'activityreport':
                navigate('/activity-log-report')
                break;
            case 'additionallimit':
                navigate('/additional-limit')
                break;
            case 'ratecard':
                navigate('/rate-card')
                break;
            case 'repcommissionstatus':
                navigate('/commission-status')
                break;
            case 'transactionmodification':
                navigate('/transaction-modification')
                break;
            case 'tranmodificationcommission':
                navigate('/modification-commission')
                break;
            case 'tranmodificationdate':
                navigate('/modification-date')
                break;
            case 'saleinvoice':
                navigate('/sale-invoice')
                break;
            case 'tranmodificationpayment':
                navigate('/payment-modification');
                break;
            default:
                navigate('/');
                break;
        }
    }

    return (
        <>
            <div className="textRight mt-0 p-0 userStrip">
                {/* {sessionStorage.entityname}&nbsp;&nbsp;({sessionStorage.userName})&nbsp;&nbsp; */}
            </div>
            <div className="menuBar">
                <ul>
                    <li onClick={(e) => goToPage('home')} >
                        <div className="menuHomeIcon"><FontAwesomeIcon icon={faHome} className="menuIcon" />&nbsp;</div>
                    </li>
                    <li style={{ display: allRight.MASTER === "1" ? 'block' : 'none' }}>
                        <div><FontAwesomeIcon icon={faLock} className="menuIcon" />&nbsp;Master</div>
                        <div className="subMenu1">
                            <ul>
                                <li style={{ display: allRight.INDIVIDUALPROFILE === "1" ? 'block' : 'none' }}
                                    onClick={(e) => goToPage('individualprofile')}><div>Individual Profile</div></li>
                                <li style={{ display: allRight.MASTER_RATE === "1" ? 'block' : 'none' }}>
                                    <div>Rate&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.MARGIN === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('margin')}><div>Margin</div></li>
                                            <li style={{ display: allRight.IBR === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('ibr')}><div>IBR</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.MASTER_FINANCIAL === "1" ? 'block' : 'none' }}>
                                    <div>Financial&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.MASTER_FINANCIAL_PARAMETER === "1" ? 'block' : 'none' }}>
                                                <div>Parameters&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.VOUCHERTYPE === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('vouchertype')}><div>Voucher Type</div></li>
                                                        <li style={{ display: allRight.DOCUMENTLINK === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('groupdoc')}><div>Group Document</div></li>
                                                        <li style={{ display: allRight.DEALERLIST === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('dealer')}><div>Dealer Category</div></li>
                                                        <li style={{ display: allRight.RISK === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('risk')}><div>Risk</div></li>
                                                        <li style={{ display: allRight.BUSINESSNATURE === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('businessnature')}><div>Business Nature</div></li>
                                                        <li style={{ display: allRight.LEGALSTATUS === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('legalentity')}><div>Legal Entity</div></li>
                                                        <li style={{ display: allRight.GSTCLASSIFICATION === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('gstclassification')}><div>GST Cassification</div></li>
                                                        <li style={{ display: allRight.MSME === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('msme')}><div>MSME</div></li>
                                                    </ul>
                                                </div>

                                            </li>
                                            <li style={{ display: allRight.VOUCHERGROUP === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('vouchergroup')}><div>Voucher Group</div></li>
                                            <li style={{ display: allRight.VOUCHERLEDGER === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('voucherledger')}><div>Voucher Ledger</div></li>
                                            <li style={{ display: allRight.MASTER_LEDGER_LINK === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('ledgerlink')}><div>Ledger Link</div></li>
                                            <li style={{ display: allRight.MASTER_AGENT_LINK === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('agentlinking')}><div>Agent Link</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.MASTER_ACCESS === "1" ? 'block' : 'none' }}>
                                    <div>Access&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.RIGHT === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('right')}><div>Right</div></li>
                                            <li style={{ display: allRight.USER === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('userlist')}><div>User</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.MASTER_AREA === "1" ? 'block' : 'none' }}>
                                    <div>Area&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.REGION === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('region')}><div>Region</div></li>
                                            <li style={{ display: allRight.COUNTRYCLASS === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('countryclass')}><div>Country class</div></li>
                                            <li style={{ display: allRight.COUNTRY === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('country')}><div>Country</div></li>
                                            <li style={{ display: allRight.STATE === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('state')}><div>State</div></li>
                                            <li style={{ display: allRight.DISTRICT === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('district')}><div>District</div></li>
                                            <li style={{ display: allRight.CITY === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('city')}><div>City</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.MASTER_PARAMETER === "1" ? 'block' : 'none' }}>
                                    <div>Parameter&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className='subMenu2'>
                                        <ul>
                                            <li style={{ display: allRight.DIVISION === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('division')}><div>Division</div></li>

                                            <li style={{ display: allRight.COSTCENTER === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('costcenter')}><div>Cost Center</div></li>

                                            <li style={{ display: allRight.DESIGNATION === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('designation')}><div>Designation</div></li>
                                            <li style={{ display: allRight.EXECUTIVE === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('executive')}><div>Executive</div></li>
                                            <li style={{ display: allRight.MASTER_RESIDENTIAL === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('residentialstatus')}><div>Residential Status</div></li>
                                            <li style={{ display: allRight.CURRENCY === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('currency')}><div>Currency</div></li>
                                            <li style={{ display: allRight.ISSUER === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('issuer')}><div>Issuer</div></li>
                                            <li style={{ display: allRight.PURPOSE === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('purpose')}><div>Purpose</div></li>
                                            <li style={{ display: allRight.IDTYPE === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('idtype')}><div>ID type</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.ENTITY === "1" ? 'block' : 'none' }}
                                    onClick={(e) => goToPage('entity')}><div>Entity</div></li>
                                <li style={{ display: allRight.USER === "1" ? 'block' : 'none' }}
                                    onClick={(e) => goToPage('finyear')}><div>Fin Year</div></li>
                                <li style={{ display: allRight.USER === "1" ? 'block' : 'none' }}
                                    onClick={(e) => goToPage('sampleform')}><div>Sample</div></li>
                                <li style={{ display: allRight.MASTER_DSR === "1" ? 'block' : 'none' }}
                                    onClick={(e) => goToPage('masterdsr')}><div>DSR</div></li>
                            </ul>
                        </div>
                    </li>
                    <li style={{ display: allRight.TRANSACTION === "1" ? 'block' : 'none' }} >
                        <div>
                            <FontAwesomeIcon icon={faRectangleList} className="menuIcon" />&nbsp;Transaction</div>
                        <div className="subMenu1">
                            <ul>
                                <li style={{ display: allRight.TRAN_PURCHASES === "1" ? 'block' : 'none' }}>
                                    <div>Purchases&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_PP === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('purchasepublic')}><div>From public</div></li>
                                            <li style={{ display: allRight.TRAN_VTM_REQUEST === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('vtmencash')}><div>VTM Encashment</div></li>
                                            <li style={{ display: allRight.TRAN_PB === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('purchasebulk')}><div>Bulk</div></li>
                                            <li style={{ display: allRight.TRAN_PI === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('purchaseinvoice')}><div>Invoice</div></li>
                                            <li style={{ display: allRight.TRAN_SETTLEMENT_VTM === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('settlement-vtm')}><div>Settlement VTM</div></li>
                                            <li style={{ display: allRight.TRAN_GL_SETTLEMENT_VTM === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('globalvtmsettle')}><div>Global Settl. VTM</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.TRAN_SALES === "1" ? 'block' : 'none' }}>
                                    <div>Sales&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_SP === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('salespublic')}><div>Public</div></li>
                                            <li style={{ display: allRight.TRAN_SB === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('salebulk')}><div>Bulk</div></li>
                                            <li style={{ display: allRight.TRAN_EVTM_SURRENDER === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('evtmsurrender')}><div>EVTM Surrender</div></li>
                                            <li style={{ display: allRight.TRAN_SI === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('saleinvoice')}><div>Invoice</div></li>
                                        </ul>
                                    </div>
                                </li>

                                <li style={{ display: allRight.TRAN_VOUCHER === "1" ? 'block' : 'none' }}>
                                    <div>Voucher&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_BOOK_ENTRY === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('fa-book-entry')}><div>FA Book</div></li>
                                            <li style={{ display: allRight.INTER_BRANCH_ENTRY === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('ib-entry')}><div>IB Entry</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.TRAN_TRF === "1" ? 'block' : 'none' }}>
                                    <div>Transfer In &nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_TRF_VTC === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('transfer-vtc')}><div>VTC</div></li>
                                            <li style={{ display: allRight.TRAN_TRF_MANUAL_BOOK === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('manual-book')}><div>Manual Book</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.TRAN_TRF_OUT === "1" ? 'block' : 'none' }}>
                                    <div>Transfer Out &nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_TRF_OUT_VTC === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('transferoutvtc')}><div>VTC</div></li>
                                            <li style={{ display: allRight.TRAN_TRF_OUT_CURRENCY === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('transeroutcurrency')}><div>Currency</div></li>
                                            <li style={{ display: allRight.TRAN_ACCEPT_BRANCH === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('acceptinterbranch')}><div>Accept Inter Branch</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.TRAN_OPENING === "1" ? 'block' : 'none' }}>
                                    <div>Opening&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_FINANCIAL === "1" ? 'block' : 'none' }}><div>Financial&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.TRAN_OPN_TRIAL === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('trial')}><div>Trial</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_CMRCMP === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('cmrcmp')}><div>CMR/CMP</div></li>
                                                        <li style={{ display: allRight.OPN_BANK_STATEMENT === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('bankstatement')}><div>Bank Statement</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li style={{ display: allRight.TRAN_OPN_STOCK === "1" ? 'block' : 'none' }}><div>Stock&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.TRAN_CURRENCY_STOCK === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('stock')}><div>Currency Stock</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_VTC === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('vtc')}><div>VTC In Stock</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_TC === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('tc')}><div>TC</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_ETC === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('etc')}><div>ETC</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_EVTM === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('evtm')}><div>EVTM</div></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li style={{ display: allRight.TRAN_OPN_LIABILITY === "1" ? 'block' : 'none' }}><div>Liability&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.TRAN_OPN_TC_SETTLEMENT === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('tc-settlement')}><div>TC Settlement</div></li>
                                                        <li style={{ display: allRight.TRAN_OPN_VTM_SETTLEMENT === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('vtm-settlement')}><div>VTM Settlement</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.TRAN_UTILITY === "1" ? 'block' : 'none' }}>
                                    <div>Utility&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_DATA_UNLOCK === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('dataunlock')}><div>Data Unlock</div></li>
                                            <li style={{ display: allRight.CANCELLATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('cancellation')}><div>Cancellation</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.OTHERS === "1" ? 'block' : 'none' }}>
                                    <div>Others&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.CURRENCY_WRITE_OFF === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('currencywriteoff')}><div>Currency Write Off</div></li>
                                            <li style={{ display: allRight.CURRENCY_CHANGE === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('currencychange')}><div>Currency Change</div></li>
                                            <li style={{ display: allRight.EXPENSE_TRANSFER === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('expense-transfer')}><div>Expense Transfer</div></li>
                                            <li style={{ display: allRight.FUND_TRANSFER === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('fund-transfer')}><div>Fund Transfer</div></li>
                                            <li style={{ display: allRight.TRAN_COMMISSION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('commission')}><div>Commission</div></li>
                                            <li style={{ display: allRight.TRAN_CONVEYANCE === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('conveyance')}><div>Conveyance Entry</div></li>
                                            <li style={{ display: allRight.TRAN_MODULE_PAYMENT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('tranmodulepayment')}><div>Payment Module</div></li>
                                            <li style={{ display: allRight.TRAN_ADDITIONAL_CREDIT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('additionallimit')}><div>Additional Limit</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.FUND_REQUEST === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('fundRequest')}>
                                    <div>Fund Request</div>
                                </li>
                                <li style={{ display: allRight.FX_DOCUMENT_UPLOAD === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('fxdocument')}>
                                    <div>Fx Document Upload</div>
                                </li>
                                <li style={{ display: allRight.COMMISSION_MODIFICATION === "1" ? 'block' : 'none' }}>
                                    <div>Modification&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRAN_MODIFICATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('transactionmodification')}><div>Modification change </div></li>
                                            <li style={{ display: allRight.TRAN_MODIFICATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('tranmodificationdate')}><div>Date</div></li>
                                            <li style={{ display: allRight.COMMISSION_MODIFICATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('tranmodificationcommission')}><div>Commission</div></li>
                                            <li style={{ display: allRight.TRAN_PAYMENT_MODIFICATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('tranmodificationpayment')}><div>Payment</div></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li style={{ display: allRight.BANK === "1" ? 'block' : 'none' }} >
                        <div><FontAwesomeIcon icon={faBuildingColumns} className="menuIcon" />&nbsp;Bank</div>
                        <div className="subMenu1">
                            <ul>
                                <li style={{ display: allRight.BANK_CHEQUES === "1" ? 'block' : 'none' }}>
                                    <div>Cheques&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.BANK_TRANSFER_IN === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('chequebookin')}><div>Book Transfer In</div></li>
                                            <li style={{ display: allRight.BANK_TRANSFER_OUT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('chequebookout')}><div>Transfer Out</div></li>
                                            <li style={{ display: allRight.CHEQUE_DEPOSIT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('chequedeposit')}><div>Cheque Deposit </div></li>
                                            <li style={{ display: allRight.RECEIVED_CHEQUE_BOUNCE === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('chequebounce')}><div>Received Cheque Bounce</div></li>
                                            <li style={{ display: allRight.ISSUE_CHEQUE_BOUNCE === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('issuechequebounce')}><div>Issue Cheque Bounce</div></li>
                                            <li style={{ display: allRight.BANK_RECONCILIATION === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('bankreconciliation')}><div>Bank Reconciliation</div></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li style={{ display: allRight.REPORTS === "1" ? 'block' : 'none' }} >
                        <div><FontAwesomeIcon icon={faChartLine} className="menuIcon" />&nbsp;Reports</div>
                        <div className="subMenu1">
                            <ul>
                                <li style={{ display: allRight.REPORTS_MIS === "1" ? 'block' : 'none' }}>
                                    <div>MIS&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.TRANSACTION_REPORT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('transactionreport')}><div>Transaction</div></li>
                                            <li style={{ display: allRight.MANUAL_BOOK_REPORT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('manualbookreport')}><div>Manual Book Report</div></li>
                                            <li style={{ display: allRight.REPORTS === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('testing')}><div>Testing</div></li>
                                            <li style={{ display: allRight.REPORTS_MIS_CURRENCY === "1" ? 'block' : 'none' }}>
                                                <div>Currency&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className="subMenu2">
                                                    <ul>
                                                        <li style={{ display: allRight.STOCK_IN_HAND_REPORT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('stockinhandreport')}><div>Stock in Hand Report</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li style={{ display: allRight.TRAN_PAYMENT_REPORT === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('tranpaymentreport')}><div>Tran Payment Report</div></li>
                                            <li style={{ display: allRight.REPORTS === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('activityreport')}><div>Audit Report</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.REPORTS_RBI === "1" ? 'block' : 'none' }}>
                                    <div>RBI&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.REPORTS_RBI_FLM === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('flmreport')}>
                                                <div>FLMs</div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.REPORTS_FINANCIAL === "1" ? 'block' : 'none' }}>
                                    <div>Financial&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.LEDGER === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('ledger')}><div>Ledger</div></li>
                                            <li style={{ display: allRight.TRIAL_BALANCE === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('trialbalance')}><div>Trial Balance</div></li>
                                            <li style={{ display: allRight.REPORTS1 === "1" ? 'block' : 'none' }}><div>Receivable&nbsp;
                                                <FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.REP_FIN_RECV_BILL_WISE_DETAIL === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('repfinrecvbillwisedetail')}><div>Bill Wise Detail</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li style={{ display: allRight.REPORTS1 === "1" ? 'block' : 'none' }}><div>Payable&nbsp;
                                                <FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.BILL_WISE_DETAIL_PAYABLE === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('billwisedetailpayable')}><div>Bill Wise Detail</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li style={{ display: allRight.CHEQUE_IN_HAND_REPORT === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('chequeinhandreport')}><div>Cheque In Hand</div></li>
                                            <li style={{ display: allRight.CHEQUE_IN_HAND_REPORT === "1" ? 'block' : 'none' }}
                                                onClick={(e) => goToPage('chequeusedreport')}><div>Cheque Used Report</div></li>
                                            <li style={{ display: allRight.REPORTS_TDS === "1" ? 'block' : 'none' }}><div>Commission&nbsp;
                                                <FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                                <div className='subMenu3'>
                                                    <ul>
                                                        <li style={{ display: allRight.REPORTS_TDS_COMMISSION === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('commissiontds')}><div>TDS</div></li>
                                                        <li style={{ display: allRight.REP_COMMISSION_VIEW === "1" ? 'block' : 'none' }}
                                                            onClick={(e) => goToPage('repcommissionstatus')}><div>Status Report</div></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.REPORTS_STATUTORY === "1" ? 'block' : 'none' }}>
                                    <div>Statutory&nbsp;<FontAwesomeIcon className="arrowIcon" icon={faCircleRight} /></div>
                                    <div className="subMenu2">
                                        <ul>
                                            <li style={{ display: allRight.REPORTS1 === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('home')}><div>Dummy</div></li>
                                            <li style={{ display: allRight.REPORTS1 === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('home')}><div>Dummy</div></li>
                                        </ul>
                                    </div>
                                </li>
                                <li style={{ display: allRight.REPORTS_STATUTORY === "1" ? 'block' : 'none' }} onClick={(e) => goToPage('ratecard')}>
                                    <div> Rate Card</div>
                                    <div className="subMenu2">
                                        <ul>

                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li style={{ display: 'block', right: '0', position: 'absolute' }} >
                        <div className="menuProfileIcon"><FontAwesomeIcon icon={faUser} /></div>
                        <div className="subMenu1">
                            <ul>
                                <li style={{ display: 'block' }} onClick={(e) => goToPage(0)}><div>Log Out</div></li>
                                <li style={{ display: 'block' }} onClick={(e) => goToPage('chngpass')}><div>Change Password</div></li>
                            </ul>
                        </div>
                    </li>
                    {
                        /*
                            <li style={{ display: 'block', right: '0', position: 'absolute' }} onClick={(e) => goToPage(0)}><div className="menuHomeIcon"><FontAwesomeIcon icon={faDoorOpen} className="menuIcon" />&nbsp;</div></li>
                        */
                    }
                </ul>
            </div>
        </>
    )
}

export default Menu
