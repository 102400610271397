
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';
import Reconciliation_activity from '../Reconciliation_activity';


const Bank_reconciliation = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);


    const [ledgerValue, setLedgerValue] = useState(null);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selectedLimit, setSelectedLimit] = useState("50");
    const [selectedOption, setSelectedOption] = useState("2");

    const [dataList, setDataList] = useState([]);

    const [showDataList, setShowDataList] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [onceRun, setOnceRun] = useState(false);

    const [bankName, setBankName] = useState("");
    const [bankGroup, setBankGroup] = useState("");
    const [bankAccount, setBankAccount] = useState("");

    const [chequeNum, setChequeNum] = useState("");

    const [amount, setAmount] = useState("");

    const currentDate = new Date();
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [datePickerStates, setDatePickerStates] = useState([]);

    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    var msg = [], i = 0;

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'BANK_RECONCILIATION'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    function handleexcel() {
        setDataList([dataList]);
        if (ledgerValue == null || fromDate == "" || toDate == "" || selectedOption == "" || selectedLimit == ""
            || (fromDate && fromDate > currentDate) || (Common.dateYMD(fromDate) < startDate)
            || (Common.dateYMD(toDate) > endDate) || (fromDate > toDate)

        ) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select bank." : '');
            msg[i++] = (fromDate == '' ? "Please select from date." : '');
            msg[i++] = (toDate == '' ? "Please select to date." : '');
            msg[i++] = (selectedOption == '' ? "Please select realisation." : '');
            msg[i++] = (selectedLimit == '' ? "Please select limit." : '');
            msg[i++] = ((fromDate && fromDate > currentDate) ? "From Date can't be selected as future date." : '');
            msg[i++] = ((Common.dateYMD(fromDate) < startDate) ? "From Date should be within financial year." : '');
            msg[i++] = ((toDate && toDate > currentDate) ? "To Date can't be selected as future date." : '');
            msg[i++] = ((fromDate > toDate) ? "From Date should be less than To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                bankGroup: bankGroup,
                bankAccount: bankAccount,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),
                realisation_date: selectedOption,
                limit: selectedLimit,
                chequeNum: chequeNum,
                amount: amount,
                bankname: bankName
            }
            Common.callDownloadApiPost1(Common.apiBankReconciliation, "post", [sid, 'downloadlist', JSON.stringify(obj)]);
            setShowDataList(true);
        }
    }

    const handleShowList = () => {
        setDataList([]);
        if (ledgerValue == null || fromDate == "" || toDate == "" || selectedOption == "" || selectedLimit == ""
            || (fromDate && fromDate > currentDate) || (Common.dateYMD(fromDate) < startDate)
            || (Common.dateYMD(toDate) > endDate) || (fromDate > toDate)

        ) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select bank." : '');
            msg[i++] = (fromDate == '' ? "Please select from date." : '');
            msg[i++] = (toDate == '' ? "Please select to date." : '');
            msg[i++] = (selectedOption == '' ? "Please select realisation." : '');
            msg[i++] = (selectedLimit == '' ? "Please select limit." : '');
            msg[i++] = ((fromDate && fromDate > currentDate) ? "From Date can't be selected as future date." : '');
            msg[i++] = ((Common.dateYMD(fromDate) < startDate) ? "From Date should be within financial year." : '');
            msg[i++] = ((toDate && toDate > currentDate) ? "To Date can't be selected as future date." : '');
            msg[i++] = ((fromDate > toDate) ? "From Date should be less than To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {

            const obj = {
                bankGroup: bankGroup,
                bankAccount: bankAccount,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),
                realisation_date: selectedOption,
                limit: selectedLimit,
                chequeNum: chequeNum,
                amount: amount
            }

            Common.callApi(Common.apiBankReconciliation, [sid, 'showList', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setDataList(resp);
            });

            setShowDataList(true);
        }
    }

    const handleDateChange = (index, value, loc, type, srno, line) => {
        const newDatePickerStates = [...datePickerStates];
        newDatePickerStates[index] = value;
        setDatePickerStates(newDatePickerStates);
        console.log('Date Picker States:', newDatePickerStates);
        console.log("value: ", value);


        const formattedDate = `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;

        const formattedDateDMY = formattedDate.split('-').reverse().join('-');


        console.log(formattedDate);


        const obj = {
            activitytype: 'Added',
            subtranno: line,
            trannumber: srno,
            trantype: type,
            branch: loc,
            remark: `Date added ${formattedDateDMY}`,

            location: loc,
            type: type,
            srno: srno,
            line: line,
            realisedDate: formattedDate,

            bankGroup: bankGroup,
            bankAccount: bankAccount,
            fromDate: Common.dateYMD(fromDate),
            toDate: Common.dateYMD(toDate),
            realisation_date: selectedOption,
            limit: selectedLimit,
            chequeNum: chequeNum,
            amount: amount
        }

        console.log(obj);
        Common.callApi(Common.apiBankReconciliation, [sid, 'setRealised', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setDataList(resp);
            setDatePickerStates([]);

        });
    };

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        const account = value.id.split('^');
        console.log(account);
        setBankGroup(account[1]);
        setBankAccount(account[2]);
        const bank = account[3];
        setBankName(bank);
        // setViewBank(bank);
    }


    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        console.log("Selected value:", value);
        // setDataList([]);
    }

    const handleLimitChange = (event) => {
        const value = event.target.value;
        setSelectedLimit(value);
        console.log("Selected value:", value);
    }
    // console.log(dataList);

    const handleRemove = (loc, type, srno, line) => {
        // setDataList([]);
        const obj = {
            activitytype: 'Removed',
            subtranno: line,
            trannumber: srno,
            trantype: type,
            branch: loc,
            remark: `Date removed`,


            location: loc,
            type: type,
            srno: srno,
            line: line,
            realisedDate: null,

            bankGroup: bankGroup,
            bankAccount: bankAccount,
            fromDate: Common.dateYMD(fromDate),
            toDate: Common.dateYMD(toDate),
            realisation_date: selectedOption,
            limit: selectedLimit,
            chequeNum: chequeNum,
            amount: amount

        }

        console.log(obj);
        Common.callApi(Common.apiBankReconciliation, [sid, 'setRealised', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setDataList(resp);
            setDatePickerStates([]);

        });
    }


    const handleShowActivity = (loc, type, srno, line) => {

        console.log('activity');
        setShowActivityLog(true);
        setLogObject({ branch: loc, trannumber: srno, trantype: type, subtranno: line });
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>

                <Row>
                    <Col>
                        <h2>Bank Reconciliation</h2>
                    </Col>
                </Row>&nbsp;

                <Row >
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={ledgerValue}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchBankPicklist(search, 1)} // location = 1 , 2
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleLedgerValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                            <DatePicker className="form-control"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                            <DatePicker className="form-control"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Label>Realisation<span className="colorThemeRed">*</span></Form.Label><br />
                        <select class="form-select" aria-label="Default select example" value={selectedOption}
                            onChange={handleSelectChange}>
                            <option value="2">Not Realised</option>
                            <option value="1">Realised</option>
                            <option value="3">Both</option>
                        </select>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Label>Limit<span className="colorThemeRed">*</span></Form.Label><br />
                        <select class="form-select" aria-label="Default select example" value={selectedLimit}
                            onChange={handleLimitChange}>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="0">All</option>
                        </select>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Label>Ref/Cheque Number</Form.Label>
                        <Form.Control
                            value={chequeNum}
                            maxLength={100}
                            onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                            placeholder="Ref/Cheque Number"
                        />
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            value={amount}
                            maxLength={15}
                            onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                            placeholder="Amount"
                        />
                    </Col>
                </Row>&nbsp;
                <Row>
                    <Col>
                        {
                            userRight.query == "1" ?
                                <Button variant='outline-primary' className='buttonStyle' size='sm'
                                    onClick={handleShowList}>List</Button>
                                : null
                        }&nbsp;
                        <Button variant='outline-danger' className='buttonStyle' size='sm'
                            onClick={handleexcel}>Download</Button>
                    </Col>

                </Row>&nbsp;
                {
                    showDataList &&
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Location</th>
                                <th>Date</th>
                                <th>Voucher</th>
                                <th style={{ textAlign: 'right' }}>Ref/Cheque Number</th>
                                <th style={{ textAlign: 'right' }}>Deposit</th>
                                <th style={{ textAlign: 'right' }}>Withdraw</th>
                                <th>Realisation Date</th>
                                <th>Log</th>

                            </tr>
                        </thead>
                        <tbody>
                            {dataList.map((res, index) =>
                                <tr key={res.index}>
                                    <td>{res.tfd_location}</td>
                                    <td>{res.tfd_location}   {res.entity_name}</td>
                                    <td>{res.formatted_date}</td>
                                    <td>{res.tfd_srno}   {res.tfd_type}</td>
                                    <td style={{ textAlign: 'right' }}>{res.tfd_cheque_no}</td>
                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'D' && res.tfd_amount}</td>
                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'C' && -1 * res.tfd_amount}</td>
                                    <td style={{ textAlign: 'right' }}>{res.realised_date != null ? <>{res.realised_date}< h6 >
                                        <Badge bg="danger" style={{ cursor: 'pointer' }} onClick={() => handleRemove(res.tfd_location, res.tfd_type, res.tfd_srno,
                                            res.tfd_line_no)}>Remove</Badge></h6></> :
                                        <DatePicker className="form-control"
                                            selected={datePickerStates[index]}
                                            onChange={(date) => handleDateChange(index, date, res.tfd_location, res.tfd_type, res.tfd_srno, res.tfd_line_no)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    }
                                    </td>
                                    <td>
                                        {userRight.query === "1" ?
                                            <td className="textCenter" >
                                                {
                                                    <span className="handCursor" style={{ cursor: 'pointer' }}
                                                        onClick={() => handleShowActivity(res.tfd_location, res.tfd_type, res.tfd_srno, res.tfd_line_no)}
                                                    >
                                                        <div className="colorThemeBlue"><FontAwesomeIcon icon={faClock} /></div>
                                                    </span>
                                                }
                                            </td>
                                            : null
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                }

                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>

                <Reconciliation_activity logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />

            </Container>

            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Bank_reconciliation
