import React, { useState } from 'react'
import { Container, Image } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as Common from "./Common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import '../mcss/loginv1.css';
import $ from "jquery";
import Dialog from './Dialog';

let imgLogo = {
    height: '100px'
}

function Loginv1() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [isSignIn, setIsSignIn] = useState(true);
    const [isForget, setIsForget] = useState(false);
    const [userId, setUserId] = useState("");
    const [userPass, setUserPass] = useState("");
    const [userEmail, setEmail] = useState("");

    const activeLocation = sessionStorage.getItem("activeBranch");
    sessionStorage.clear();
    sessionStorage.setItem("activeBranch", activeLocation);

    const onClickSignIn = () => {
        //sessionStorage.clear();
        if (userId === "" || userPass === "") {
            setMsgText(Common.getMessage("MSG0001"));
            setMsgBox(true);
        } else {
            Common.callApi(Common.apiLogin, ['validate', userId, userPass], function (result) {
                let resp = JSON.parse(result);
                console.log(result);
                if (resp.status === "MSG0000") {
                    sessionStorage.sessionId = resp.session;
                    sessionStorage.userName = (resp.name).toUpperCase();
                    sessionStorage.entityId = resp.entityid;
                    sessionStorage.entityType = resp.entitytype;
                    sessionStorage.dbname = resp.dbname;
                    sessionStorage.branchallowed = resp.entitybranchallowed;
                    sessionStorage.userSrno = resp.srno;
                    sessionStorage.startDate = resp.fystart_date;
                    sessionStorage.endDate = resp.fyend_date;
                    sessionStorage.myInterval = "";
                    sessionStorage.finyear = resp.finyear;
                    sessionStorage.userId = (resp.id).toUpperCase();
                    const entityArr = resp.entitybranchallowed.split(',').map(Number);
                    sessionStorage.branchDisable = false;
                    if (entityArr.length == 1) {
                        sessionStorage.branchDisable = true;
                    }
                    const activeloc = sessionStorage.getItem("activeBranch");
                    if (!activeloc || !entityArr.includes(activeloc * 1))
                        sessionStorage.activeBranch = resp.activeBranch;
                    console.log('Inside Sign In ');
                    if (resp.chg_pw_on_logon === "0" || resp.chg_pw_on_logon === 0) {
                        sessionStorage.chngPassOnLogin = 0;
                        navigate("/home");
                    } else if (resp.chg_pw_on_logon === "1" || resp.chg_pw_on_logon === 1) {
                        sessionStorage.chngPassOnLogin = 1;
                        navigate("/changepassword");
                    } else {
                        setMsgText(Common.getMessage("ERR0000"));
                        setMsgBox(true);
                    }
                } else {
                    setMsgText(Common.getMessage(resp.status));
                    setMsgBox(true);
                }
            });
        }
    }

    const onClickReset = () => {
        $(".loader").show();
        if (userId === "" || userEmail === "") {
            $(".loader").hide();
            setMsgText("Invalid User Id or Email Id.");
            setMsgBox(true);
        } else {
            Common.callApi(Common.apiLogin, ['reset', userId, userEmail], function (result) {
                let resp = JSON.parse(result);
                $(".loader").hide();
                setMsgText(resp.msg);
                setMsgBox(true);
                onClickGoBack();
            });
        }
    }

    const onClickGoBack = () => {
        setIsForget(false);
        setIsSignIn(true);
    }

    const onClickForget = () => {
        setIsSignIn(false);
        setIsForget(true);
    }

    return (
        <>
            <Container>
                <div className="row">
                    <div className='col'>
                        &nbsp;
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-12 align-middle" style={{ height: '100vh' }} >
                        <div className="wrap d-md-flex">
                            <div className="login-wrap">
                                <Image src="img/loginpage.png" fluid />
                            </div>
                            <div className="login-wrap p-4 p-md-5">
                                <div className="row justify-content-center">
                                    <div className="col" align="center">
                                        <Image style={imgLogo} src="img/logo.png" fluid />
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="w-100">
                                        <h3 className="mb-4">
                                            {isSignIn ? "Sign In"
                                                : "Reset Password"
                                            }
                                        </h3>
                                    </div>
                                    <div className="w-100">
                                        <p className="social-media d-flex justify-content-end">
                                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/zenithfinserv/" className="social-icon d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={faFacebook} /></a>
                                            <a target="_blank" rel="noreferrer" href="https://twitter.com/ZenithFinserv" className="social-icon d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={faTwitter} /></a>
                                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/zenithfinserv/" className="social-icon d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={faInstagram} /></a>
                                            {
                                                /*
                                                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/14636373/admin/" className="social-icon d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={faLinkedin} /></a>
                                                */
                                            }
                                        </p>
                                    </div>
                                </div>
                                <form className="signin-form">
                                    <div className="form-group mb-3">
                                        <label className="label text-uppercase mb-1" >Username</label>
                                        <input type="text" className="form-control form-control-lg" placeholder="Username" required=""
                                            maxLength="20"
                                            value={userId}
                                            onChange={(e) => Common.validateAlpNumSplValue(e.target.value.trim(), setUserId)} autoComplete="off"
                                        ></input>
                                    </div>
                                    {
                                        isSignIn ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <label className="label text-uppercase mb-1" >Password</label>
                                                    <input type="password" className="form-control form-control-lg" placeholder="Password" required=""
                                                        maxLength="20"
                                                        value={userPass}
                                                        onChange={(e) => Common.validateAlpNumSplValue(e.target.value.trim(), setUserPass)} autoComplete="off"
                                                    ></input>
                                                </div>
                                                <div className="form-group pt-2">


                                                    <button type="button" className="form-control btn btn-lg btn-primary rounded px-3"
                                                        onClick={(e) => onClickSignIn()}>
                                                        Sign In
                                                    </button>
                                                </div>
                                            </>
                                            : null
                                    }
                                    {
                                        isForget ?
                                            <>
                                                <div className="form-group mb-3">
                                                    <label className="label text-uppercase mb-1" >Email Id</label>
                                                    <input type="email" className="form-control form-control-lg" placeholder="Email" required=""
                                                        onChange={(e) => setEmail(e.target.value.trim())} autoComplete="off"
                                                        onBlur={(e) => Common.validtateEmail(e.target.value.trim(), setEmail)}
                                                    ></input>
                                                </div>
                                                <div className="form-group pt-2">
                                                    <button type="button" className="form-control btn btn-lg btn-primary rounded px-3" onClick={(e) => onClickReset()} >Reset</button>&nbsp;
                                                    <button type="button" className="form-control btn btn-lg btn-primary rounded px-3" onClick={(e) => onClickGoBack()} >Back</button>
                                                </div>

                                            </>
                                            : null
                                    }
                                    {
                                        isSignIn ?
                                            <div className="form-group d-flex my-3">
                                                <div className="w-50 text-start">
                                                </div>
                                                <div className="w-50 text-end">
                                                    <span onClick={onClickForget} className="handCursor colorBlue" >Forgot Password</span>
                                                </div>
                                            </div>
                                            : null}
                                </form>
                                <div className="row justify-content-center">
                                    <div className="col" align="center">
                                        <FontAwesomeIcon icon={faEnvelope} />&nbsp;appsupport@zenithforex.com&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
        </>
    )
}

export default Loginv1
