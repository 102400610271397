import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../../ActivityLog';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';
import Login_header from '../../Login_header';
import Master_individual_profile from '../../master/Master_individual_profile';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';
import Tran_data_unlock_request from '../../Tran_data_unlock_request';
import Tran_fa_book_print from './Tran_fa_book_print';
import Tran_fa_book_print_advance from './Tran_fa_book_print_advance';

const Tran_fa_book = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState({ value: 'BK', label: 'Bank Book' });
    const [flag, setFlag] = useState('C');
    const [amount, setAmount] = useState("");
    const [onceRun, setOnceRun] = useState(false);
    const [radioType, setRadioType] = useState("N");
    const [srnoFrom, setSrnoFrom] = useState("");
    const [srnoTo, setSrnoTo] = useState("");
    const [ledgerValue, setLedgerValue] = useState(null);
    const [srNo, setSrNo] = useState("0");
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [showList, setShowList] = useState(false);
    const [filterDate, setFilterDate] = useState(new Date());
    const [bookList, setBookList] = useState([]);
    const [showHeaderList, setShowHeaderList] = useState(false);
    const [headerList, setHeaderList] = useState([]);
    const [counter, setCounter] = useState("0");
    const [viewSrno, setViewSrno] = useState("");
    const [viewDate, setViewDate] = useState("");
    const [viewType, setViewType] = useState("");
    const [viewAccount, setViewAccount] = useState(null);

    const [knockoffData, setKnockoffData] = useState([]);

    const [knockoffValue, setKnockoffValue] = useState(null);
    const [knockoffSrno, setKnockoffSrno] = useState('');
    const [knockoffType, setKnockoffType] = useState('');
    const [knockoffAmt, setKnockoffAmt] = useState('');
    const [knockoffPending, setKnockoffPending] = useState('');
    const [knockoffLineNo, setKnockoffLineNo] = useState('');
    const [knockoffFlag, setKnockoffFlag] = useState('');
    const [knockoffLedger, setKnockoffLedger] = useState('');

    const [showKnockoff, setShowKnockoff] = useState(true);
    const [show, setShow] = useState(false);

    const [limit, setLimit] = useState('10');
    const [srch, setSrch] = useState('');

    const [totalDebit, setTotalDebit] = useState("0");
    const [totalCredit, setTotalCredit] = useState("0");
    const [totalDifference, setTotalDifference] = useState("0");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [narration, setNarration] = useState("");
    const [scrMode, setScrMode] = useState("");
    const [addCount, setAddCount] = useState("0");
    const [showIndividualList, setIndividualList] = useState(false);
    const [nIndividual, setNIndividual] = useState(null);
    const [nCorporate, setNCorporate] = useState(null);
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [faStateValue, setFAStateValue] = useState(0);
    const [faStateName, setFAStateName] = useState("");
    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [showCheque, setShowCheque] = useState(false);
    const [chequeNum, setChequeNum] = useState("");
    const [bankName, setBankName] = useState("");
    const [chequeDate, setChequeDate] = useState(new Date());
    const [mvgType, setMvgType] = useState("");
    const [chequeStatus, setChequeStatus] = useState(null);
    const [type, setType] = useState('T');
    const [chequeValue, setChequeValue] = useState("");
    const [chequeValueNum, setChequeValueNum] = useState("");
    const [chequeLine, setChequeLine] = useState("");
    const [chequeSrno, setChequeSrno] = useState("");
    const [chequeLocation, setChequeLocation] = useState("");
    const [grpCode, setGrpCode] = useState("0");
    const [accCode, setAccCode] = useState("0");
    const options = [
        { value: 'BK', label: 'Bank Book' },
        { value: 'CS', label: 'Cash Book' },
        { value: 'JV', label: 'Journal Voucher' },
    ];
    const [displayFlag, setDiplayFlag] = useState('');
    const [isChecked, setChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [acRemark, setAcRemark] = useState("");
    const [code, setCode] = useState(0);
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [editReqDate, setEditReqDate] = useState('');
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [randomString, setRandomString] = useState("0");

    const currentDate = new Date();

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedLocation(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_BOOK_ENTRY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const handleAddNew = () => {
        var msg = [], i = 0;
        var d = Common.dateYMD(filterDate);
        if (selectedLocation === null || !filterDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedLocation === null ? "Select Location." : '');
            msg[i++] = (!filterDate ? "Select Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (d > finEndDate || d < finStartDate) {
            setMsgText(Common.getMessage("MSG0010"));
            setMsgBox(true);
        } else {
            setScrMode('A');
            setSrNo(0);
            setLedgerValue(null);
            setShowHeaderList(false);
            setShowList(true);
            setBookList([]);
            setTotalCredit('0');
            setTotalDebit('0');
            setTotalDifference('0');
            setNarration("");
            setViewType(selectedOption.value);
            setViewDate(Common.dateDMY(filterDate));
            setRandomString(Common.getRandomString(15));
        }
        setAddCount(0);
        setCode(0);
    }

    const handleAdd = () => {
        var msg = [], i = 0;
        $(".loader").show();
        let account;
        let accountType;
        if (ledgerValue != null) {
            account = ledgerValue.id.split('^');
            accountType = account[4];
        }
        if (amount == "" || ledgerValue == null || (accountType == 'CHEQUE' && chequeDate == null) ||
            (accountType == 'CHEQUE' && chequeNum == '') ||
            (accountType == 'CHEQUE' && bankName == '') || (accountType == 'BANK' && flag == 'C' && type == 'T' && chequeNum == '') ||
            (accountType == 'BANK' && flag == 'C' && type == 'C' && chequeNum == '') ||
            (flag == 'D' && type == 'T' && accountType == 'BANK' && chequeNum == '') ||
            (flag == 'D' && type == 'C' && accountType == 'BANK' && chequeNum == '')
            || (flag == 'D' && type == 'C' && accountType == 'BANK' && bankName == '')) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select account." : '');
            msg[i++] = (amount === '' ? "Please fill amount." : '');
            msg[i++] = ((accountType == 'CHEQUE' && chequeNum == '') ? 'Fill Cheque number' : '');
            msg[i++] = ((accountType == 'CHEQUE' && bankName == '') ? 'Fill Bank Name' : '');
            msg[i++] = ((accountType == 'CHEQUE' && chequeDate == null) ? "Please select date." : '');
            msg[i++] = ((accountType == 'BANK' && flag == 'C' && type == 'T' && chequeNum == '') ? 'Fill Reference Number' : '');
            msg[i++] = ((accountType == 'BANK' && flag == 'C' && type == 'C' && chequeNum == '') ? 'Fill Cheque Number' : '');
            msg[i++] = ((accountType == 'BANK' && flag == 'D' && type == 'T' && chequeNum == '') ? 'Fill Reference Number' : '');
            msg[i++] = ((accountType == 'BANK' && flag == 'D' && type == 'C' && chequeNum == '') ? 'Fill Cheque Number' : '');
            msg[i++] = ((flag == 'D' && type == 'C' && accountType == 'BANK' && bankName == '') ? 'Fill Bank Name' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setAddCount(addCount + 1);
            $(".loader").hide();
            // const account = ledgerValue.id.split('^');
            //console.log(account);
            const groupCode = account[1];
            const accountCode = account[2];
            let individual_name = '';
            let individual_id = 0;
            // console.log("mmmmmmmmmmmmmmmmm", ledgerValue);
            console.log(nIndividual);
            if (nIndividual != null) {
                let individual = nIndividual.id.split('^');
                individual_name = individual[2];
                individual_id = individual[1];
            }
            // console.log(nIndividual[3]);
            let chqDate = "";
            let transferType = "";
            if (accountType == 'CHEQUE') {
                chqDate = Common.dateYMD(chequeDate);
            } else if (accountType == 'BANK') {
                transferType = type;
                chqDate = chequeDate;
            }
            let amt = amount;
            if (flag == 'C') {
                if (amount > 0) {
                    amt = -1 * amount;
                }
            } else {
                if (amount < 0) {
                    amt = -1 * amount;
                }
            }
            let obj;
            if (knockoffValue == null) {
                console.log()
                obj = {
                    uniquekey: Common.getRandomString(15),
                    tfd_date: Common.dateYMD(filterDate),
                    type: selectedOption,
                    tfd_type: selectedOption.value,
                    tfd_flag: flag,
                    tfd_amount: amt,
                    tfd_location: locationCode,
                    tfd_group: account[1],
                    tfd_account: account[2],
                    tfd_account_type: account[4],
                    mvli_ledger_name: account[3],
                    srno: srNo,
                    tfd_individual: individual_name,
                    tfd_individual_code: individual_id,
                    tfd_cheque_no: chequeNum,
                    tfd_cheque_bank_name: accountType == 'BANK' ? account[3] : bankName,
                    cheque_date: Common.dateYMD(chqDate),//
                    tfd_cheque_status: chequeStatus,
                    chequeValueNum: chequeValueNum,
                    tfd_transfer_type: transferType,
                    tfd_knockoff_srno: 'null',
                    tfd_knockoff_type: "",
                    tfd_knockoff_lineno: 'null',
                    delny: 'Y',
                }
            } else {
                obj = {
                    tfd_location: locationCode,
                    tfd_type: knockoffType,
                    tfd_srno: knockoffSrno,
                    tfd_line_no: knockoffLineNo,
                    tfd_knockoff_type: knockoffType,
                    tfd_knockoff_srno: knockoffSrno,
                    tfd_knockoff_lineno: knockoffLineNo,
                    tfd_group: grpCode,
                    tfd_account: accCode,
                    tfd_amount: amt,
                    tfd_flag: knockoffFlag == 'D' ? 'C' : 'D',
                    tfd_date: Common.dateYMD(filterDate),
                    mvli_ledger_name: knockoffLedger,
                    randomString: randomString,
                    delny: 'Y',
                    tfd_transfer_type: "",
                    tfd_cheque_status: "",
                    cheque_date: "",
                    tfd_cheque_bank_name: "",
                    tfd_cheque_no: "",
                    tfd_individual_code: "",
                    tfd_account_type: "",
                    chequeValueNum: "",
                }
                Common.callApi(Common.apiBookEntry, [sid, 'addknockoffSingle', JSON.stringify(obj)], (result) => {
                    let resp = JSON.parse(result);
                })
            }
            setBookList(Common.arrayAddItem(bookList, obj));
            setCounter(counter + 1);
            setShowList(true);
            setAmount("");
            setFlag("C");
            setLedgerValue(null);
            setBankName("");
            setChequeNum("");
            setBankName("");
            setNIndividual(null);
            setChequeStatus(null);
            calculateTotal(bookList);
            setChequeDate(new Date());
            setMvgType("");
            setType('T');
            setKnockoffValue(null);
            setKnockoffType("");
            setKnockoffSrno("");
            setKnockoffAmt("");
        }
    }

    const handleSave = () => {
        let expectedAccountType;
        if (selectedOption.value === 'BK') {
            expectedAccountType = ['BANK', 'CHEQUE'];
        } else if (selectedOption.value === 'CS') {
            expectedAccountType = ['CASH'];
        } else if (selectedOption.value === 'JV') {
            const hasForbiddenAccount = bookList.some(
                (entry) => ['BANK', 'CASH', 'CHEQUE'].includes(entry.tfd_account_type)
            );
            if (hasForbiddenAccount) {
                setMsgText("No account should have type BANK , CHEQUE or CASH");
                setMsgBox(true);
                return; // Stop 
            } else {
                handleSaveData();
            }
        }
        if (expectedAccountType) {
            const hasExpectedAccount = bookList.some((entry) => {
                if (Array.isArray(expectedAccountType)) {
                    return expectedAccountType.includes(entry.tfd_account_type);
                } else {
                    return entry.tfd_account_type === expectedAccountType;
                }
            });
            // console.log(bookList);
            if (!hasExpectedAccount) {
                setMsgText(`At least one account should be of  '${expectedAccountType.join("' or '")}'`);
                setMsgBox(true);
                return; // Stop
            } else {
                handleSaveData();
            }
        }
    }

    const handleSaveData = () => {
        var msg = [], i = 0;
        console.log(bookList);
        console.log(totalCredit);
        console.log(totalDebit);
        console.log(totalDifference)
        if (bookList.length == '0') {
            setMsgText("You can't save without adding data");
            setMsgBox(true);
        } else if ((totalDifference != '0.00')) {
            msg[i++] = ((totalDifference != '0.00') ? "Total Debit and Total Credit should be equal" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (scrMode === 'E' && acRemark === '') {
            setShowModal(true);
            $(".loader").hide();
        }
        else {
            const obj = {
                remark: acRemark,
                activitytype: code == 1 ? 'MODIFY' : 'CREATE',
                subtranno: code,
                trannumber: srNo,
                trantype: selectedOption.value,
                trancategory: 'T',
                branch: locationCode,

                location: locationCode,
                date: Common.dateYMD(filterDate),
                type: selectedOption.value,
                narration: narration,
                bookList: bookList,
                radioType: radioType,
                scrMode: scrMode,
                srNo: srNo,
                totalCredit: totalCredit,
                totalDebit: totalDebit,
                chequeSrno: chequeSrno,
                chequeLine: chequeLine,
                chequeLocation: chequeLocation,
                chequeNum: chequeNum,
                randomString: randomString,
                knockoffValue: knockoffValue
            }
            console.log(obj);
            Common.callApi(Common.apiBookEntry, [sid, 'saveBookEntry', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setViewSrno(resp.srno);
                console.log(resp);
                if (radioType == 'N') {
                    setBookList(resp.resarray);
                    setSrNo(resp.srno);
                    setScrMode('Q');
                    setEditReqDate(Common.dateYMD(new Date()))
                } else {
                    setNarration(narration);
                    setBookList(resp.resarray);
                    setScrMode('Q');
                }
                setChecked(false);
                setAddCount(0);
                setIndividualList(false);
                setAcRemark('');
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: selectedOption.value,
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_BOOK_ENTRY'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }

    const handleShowList = () => {
        var msg = [], i = 0;
        if (selectedLocation === null || (radioType == 'S' && !srnoFrom) || (radioType == 'S' && !srnoTo) || (radioType == 'D' && !fromDate) || (radioType == 'D' && !toDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedLocation == null ? " Select location." : '');
            msg[i++] = (radioType == 'S' && !srnoFrom ? " From Srno." : '');
            msg[i++] = (radioType == 'S' && !srnoTo ? " To Srno." : '');
            msg[i++] = ((radioType == 'D' && !fromDate) ? " From date." : '');
            msg[i++] = ((radioType == 'D' && !toDate) ? " To date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                date: Common.dateYMD(filterDate),
                type: selectedOption.value,
                location: locationCode,
                radioType: radioType,
                fromSrno: srnoFrom,
                toSrno: srnoTo,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate)
            }
            Common.callApi(Common.apiBookEntry, [sid, 'showBookEntry', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setHeaderList(resp);
                setShowHeaderList(true);
            });
        }
    }

    const handleView = (vsrno, vtype, vdate, vnarration, date) => {
        $(".loader").show();
        setEditReqDate(date)
        if (radioType == 'N') {
            setRadioType('A');
        }
        setSrNo(vsrno);
        const obj = {
            srNo: vsrno,
            location: locationCode,
            date: vdate,
            type: vtype,
            narration: vnarration,
            operation: 'view'
        }
        Common.callApi(Common.apiBookEntry, [sid, 'viewdata', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setBookList(resp);
            $(".loader").hide();
            setScrMode('Q');
            setShowHeaderList(false);
            setShowList(true);
            setViewSrno(vsrno);
            setViewDate(vdate);
            setViewType(vtype);
            setNarration(vnarration);
            calculateTotal(resp);
            setNIndividual(null);
            // setSelectedLocation(null);
        });
    }

    const calculateTotal = (list) => {
        let totalDebt = 0;
        let totalCred = 0;
        let i;
        for (i = 0; i < list.length; i++) {
            let amt = 0;
            if (list[i].tfd_flag == 'D') {
                if (list[i].tfd_amount < 0) {
                    amt = -1 * list[i].tfd_amount;
                } else {
                    amt = list[i].tfd_amount;
                }
                totalDebt = totalDebt + amt * 1;
            } else {
                if (list[i].tfd_amount > 0) {
                    amt = -1 * list[i].tfd_amount;
                } else {
                    amt = list[i].tfd_amount;
                }
                totalCred += amt * 1;
            }
        }
        setTotalDebit(totalDebt);
        setTotalCredit(totalCred);
        let totalDiff = totalDebt + totalCred;
        console.log("hi", totalDiff, typeof totalDiff)
        setTotalDifference(totalDiff.toFixed(2));
        console.log(totalDiff);
        if (totalDiff < 0) {
            console.log("jjjjjj");
            setFlag('D');
            setAmount(-1 * totalDiff);
        } else if (totalDiff == 0) {
            setAmount("");
        }
        else {
            setFlag('C');
            setAmount(totalDiff);
        }
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        setViewAccount(value);
        const account = value.id.split('^');
        console.log(account);
        const groupCode = account[1];
        const accountType = account[4];
        setMvgType(accountType);
        setGrpCode(account[1]);
        setAccCode(account[2]);
        if (accountType == 'WALKIN') {
            setIndividualList(true);
            setChequeStatus(null);
        } else if (accountType == 'CHEQUE') {
            setFlag('D');
            setShowCheque(true);
            setNIndividual(null);
            setIndividualList(false);
            setChequeStatus("I");
        } else {
            setNIndividual(null);
            setIndividualList(false);
            setShowCheque(false);
            setChequeStatus(null);
        }
    }

    const handleClose = () => {
        setShow(false);
        setShowKnockoff(true);
    }

    const handleKnockoffSelective = () => {
        var msg = [], i = 0;
        if (ledgerValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue == null ? "Please select account." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                data: `${flag}^${grpCode}^${accCode}^${Common.dateYMD(filterDate)}`,
                limit: limit,
                srch: srch
            }
            Common.callApi(Common.apiBookEntry, [sid, 'getknockoff', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setKnockoffData(resp);
            });
            setShow(true);
            setShowKnockoff(false)
        }

    }

    const handleKnockoffValue = (value) => {
        setKnockoffValue(value);
        console.log(value);
        const knockoff = value.id.split('^');
        console.log(knockoff);
        setKnockoffSrno(knockoff[3]);
        setKnockoffType(knockoff[2]);
        setKnockoffAmt(knockoff[6]);
        setKnockoffLineNo(knockoff[4]);
        setKnockoffPending(knockoff[8]);
        setKnockoffFlag(knockoff[9]);
        setKnockoffLedger(knockoff[10]);
    }

    const updateKnockoffFields = (index, e, name) => {
        let tempArr = knockoffData.map((item, i) => {
            if (index === i) {
                if (name === "tfd_amount") {
                    return { ...item, [name]: e.target.value };
                } else {
                    if (e.target.checked) {
                        return { ...item, [name]: '1' };
                    } else {

                        return { ...item, [name]: '0' };
                    }
                }
            } else {
                return item;
            }
        });
        console.log(tempArr);
        setKnockoffData(tempArr);
    }

    const handleAddKnockoff = () => {
        const obj = {
            knockoffData: knockoffData,
            randomString: randomString,
        }
        Common.callApi(Common.apiBookEntry, [sid, 'addknockoffSelective', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setShow(false);
            setShowKnockoff(true);
            setCounter(counter + 1);
            setShowList(true);
            setFlag("C");
            setLedgerValue(null);
            setNIndividual(null);
            calculateTotal(resp);
            setKnockoffValue(null);
            setKnockoffType("");
            setKnockoffSrno("");
            setKnockoffAmt("");
            setAmount('');
        });
        for (let i = 0; i < knockoffData.length; i++) {
            knockoffData[i].tfd_transfer_type = "";
            knockoffData[i].tfd_cheque_status = "";
            knockoffData[i].cheque_date = "";
            knockoffData[i].tfd_cheque_bank_name = "";
            knockoffData[i].tfd_cheque_no = "";
            knockoffData[i].tfd_individual_code = "";
            knockoffData[i].tfd_account_type = "";
            knockoffData[i].chequeValueNum = "";
            if (knockoffData[i].ischecked == '0') continue;
            setBookList(Common.arrayAddItem(bookList, knockoffData[i]));
        }
    }

    const handleBack = () => {
        setScrMode('');
    }

    const handleCancel = () => {
        setMsgBoxYN(true);
        setMsgTextYN("Do you really want to exit");
    }

    const cancelData = () => {
        setScrMode('');
        setShowHeaderList(true);
        handleShowList();
        setChecked(false);
        setMsgBoxYN(false);
        setSelectedOption({ value: "BK", label: 'Bank Book' });
        setAmount("");
        setLedgerValue(null);
        setNIndividual(null);
        setIndividualList(false);
    }

    const handleDelete = (obj) => {
        // val, chqNo, loc, type, srno, lineno, amt
        // res.uniquekey, res.tfd_cheque_no, res.tfd_location, res.tfd_type, res.tfd_srno, res.tfd_line_no, res.tfd_amount
        console.log(obj);
        if (scrMode == 'E') {
            // const chq = val.split('^');
            // console.log(chq);
            // const obj = {
            //     location: chq[0],
            //     type: chq[1],
            //     srno: chq[2],
            //     lineno: chq[3],
            //     chqNo: chqNo
            // }
            // console.log(obj);
            // Common.callApi(Common.apiBookEntry, [sid, 'deleteCheque', JSON.stringify(obj)], (result) => {
            // });
            const object = {
                location: obj.tfd_location,
                type: obj.tfd_type,
                srno: obj.tfd_srno,
                lineno: obj.tfd_line_no,
                amt: obj.tfd_amount < 0 ? -1 * obj.tfd_amount : obj.tfd_amount,
                randomString: randomString
            }
            console.log(object);
            Common.callApi(Common.apiBookEntry, [sid, 'deleteKnockoff', JSON.stringify(object)], (result) => {
                let resp = JSON.parse(result);
                setBookList(Common.arrayRemoveItem(bookList, "uniquekey", obj.uniquekey));
                console.log(bookList);
                setCounter(counter - 1);
                console.log(bookList);
                calculateTotal(bookList);
            });
        } else {
            console.log(obj.uniquekey);
            setBookList(Common.arrayRemoveItem(bookList, "uniquekey", obj.uniquekey));
            setCounter(counter - 1);
            console.log(bookList);
            calculateTotal(bookList);
            console.log(bookList);
        }
    }


    const handleChange = value => {
        setSelectedLocation(value);
        // console.log(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleChequeNum = value => {
        setChequeValue(value);
        // console.log(value);
        const cheque = value.id.split('^');
        console.log(cheque);
        setChequeNum(cheque[4]);
        setChequeValueNum(cheque[4]);
        setChequeSrno(cheque[1]);
        setChequeLine(cheque[2]);
        setChequeLocation(cheque[3]);
        setChequeDate(cheque[5]);

    }

    const newIndividual = () => {
        setShowIndividualForm(true);
    }

    const handleNewIndiviualChange = (value) => {
        console.log(value);
        setNIndividual(value);
        if (nCorporate == null) {
            setNState({ value: faStateValue, label: faStateName });
        }
    }

    const hideIndividualProfile = (val, individualsrno) => {
        setShowIndividualForm(val);
        if (individualsrno > 0) {
            Common.callApi(Common.apiBookEntry, [sid, "individual", individualsrno], result => {
                console.log(result);
                let resp = JSON.parse(result);
                setNIndividual(resp);
            });
        }
    }

    const handleFlagChange = (e) => {
        setFlag(e.target.value);
        const selectedFlag = e.target.value;
        if (ledgerValue != null) {
            const account = ledgerValue.id.split('^');
            if (account[4] == 'CHEQUE' && selectedFlag == 'C') {
                setMsgText("'CHEQUE'can not be Credit");
                setMsgBox(true);
                setFlag('D')
            }
        }
    }

    const handleDownload = () => {
        setDiplayFlag('P');
    }

    const hidePrint = (data) => {
        setDiplayFlag(data);
    }

    const handlePrintAdvance = () => {
        setDiplayFlag('A');
    }

    const handleReset = () => {
        setNIndividual(null);
    }

    const btnSaveModal = () => {
        if (scrMode === 'E' && acRemark === '') {
            setShowModal(false);
            setMsgText('Please fill Activity Remark!');
            setMsgBox(true);
        } else {
            handleSaveData();
            setShowModal(false);
        }
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: selectedOption.value, trannumber: srNo, trancategory: "T" });
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = editReqDate.split('/');
        console.log(newDate);
        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;
        console.log(dateB, dateA)
        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: srNo === 0 ? viewSrno : srNo,
            type: selectedOption.value,
            pageright: 'TRAN_BOOK_ENTRY'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: selectedOption.value, trannumber: viewSrno == 0 ? srNo : viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }

    return (
        <>

            {
                displayFlag === 'P' ?
                    <>
                        <Tran_fa_book_print srno={srNo} location={locationCode} type={selectedOption.value} hidePrint={hidePrint} />
                    </>
                    : displayFlag === 'A' ?
                        <>
                            <Tran_fa_book_print_advance srno={srNo} location={locationCode} type={selectedOption.value} hidePrint={hidePrint} />
                        </>
                        :
                        <>
                            {
                                showIndividualForm ?
                                    <Master_individual_profile value="FABOOK" hideIndividualProfile={hideIndividualProfile} />
                                    : <>
                                        <Login_header />
                                        <Menu />
                                        <Container fluid>

                                            <Row>
                                                <Col>
                                                    <h2>FA Book Entry  <span style={{ color: 'white' }}>{counter}</span></h2>
                                                </Col>
                                            </Row>
                                            &nbsp;
                                            {
                                                scrMode == '' ?
                                                    <>
                                                        <Row>

                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={selectedLocation}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={(e) => handleChange(e)}
                                                                        isDisabled={branchDisable === "true"}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>FA Type<span className="text-danger">*</span></Form.Label>
                                                                    <Select value={selectedOption} onChange={(e) => setSelectedOption(e)} options={options} />
                                                                </Form.Group>
                                                            </Col>

                                                            <Col className='col-md-4 col-6'>
                                                                <Form.Group>
                                                                    <Row style={{ marginTop: "30px" }}>
                                                                        <Col>
                                                                            <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" selected />
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>&nbsp;
                                                        <Row>
                                                            {
                                                                radioType === "N" ?
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                                            <DatePicker className="form-control"
                                                                                selected={filterDate}
                                                                                onChange={(date) => setFilterDate(date)}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                showYearDropdown
                                                                                showMonthDropdown
                                                                                useShortMonthInDropdown
                                                                                dropdownMode="select"
                                                                                placeholder="dd/mm/yyyy"
                                                                                peekNextMonth
                                                                                // maxDate={new Date()}
                                                                                customInput={
                                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                }
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    : radioType === "S" ?
                                                                        <>
                                                                            <Col className='col-md-3 col-6'>
                                                                                <Form.Group>
                                                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control value={srnoFrom}
                                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setSrnoFrom)}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className='col-md-3 col-6'>
                                                                                <Form.Group>
                                                                                    <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control value={srnoTo}
                                                                                        onChange={(e) => Common.validateNumValue(e.target.value, setSrnoTo)}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </>
                                                                        : radioType === "D" ?
                                                                            <>
                                                                                <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>
                                                                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                                                        <DatePicker className="form-control"
                                                                                            selected={fromDate}
                                                                                            onChange={(date) => setFromDate(date)}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            showYearDropdown
                                                                                            showMonthDropdown
                                                                                            useShortMonthInDropdown
                                                                                            dropdownMode="select"
                                                                                            peekNextMonth
                                                                                            customInput={
                                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>
                                                                                        <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                                                        <DatePicker className="form-control"
                                                                                            selected={toDate}
                                                                                            onChange={(date) => setToDate(date)}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            showYearDropdown
                                                                                            showMonthDropdown
                                                                                            useShortMonthInDropdown
                                                                                            dropdownMode="select"
                                                                                            peekNextMonth
                                                                                            customInput={
                                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </>
                                                                            : null
                                                            }
                                                        </Row>&nbsp;

                                                        <Row>
                                                            <Col>
                                                                {radioType == 'N' ?
                                                                    <Button variant='outline-success' className='buttonStyle' size='sm'
                                                                        onClick={handleAddNew}>Add New
                                                                    </Button>
                                                                    :
                                                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={handleShowList}>
                                                                        List
                                                                    </Button>
                                                                }
                                                            </Col>
                                                        </Row>&nbsp;
                                                        {
                                                            showHeaderList &&
                                                            <Row>
                                                                <Col>
                                                                    <Table striped bordered hover>
                                                                        <thead>
                                                                            <tr>
                                                                                {userRight.add === "1" ? <th></th> : null}
                                                                                <th>Srno.</th>
                                                                                <th>Type</th>
                                                                                <th>Date</th>
                                                                                <th>Total Debit</th>
                                                                                <th>Total Credit</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {headerList.map((res) =>
                                                                                <tr key={res.tfd_line_no}>
                                                                                    {userRight.delete === "1" ?
                                                                                        <td className="textCenter" >
                                                                                            {
                                                                                                <span className="handCursor"
                                                                                                    onClick={() => handleView(res.tfh_srno, res.tfh_type, res.formatted_date, res.tfh_narration, res.tfh_date)}
                                                                                                >
                                                                                                    <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                                                                </span>
                                                                                            }
                                                                                        </td>
                                                                                        : null
                                                                                    }
                                                                                    <td>{res.tfh_srno}</td>
                                                                                    <td>{res.tfh_type}</td>
                                                                                    <td>{res.formatted_date}</td>
                                                                                    <td>{res.tfh_total_debit}</td>
                                                                                    <td>{res.tfh_total_credit * -1}</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col >
                                                                {scrMode == 'A' || scrMode == 'E' ?
                                                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleCancel} >Cancel</Button>
                                                                    : <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>
                                                                }&nbsp;
                                                                {
                                                                    (userRight.edit === "1" && scrMode === 'Q') ? <>
                                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                            btnEditRequest()}>Edit</Button>&nbsp;
                                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() =>
                                                                            handleDownload()}>Print</Button> &nbsp;
                                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                            handlePrintAdvance()}>Advance
                                                                        </Button>
                                                                    </> : null
                                                                }
                                                                {
                                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ?
                                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSave} >Save</Button> : null
                                                                }
                                                                &nbsp;
                                                                {
                                                                    (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ? <>
                                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                            btnActivityLog()}>Activity Log</Button>
                                                                    </> : null
                                                                }
                                                            </Col>
                                                        </Row>&nbsp;
                                                        <Row>
                                                            {/* { */}
                                                            {/* // srNo > 0 && */}
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>SrNo : {srNo}</Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                            {/* } */}
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Type : {viewType}</Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Location : {location}</Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Date : {viewDate}</Form.Label>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        &nbsp;

                                                        {scrMode == 'E' || scrMode == 'A' ?
                                                            <>
                                                                <Row>

                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Cr/Dr<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Select value={flag} onChange={(e) => handleFlagChange(e)} required>
                                                                                <option value="C">Credit</option>
                                                                                <option value="D">Debit</option>
                                                                            </Form.Select>
                                                                            {/* <Select value={flag} onChange={(e) => setFlag(e)} options={flagOptions} isSearchable={false} /> */}
                                                                        </Form.Group>

                                                                    </Col>

                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Account<span className="text-danger">*</span></Form.Label>
                                                                            <AsyncSelect
                                                                                cacheOptions={false}
                                                                                defaultOptions={false}
                                                                                value={ledgerValue}
                                                                                getOptionLabel={e => e.label + ' '}
                                                                                getOptionValue={e => e.id}
                                                                                loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                                                                onInputChange={Picklist.handleInputChange}
                                                                                onChange={handleLedgerValue}
                                                                            >
                                                                            </AsyncSelect>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control
                                                                                value={amount}
                                                                                maxLength={Common.maximumLength}
                                                                                onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                                                                                placeholder="Amount"
                                                                                disabled={!showKnockoff}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>&nbsp;

                                                                <Row>
                                                                    {showKnockoff &&
                                                                        <Col className='col-md-9 col-12'>
                                                                            <Form.Group>
                                                                                <Form.Label>Knockoff<span className="text-danger">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={knockoffValue}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={(search) => Picklist.fetchKnockoffPicklist(search, 1, `${flag}^${grpCode}^${accCode}^${Common.dateYMD(filterDate)}`)} // location = 1 , 2
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleKnockoffValue}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }
                                                                </Row>&nbsp;
                                                                <Row>
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Button variant="outline-primary" onClick={handleKnockoffSelective}>
                                                                            Selective Knockoff
                                                                        </Button>
                                                                    </Col></Row>&nbsp;

                                                                <Modal show={show} onHide={handleClose} size='xl'>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Selective Knockoff</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <Row>
                                                                            <Col className='col-md-4 '>
                                                                                <Form.Label>Search</Form.Label>
                                                                                <Form.Control
                                                                                    value={srch}
                                                                                    maxLength={Common.maximumLength}
                                                                                    placeholder="Search"
                                                                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setSrch)}
                                                                                />
                                                                            </Col>&nbsp;
                                                                            <Col className='col-md-4 '>
                                                                                <Form.Label>Limit</Form.Label>
                                                                                <Form.Select value={limit} onChange={(e) => setLimit(e.target.value)} required>
                                                                                    <option value="10">10</option>
                                                                                    <option value="2">15</option>
                                                                                    <option value="20">20</option>
                                                                                </Form.Select>
                                                                            </Col>&nbsp;

                                                                            <Col className='col-md-3 '>
                                                                                <Button variant="outline-primary" onClick={handleKnockoffSelective}>
                                                                                    Search
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                <Table striped bordered hover>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th></th>
                                                                                            <th>Pending</th>
                                                                                            <th>Type</th>
                                                                                            <th>Date</th>
                                                                                            <th>Total Amount</th>
                                                                                            <th>KnockOff Amount</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {knockoffData.map((res, index) =>
                                                                                            <tr key={index}>
                                                                                                <td> <Form.Check
                                                                                                    type="checkbox"
                                                                                                    checked={res.ischecked == '1'}
                                                                                                    name="ischecked"
                                                                                                    onChange={(e) => updateKnockoffFields(index, e, "ischecked")}
                                                                                                /></td>
                                                                                                <td> <Form.Control value={res.tfd_amount} name='tfd_amount'
                                                                                                    onChange={(e) => updateKnockoffFields(index, e, "tfd_amount")}
                                                                                                /></td>
                                                                                                <td>{res.tfd_knockoff_type}</td>
                                                                                                <td>{res.tfd_date}</td>
                                                                                                <td>{res.total_amount}</td>
                                                                                                <td>{res.knockoff_amount}</td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </Col>
                                                                        </Row>


                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={handleClose}>
                                                                            Close
                                                                        </Button>
                                                                        <Button variant="primary" onClick={handleAddKnockoff}>
                                                                            Add
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                <Row>
                                                                    {mvgType == 'BANK' &&
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                                                <Form.Select value={type} onChange={(e) => setType(e.target.value)} required>
                                                                                    <option value="T">Transfer/Ref No.</option>
                                                                                    <option value="C">Cheque</option>
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }
                                                                    {
                                                                        mvgType == "CHEQUE" ?
                                                                            <>
                                                                                <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            value={chequeNum}
                                                                                            maxLength={Common.maximumLength}
                                                                                            onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                                            placeholder={mvgType == 'CHEQUE' || mvgType == 'BANK' && type == 'C' ? 'Cheque Number' : 'Reference Number'}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Bank Name<span className="text-danger">*</span></Form.Label>
                                                                                        <Form.Control
                                                                                            value={bankName}
                                                                                            maxLength={100}
                                                                                            onChange={(e) => Common.validateAlpValue(e.target.value, setBankName)}
                                                                                            placeholder="Bank Name"
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Cheque Date<span className="text-danger">*</span></Form.Label><br />
                                                                                        <DatePicker className="form-control"
                                                                                            selected={chequeDate}
                                                                                            onChange={(date) => setChequeDate(date)}
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            showYearDropdown
                                                                                            showMonthDropdown
                                                                                            useShortMonthInDropdown
                                                                                            dropdownMode="select"
                                                                                            placeholder="dd/mm/yyyy"
                                                                                            peekNextMonth
                                                                                            customInput={
                                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                            }
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </> : mvgType == "BANK" ?
                                                                                <> <Col className='col-md-3 col-6'>
                                                                                    <Form.Group>

                                                                                        {
                                                                                            flag == 'C' && type == 'T' &&
                                                                                            <>
                                                                                                <Form.Label>Reference Number<span className="text-danger">*</span></Form.Label>
                                                                                                <Form.Control
                                                                                                    value={chequeNum}
                                                                                                    maxLength={100}
                                                                                                    onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                                                    placeholder="Reference Number"
                                                                                                />
                                                                                            </>

                                                                                        }

                                                                                        {
                                                                                            flag == 'C' && type == 'C' &&
                                                                                            <>
                                                                                                <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                                                <AsyncSelect
                                                                                                    cacheOptions={false}
                                                                                                    defaultOptions={false}
                                                                                                    value={chequeValue}
                                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                                    getOptionValue={e => e.id}
                                                                                                    loadOptions={(search) => Picklist.fetchChequeBookBankPicklist(search, grpCode, accCode, locationCode)}
                                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                                    onChange={(e) => handleChequeNum(e)}
                                                                                                >
                                                                                                </AsyncSelect>
                                                                                            </>
                                                                                        }

                                                                                        {
                                                                                            flag == 'D' && type == 'T' &&
                                                                                            <>
                                                                                                <Form.Label>Reference Number<span className="text-danger">*</span></Form.Label>
                                                                                                <Form.Control
                                                                                                    value={chequeNum}
                                                                                                    maxLength={100}
                                                                                                    onChange={(e) => Common.validateAlpNumSplValue(e.target.value, setChequeNum)}
                                                                                                    placeholder="Reference Number"
                                                                                                />
                                                                                            </>
                                                                                        }&nbsp;

                                                                                        {
                                                                                            flag == 'D' && type == 'C' &&
                                                                                            <>
                                                                                                <Form.Label>Cheque Number<span className="text-danger">*</span></Form.Label>
                                                                                                <AsyncSelect
                                                                                                    cacheOptions={false}
                                                                                                    defaultOptions={false}
                                                                                                    value={chequeValue}
                                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                                    getOptionValue={e => e.id}
                                                                                                    loadOptions={(search) => Picklist.fetchChequeBookBankPicklist(search, grpCode, accCode, locationCode)}
                                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                                    onChange={(e) => handleChequeNum(e)}
                                                                                                >
                                                                                                </AsyncSelect>
                                                                                            </>
                                                                                        }&nbsp;
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                    {flag == 'D' && type == 'C' &&
                                                                                        <Col className='col-md-3 col-6'>
                                                                                            <Form.Group>
                                                                                                <Form.Label>Bank Name<span className="text-danger">*</span></Form.Label>
                                                                                                <Form.Control
                                                                                                    value={bankName}
                                                                                                    maxLength={100}
                                                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setBankName)}
                                                                                                    placeholder="Bank Name"
                                                                                                />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    }&nbsp;
                                                                                </>
                                                                                : null
                                                                    }

                                                                </Row>
                                                                <Row>
                                                                    {showIndividualList &&
                                                                        <Col className="col-md-9  col-12">
                                                                            <Form.Group>
                                                                                <Form.Label>Individual Profile
                                                                                    {
                                                                                        scrMode === "A" &&
                                                                                        <span>  <span className='tranNewFormText'
                                                                                            onClick={() => newIndividual()}
                                                                                        >Not in list? Create New </span>
                                                                                            <span>
                                                                                                <Badge bg="secondary" onClick={handleReset}>Reset</Badge>
                                                                                            </span>
                                                                                        </span>

                                                                                    }
                                                                                </Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={nIndividual}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={Picklist.fetchIndividualMainPicklist}
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleNewIndiviualChange}
                                                                                    isDisabled={scrMode === "Q"}
                                                                                    formatOptionLabel={Picklist.formatOptionLabel}
                                                                                    components={{ Menu: Picklist.menu }}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    }
                                                                </Row>&nbsp;
                                                                <Row>
                                                                    <Col className="col-md-3 col-6">
                                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAdd} >Add</Button>&nbsp;
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label="Show Additional Details"
                                                                            checked={isChecked}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </> : null
                                                        }&nbsp;
                                                        {showList &&
                                                            <Row>
                                                                <Col>
                                                                    <Table striped bordered hover>
                                                                        <thead>
                                                                            {
                                                                                totalDifference != '0' &&
                                                                                <>
                                                                                    <tr>
                                                                                        {
                                                                                            isChecked ?
                                                                                                <td
                                                                                                    colSpan={10}
                                                                                                ></td> : <td
                                                                                                    colSpan={7}
                                                                                                ></td>
                                                                                        }
                                                                                        {
                                                                                            totalDifference != 0 &&
                                                                                            <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Difference:</b> <span>{totalDifference}</span></td>
                                                                                        }

                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            <tr>
                                                                                {
                                                                                    isChecked ?
                                                                                        <td
                                                                                            colSpan={9}
                                                                                        ></td> : <td
                                                                                            colSpan={6}
                                                                                        ></td>
                                                                                }
                                                                                {/* <td
                                                                                    colSpan={8}
                                                                                ></td> */}
                                                                                <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Debit:</b> <span>{Math.abs(totalDebit)}</span></td>
                                                                                <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Credit:</b> <span>{Math.abs(totalCredit)}</span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                {userRight.delete === "1" ? <th>Action</th> : null}
                                                                                {/* <th>Sr No</th> */}
                                                                                <th>Group</th>
                                                                                <th>Account</th>
                                                                                {/* <th>Account Type</th> */}
                                                                                <th>Account Name</th>
                                                                                <th>Individual</th>
                                                                                <th>Knockoff</th>

                                                                                <th style={{ textAlign: 'right' }}>Debit</th>
                                                                                <th style={{ textAlign: 'right' }}>Credit</th>
                                                                                {isChecked && <>
                                                                                    <th>Cheque Number</th>
                                                                                    <th>Cheque Date</th>
                                                                                    <th>Cheque Bank Name</th>
                                                                                </>}

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {bookList.map((res) =>
                                                                                <tr key={res.tfd_line_no}>
                                                                                    {userRight.delete === "1" && (scrMode == 'E' || scrMode == 'A') ?
                                                                                        <td className="textCenter" >
                                                                                            {
                                                                                                  res.delny == "Y" ?
                                                                                                    <span className="handCursor colorThemeBlue"
                                                                                                        onClick={() => handleDelete(res)}>
                                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                                    </span>
                                                                                                    : null
                                                                                            }
                                                                                        </td>
                                                                                        : null
                                                                                    }
                                                                                    {/* <td>{res.tfd_line_no}</td> */}
                                                                                    <td>{res.tfd_group}</td>
                                                                                    <td>{res.tfd_account}</td>
                                                                                    <td>{res.mvli_ledger_name}</td>
                                                                                    <td>{res.tfd_individual}</td>
                                                                                    <td>{res.tfd_knockoff_srno == "null" || res.tfd_knockoff_srno == null ? null : res.tfd_knockoff_type + '^' + res.tfd_knockoff_srno + '^' + res.tfd_knockoff_lineno}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'D' && Math.abs(res.tfd_amount)}</td>
                                                                                    <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'C' && Math.abs(res.tfd_amount)}
                                                                                    </td>
                                                                                    {isChecked && <>
                                                                                        <td>{res.tfd_cheque_no}</td>
                                                                                        <td>{res.cheque_date}</td>
                                                                                        <td>{res.tfd_cheque_bank_name}</td>
                                                                                    </>}

                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {scrMode != '' ?
                                                            <FloatingLabel controlId="floatingTextarea2" label="Narration">
                                                                <Form.Control
                                                                    value={narration}
                                                                    maxLength={500}
                                                                    as="textarea"
                                                                    placeholder="Leave a comment here"
                                                                    style={{ height: '100px' }}
                                                                    onChange={(e) => setNarration(e.target.value)}
                                                                    disabled={scrMode == 'Q'}
                                                                />
                                                            </FloatingLabel>
                                                            : null
                                                        }
                                                    </>
                                            }
                                            <Row>&nbsp;</Row>
                                            <Row>&nbsp;</Row>
                                            <Row>&nbsp;</Row>
                                        </Container>
                                    </>
                            }
                        </>
            }


            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            scrMode === 'E' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Reason" maxLength={499}
                                            value={acRemark}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAcRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group>
                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnSaveModal()}>
                            Save
                        </Button>{' '}
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Tran_fa_book;
