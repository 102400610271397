import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_data_unlock_request from '../Tran_data_unlock_request';

function Tran_si() {
    const taxClassOPtions = [{ value: 0, label: "Select" },
    { value: 1, label: "Gst" },
    { value: 2, label: "Unregistered" },
    { value: 3, label: "Gst Excluded" }];
    const productRef = useRef();
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [key, setKey] = useState("INFO");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [accountFilter, setAccountFilter] = useState(null);

    const [counter, setCounter] = useState(0);
    const [hdrSrno, setHdrSrno] = useState(0);
    const [hdrDate, setHdrDate] = useState(new Date());
    const [radioType, setRadioType] = useState("N");
    const [siLocation, setSILocation] = useState(null);
    const [userRight, setUserRight] = useState([]);

    const [siNewDate, setSINewDate] = useState(new Date());
    const [siSrnoFrom, setSISrnoFrom] = useState("");
    const [siSrnoTo, setSISrnoTo] = useState("");
    const [siFromDate, setSIFromDate] = useState(new Date());
    const [siToDate, setSIToDate] = useState(new Date());
    const [siList, setSIList] = useState([]);
    const [siParty, setSIParty] = useState(null);

    const [nParty, setNParty] = useState(null);
    const [nShipTo, setNShipTo] = useState(null);
    const [nBillDate, setNBillDate] = useState(null);
    const [nPODate, setNPODate] = useState(null);
    const [nManualBill, setNManualBill] = useState("");
    const [nReverseCharge, setNReverseCharge] = useState("1");
    const [nRemark1, setNRemark1] = useState("");
    const [nRemark2, setNRemark2] = useState("");
    const [nState, setNState] = useState();
    const [nStateReceiver, setNStateReceiver] = useState(null);
    const [nStateSupplier, setNStateSupplier] = useState(null);
    const [nGstin, setNGstin] = useState("");
    const [nTaxClass, setNTaxClass] = useState("");

    const [siLocationValue, setSILocationValue] = useState(0);
    const [siStateValue, setSIStateValue] = useState(0);
    const [siStateType, setSIStateType] = useState("");
    const [siStateName, setSIStateName] = useState("");
    const [hdrLocation, setHdrLocation] = useState(0);
    const [scrMode, setScrMode] = useState("");
    const [showFaView, setShowFaView] = useState(false);
    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });

    const [pAccount, setPAccount] = useState(null);
    const [pAmount, setPAmount] = useState("");
    const [pFCgst, setPFCgst] = useState("");
    const [pFSgst, setPFSgst] = useState("");
    const [pTds, setPTds] = useState("");
    const [pTds1, setPTds1] = useState("");
    const [pSac, setPSac] = useState("");
    const [pTaxable, setPTaxable] = useState("");

    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setSIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);
    const [productList, setProductList] = useState([]);
    const [productLineNo, setProductLineNo] = useState(1);

    const [disableOtherCharge, setDisableOtherCharge] = useState(true);

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [pdAccountOptions, setPdAccountOptions] = useState([]);
    const [pdAccountValue, setPdAccountValue] = useState("");
    const [pdGroupValue, setPdGroupValue] = useState(0);
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentLineNo, setPaymentLineNo] = useState(1);
    const [paymentData, setPaymentData] = useState([]);

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [tranAuditTrail, setTranAuditTrail] = useState([]);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [nPono, setNPono] = useState("");
    const [nEwayBillNo, setNEwayBillNo] = useState('');
    const [nPaymentDueDate, setNPaymentDueDate] = useState(new Date());
    const [pQuantity, setPQuantity] = useState("");
    const [pRate, setPRate] = useState("");
    const [nManualNo, setNManualno] = useState(null);
    const [pservice, setPService] = useState("Y");
    const [narration, setNarration] = useState("");

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setSILocation(response);
            response != null && handleLocationChange(response);
        });

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_SI'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        // Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", pProduct], result => {
        //     setIsdOptions(JSON.parse(result));
        // });
        // Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
        //     setResidentOptions(JSON.parse(result));
        // });
        // Common.callApi(Common.apiState, [sid, "getState", 1], result => {
        //     setStateOptions(JSON.parse(result));
        // });
    }, []);

    const handleLocationChange = (value) => {
        console.log("this is location Value", value);
        setSILocation(value);

        var id = value.id;
        var location = id.split("^");

        setSILocationValue(location[1]);
        setSIStateValue(location[3] == null ? 0 : location[3]);
        setSIStateType(location[4]);
        setSIStateName(location[5] === "" ? "Select" : location[5]);
        setHdrLocation(location[2]);

        setNStateSupplier({
            id: location[3] == null ? 0 : location[3],
            label: location[5] === "" ? "Select" : location[5]
        });

        sessionStorage.activeBranch = location[1];
    };



    const handleProductAccountChange = (value) => {
        setSIParty(value);
    }

    const handlePartyChange = (value) => {
        setNParty(value);
        setNShipTo(value);
        var id = value.id;
        var location = id.split("^");
        setNStateReceiver({
            id: location[5] == null ? 0 : location[5],
            label: location[6] === "" ? "Select" : location[6]
        });
        setNGstin(location[7]);
        const matchingOption = taxClassOPtions.find(val => val.value == location[8]);
        if (matchingOption) {
            console.log("Setting the tax class to:", matchingOption.label);
            setNTaxClass(matchingOption.value);
        } else {
            console.log("No match found for the value in location[8]");
        }



        //setNState({ value: corp[3], label: corp[4] });
    }
    const handleShipChange = (value) => {
        console.log("this is value of shipoooiung ", value)
        setNShipTo(value);
    }

    const handleAccountFilter = (value) => {
        setAccountFilter(value);
    }

    const handleTabChange = (k) => {
        setKey(k);
        // if (k === "PAYMENT") {
        //     var amt = 0;
        //     for (var i = 0; i < paymentData.length; i++) {
        //         amt += paymentData[i].tp_amount * 1;
        //     }
        //     setPdAmount(1 * pTotalAmount - amt);
        //     const obj = {
        //         nCorporate: nCorporate
        //     }
        //     Common.callApi(Common.apiTranPP, [sid, "getcorporate", JSON.stringify(obj)], result => {
        //         setPdAccountOptions(JSON.parse(result));
        //     });
        // }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const otherChargeChange = (v) => {
        const obj = {
            type: "PURCHASE",
            uniquekey: Common.getRandomString(15),
            nState: nState.value,
            pOtherCharges: v === "" ? 0 : v,
            ppStateType: siStateType,
            ppStateValue: siStateValue
        }
        Common.validateDecValue(v, setPOtherCharges);
        //calcInvoice(pProductList, obj);
    }

    const calcInvoice = (productlist) => {
        let forextotal = 0;
        let sgst = 0;
        let cgst = 0;
        let taxablevalue = 0;
        for (var i = 0; i < productlist.length; i++) {
            forextotal = forextotal + productlist[i].inv_amount * 1;
            sgst = sgst + productlist[i].inv_sgst * 1;
            cgst = cgst + productlist[i].inv_cgst * 1;
            taxablevalue = taxablevalue + productlist[i].inv_taxable * 1;
        }
        let totalamount = forextotal + sgst + cgst;
        let roundoff = Math.round(totalamount) - totalamount;
        setPForexTotal(forextotal.toFixed(2));
        setPSgst(sgst.toFixed(2));
        setPTotalAmount(totalamount.toFixed(2));
        setPTaxableVal(taxablevalue.toFixed(2));
        setPRoundOff(roundoff.toFixed(2));
        setPCgst(cgst.toFixed(2));
        setSIgst(0);
        setPUgst(0);
        var amt = 0;
        for (var i = 0; i < paymentData.length; i++) {
            amt += paymentData[i].tp_amount * 1;
        }
        setPdAmount(1 * totalamount - amt);
    }

    const switchToPaymentTab = (e) => {
        if (e.key === 'Enter') setKey("PAYMENT");
        else if (e.key === "Tab") {
            e.preventDefault();
            productRef.current.focus();
        }
    }

    const handleAccountValue = (value) => {
        setPdAccount(value);
        var id = value.id.split("^");
        setPdGroupValue(id[1]);
        setPdAccountValue(id[2]);
    }

    const handlePaymentType = (v) => {
        setPdPaymentType(v);
        setPdAccount(null);
        setPdAccountValue(0);
        setPdChequeNum("");
        var ldgType = "";
        if (v === "") return;
        if (v === "CH") {
            ldgType = "CASH";
        } else if (v === "BN" || v === "CQ") {
            ldgType = "BANK";
            setLdgSrchType("BANK");
            return;
        } else if (v === "RC") {
            if (nParty !== null) {
                setPdAccount(nParty);
                // setDisablePaymentAccount(true);
                return;
            }
        }
        setLdgSrchType(ldgType);
        Common.callApi(Common.apiLedger, [sid, "getledgerinfo", ldgType], result => {
            let resp = JSON.parse(result);
            setPdAccount({ id: resp.id, label: resp.label });
        });
    }

    const addNewForm = () => {
        var d = Common.dateYMD(siNewDate);
        var ddmy = Common.dateDMY(siNewDate);
        var today = Common.dateYMD(new Date());
        if (siLocation == null) {
            setMsgBox(true);
            setMsgText("Please select location");
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else if (d > today) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0011"));
        } else {
            setHdrDate(ddmy);
            setScrMode('A');
            setKey('INFO');
            setHdrSrno(0);
            // setNBillDate(null);
            setNParty(null);
            setNStateReceiver(null);
            setNGstin("");
            setNTaxClass("");
            setNShipTo(null);
            setNPono("");
            setNPODate(null);
            setNEwayBillNo("");
            setNPaymentDueDate(siNewDate);
            setProductList([]);
            setPaymentData([]);
            setPTotalAmount(0);
            setPForexTotal(0);

            setSIParty(null);
            setNReverseCharge("1");
            setNManualBill("");
        }
    }


    const addProduct = () => {
        var msg = [], i = 0;
        if (siParty === null || !pAmount || !pTaxable || !pFCgst || !pFSgst || !pSac) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (siParty === null ? " Account." : "");
            msg[i++] = (!pAmount ? " Amount." : "");
            msg[i++] = (!pTaxable ? " GST Taxable Value(INR)." : "");
            msg[i++] = (!pFCgst ? " CGST." : "");
            msg[i++] = (!pFSgst ? " SGST." : "");
            msg[i++] = (!pSac ? " SAC." : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            let accnt = 0;
            let grp = 0;
            let corp = siParty.id.split("^");
            grp = corp[1];
            accnt = corp[2];
            const obj = {
                type: "PURCHASE",
                uniquekey: Common.getRandomString(15),
                inv_line_no: productLineNo,
                inv_location: siLocationValue,
                inv_type: 'SI',
                inv_group: grp,
                inv_account: accnt,
                inv_srno: hdrSrno,
                inv_date: Common.dateYMD(siNewDate),
                inv_amount: pAmount,
                inv_taxable: pTaxable,
                inv_sac: pSac,
                inv_tds: pTds === "" ? 0 : pTds,
                inv_sgst: pFSgst,
                inv_cgst: pFCgst,
                inv_pservice: pservice,
                inv_narration: narration,
                prd_product: 'SI',
                prd_amount: pAmount,
                delny: "Y"
            }
            var productlist = Common.arrayAddItem(productList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            const obj2 = {
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                // TLine: productLineNo + 1,
                TLoc: siLocationValue,
                TType: 'SI',
                Group: grp,
                Ledger: accnt,
                Amount: pAmount,
                Taxable: pTaxable,
                SAC: pSac,
                TDS: pTds,
                SGST: pFSgst,
                CGST: pFCgst,


            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            setProductLineNo(productLineNo + 1);
            setProductList(productlist);
            setPAccount(null);
            setPAmount("");
            setPTaxable("");
            setPFCgst("");
            setPFSgst("");
            setPSac("");
            setPTds("");
            setPTds1("");
            setPQuantity("");
            setPRate("");
            setPService("");
            setNarration("");
            calcInvoice(productlist);
            setSIParty(null);
        }
    }

    const addPayment = () => {
        var msg = [], i = 0;
        if (pTotalAmount < 1) {
            msg[i++] = (pTotalAmount < 1 ? "Total amount must be greater than 0." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pdPaymentType === "" || pdAccount === null || pdAmount < 1 || (pdPaymentType === "BN" && pdChequeNum === "")) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pdAccount === null ? "Account." : '');
            msg[i++] = (pdPaymentType === "" ? "Payment Type." : '');
            msg[i++] = (pdAmount < 1 ? "Entered amount can't be 0." : '');
            if (pdPaymentType === "BN" && pdChequeNum === "") msg[i++] = (pdChequeNum === "" ? "Refernece Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var grp = 0;
            var accnt = 0;
            var accntname = '';
            if (pdAccount !== null) {
                var a = pdAccount.id.split("^");
                grp = a[1];
                accnt = a[2];
                if (pdPaymentType === 'RC') {
                    accntname = a[3];
                } else {
                    accntname = a[5];
                }
            }
            const obj = {
                uniquekey: Common.getRandomString(10),
                tp_line_no: paymentLineNo,
                tp_location: siLocationValue,
                tp_srno: hdrSrno,
                tp_payment_type: pdPaymentType,
                tp_account: accnt,
                tp_group: grp,
                mvli_ledger_name: accntname,
                tp_amount: pdAmount,
                tp_cheque: pdChequeNum,
                tp_type: "SINV",
                prd_product: 'SI',
                delny: 'Y'
            }
            const paymentdata = Common.arrayAddItem(paymentData, obj);
            const obj2 = {
                TabType: "Payment",
                AType: "A",
                ALineNo: tranAuditTrail.length + 1,
                // TLine: paymentLineNo + 1,
                TLoc: siLocationValue,
                TType: 'SI',
                PaymentType: pdPaymentType,
                PaymentLedgerCode: accnt,
                PaymentGroupCode: grp,
                PaymentAccountName: accntname,
                PaymentAmount: pdAmount,
                // ...(pdPaymentType == "BN" ? {
                ReferenceNo: pdChequeNum
                // } : {}),

            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, obj2]);
            }
            console.log("this is payment data ", paymentData);
            setPaymentData(paymentdata);
            setPdPaymentType("");
            setPaymentLineNo(paymentLineNo + 1);
            setPdAmount("");
            setPdAccount(null);
            //setChequeValue(null);
            //setChequeLocation("");
            //setChequeSrno("");
            setCounter(counter + 1);
            var amt = 0;
            for (var i = 0; i < paymentdata.length; i++) {
                amt += paymentdata[i].tp_amount * 1;
            }
            setPdAmount(1 * pTotalAmount - amt);
        }
    }

    const deleteProduct = (value) => {
        const itemToDelete = productList.find(item => item.uniquekey === value);

        if (itemToDelete) {
            const formattedItem = {
                Product: itemToDelete.prd_product,
                TabType: "Product",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLoc: siLocationValue,
                TType: 'SINV',
                Group: itemToDelete.inv_group,
                Ledger: itemToDelete.inv_account,
                Amount: itemToDelete.inv_amount,
                Taxable: itemToDelete.inv_taxable,
                SAC: itemToDelete.inv_sac,
                TDS: itemToDelete.inv_tds,
                SGST: itemToDelete.inv_sgst,
                CGST: itemToDelete.inv_cgst,
            }
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }
        }
        const productlist = Common.arrayRemoveItem(productList, "uniquekey", value);
        setProductList(productlist);
        calcInvoice(productlist);
        setCounter(counter - 1);
    }

    const deletePayment = (value, delny) => {
        const itemToDelete = paymentData.find(item => item.uniquekey === value);

        if (itemToDelete) {
            const formattedItem = {
                TabType: "Payment",
                ALineNo: tranAuditTrail.length + 1,
                AType: "D",
                TLine: paymentLineNo + 1,
                TLoc: siLocationValue,
                TType: 'SI',
                PaymentType: itemToDelete.tp_payment_type,
                PaymentGroupCode: itemToDelete.tp_group,
                PaymentLedgerCode: itemToDelete.tp_account,
                PaymentAccountName: itemToDelete.mvli_ledger_name,
                PaymentAmount: itemToDelete.tp_amount,
                ReferenceNo: itemToDelete.tp_cheque,
            };
            if (userRight.edit === "1" && scrMode === 'E') {
                setTranAuditTrail(prevtranAuditTrail => [...prevtranAuditTrail, formattedItem]);
            }

        }
        const paymentdata = Common.arrayRemoveItem(paymentData, "uniquekey", value);
        setPaymentData(paymentdata);
        var amt = 0;
        for (var i = 0; i < paymentdata.length; i++) {
            amt += paymentdata[i].tp_amount * 1;
        }
        setPdAmount(1 * pTotalAmount - amt);
        //}
        setCounter(counter - 1);
    }
    const handleManualNo = (value) => {
        setNManualno(value);
    }

    const saveData = () => {
        $(".loader").show();
        var totalinr = 0;
        console.log("totalinr", totalinr, "PAYMEN DATA", paymentData);
        for (var i = 0; i < paymentData.length; i++) {
            console.log("ME LOOP ME AAYA HU ")
            if (paymentData[i].tp_payment_type === "PB")
                totalinr -= paymentData[i].tp_amount * 1;
            else totalinr += paymentData[i].tp_amount * 1;
        }
        var msg = [], i = 0;
        if ((nParty === null) || (pTotalAmount != totalinr)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (nParty === null ? "Party." : '');
            msg[i++] = (pTotalAmount != totalinr ? "Total amount of payment must be equal to calculated INR." : "");
            setMsgBox(true);
            setMsgText(Common.buildMessageFromArray(msg));
        } else {
            const obj = {
                srno: hdrSrno,
                type: 'SI',
                siLocationValue: siLocationValue,
                siNewDate: Common.dateYMD(siNewDate),
                nBillDate: Common.dateYMD(siNewDate),
                nManualBill: nManualBill,
                nManualNo: nManualNo,
                nParty: nParty,
                nStateReceiver: nStateReceiver === "" ? 0 : nStateReceiver,
                nStateSupplier: nStateSupplier,
                nGstin: nGstin,
                nTaxClass: nTaxClass === "" ? 0 : nTaxClass,
                nShipTo: nShipTo,
                nPono: nPono,
                nPODate: Common.dateYMD(nPODate),
                nEwayBillNo: nEwayBillNo,
                nPaymentDueDate: Common.dateYMD(nPaymentDueDate),
                nReverseCharge: nReverseCharge,
                nRemark1: nRemark1,
                nRemark2: nRemark2,
                pForexTotal: pForexTotal,
                pSgst: pSgst,
                pIgst: pIgst,
                pUgst: pUgst,
                pCgst: pCgst,
                pOtherCharges: pOtherCharges,
                pRoundOff: pRoundOff,
                pTaxableVal: pTaxableVal === "" ? 0 : pTaxableVal,
                pTotalAmount: pTotalAmount,
                productList: productList,
                paymentData: paymentData,
            }
            Common.callApi(Common.apiTranSI, [sid, "savedata", JSON.stringify(obj), JSON.stringify(tranAuditTrail)], result => {
                let resp = JSON.parse(result);
                if (resp.msg === "MSG0000") {
                    $(".loader").hide();
                    setScrMode("");
                    setMsgBox(true);
                    setMsgText(Common.getMessage(resp.msg));
                    listSIdata();
                    setTranAuditTrail([]);
                } else {
                    $(".loader").hide();
                    setMsgBox(true);
                    setMsgText(Common.getMessage(resp.msg));
                }
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: hdrSrno,
                    type: 'SINV',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_SI'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }

    const fillViewForm = (hdrsrno, location, type, date) => {
        setHdrSrno(hdrsrno);
        setKey('INFO');
        setViewDate(date);
        // setRandomString("0");
        $(".loader").show();
        const obj = {
            srno: hdrsrno,
            siLocationValue: location,
            type: type
        }
        Common.callApi(Common.apiTranSI, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("Q");

                setNRemark1(resp.remark1);
                setNRemark2(resp.remark2);
                setNReverseCharge(resp.reversecharge);
                setNParty({ id: resp.acct_id, label: resp.acct_label });
                setNManualBill(resp.invoice);
                setNBillDate(resp.bill_date ? new Date(resp.bill_date) : null);
                setNPODate(resp.podate ? new Date(resp.podate) : null);
                setNPaymentDueDate(resp.payment_due_date ? new Date(resp.payment_due_date) : null);


                setPForexTotal(resp.forextotal);
                setPCgst(resp.cgst);
                setPSgst(resp.sgst);
                setSIgst(resp.igst);
                setPUgst(resp.ugst);
                setPRoundOff(resp.round_off);
                setPTotalAmount(resp.total_inr);
                setPOtherCharges(resp.other_charge);
                setPTaxableVal(resp.taxablevalue);
                setHdrDate(resp.date);

                setProductLineNo(resp.maxlineno * 1);
                setProductList(resp.productlist);
                setPaymentLineNo(resp.paymaxlineno * 1);
                setPaymentData(resp.paydetails);
                setHdrSrno(resp.srno);
                setNGstin(resp.gstin);
                setNTaxClass(resp.taxclass);/////. ye thik karna h 
                setNStateReceiver({ id: resp.receiver_state_id, label: resp.receiver_state_name });
                setNStateSupplier({ id: resp.supply_state_id, label: resp.supply_state_name });
                setNPono(resp.pono);
                setNEwayBillNo(resp.ewaybillno);
                setNShipTo({ id: resp.ship_acct_id, label: resp.ship_acct_label });
            }

        });
    }

    const listSIdata = () => {
        var msg = [], i = 0;
        $(".loader").show();
        if (siLocation === null || (radioType === 'S' && !siSrnoFrom) || (radioType === 'S' && !siSrnoTo) || (radioType === 'D' && !siFromDate)
            || (radioType === 'D' && !siToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (siLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !siSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !siSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !siFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !siToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                radioType: radioType,
                siLocation: siLocationValue,
                accountFilter: accountFilter,
                siNewDate: Common.dateYMD(siNewDate),
                siSrnoTo: siSrnoTo,
                siSrnoFrom: siSrnoFrom,
                siFromDate: Common.dateYMD(siFromDate),
                siToDate: Common.dateYMD(siToDate),
            }
            Common.callApi(Common.apiTranSI, [sid, "silist", JSON.stringify(obj)], result => {
                $(".loader").hide();
                setSIList(JSON.parse(result));
            });
        }
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: hdrSrno,
            type: 'SI',
            pageright: 'TRAN_SI'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');
                if (productList.length > 0) {
                    setDisableOtherCharge(false);
                }
            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'SI', trannumber: hdrSrno, location: siLocationValue, tranDate: Common.dateYMD(currDate) });
            }
        })


    }
    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "SI", trannumber: hdrSrno, trancategory: "T", location: siLocationValue });
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        setPRate("");
    }
    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setPAmount(v * 1 * pQuantity * 1);

    }
    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: siLocationValue, trannumber: hdrSrno, type: 'SI' });
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Sale Invoice</h1>
                    </Col>
                    <hr />
                </Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Form.Group>
                                    <Col style={{ display: "flex" }}>
                                        <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />&nbsp;
                                        <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                        {/* <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                <Form.Check checked={radioType === "I"} onChange={() => setRadioType("I")} type='radio' label="Individual" /> */}
                                    </Col>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={siLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLocationChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === "N" ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={siNewDate}
                                                    onChange={(date) => setSINewDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        : radioType === "S" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={siSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSISrnoFrom)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={siSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setSISrnoTo)} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === "D" || radioType === "I" || radioType === "C" ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={siFromDate}
                                                                onChange={(date) => setSIFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={siToDate}
                                                                onChange={(date) => setSIToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" && radioType !== "N" ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listSIdata()}>List</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.add === "1" && radioType === "N" ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Sr No </th>
                                            <th>Type</th>
                                            <th>Location</th>
                                            <th>Bill Date</th>
                                            <th>Account</th>
                                            <th>Total INR</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            siList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type, item.new_date)} className='pointer colorThemeBlue'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    </td>
                                                    <td>{item.hdr_srno}</td>
                                                    <td>{item.hdr_type}</td>
                                                    <td>{item.entity_name}</td>
                                                    <td>{item.hdr_bill_date}</td>
                                                    <td>{item.mvli_ledger_name}</td>
                                                    <td>{item.hdr_total_inr}</td>
                                                    <td>{item.hdr_date}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnEditRequest()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>&nbsp;
                                        </> : null
                                    }

                                    {
                                        (userRight.query === "1" && scrMode === "Q") ? <>
                                            {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => print("D")}>With Deno</Button>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => print("")}>Without Deno</Button>&nbsp; */}
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                        </> : null}

                                    {(userRight.edit === "1" && scrMode === 'Q') ? <>
                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                    </> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Serial Number : {hdrSrno}</Form.Label>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Location : <b>{hdrLocation} </b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Date : <b>{hdrDate}</b></span>
                                </Col>
                                <Col className='ppCharges'>
                                    <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                    &nbsp;
                                    <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                <Tab eventKey="INFO" title="Info">
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Bill Date<span className='colorThemeRed'>*</span></Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={siNewDate}
                                                    onChange={(date) => setNBillDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={true}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Manual Number</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nManualNo}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchManualTypePicklist(search, siLocationValue, 'SP')} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleManualNo}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col> */}
                                        <Col className='col-md-6 col-12'>
                                            <Form.Group>
                                                <Form.Label>Party/Bill To<span className='colorThemeRed'>*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nParty}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, siLocationValue)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handlePartyChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>State of Receiver</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nStateReceiver}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchStatePicklist(search, 1)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={(value) => setNStateReceiver(value)}
                                                    isDisabled={true}
                                                ></AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>State of Supplier</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nStateSupplier}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchStatePicklist(search, 1)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={(value) => setNStateSupplier(value)}
                                                    isDisabled={true}
                                                ></AsyncSelect>

                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>GSTIN</Form.Label>
                                                <Form.Control type="text" maxLength={30} placeholder='GSTIN' disabled={true} value={nGstin} onChange={e => setNGstin(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Tax Classification</Form.Label>
                                                <Form.Select
                                                    value={nTaxClass}
                                                    onChange={e => setNTaxClass(e.target.value)}
                                                    disabled={true}
                                                >
                                                    {taxClassOPtions.map(option => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>


                                        </Col>
                                        <Col className='col-md-6 col-12'>
                                            <Form.Group>
                                                <Form.Label>Ship To</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nShipTo}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, siLocationValue)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleShipChange}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>PO No.</Form.Label>
                                                <Form.Control type="text" maxLength={30} placeholder='PO No.'
                                                    value={nPono} onChange={e => setNPono(e.target.value)}
                                                    disabled={scrMode === "Q"} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>PO Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={nPODate}
                                                    onChange={(date) => setNPODate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={scrMode === "Q"}
                                                    maxDate={new Date()}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label> E-way Bill No</Form.Label>
                                                <Form.Control type="text" maxLength={20} placeholder='E-wayBillNo' value={nEwayBillNo}
                                                    onChange={e => setNEwayBillNo(e.target.value)}
                                                    disabled={scrMode === "Q"} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Payment Due Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={nPaymentDueDate}
                                                    onChange={(date) => setNPaymentDueDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={scrMode === "Q"}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("DETAILS")}>Product-&gt;</Button>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="DETAILS" title="Details">
                                    <Row>
                                        <Col>
                                            <h4>Product Info</h4>
                                        </Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null :
                                            <>
                                                <Row>
                                                    <Col className='col-md-6 col-12'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className='colorThemeRed'>*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={siParty}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, siLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleProductAccountChange}
                                                                isDisabled={scrMode === "Q"}
                                                                ref={productRef}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={9}
                                                                onChange={e => handleQuantityChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                                onChange={e => handleRateChange(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Amount<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control placeholder='Amount' type='text' maxLength={10} disabled={true} value={pAmount} onChange={e => Common.validateDecValue(e.target.value, setPAmount)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>GST Taxable Value(INR)<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control placeholder='Amount' type='text' maxLength={10} value={pTaxable} onChange={e => Common.validateDecValue(e.target.value, setPTaxable)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>SGST %<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control placeholder='%' type='text' maxLength={4} value={pFSgst} onChange={e => Common.validateDecValue(e.target.value, setPFSgst)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>CGST %<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control placeholder='%' type='text' maxLength={4} value={pFCgst} onChange={e => Common.validateDecValue(e.target.value, setPFCgst)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>SAC<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control placeholder='SAC' type='text' maxLength={10} value={pSac} onChange={e => Common.validateDecValue(e.target.value, setPSac)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>TDS</Form.Label>
                                                            <Form.Control placeholder='%' type='text' maxLength={10} value={pTds} onChange={e => Common.validateDecValue(e.target.value, setPTds)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>TDS 1</Form.Label>
                                                            <Form.Control placeholder='%' type='text' maxLength={10} value={pTds1} onChange={e => Common.validateDecValue(e.target.value, setPTds1)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Is Service<span className="text-danger">*</span></Form.Label>
                                                            <Form.Select value={pservice} onChange={e => setPService(e.target.value)}
                                                                required>
                                                                <option value="Y">YES</option>
                                                                <option value="N">No</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Narration</Form.Label>
                                                            <Form.Control
                                                                value={narration}
                                                                maxLength={500}
                                                                as="textarea"
                                                                placeholder="Leave a comment here"
                                                                style={{ height: '100px' }}
                                                                onChange={(e) => setNarration(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>&nbsp;
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                                        &nbsp;
                                                        <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                            </>
                                    }
                                    <Row>
                                        <Table responsive bordered striped>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Account</th>
                                                    <th>Group</th>
                                                    <th>Amount</th>
                                                    <th>Taxable Value</th>
                                                    <th>SGST</th>
                                                    <th>CGST</th>
                                                    <th>SAC</th>
                                                    <th>TDS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productList.map((item) => (
                                                        <tr key={item.inv_line_no}>
                                                            <td>
                                                                {
                                                                    scrMode === "Q" || item.delny === "N" ? null :
                                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                }
                                                            </td>
                                                            <td>{item.inv_account}</td>
                                                            <td>{item.inv_group}</td>
                                                            <td>{item.inv_amount}</td>
                                                            <td>{item.inv_taxable}</td>
                                                            <td>{item.inv_sgst}</td>
                                                            <td>{item.inv_cgst}</td>
                                                            <td>{item.inv_sac}</td>
                                                            <td>{item.inv_tds}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="PAYMENT" title="Payments">
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Taxable Value: {pTaxableVal}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>SGST: {pSgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>IGST: {pIgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>CGST : {pCgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>UGST : {pUgst}</Form.Label>
                                        </Col>
                                        <Col className="col-md-2 col-4">
                                            <Form.Label>Round Off: {pRoundOff}</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-3 col-6" style={{ display: "flex", alignItems: "center" }}>
                                            <span>OtherCharges: &nbsp;</span>
                                            <span>
                                                <Form.Control size='sm' placeholder='Other Charges' type='text'
                                                    maxLength={5}
                                                    value={pOtherCharges}
                                                    onChange={e => otherChargeChange(e.target.value)}
                                                    disabled={disableOtherCharge || scrMode === "Q"}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <h4>Payment Details</h4>
                                        </Col>
                                    </Row>
                                    {
                                        scrMode === "Q" ? null
                                            :
                                            <>
                                                <Row>
                                                    {/* <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Payment Type</Form.Label>
                                                            <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="CH">Cash</option>
                                                                <option value="BN">Bank</option>
                                                                <option value="PB">Payables</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pdAccount}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, siLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleAccountValue}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Payment Type</Form.Label>
                                                            <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="CH">Cash</option>
                                                                <option value="BN">Bank</option>
                                                                <option value="RC">Recievables</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pdAccount}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, siLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleAccountValue}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        pdPaymentType !== "" ?
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Amount</Form.Label>
                                                                    <Form.Control placeholder='Amount' value={pdAmount} onChange={e => Common.validateDecValue(e.target.value, setPdAmount)} />
                                                                </Form.Group>
                                                            </Col> : null
                                                    }
                                                </Row>
                                                {
                                                    pdPaymentType === "BN" ?
                                                        <Row>
                                                            <Col className="col-md-3 col-6">
                                                                <Form.Group>
                                                                    <Form.Label>Reference No</Form.Label>
                                                                    <Form.Control placeholder='Reference No' maxLength={20} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        : null
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addPayment()}>Add</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Table responsive striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Type</th>
                                                    <th>Group</th>
                                                    <th>Account</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th>Refernce No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    paymentData.map((item, index) => (
                                                        <tr key={item.uniquekey}>
                                                            <td>
                                                                {
                                                                    scrMode !== "Q" ?
                                                                        <span className='colorThemeBlue pointer' onClick={() => deletePayment(item.uniquekey, item.delny)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span> : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.tp_payment_type === "BN"
                                                                        ? "Bank" : item.tp_payment_type === "CQ"
                                                                            ? "Cheque" : item.tp_payment_type === "CH"
                                                                                ? "Cash" : item.tp_payment_type === "RC"
                                                                                    ? "Reveivables" : item.tp_payment_type === "PB"
                                                                                        ? "Payables" : item.tp_payment_type === "AP"
                                                                                            ? "Advance Paid" : item.tp_payment_type === "AR"
                                                                                                ? "Advance Received" : ""
                                                                }
                                                            </td>
                                                            <td>{item.tp_group}</td>
                                                            <td>{item.tp_account}</td>
                                                            <td>{item.mvli_ledger_name}</td>
                                                            <td>{item.tp_amount}</td>
                                                            <td>{item.tp_cheque}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>

                                </Tab>
                            </Tabs>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            <span className="corner-span">{counter}</span>
            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />
        </>
    )
}

export default Tran_si