import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ActivityLog from '../ActivityLog';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';


function Master_division() {

    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [divisionData, setDivisionData] = useState([]);
    const [userRight, setUserRight] = useState([]);

    const [srNo, setSrNo] = useState(0);
    const [division, setDivision] = useState("");
    const [status, setStatus] = useState(1);

    const [remark, setRemark] = useState("");

    const [showAddNewBtn, setShowAddNewBtn] = useState(true);
    const [showFormBtn, setShowFormBtn] = useState(false);

    const [formMode, setFormMode] = useState('Q'); // Q A E 

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [showActivityLog, setShowActivityLog] = useState(false);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [tranAuditTrail, setTranAuditTrail] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'DIVISION'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiDivision, [sid, 'getList'], (result) => {
                let resp = JSON.parse(result);
                setDivisionData(resp);
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const addNew = () => {
        setSrNo(0);
        // setActivityLog([]);
        setDivision("");
        setStatus(1);
        setFormMode('A');
        setShowAddNewBtn(false);
        setShowFormBtn(true);
    }

    const saveData = () => {
        if (division === "") {
            setMsgText("Please fill Division!");
            setMsgBox(true);
        } else if (formMode === 'E' && remark === '') {
            setMsgText('Please fill Remark!');
            setMsgBox(true);
        } else {
            var obj = {
                srno:srNo,
                division: division,
                status: status,
                branch: 0,
                activitytype: srNo * 1 > 0 ? 'MODIFY' : 'CREATE',
                trancategory: 'M',
                trantype: 'MDVSN',
                trannumber: srNo,
                subtranno: 0,
                remark: remark
            };
            var obj2 = {
                TabType: "Edit",
                ALineNo: tranAuditTrail.length + 1,
                AType: "A",
                TType: 'MDVSN',
                Division: division,
                Status: status,
                remark: remark
            }
            var tran_audit_trail = Common.arrayAddItem(tranAuditTrail, obj2);
            setTranAuditTrail(tran_audit_trail);
            Common.callApi(Common.apiDivision, [sid, 'savelist', srNo, JSON.stringify(obj), JSON.stringify(tran_audit_trail)], (result) => {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setShowFormBtn(false);
                    setShowAddNewBtn(true);
                    setRemark("");

                    Common.callApi(Common.apiDivision, [sid, 'getList'], (result) => {
                        let resp = JSON.parse(result);
                        setDivisionData(resp);
                    });
                    setTranAuditTrail([]);
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }
    }

    const cancelConfirm = () => {
        if (formMode === "A" || formMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setShowFormBtn(false);
        setShowAddNewBtn(true);
        setShowActivityLog(false);
    }

    // const cancelData = () => {
    //     setShowFormBtn(false);
    //     setShowAddNewBtn(true);
    // }

    const fillEditForm = (id) => {
        setFormMode('Q');
        setShowActivityLog(false);
        // setActivityLog([]);
        Common.callApi(Common.apiDivision, [sid, 'editList', id], (result) => {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setDivision(resp.division);
            setStatus(resp.status);

            setShowFormBtn(true);
            setShowAddNewBtn(false);
        });
    }

    const editData = () => {
        setFormMode('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'DIVISION'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }


    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MDVSN", trannumber: srNo, trancategory: "M" });
    }

    return (
        <>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h1>Master Division </h1>
                    </Col>
                </Row>
                {
                    showAddNewBtn ?
                        <Row>
                            <Col>
                                {userRight.add === "1" ?
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={addNew}> Add New</Button>
                                    : null}
                                {
                                    userRight.query === "1" ?
                                        <>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                        </>
                                        : null
                                }
                            </Col>
                        </Row>
                        : null

                }
                <Row><Col>&nbsp;</Col></Row>
                {
                    showFormBtn ?
                        <>
                            <Row>
                                <Col className="divContentCenter">
                                    <div className="maxFormWidth">
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Serial Number</Form.Label>
                                                        <Form.Control type="text" placeholder="Serial Number" value={srNo}
                                                            disabled />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Division<span className="mandatory text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" placeholder="Division" maxLength={50}
                                                            value={division}
                                                            onChange={(e) => Common.validateAlpValue(e.target.value, setDivision)}
                                                            autoComplete="off"
                                                            disabled={formMode === 'Q'}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Status</Form.Label>
                                                        <Form.Select value={status} onChange={e => setStatus(e.target.value)}
                                                            disabled={formMode === 'Q'}
                                                            required>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {
                                                formMode === 'E' ?
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Remark<span className="mandatory text-danger">*</span></Form.Label>
                                                                    <Form.Control type="text" placeholder="Remark" maxLength={50}
                                                                        value={remark}
                                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setRemark)}
                                                                        autoComplete="off"
                                                                        required />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>&nbsp;</Col>
                                                            <Col>&nbsp;</Col>
                                                            <Col>&nbsp;</Col>
                                                        </Row>
                                                    </> :
                                                    null
                                            }
                                            <Row><Col>&nbsp;</Col></Row>
                                            <Row>
                                                <Col>
                                                    {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                        saveData()}>Save</Button>&nbsp;
                                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                        cancelData()}>Cancel</Button> */}

                                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                        cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                                                    {
                                                        (userRight.edit === "1" && formMode === 'Q') ? <>
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                editData()}>Edit</Button>&nbsp;
                                                        </> : null
                                                    }
                                                    {
                                                        (userRight.edit === "1" && formMode === 'E') || (userRight.add === "1" && formMode === 'A') ? <>
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                saveData()}>Save</Button>&nbsp;
                                                        </> : null
                                                    }
                                                    {
                                                        (userRight.query === "1" && (formMode === 'E' || formMode === 'Q')) ? <>
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                btnActivityLog()}>Activity Log</Button>&nbsp;
                                                        </> : null
                                                    }
                                                </Col>
                                            </Row>
                                            <Row><Col>&nbsp;</Col></Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    showActivityLog &&
                                    <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
                                }
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th className="textRight">Sr No</th>
                                                <th>Division</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {divisionData.map((res) =>
                                                <tr key={res.md_srno}>
                                                    {userRight.edit === "1" ?
                                                        <td className="textCenter" >
                                                            <span className="handCursor" onClick={() => fillEditForm(res.md_srno)} >
                                                                <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                            </span>
                                                        </td>
                                                        : null
                                                    }
                                                    <td className="textRight">{res.md_srno}</td>
                                                    <td>{res.md_division}</td>
                                                    <td>{res.md_status == 1 ? "ACTIVE" : "INACTIVE"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    )
}

export default Master_division;
